<template>
  <div class="invite-m">
    <div v-if="mainShow === 1" class="add-team">
      <div class="title-box">
        <div class="imgs">
          <img
            style="width: 40px; height: 36.53px"
            src="@/assets/imgs/add-team.png"
            alt=""
          />
        </div>
        <div class="text">加入团队</div>
      </div>
      <div class="main-boxAdd">
        <div class="portrait">
          <img
            style="width: 28px; height: 28px; border-radius: 100px"
            v-if="avatar"
            :src="avatar"
            alt=""
          />
          <img
            v-else
            style="width: 28px; height: 28px"
            src="@/assets/imgs/portrait-m.png"
            alt=""
          />
        </div>
        <div class="number">{{ nickName }}</div>
        <div class="text">邀请成员加入</div>
      </div>
      <div class="company">{{ teamName }}</div>
      <div class="main">
        <div class="main-box">
          <div v-if="isShow == 1" class="login">
            <el-form
              class="login-form"
              autocomplete="on"
              :model="loginForm1"
              ref="loginFormRef1"
              label-position="left"
            >
              <div>
                <el-form-item
                  prop="phone"
                  style="position: relative; margin-bottom: 30px"
                >
                  <div class="icon-style"></div>
                  <el-input
                    name="phone"
                    type="text"
                    v-model="loginForm1.phone"
                    autocomplete="on"
                    placeholder="请输入手机号"
                  />
                </el-form-item>
                <div
                  v-show="isShow3 == true"
                  style="
                    font-size: 10px;
                    color: #f56c6c;
                    margin-top: -20px;
                    margin-bottom: 6px;
                  "
                >
                  手机号格式不正确
                </div>
                <el-form-item prop="codeSms" style="position: relative">
                  <div class="icon-style"></div>
                  <el-input
                    name="codeSms"
                    @keyup.enter.native="handleLogin1"
                    v-model="loginForm1.codeSms"
                    autocomplete="on"
                    placeholder="请输入短信验证码"
                  ></el-input>
                  <span class="show-pwd">
                    <span
                      :class="grey ? 'greyStart' : 'greyEnd'"
                      style="font-weight: 600; font-size: 14px"
                      :disabled="isSend"
                      @click="sendBtn01(1)"
                      >{{ codeName }}</span
                    >
                  </span>
                </el-form-item>
              </div>
              <div class="title">登录账户即加入该团队</div>
              <div class="login-btn" @click="handleLogin1">登录</div>
            </el-form>
          </div>
          <div v-if="isShow == 2" class="login2">
            <!-- 账号密码登录 -->
            <el-form
              class="login-form"
              autocomplete="on"
              :model="loginForm"
              ref="loginFormRef"
              label-position="left"
            >
              <div>
                <el-form-item
                  prop="username"
                  style="position: relative; margin-bottom: 30px"
                >
                  <div class="icon-style">
                    <!-- <img src="../../assets/imgs/shoujihao.png" alt=""> -->
                  </div>
                  <el-input
                    name="username"
                    type="text"
                    v-model="loginForm.username"
                    autocomplete="on"
                    placeholder="请输入账号"
                  />
                </el-form-item>
                <el-form-item prop="password" style="position: relative">
                  <div class="icon-style">
                    <!-- <img src="../../assets/imgs/mima.png" alt=""> -->
                  </div>
                  <el-input
                    name="password"
                    :type="pwdType"
                    @keyup.enter.native="handleLogin"
                    v-model="loginForm.password"
                    autocomplete="on"
                    placeholder="请输入登录密码"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="title">登录账户即加入该团队</div>
              <div class="login-btn" @click="handleLogin">登录</div>
            </el-form>
          </div>
          <div v-if="isShow == 1" @click="switchBtn(1)" class="tab-text">
            账号密码登录＞
          </div>
          <div v-else @click="switchBtn(2)" class="tab-text">短信验证码登录＞</div>
        </div>
      </div>
    </div>
    <div v-if="mainShow === 2">
      <div class="main-box2">
        <div class="box2">
          <div class="top">
            <img src="@/assets/imgs/addTeam-icon.png" alt="" />
          </div>
          <div class="middle">恭喜您已加入</div>
          <div class="company">{{ teamName }}</div>
        </div>
        <div class="addText">
          <img src="@/assets/imgs/add-text.png" alt="" />
        </div>
        <div class="downloadApp">
          <div @click="downloadApp" class="btn">
            <img src="@/assets/imgs/android2.png" alt="" />
            <div class="text">下载Nvwa智播</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mainShow === 3">
      <div class="main-box3">
        <div class="box3">
          <img src="@/assets/imgs/invite-not.png" alt="" />
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="addTeamImg">
        <img style="width: 204px; height: 30px" src="@/assets/imgs/addTeam.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { send, login, getNext, getNextSmsCode } from "@/api/login";
import { saveTeamUser, getInviteTeamById, getInviteUserById } from "@/api/team";
import Base64 from "@/utils/base64.js";
import request from "@/utils/request";
import wxlogin from "vue-wxlogin";
import Cookies from "js-cookie";
import md5 from "js-md5";
export default {
  name: "HomeView",
  components: { wxlogin },
  data() {
    return {
      checked: false,
      topStatus: 2,
      teamId: "",
      dialogVisible: true,
      isShow: 1,
      isShow3: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginForm1: {
        phone: "",
        codeSms: "",
      },
      grey: true,
      isSend: false,
      checked: false,
      isShow1: true,
      codeName: "发送验证码",
      isShow5: false,
      pwdType: "password",
      id: "",
      totalTime: "60",
      timer: "",
      grey: true,
      forgetIshow: 1,
      title: "登录",
      loginForm2: {
        findPhone: "",
        findCodeSms: "",
        findPassword: "",
        findPassword1: "",
      },
      loginForm3: {
        phone: "",
        codeSms: "",
      },
      redirect_uri: encodeURIComponent(
        "https://hello.yohelo.com/api/hello-auth/api/ucenter/wx/callback"
      ),
      href:
        "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg==",
      state: "pc",
      code: localStorage.getItem("code"),
      openid: localStorage.getItem("openid"),
      authCode: "",
      nickName: "",
      teamName: "",
      userId: "",
      queryList: [],
      avatar: "",
      mainShow: 1,
      invalid: false,
    };
  },
  computed: {},
  created() {
    try {
      this.userId = Base64.decode(this.$route.query.userId);
      this.teamId = Base64.decode(this.$route.query.teamId);
    } catch (error) {
      console.log(error,'errorerrorerrorerror');
    }
    
    console.log(this.userId,this.teamId,'sdasdasdadasdasd');
    if (this.code == "false") {
      this.dialogVisible = true;
      this.forgetIshow = 4;
      this.title = "绑定手机号";
    }
    this.getInviteTeamByIdApi();
    this.getInviteUserByIdApi();
  },
  mounted() {
    // if (localStorage.getItem("nickName")) {
    //   this.mainShow = 2;
    //   this.addTeamHandel();
    // } else {
    //   this.mainShow = 1;
    // }
  },
  methods: {
    getInviteTeamByIdApi() {
      let params = {
        teamId: this.teamId,
        // teamId: "17703497175001",
      };
      getInviteTeamById(params)
        .then((res) => {
          if (Object.keys(res.data.data).length === 0) {
            console.log("空对象");
            this.mainShow = 3;
            this.invalid = true;
          } else {
            this.teamName = res.data.data.teamName;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code === 400) {
            this.mainShow = 3;
          }
        });
    },
    getInviteUserByIdApi() {
      let params = {
        userId: this.userId,
        // userId: "17703497175872512001",
      };
      getInviteUserById(params)
        .then((res) => {
          if (Object.keys(res.data.data).length === 0) {
            console.log("空对象11111111111111111111");
            this.mainShow = 3;
            this.invalid = true;
          } else {
            this.avatar = res.data.data.avatar;
            this.nickName = res.data.data.name;
          }
        })
        .catch((error) => {
          console.log(error.response.data.code, "12121212");
          if (error.response.data.code === 400) {
            this.mainShow = 3;
          }
        });
    },
    switchBtn(val) {
      if (val == 1) {
        this.isShow = 2;
      }
      if (val == 2) {
        this.isShow = 1;
      }
    },
    refresh() {
      // this.setWxerwma()
    },
    setWxerwma() {},
    forget() {
      this.forgetIshow = 2;
      this.title = "找回密码";
    },
    weixinEvn() {
      this.forgetIshow = 3;
      this.title = "微信登录";
    },
    dengluEvn() {
      this.forgetIshow = 1;
      this.title = "登录";
    },
    async handleLogin3() {
      const res = await getNextSmsCode(
        this.loginForm3.phone,
        this.id,
        this.loginForm3.codeSms
      );
      if (res.data.code == 200) {
        this.authCode = res.data.msg;
        let params = {
          tenantId: "000000",
          openid: this.openid,
          authCode: this.authCode,
          grant_type: "wechat",
          scope: "all",
        };
        login(params)
          .then((res) => {
            localStorage.removeItem("code");
            this.dialogVisible = false;
            this.forgetIshow = 0;
            this.title = "";
            // PSPS邀请团队成员

            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("userId", res.data.user_id);
            localStorage.setItem("nickName", res.data.nick_name);
            localStorage.setItem("avatar", res.data.avatar);
            request({
              url: `/hello-user/team/saveTeamUser?` + "teamId" + "=" + this.teamId,
              method: "POST",
              headers: {
                "Tenant-Id": "000000",
                Authorization: "Basic aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=",
                "Blade-Auth": `bearer ${res.data.access_token}`,
              },
            }).then((res) => {
              // this.$router.push("/control").catch((error) => error);
              this.mainShow = 2;
              if (this.invalid) {
                this.mainShow = 3;
              } else {
                this.mainShow = 2;
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "登录失败",
            });
          });
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async handleLogin2() {
      let passwordData = md5(this.loginForm2.findPassword);
      let passwordData1 = md5(this.loginForm2.findPassword1);
      let a = {
        phone: this.loginForm2.findPhone,
        id: this.id,
        value: this.loginForm2.findCodeSms,
        newPassword: passwordData,
        newPassword1: passwordData1,
      };

      const res = await getNext(
        this.loginForm2.findPhone,
        this.id,
        this.loginForm2.findCodeSms,
        passwordData,
        passwordData1
      );
      if (res.data.code == 200) {
        this.$message({
          type: "success",
          message: "重置成功",
        });
        this.forgetIshow = 1;
        this.title = "登录";
        this.isShow = 2;
        // this.$router.push({ path: '/password', query: { msg: res.data.msg } }).catch(error => error);
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async sendBtn01(val) {
      this.isShow3 = false;
      if (this.isSend) return;
      this.isSend = true;
      this.codeName = this.totalTime + "s后重新发送";
      this.grey = false;
      this.timer = setInterval(() => {
        this.totalTime--;
        this.codeName = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          clearInterval(this.timer);
          this.grey = true;
          this.codeName = "重新获取验证码";
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
      if (val == 1) {
        const res = await send(this.loginForm1.phone);
        this.id = res.data.data.id;
      } else if (val == 2) {
        const res = await send(this.loginForm2.findPhone);
        this.id = res.data.data.id;
      } else if (val == 3) {
        const res = await send(this.loginForm3.phone);
        this.id = res.data.data.id;
      }
    },
    handleLogin() {
      let params = {
        tenantId: "000000",
        password: md5(this.loginForm.password),
        username: this.loginForm.username,
        grant_type: "password",
        scope: "all",
      };

      login(params)
        .then((res) => {
          // PSPS邀请团队成员
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          request({
            url: `/hello-user/team/saveTeamUser?` + "teamId" + "=" + this.teamId,
            method: "POST",
            headers: {
              "Tenant-Id": "000000",
              Authorization: "Basic aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=",
              "Blade-Auth": `bearer ${res.data.access_token}`,
            },
          }).then((res) => {
            // this.$router.push("/control").catch((error) => error);
            this.mainShow = 2;
          });
        })
        .catch(() => {});
    },
    handleLogin1() {
      let params = {
        tenantId: "000000",
        phone: this.loginForm1.phone,
        codeSms: this.loginForm1.codeSms,
        grant_type: "sms_code",
        scope: "all",
        id: this.id,
      };
      login(params)
        .then((res) => {
          //PSPS邀请团队成员
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          request({
            url: `/hello-user/team/saveTeamUser?` + "teamId" + "=" + this.teamId,
            method: "POST",
            headers: {
              "Tenant-Id": "000000",
              Authorization: "Basic aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=",
              "Blade-Auth": `bearer ${res.data.access_token}`,
            },
          }).then((res) => {
            // this.$router.push("/control").catch((error) => error);
            this.mainShow = 2;
          });
        })
        .catch(() => {});
    },
    addTeamHandel() {
      saveTeamUser(this.teamId)
        .then((res) => {
          // this.$router.push("/control").catch((error) => error);
          // this.mainShow = 2;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pdfFile() {},
    handleClose(done) {
      done();
      this.forgetIshow = 1;
      this.title = "登录";
    },
    downloadApp() {
      this.$router.push("/help/androidApp").catch((error) => error);
    },
  },
};
</script>
<style lang="less" scoped>
.invite-m {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  .add-team {
    .title-box {
      margin-top: 42px;
      display: flex;
      justify-content: center;
      .imgs {
        width: 40px;
        height: 36.53px;
        margin-right: 10px;
        margin-top: 2px;
      }
      .text {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 34px;
        color: #8b5ef5;
        line-height: 43px;
      }
    }
    .main-boxAdd {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .portrait {
        width: 28px;
        height: 28px;
      }
      .number {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 18px;
        color: #e3882d;
        margin: 0 5px;
      }
      .text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #8a8a8a;
      }
    }
    .company {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: bold;
      font-size: 28px;
      color: #8b5ef5;
      line-height: 60px;
      text-align: center;
      margin: 0 20px;
      /* 一行文字超出点点点 */
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .main {
      display: flex;
      margin-top: 30px;
      justify-content: center;
      .main-box {
        .login {
          display: flex;
          justify-content: center;
          .login-form {
            width: 320px;
            margin-top: 40px;
            .icon-style {
              position: absolute;
              z-index: 99;
              top: 10px;
              left: 10px;
            }
            ::v-deep .el-input__inner {
              border-top: none;
              border-left: none;
              border-right: none;
              border-radius: 0;
            }
          }
          .title {
            text-align: center;
            margin-top: 38px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #5c92fe;
          }
          .login-btn {
            margin-top: 47px;
            width: 312px;
            height: 52px;
            background: #8b5ef5;
            box-shadow: 0px 0px 4px 1px rgba(15, 12, 84, 0.2);
            border-radius: 6px 6px 6px 6px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            line-height: 45px;
          }
        }
        .login2 {
          display: flex;
          justify-content: center;
          .login-form {
            width: 320px;
            margin-top: 40px;
            .icon-style {
              position: absolute;
              z-index: 99;
              top: 10px;
              left: 10px;
            }
            ::v-deep .el-input__inner {
              border-top: none;
              border-left: none;
              border-right: none;
              border-radius: 0;
            }
          }
          .title {
            text-align: center;
            margin-top: 38px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #5c92fe;
          }
          .login-btn {
            margin-top: 47px;
            width: 312px;
            height: 52px;
            background: #8b5ef5;
            box-shadow: 0px 0px 4px 1px rgba(15, 12, 84, 0.2);
            border-radius: 6px 6px 6px 6px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            line-height: 45px;
          }
        }
        .tab-text {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 600;
          font-size: 14px;
          color: #8b5ef5;
          line-height: 24px;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
  .main-box2 {
    width: 100%;
    // height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    .box2 {
      width: 100%;
      height: 340px;
      border-radius: 16px;
      margin-top: 70px;
      background-image: url("@/assets/imgs/addTeamBg.png");
      background-size: 100% 100%;
      position: relative;
      .top {
        width: 100%;
        height: 106px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: -27px;
        img {
          width: 107px;
          height: 106px;
        }
      }
      .middle {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 20px;
        color: #505052;
        line-height: 25px;
        text-align: center;
        margin-top: 190px;
      }
      .company {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 24px;
        color: #e3882d;
        line-height: 60px;
        text-align: center;
        margin: 0 20px;
        /* 一行文字超出点点点 */
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .addText {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: center;
      margin-top: 100px;
      img {
        height: 20px;
      }
    }
    .downloadApp {
      width: 100%;
      height: 52px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .btn {
        width: 343px;
        height: 52px;
        background: linear-gradient(108deg, #8b5ef5 0%, #6c2dff 100%);
        box-shadow: 0px 3px 6px 1px rgba(15, 12, 84, 0.3);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 15.42px;
          height: 18px;
          margin-right: 8px;
        }
        .text {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }
  .main-box3 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 46px;
    .box3 {
      img {
        width: 343px;
        height: 343px;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    .addTeamImg {
      width: 204px;
      height: 30px;
      position: fixed;
      bottom: 20px;
    }
  }
}
</style>
