<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent></headComponent>
    <div class="mainStyle">
      <div class="img-box">
        <img class="images" src="@/assets/imgs/flow-bg.png" alt="" />
      </div>
      <div class="mainStyle-box">
        <div class="left">
          <div>
            <img src="@/assets/imgs/about-left.png" alt="" />
          </div>
        </div>
        <div class="right">
          <img src="@/assets/imgs/about-right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="about-one">
      <div class="one-main">
        <div class="title">Hello 数字虚拟化AIGC平台</div>
        <div class="main">
          <div class="left">
            <div class="laber">打造数字虚拟化劳动力，降本增效</div>
            <div class="conter">
              Hello（hello.yohelo.com）创立于2023年，是河络科技旗下帮助个人、企业引流、获流、锁流的数字虚拟化AIGC端到端服务平台。随着流量碎片化和AI时代的到来，Hello数字虚拟化全面跟进数字社会发展，为用户提供覆盖全发展周期数字虚拟化服务，帮助企业从引流获客、品牌声量提升、客户服务、自媒体经营建设等全维度为企业营销发展赋能，为企业数字营销打造数字虚拟化劳动力，降本增效！
            </div>
          </div>
          <div class="right">
            <img src="@/assets/imgs/about-one-right.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="about-two">
      <div class="two-main">
        <div class="title">丰富应用场景、助力业务成功</div>
        <div class="item-box">
          <div class="item">
            <div class="img">
              <img src="@/assets/imgs/about-2-1.png" alt="" />
            </div>
            <div class="text">短视频&直播</div>
          </div>
          <div class="item">
            <div class="img">
              <img src="@/assets/imgs/about-2-2.png" alt="" />
            </div>
            <div class="text">交互式数字人</div>
          </div>
          <div class="item">
            <div class="img">
              <img src="@/assets/imgs/about-2-3.png" alt="" />
            </div>
            <div class="text">文本配音</div>
          </div>
          <div class="item">
            <div class="img">
              <img src="@/assets/imgs/about-2-4.png" alt="" />
            </div>
            <div class="text">AI弹幕游戏</div>
          </div>
        </div>
      </div>
    </div>

    <footerComponent></footerComponent>
  </div>
</template>

<script>
import headComponent from "@/views/component/headComponent.vue";
import footerComponent from "@/views/component/footerComponent.vue";
export default {
  name: "HomeView",
  components: { headComponent, footerComponent },
  data() {
    return {};
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
  .mainStyle {
    margin-top: 64px;
    flex-grow: 1;
    min-width: 1200px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    .img-box {
      position: absolute;
      height: 100%;
      width: 100%;
      .images {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        object-fit: cover;
      }
    }
    .mainStyle-box {
      z-index: 1;
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-family: DingTalk JinBuTi, DingTalk JinBuTi;
        font-weight: 400;
        font-size: 58px;
        color: #243042;
      }
    }
  }

  .about-one {
    width: 100%;
    height: 658px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .one-main {
      width: 1200px;
      .title {
        width: 376px;
        height: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 30px;
        color: #8b5ef5;
        line-height: 13px;
        margin-top: 94px;
        margin-left: 66.5px;
        border-bottom: 1px solid #8b5ef5;
        position: relative;
        top: 1px;
      }
      .main {
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        .left {
          width: 507px;
          .laber {
            margin-left: 66.5px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 26px;
            color: #243042;
            line-height: 60px;
          }
          .conter {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 16px;
            color: #243042;
            line-height: 40px;
          }
        }
        .right {
          img {
            margin-left: 170px;
            margin-top: -30px;
          }
        }
      }
    }
  }

  .about-two {
    width: 100%;
    height: 470px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    background-image: url("@/assets/imgs/about-two-bg.png");
    background-size: 100% 100%;
    .two-main {
      width: 1200px;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 34px;
        color: #243042;
        line-height: 28px;
        text-align: center;
        margin-top: 45px;
      }
      .item-box {
        margin-top: 84px;
        display: flex;
        justify-content: space-between;
        .img {
        }
        .text {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 24px;
          color: #243042;
          line-height: 41px;
          text-align: center;
        }
      }
    }
  }
}
</style>
