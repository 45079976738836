<template>
  <div class="home">
    <headComponent :dataTop="topStatus"></headComponent>
    <div class="big-box">
      <div class="box">
        <div class="jrtd">
          <img src="@/assets/imgs/jrtd.png" alt="" />
        </div>
        <div class="info">
          <div class="picture">
            <img v-if="avatar" :src="avatar" alt="" />
            <img v-else src="@/assets/imgs/touxiang3.png" alt="" />
          </div>
          <div class="text">
            <span style="color: #8b5ef5">{{ nickName }}</span>
            <span>邀请你加入</span>
            <span style="color: #e3882d">{{ teamName }}</span>
          </div>
        </div>
        <div class="main">
          <div v-if="mainShow === 1" class="main-box">
            <div v-if="isShow == 1" @click="switchBtn(1)" class="tab-text">
              账号密码登录＞
            </div>
            <div v-else @click="switchBtn(2)" class="tab-text">短信验证码登录＞</div>
            <div v-if="isShow == 1" class="login">
              <el-form
                class="login-form"
                autocomplete="on"
                :model="loginForm1"
                ref="loginFormRef1"
                label-position="left"
              >
                <div>
                  <el-form-item prop="phone" style="position: relative">
                    <div class="icon-style"></div>
                    <el-input
                      name="phone"
                      type="text"
                      v-model="loginForm1.phone"
                      autocomplete="on"
                      placeholder="请输入手机号"
                    />
                  </el-form-item>
                  <div
                    v-show="isShow3 == true"
                    style="
                      font-size: 10px;
                      color: #f56c6c;
                      margin-top: -20px;
                      margin-bottom: 6px;
                    "
                  >
                    手机号格式不正确
                  </div>
                  <el-form-item prop="codeSms" style="position: relative">
                    <div class="icon-style"></div>
                    <el-input
                      name="codeSms"
                      @keyup.enter.native="handleLogin1"
                      v-model="loginForm1.codeSms"
                      autocomplete="on"
                      placeholder="请输入短信验证码"
                    ></el-input>
                    <span class="show-pwd">
                      <span
                        :class="grey ? 'greyStart' : 'greyEnd'"
                        style="font-size: 11px"
                        :disabled="isSend"
                        @click="sendBtn01(1)"
                        >{{ codeName }}</span
                      >
                    </span>
                  </el-form-item>
                </div>
                <div class="title">登录账户即加入该团队</div>
                <div class="login-btn">
                  登录
                </div>
              </el-form>
            </div>
            <div v-if="isShow == 2" class="login2">
              <!-- 账号密码登录 -->
              <el-form
                class="login-form"
                autocomplete="on"
                :model="loginForm"
                ref="loginFormRef"
                label-position="left"
              >
                <div>
                  <el-form-item prop="username" style="position: relative">
                    <div class="icon-style">
                      <!-- <img src="../../assets/imgs/shoujihao.png" alt=""> -->
                    </div>
                    <el-input
                      name="username"
                      type="text"
                      v-model="loginForm.username"
                      autocomplete="on"
                      placeholder="请输入账号"
                    />
                  </el-form-item>
                  <el-form-item prop="password" style="position: relative">
                    <div class="icon-style">
                      <!-- <img src="../../assets/imgs/mima.png" alt=""> -->
                    </div>
                    <el-input
                      name="password"
                      :type="pwdType"
                      @keyup.enter.native="handleLogin"
                      v-model="loginForm.password"
                      autocomplete="on"
                      placeholder="请输入登录密码"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="title">登录账户即加入该团队</div>
                <div class="login-btn">
                  登录
                </div>
              </el-form>
            </div>
          </div>
          <div v-else class="main-box2">
            <div class="checkbox">
              <el-checkbox v-model="checked">同意加入该团队</el-checkbox>
            </div>
            <div class="btn-box">
              <div class="login-btn" @click="addTeam">
                确认
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { send, login, getNext, getNextSmsCode } from "@/api/login";
import { saveTeamUser, getInviteTeamById, getInviteUserById } from "@/api/team";
import Base64 from "@/utils/base64.js";
import headComponent from "@/views/component/headComponent.vue";
import request from "@/utils/request";
import wxlogin from "vue-wxlogin";
import Cookies from "js-cookie";
import md5 from "js-md5";
export default {
  name: "HomeView",
  components: { wxlogin, headComponent },
  data() {
    return {
      checked: false,
      topStatus: 2,
      teamId: "",
      dialogVisible: true,
      isShow: 1,
      isShow3: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginForm1: {
        phone: "",
        codeSms: "",
      },
      grey: true,
      isSend: false,
      checked: false,
      isShow1: true,
      codeName: "获取验证码",
      isShow5: false,
      pwdType: "password",
      id: "",
      totalTime: "60",
      timer: "",
      grey: true,
      forgetIshow: 1,
      title: "登录",
      loginForm2: {
        findPhone: "",
        findCodeSms: "",
        findPassword: "",
        findPassword1: "",
      },
      loginForm3: {
        phone: "",
        codeSms: "",
      },
      redirect_uri: encodeURIComponent(
        "https://hello.yohelo.com/api/hello-auth/api/ucenter/wx/callback"
      ),
      href:
        "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg==",
      state: "pc",
      code: localStorage.getItem("code"),
      openid: localStorage.getItem("openid"),
      authCode: "",
      nickName: "",
      teamName: "",
      userId: "",
      queryList: [],
      avatar: "",
      mainShow: 1,
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem("nickName")) {
      this.mainShow = 2;
    } else {
      this.mainShow = 1;
    }
  },
  mounted() {
    this.userId = Base64.decode(this.$route.query.userId);
    this.teamId = Base64.decode(this.$route.query.teamId);

    if (this.code == "false") {
      this.dialogVisible = true;
      this.forgetIshow = 4;
      this.title = "绑定手机号";
    }
    this.getInviteTeamByIdApi();
    this.getInviteUserByIdApi();
  },
  methods: {
    getInviteTeamByIdApi() {
      let params = {
        teamId: this.teamId,
      };
      getInviteTeamById(params).then((res) => {
        console.log(res.data.data, "teamIdteamIdteamIdteamIdteamId>>>>>>>>>>>>>>>>>>");
        this.teamName = res.data.data.teamName;
      });
    },
    getInviteUserByIdApi(){
      let params = {
        userId: this.userId,
      };
      getInviteUserById(params).then((res) => {
        console.log(res.data.data, "nickNamenickNamenickNamenickName>>>>>>>>>>>>>>>>>>");
        this.avatar = res.data.data.avatar;
        this.nickName = res.data.data.name;
      });
    },
    switchBtn(val) {
      if (val == 1) {
        this.isShow = 2;
      }
      if (val == 2) {
        this.isShow = 1;
      }
    },
    refresh() {
      // this.setWxerwma()
    },
    setWxerwma() {},
    forget() {
      this.forgetIshow = 2;
      this.title = "找回密码";
    },
    weixinEvn() {
      this.forgetIshow = 3;
      this.title = "微信登录";
    },
    dengluEvn() {
      this.forgetIshow = 1;
      this.title = "登录";
    },
    async handleLogin3() {
      const res = await getNextSmsCode(
        this.loginForm3.phone,
        this.id,
        this.loginForm3.codeSms
      );
      if (res.data.code == 200) {
        this.authCode = res.data.msg;
        let params = {
          tenantId: "000000",
          openid: this.openid,
          authCode: this.authCode,
          grant_type: "wechat",
          scope: "all",
        };
        login(params)
          .then((res) => {
            localStorage.removeItem("code");
            this.dialogVisible = false;
            this.forgetIshow = 0;
            this.title = "";
            // PSPS邀请团队成员

            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("userId", res.data.user_id);
            localStorage.setItem("nickName", res.data.nick_name);
            localStorage.setItem("avatar", res.data.avatar);
            request({
              url: `/hello-user/team/saveTeamUser?` + "teamId" + "=" + this.teamId,
              method: "POST",
              headers: {
                "Tenant-Id": "000000",
                Authorization: "Basic aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=",
                "Blade-Auth": `bearer ${res.data.access_token}`,
              },
            }).then((res) => {
              this.$router.push("/control").catch((error) => error);
            });
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "登录失败",
            });
          });
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async handleLogin2() {
      let passwordData = md5(this.loginForm2.findPassword);
      let passwordData1 = md5(this.loginForm2.findPassword1);
      let a = {
        phone: this.loginForm2.findPhone,
        id: this.id,
        value: this.loginForm2.findCodeSms,
        newPassword: passwordData,
        newPassword1: passwordData1,
      };

      const res = await getNext(
        this.loginForm2.findPhone,
        this.id,
        this.loginForm2.findCodeSms,
        passwordData,
        passwordData1
      );
      if (res.data.code == 200) {
        this.$message({
          type: "success",
          message: "重置成功",
        });
        this.forgetIshow = 1;
        this.title = "登录";
        this.isShow = 2;
        // this.$router.push({ path: '/password', query: { msg: res.data.msg } }).catch(error => error);
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async sendBtn01(val) {
      this.isShow3 = false;
      if (this.isSend) return;
      this.isSend = true;
      this.codeName = this.totalTime + "s后重新发送";
      this.grey = false;
      this.timer = setInterval(() => {
        this.totalTime--;
        this.codeName = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          clearInterval(this.timer);
          this.grey = true;
          this.codeName = "重新获取验证码";
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
      if (val == 1) {
        const res = await send(this.loginForm1.phone);
        this.id = res.data.data.id;
      } else if (val == 2) {
        const res = await send(this.loginForm2.findPhone);
        this.id = res.data.data.id;
      } else if (val == 3) {
        const res = await send(this.loginForm3.phone);
        this.id = res.data.data.id;
      }
    },
    handleLogin() {
      // localStorage.removeItem("token");
      // localStorage.removeItem("userId");
      // localStorage.removeItem("nickName");
      // localStorage.removeItem("avatar");
      // localStorage.removeItem("refresh_token");
      // if (checked.value == false) {
      //   isShow1.value = false
      //   return
      // } else {
      //   isShow1.value = true
      //   loginFormRef.value.validate((valid) => {
      //     if (valid) {
      //       // 账号密码
      let params = {
        tenantId: "000000",
        password: md5(this.loginForm.password),
        username: this.loginForm.username,
        grant_type: "password",
        scope: "all",
      };

      login(params)
        .then((res) => {
          // PSPS邀请团队成员

          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          request({
            url: `/hello-user/team/saveTeamUser?` + "teamId" + "=" + this.teamId,
            method: "POST",
            headers: {
              "Tenant-Id": "000000",
              Authorization: "Basic aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=",
              "Blade-Auth": `bearer ${res.data.access_token}`,
            },
          }).then((res) => {
            this.$router.push("/control").catch((error) => error);
          });
        })
        .catch(() => {});
    },
    handleLogin1() {
      let params = {
        tenantId: "000000",
        phone: this.loginForm1.phone,
        codeSms: this.loginForm1.codeSms,
        grant_type: "sms_code",
        scope: "all",
        id: this.id,
      };
      login(params)
        .then((res) => {
          //PSPS邀请团队成员

          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          // saveTeamUser(this.teamId).then((res) => {

          //   this.$router.push("/control").catch((error) => error);
          // });
          request({
            url: `/hello-user/team/saveTeamUser?` + "teamId" + "=" + this.teamId,
            method: "POST",
            headers: {
              "Tenant-Id": "000000",
              Authorization: "Basic aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=",
              "Blade-Auth": `bearer ${res.data.access_token}`,
            },
          }).then((res) => {
            this.$router.push("/control").catch((error) => error);
          });
          // getId(res.data.user_id).then((res) => {
          //   if (res.data.data.avatar == "" || res.data.data.avatar == undefined || res.data.data.avatar == null) {
          //     localStorage.setItem('avatar', avatarImg.value);
          //   } else {
          //     localStorage.setItem('avatar', res.data.data.avatar);
          //   }
          // })
        })
        .catch(() => {});
    },
    addTeam() {
      if (this.checked) {
        saveTeamUser(this.teamId).then((res) => {
          this.$router.push("/control").catch((error) => error);
        });
      }
    },
    pdfFile() {},
    handleClose(done) {
      done();
      this.forgetIshow = 1;
      this.title = "登录";
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  .big-box {
    margin-top: 64px;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/imgs/invite-bg.png");
    background-size: 100% 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .box {
      width: 1200px;
      height: 100%;
      .jrtd {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: center;
      }
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        .picture {
          width: 40px;
          height: 40px;
          margin-right: 5px;
          border-radius: 20px;
          overflow: hidden;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 24px;
        }
      }
      .main {
        display: flex;
        margin-top: 42px;
        justify-content: center;
        .main-box {
          width: 429px;
          height: 400px;
          background: #ffffff;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 16px;
          .tab-text {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 16px;
            color: #8b5ef5;
            line-height: 24px;
            text-align: right;
            margin-top: 40px;
            margin-right: 54px;
          }
          .login {
            display: flex;
            justify-content: center;
            .login-form {
              width: 320px;
              margin-top: 40px;
              .icon-style {
                position: absolute;
                z-index: 99;
                top: 10px;
                left: 10px;
              }
              ::v-deep .el-input__inner {
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0;
              }
            }
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 10px;
              color: #5c92fe;
              text-align: center;
              margin-top: 38px;
            }
            .login-btn {
              margin-top: 47px;
              width: 312px;
              height: 52px;
              background: #8b5ef5;
              box-shadow: 0px 0px 4px 1px rgba(15, 12, 84, 0.2);
              border-radius: 6px 6px 6px 6px;
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              text-align: center;
              line-height:45px;
            }
          }
          .login2 {
            display: flex;
            justify-content: center;
            .login-form {
              width: 320px;
              margin-top: 40px;
              .icon-style {
                position: absolute;
                z-index: 99;
                top: 10px;
                left: 10px;
              }
              ::v-deep .el-input__inner {
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0;
              }
            }
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 10px;
              color: #5c92fe;
              text-align: center;
              margin-top: 38px;
            }
            .login-btn {
              margin-top: 47px;
              width: 312px;
              height: 52px;
              background: #8b5ef5;
              box-shadow: 0px 0px 4px 1px rgba(15, 12, 84, 0.2);
              border-radius: 6px 6px 6px 6px;
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              text-align: center;
              line-height:45px;
            }
          }
        }
        .main-box2 {
          width: 429px;
          height: 300px;
          background: #ffffff;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 16px;
          .checkbox {
            margin-top: 101px;
            display: flex;
            justify-content: center;
            ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: #8b5ef5;
              border-color: #8b5ef5;
            }
            ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
              font-weight: 400;
              color: #444444;
            }
          }
          .btn-box {
            display: flex;
            justify-content: center;
            .login-btn {
              margin-top: 72px;
              width: 312px;
              height: 52px;
              background: #8b5ef5;
              box-shadow: 0px 0px 4px 1px rgba(15, 12, 84, 0.2);
              border-radius: 6px;
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              text-align: center;
              line-height:45px;
              cursor:pointer;
            }
          }
        }
      }
    }
  }
}
</style>
