<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent></headComponent>

    <div class="mainStyle">
      <div style="width: 100%">
        <div class="main-one">
          <div class="img-box">
            <img class="images" src="@/assets/imgs/dsdh-bg-1.png" alt="" />
          </div>
          <div class="middle">
            <div class="middle-text">
              <div class="middle-download">
                <img src="@/assets/imgs/jypx-bg-left.png" alt="" />
              </div>
              <div class="middle-conter">
                课程、品牌宣传获取生源、课件制作\虚拟教师等数字虚拟化解决方案
              </div>
              <div class="middle-btn">联系我们</div>
            </div>
            <div class="middle-images">
              <img style="margin-top:85px;" src="@/assets/imgs/jypx-bg-right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dsdh-one">
      <div class="one-box">
        <div class="one-title">方案特性</div>
        <div class="one-main">
          <div class="main-item">
            <div class="logo">
              <img src="@/assets/imgs/jypx-1.png" alt="" />
            </div>
            <div class="title">提升生源引流</div>
            <div class="conter">
                吸引更多潜在学员的关注和兴趣，提高品牌知名度和影响力，进而实现生源的有效引流。
            </div>
          </div>
          <div class="main-item">
            <div class="logo">
              <img src="@/assets/imgs/jypx-2.png" alt="" />
            </div>
            <div class="title">课件制作灵活</div>
            <div class="conter">
                通过数字人的形象来展示和传授知识。无需真实教师录制，课件修改灵活。
            </div>
          </div>
          <div class="main-item">
            <div class="logo">
              <img src="@/assets/imgs/jypx-3.png" alt="" />
            </div>
            <div class="title">直播成本下降90%</div>
            <div class="conter">
                直播团队运营、主播、编导+剪辑，最少可减为1人。无需直播硬件设施投入相机、声卡、麦，以及直播间场景布置等投入，只需一台电脑/手机即可全场景数字虚拟化开播。
            </div>
          </div>
          <div class="main-item">
            <div class="logo">
              <img src="@/assets/imgs/jypx-4.png" alt="" />
            </div>
            <div class="title">多平台同步直播</div>
            <div class="conter">
              可同时在抖音、快手、视频号等直播平台同步直播，用最少的直播成本创造最大的流量收入。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="yljk-two">
      <div class="two-box">
        <div class="title">应用场景</div>
        <div class="item-box">
          <div class="item1">
            <div class="text">
              <div class="text1">生源获取</div>
              <div class="text2">短视频+直播</div>
            </div>
          </div>
          <div class="item2">
            <div class="text">
              <div class="text1">课件制作</div>
              <div class="text2">动态数字人/语音课件</div>
            </div>
          </div>
          <div class="item3">
            <div class="text">
              <div class="text1">虚拟教师</div>
              <div class="text2">交互式数字人</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dsdh-three">
      <div class="three-box">
        <div class="title">客户案例</div>
        <div class="item-box">
          <div class="item">
          <img style="width:100%;height:100%;" src="@/assets/imgs/heluo.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="dsdh-four">
      <div class="four-box">
        <div class="title">咨询行业专业顾问</div>
        <div class="conter">关于使用场景和产品方案的更多咨询，请联系我们的销售团队。</div>
        <div class="btn">立即咨询</div>
      </div>
    </div>
    <footerComponent></footerComponent>
  </div>
</template>

<script>
import headComponent from "@/views/component/headComponent.vue";
import footerComponent from "@/views/component/footerComponent.vue";
export default {
  name: "HomeView",
  components: { headComponent, footerComponent },
  data() {
    return {
      indexc: 1,
    };
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
  .mainStyle {
    margin-top: 64px;
    flex-grow: 1;
    min-width: 1200px;
    background-color: #e0dcf8;
    .main-one {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;
      position: relative;
      .img-box {
        position: absolute;
        height: 100%;
        width: 100%;
        .images {
          position: absolute;
          top: 0;
          width: 100%;
          left: 0;
          height: 100%;
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;
          object-fit: cover;
        }
      }
      .middle {
        z-index: 1;
        width: 1200px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .middle-text {
          width: 50%;
          .middle-download {
          }
          .middle-conter {
            width: 650px;
            margin-top: 10px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #243042;
            line-height: 40px;
          }
          .middle-btn {
            margin-top: 30px;
            width: 205px;
            height: 64px;
            background: linear-gradient(256deg, #8b5ef5 0%, #6d2fff 97%, #6c2dff 100%);
            box-shadow: 0px 1px 3px 1px rgba(108, 45, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            line-height: 62px;
            text-align: center;
          }
        }
        .middle-images {
        }
      }
    }
  }

  .dsdh-one {
    min-width: 1200px;
    height: 336px;
    display: flex;
    justify-content: center;
    .one-box {
      width: 1200px;
      height: 100%;
      .one-title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #080808;
        line-height: 28px;
        text-align: center;
        margin-top: 27px;
        height: 52px;
      }
      .one-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 27px;
        .main-item {
          width: 290px;
          height: 220px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #ffffff;
          background-image: url("@/assets/imgs/fatx-bg.png");
          background-size: 100% 100%;
          .logo {
            margin-left: 18px;
            margin-top: 20px;
          }
          .title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 18px;
            color: #243042;
            text-align: left;
            margin-left: 18px;
            margin-top: 5px;
            margin-bottom: 10px;
          }
          .conter {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 12px;
            color: #243042;
            text-align: left;
            line-height: 22px;
            margin-left: 18px;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .yljk-two {
    min-width: 1200px;
    height: 740px;
    background-image: url("@/assets/imgs/yljk-bg-2.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .two-box {
      width: 1200px;
      height: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #243042;
        line-height: 28px;
        text-align: center;
        margin-top: 30px;
      }
      .item-box {
        margin-top: 78px;
        display: flex;
        justify-content: space-between;
        .item1 {
          width: 360px;
          height: 520px;
          background-image: url("@/assets/imgs/jypx-2-1.png");
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            .text1 {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 34px;
              color: #ffffff;
              line-height: 41px;
              text-align: center;
            }
            .text2 {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 26px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
        }
        .item2 {
          width: 360px;
          height: 520px;
          background-image: url("@/assets/imgs/jypx-2-2.png");
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            .text1 {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 34px;
              color: #ffffff;
              line-height: 41px;
              text-align: center;
            }
            .text2 {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 26px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
        }
        .item3 {
          width: 360px;
          height: 520px;
          background-image: url("@/assets/imgs/jypx-2-3.png");
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            .text1 {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 34px;
              color: #ffffff;
              line-height: 41px;
              text-align: center;
            }
            .text2 {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 26px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .dsdh-three {
    min-width: 1200px;
    height: 516px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    .three-box {
      width: 1200px;
      height: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #243042;
        text-align: center;
        margin-top: 26px;
      }
      .item-box {
        margin-top: 95px;
        width: 100%;
        height: 246px;
        .item {
          width: 230px;
          height: 115px;
          background: #ffffff;
          box-shadow: 0px 0.7px 7px 0.2px rgba(0, 0, 0, 0.1);
          border-radius: 15px 15px 15px 15px;
          padding:10px;
        }
      }
    }
  }

  .dsdh-four {
    min-width: 1200px;
    height: 220px;
    background-image: url("@/assets/imgs/four-bg.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .four-box {
      width: 1200px;
      height: 100%;
      .title {
        margin-top: 44px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 34px;
        color: #ffffff;
        line-height: 28px;
      }
      .conter {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #f2f3ff;
        line-height: 28px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .btn {
        width: 144px;
        height: 42px;
        background: #ffffff;
        box-shadow: 0px 1px 3px 1px rgba(108, 45, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #8b5ef5;
        line-height: 28px;
      }
    }
  }
}
</style>
