<template>
  <div class="personalFiles">
    <audio
      style="display: none"
      ref="audioPlayer"
      @play="handlePlay"
      @pause="handlePause"
      @ended="handleNext"
    ></audio>
    <div class="top">
      <div class="left">
        <div class="left">
          <div class="left-item" v-for="(item, index) in routerList">
            <span
              @click="teamHandel(index, item.isShow)"
              :style="item.isShow ? 'cursor: pointer;color:#92929D;' : ''"
              >{{ item.name }}</span
            >
            <span v-show="item.isShow" style="margin: 0 5px">/</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="one">存储空间：</div>
        <div class="two">
          <span style="color: #8b5ef5">{{ useCloudSpaceSize }} MB</span>
          <span>/{{ cloudSpaceSize }}GB</span>
        </div>
      </div>
    </div>
    <div class="background-box">
      <el-form ref="form" :model="form" label-width="20px">
        <el-form-item label="">
          <el-input
            style="width: 300px"
            v-model="form.materialName"
            placeholder="输入关键词进行搜索"
          >
            <template #suffix>
              <i
                style="margin-right: 10px"
                class="el-icon-search"
                @click="handleGetTeamUser"
              ></i>
            </template>
          </el-input>
          <el-input v-show="false"></el-input>
        </el-form-item>
      </el-form>

      <div v-if="isTeamAdmin == 1">
        <div style="display: flex" v-show="isShow == 6">
          <div
            v-for="(item, index) in teamList1"
            :key="item.index"
            @click="teamEvn(item)"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 95px;
              margin-left: 20px;
              cursor: pointer;
            "
          >
            <div @click="userNameHandel(item.userName)">
              <img src="@/assets/imgs/wenjianjia.png" alt="" />
            </div>
            <div>
              {{ item.userName }}
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex" v-show="isShow == 7">
        <div class="teamStyle" @click="getTeamUser(4, '数字人')">
          <div>
            <img src="@/assets/imgs/wenjianjia.png" alt="" />
          </div>
          <div>数字人</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(5, '数字语音')">
          <div>
            <img src="@/assets/imgs/shuziyuyintubiao.png" alt="" />
          </div>
          <div>数字语音</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(1, '视频')">
          <div>
            <img src="@/assets/imgs/shipintubiao.png" alt="" />
          </div>
          <div>视频</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(2, '音频')">
          <div>
            <img src="@/assets/imgs/yinpintubiao.png" alt="" />
          </div>
          <div>音频</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(3, '图片')">
          <div>
            <img src="@/assets/imgs/tupiantubiao.png" alt="" />
          </div>
          <div>图片</div>
        </div>
      </div>

      <div class="search-for" v-show="isShow == 0">
        <div class="search-item" v-for="(list, index) in teamList" :key="list.index">
          <div
            v-if="list.materialType == 1"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <video
                id="videL"
                style="background-color: #eeeeee"
                :src="list.materialUrl"
                width="180px"
                height="135px"
                controls
                controlslist="nodownload"
              ></video>
            </div>
            <!-- <div class="main-chakan">
              <img @click="viewVideo(list)" src="@/assets/imgs/chakan.png" alt="" />
            </div> -->
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon">
                <!-- <img @click="viewVideo(list)" src="@/assets/imgs/view-Video.png" alt="" /> -->
              </div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div
              v-if="list.status === 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-if="list.status === 1" class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div class="round1-name-two" @click="delMaterial(1, list.id)">删除</div>
            </div>
            <div v-if="list.status !== 1" class="cover-up">
              <div class="images">
                <img src="@/assets/imgs/get-out.png" alt="" />
              </div>
              <div class="title">内容违规</div>
              <div class="text">此内容因涉嫌违规，禁止访问</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 2"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <img src="@/assets/imgs/ylbj.png" alt="" width="180px" height="135px" />
            </div>
            <div @click="playPause(list)" class="main-chakan">
              <img v-if="audioId !== list.id" src="@/assets/imgs/bofang.png" alt="" />
              <img v-else src="@/assets/imgs/bofangzhon.gif" alt="" />
            </div>
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon"></div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div v-if="list.status === 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-if="list.status === 1" class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div class="round1-name-two" @click="delMaterial(2, list.id)">删除</div>
            </div>
            <div v-if="list.status !== 1" class="cover-up">
              <div class="images">
                <img src="@/assets/imgs/get-out.png" alt="" />
              </div>
              <div class="title">内容违规</div>
              <div class="text">此内容因涉嫌违规，禁止访问</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 3"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <div
                style="
                  width: 180px;
                  height: 135px;
                  align-items: center;
                  position: relative;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  :src="list.materialUrl"
                  alt=""
                  style="max-width: 100%; max-height: 100%; display: block; margin: auto"
                />
              </div>
            </div>
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon"></div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div v-if="list.status === 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-if="list.status === 1" class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div class="round1-name-two" @click="delMaterial(3, list.id)">删除</div>
            </div>
            <div v-if="list.status !== 1" class="cover-up">
              <div class="images">
                <img src="@/assets/imgs/get-out.png" alt="" />
              </div>
              <div class="title">内容违规</div>
              <div class="text">此内容因涉嫌违规，禁止访问</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 4"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <video
                id="videL2"
                style="background-color: #eeeeee"
                :src="list.materialUrl"
                width="180px"
                height="135px"
                controls
                controlslist="nodownload"
              ></video>
            </div>
            <div class="main-icon">
              <!-- <div style="margin-right: 8px" class="icon">
                <img @click="viewVideo(list)" src="@/assets/imgs/view-Video.png" alt="" />
              </div> -->
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div v-if="list.status === 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-if="list.status === 1" class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div class="round1-name-two" @click="delMaterial(4, list.id)">删除</div>
            </div>
            <div v-if="list.status !== 1" class="cover-up">
              <div class="images">
                <img src="@/assets/imgs/get-out.png" alt="" />
              </div>
              <div class="title">内容违规</div>
              <div class="text">此内容因涉嫌违规，禁止访问</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 5"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <img
                src="@/assets/imgs/yuyinbeijingtu1.png"
                alt=""
                width="180px"
                height="135px"
              />
            </div>
            <div @click="viewVideo(list)" class="main-chakan">
              <!-- <img  src="@/assets/imgs/bofang.png" alt=""> -->
            </div>
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon">
                <img @click="viewVideo(list)" src="@/assets/imgs/view-Video.png" alt="" />
              </div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div v-if="list.status === 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-if="list.status === 1" class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div class="round1-name-two" @click="delMaterial(5, list.id)">删除</div>
            </div>
            <div v-if="list.status !== 1" class="cover-up">
              <div class="images">
                <img src="@/assets/imgs/get-out.png" alt="" />
              </div>
              <div class="title">内容违规</div>
              <div class="text">此内容因涉嫌违规，禁止访问</div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog title="预览" :visible.sync="dialogVisible" width="40%">
        <div class="dialog-box">
          <div class="title">{{ dialogTitle }}</div>
          <div class="conter" v-html="dialogUrl">111</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { teamUserEquity } from "@/api/information";
import { teamUser, getMaterialList, getMaterialDel, getPut } from "@/api/cloudSpace";
import Cookies from "js-cookie";
export default {
  name: "centre",
  data() {
    return {
      teamList1: [],
      teamList: [
        {
          liveName: "你好",
        },
      ],
      form: {
        name: "",
      },
      isShow: 6,
      showId: "",
      renameName: "",
      renameId: "",
      userId: "",
      cloudSpaceSize: "",
      useCloudSpaceSize: "",
      routerList: [{ name: "个人文件", isShow: false }],
      type: "",
      routerName: "",
      dialogVisible: false,
      dialogType: "",
      dialogUrl: "",
      dialogTitle: "",
      isPlaying: false,
      player: null,
      audioId: "",
      typeShow: "",
      isTeamAdmin: "",

    };
  },
  created() {
    this.isTeamAdmin = localStorage.getItem("isTeamAdmin");
    this.getTeamUser1();
    this.userEquity();
  },
  mounted() {
    document.addEventListener("click", this.handleBlur);
    this.player = this.$refs.audioPlayer;

  },
  methods: {
    teamHandel(inx, isShow) {
      if (!this.$refs.audioPlayer) {
     
      } else {
        this.$refs.audioPlayer.pause();
      }

      var videL = document.getElementById("videL");
      if (!videL) {
    
      } else {
        videL.pause();
      }

      var videL2 = document.getElementById("videL2");
      if (!videL2) {
       
      } else {
        videL2.pause();
      }
      if (this.type === "") {
        isShow = true;
      }
  
      if (this.isTeamAdmin === "1") {
        if (isShow) {
       
          if (inx === 0) {
            this.type = "";
            this.isShow = 6;
            this.routerList = [{ name: "个人文件", isShow: false }];
          } else if (inx === 1) {
            this.isShow = 7;
            this.routerList[1].isShow = false;
            this.routerList.pop();
          
          }
        }
        if (inx === 0 && !isShow) {
          this.type = "";
          this.isShow = 6;
          this.routerList = [{ name: "个人文件", isShow: false }];
        }
      } else {
        this.type = "";
        this.isShow = 7;
        this.routerList = [{ name: "个人文件", isShow: false }];
      }
    },
    playPause(item) {
      this.audioId = item.id;
      this.player.src = item.materialUrl;
      if (this.isPlaying) {
        this.audioId = "";
        this.isPlaying = false;
        this.player.pause();
      } else {
        setTimeout(() => {
          this.player.play();
        }, 1000);
      }
    },
    handlePlay() {
      
      this.isPlaying = true;
    },
    handlePause() {
      this.audioId = "";
      this.isPlaying = false;
    },
    handleNext() {
     
      this.audioId = "";
      this.isPlaying = false;
    },
    viewVideo(item) {
    
      this.dialogVisible = true;
      this.dialogTitle = item.materialName;
      this.dialogUrl = JSON.parse(item.voiceText).voiceHtml;
    },
    downloadHandel(url) {
      this.showId = 1;
      window.open(url);
    },
    userNameHandel(userName) {
      this.routerList[0].isShow = true;
      this.routerList.push({ name: userName, isShow: false });
    },
    pushHandel(val, userName) {
  
      this.routerList.push({ name: userName, isShow: false });
    },
    async userEquity() {
      const a = {
        teamId: localStorage.getItem("teamId"),
        userId: localStorage.getItem("userId"),
      };
      const res = await teamUserEquity(a);
      this.cloudSpaceSize = res.data.data.cloudSpaceSize;
      this.useCloudSpaceSize = res.data.data.useCloudSpaceSize;
    
    },
    async getTeamUser1() {
      const a = {
        teamId: localStorage.getItem("teamId"),
      };
      const res = await teamUser(a);
      this.teamList1 = res.data.data;
      this.teamList1.forEach((item) => {
        if (item.userId === localStorage.getItem("userId")) {
          if (this.isTeamAdmin !== "1") {
            this.teamEvn(item);
          }
        }
      });
    },

    async getTeamUser(val, userName) {
      if (this.isTeamAdmin === "1") {
        this.typeShow = val;
        this.routerName = userName;
        this.type = val;
        this.routerList[1].isShow = true;
        
        if (this.routerList.length !== 3) {
          this.routerList.push({ name: userName, isShow: false });
        }
      } else {
        this.typeShow = val;
        this.routerName = userName;
        this.routerList[0].isShow = true;
        this.routerList.push({ name: userName, isShow: false });
        this.type = val;
      }

  
      this.isShow = 0;
      const material = {
        current: this.current,
        size: this.limit,
        materialType: val,
        relevancyType: 1,
        teamId: localStorage.getItem("teamId"),
        userId: this.userId,
      };
      const res = await getMaterialList(material);
      this.teamList = res.data.data.records;
      this.teamList.forEach((item) => {
        item.materialUrl =
          window.localStorage.getItem("OssPrefix") + "/" + item.materialUrl;
        item.createTime = item.createTime.substr(0, 10);
      });
    },
    async handleGetTeamUser() {
      if (this.routerList.length === 1) {
        this.userId = "";
      }
      this.isShow = 0;
      const material = {
        current: this.current,
        size: this.limit,
        relevancyType: 1,
        materialName: this.form.materialName,
        teamId: localStorage.getItem("teamId"),
        userId: this.userId,
      };
      const res = await getMaterialList(material);
      this.teamList = res.data.data.records;
      this.teamList.forEach((item) => {
        item.materialUrl =
          window.localStorage.getItem("OssPrefix") + "/" + item.materialUrl;
        item.createTime = item.createTime.substr(0, 10);
      });
    },

    teamEvn(item) {
      this.userId = item.userId;
      this.isShow = 7;
    },
    handleBlur() {
      // 对失去焦点的监听
      this.showId = 1;
      // this.renameId = 1
    },
    roundList(id) {
      this.showId = id;
    },
    async rename(id, materialId, item) {

      this.renameName = item.materialName;
      this.renameId = id;
      this.showId = 1;
    },
    async renameEvn(id, materialId, item) {
      item.materialName = this.renameName;
      const liveData = {
        materialName: this.renameName,
        materialId: materialId,
      };
      const res = await getPut(id, liveData);
      this.renameName = "";
      this.renameId = 1;
    },
    async delMaterial(val, id) {
      this.showId = 1;
      const res = await getMaterialDel(id);
      this.$message({
        type: "success",
        message: "删除成功!",
      });
      this.getTeamUser(val, this.routerName);
    },
  },
};
</script>

<style lang="less" scoped>
/*video默认全屏按钮*/
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

/*video默认aduio音量按钮*/
video::-webkit-media-controls-mute-button {
  display: none !important;
}

/*video默认setting按钮*/
video::-internal-media-controls-overflow-button {
  display: none !important;
}

/*隐藏controls中的音量控制*/
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button {
  //隐藏android端video标签自带的音量调节按钮
  display: none !important;
}
/*video默认aduio音量按钮*/
video::-webkit-media-controls-mute-button {
  display: none !important;
}

::v-deep .el-dialog__header {
  background: linear-gradient(to bottom, #f2ecff, #ffffff);
}
.personalFiles {
  height: 90vh;
  .dialog-box {
    width: 100%;
    height: 380px;
    border-radius: 6px;
    border: 1px solid #e2e2ea;
    overflow-y: auto;
    .title {
      text-align: center;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: bold;
      font-size: 18px;
      color: #171725;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .conter {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    .left {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: bold;
      font-size: 22px;
      color: #444444;
      line-height: 24px;
      margin-bottom: 10px;
      display: flex;
    }
    .right {
      display: flex;
      .one {
        margin-right: 5px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
      }
      .two {
        margin-right: 15px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
      }
      .three {
        margin-right: 10px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
      }
      .four {
        margin-right: 5px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
        color: #ce8831;
      }
    }
  }
  .background-box {
    background-color: #ffffff;
    height: 85vh;
  }
}
::v-deep .el-input__inner {
  height: 30px;
  border-radius: 20px;
}

.teamStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
  margin-left: 20px;
  cursor: pointer;
}

.button-img {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-right: 15px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  .round1 {
    width: 100px;
    height: 90px;
    background-color: #ffffff;
    border-radius: 8px;
    position: absolute;
    z-index: 999;
    top: 30px;
    left: 50px;
    overflow: hidden;
    // padding: 10px 20px;
    display: flex;
        flex-direction: column;
        justify-content: center;

    .round1-name {
      color: #000000;
      font-size: 10px;
      cursor: pointer;
    }

    .round1-name-two {
      color: #000000;
      font-size: 10px;
      padding-left: 10px;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
    }

    .round-name:hover {
      background-color: #a0a0a0;
    }

    .round1-name-two:hover {
      background-color: #a0a0a0;
    }
  }

  .beijingStyle {
    position: absolute;
    top: 40px;
    left: 80px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 20px;
    background-color: rgba(128, 128, 128, 0.5);
  }

  .button-big {
    width: 135px;
    height: 180px;
    border-radius: 8px;
    overflow: hidden;
  }

  .button-main {
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    ::v-deep .el-input__inner {
      height: 30px;
      border-radius: 0px;
      width: 100px;
    }

    // .button-main1 {
    //     font-size: 12px;
    //     color: #fff;
    //     width: 60px;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    // }

    .button-main2 {
      font-size: 12px;
      color: #9a979e;
    }
  }

  .roundStyle {
    // .round {
    //     width: 3px;
    //     height: 3px;
    //     background-color: #0307fc;
    //     border-radius: 3px;
    //     margin-top: 2px;
    // }
  }
}
.search-for {
  display: flex;
  width: 95%;
  height: 155px;
  flex-wrap: wrap;
  justify-content: flex-start;
  .search-item {
    .button-img {
      width: 100%;
      height: 100%;
      margin-top: 20px;
      margin-right: 15px;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
      .cover-up {
        position: absolute;
        top: 0px;
        left: 20px;
        width: 180px;
        height: 135px;
        background-color: rgba(7, 7, 8, 0.9);
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .images {
          width: 30px;
          height: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 12px;
          color: #ff3131;
          line-height: 35px;
        }
        .text {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 10px;
          color: #ffffff;
        }
      }
      .main-box {
        margin-left: 20px;
        width: 180px;
      }
      .main-chakan {
        position: absolute;
        margin-left: 20px;
        top: 0px;
        width: 180px;
        height: 135px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .main-icon {
        position: absolute;
        right: 23px;
        bottom: 52px;
        display: flex;
        .icon {
          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .round1 {
        width: 100px;
        height: 90px;
        background-color: #ffffff;
        border-radius: 8px;
        position: absolute;
        z-index: 999;
        top: 38px;
        left: 170px;
        overflow: hidden;
        background: #ffffff;
        box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.12);
        border-radius: 4px 4px 4px 4px;

        .round1-name {
          color: #000000;
          font-size: 10px;
          cursor: pointer;
        }

        .round1-name-two {
          color: #000000;
          font-size: 10px;
          padding-left: 10px;
          cursor: pointer;
          height: 30px;
          line-height: 30px;
        }

        .round-name:hover {
          background-color: #a0a0a0;
        }

        .round1-name-two:hover {
          background-color: #a0a0a0;
        }
      }

      .button-main {
        width: 180px;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        margin-left: 20px;
        .main-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 14px;
          color: #444444;
          width: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .main-time {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 14px;
          color: #444444;
        }
        .main-input {
          position: relative;
          left: 35px;
          top: 5px;
        }

        ::v-deep .el-input__inner {
          height: 30px;
          border-radius: 0px;
          width: 100px;
          border-radius: 10px;
        }
      }
      .box-dot {
        position: absolute;
        top: 5px;
        right: 30px;
        cursor: pointer;
        width: 10px;
        padding-left: 5px;
        height: 50px;
        z-index: 10;
      }
    }
  }
}
</style>
