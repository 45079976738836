<template>
    <div class="userStyle-fillInvoice">
        <div style="padding: 10px 15px; width: 100%; text-align: center;height: 480px;overflow: auto;">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" style="width: 100%;">
                <el-form-item label="退票原因：" prop="refundReason" style="margin-top: 15px;">
                    <el-select v-model="ruleForm.refundReason" placeholder="请选择" :popper-append-to-body="false"
                        style="width: 100%;">
                        <el-option v-for="(item, index ) in remarksList" :key="item.index" :label="item.dictValue"
                            :value="item.dictKey"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" prop="remarks" style="margin-top: 20px;">
                    <el-input placeholder="请输入纳税人识别号" type="textarea" v-model="ruleForm.remarks" :rows="5"></el-input>
                </el-form-item>
            </el-form>
            <div style="margin-top: 35px;">
                <el-button class="but8" type="primary" size="small" @click="closeBtn">取消</el-button>
                <!-- <el-button class="but7 but8" type="primary" size="small" @click="submitForm('ruleForm')">取消</el-button> -->
                <el-button class="but7" type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { getInvoiceUp } from "@/api/invoice"
import { getDictList } from "@/api/information"
export default {
    name: 'sourceP',
    props:['id'],
    data() {
        return {
            loginForm02: {
                name: "",
                desc: ""
            },
            radio: 1,
            radio1: 1,
            radioShow: true,
            inputShow: 1,
            ruleForm: {
                refundReason: '',
                remarks: ""
            },
            rules: {
                remarks: [
                    { required: true, message: '请输入备注', trigger: 'blur' },
                ],
                refundReason: [
                    { required: true, message: '请选择退票原因', trigger: 'blur' },
                ],
            },
            remarksList: [],
            // id: ""
        }
    },
    created() {
        setTimeout(() => {
            this.questionType()
        }, 500);
    },
    mounted() {

    },
    methods: {
        async questionType() {
            let data = {
                code: 'reason_refund'
            }
            const res = await getDictList(data)
            this.remarksList = res.data.data[0].children
        },
        // 
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // getInvoiceUp
                    let data = {
                        id: this.id,
                        refundReason: this.ruleForm.refundReason,
                        rejectionReason: this.ruleForm.rejectionReason,
                        remarks: this.ruleForm.remarks,
                        status: 3
                    }
                    const res = await getInvoiceUp(data)
                    if (res.data.code == 200) {
                        this.$emit('handel6')
                    }

                } else {
                    
                    return false;
                }
            });

        },
        closeBtn(){
            this.$emit('handel6')
        }
    }
}
</script>
<style lang='less' scoped>
.userStyle-fillInvoice {
    // background-color: #2B2B2B;
    width:100%;
    height: 360px;
    background-size: 100% 100%;
    background-attachment: fixed;
    overflow: hidden;
    user-select: none;
    overflow-x:scroll;
    // border: 1px solid #515152;

    .but7 {
        position: relative;
        width: 120px;
        font-size: 16px;
        color: white;
        background-color: #8B5EF5 !important;
        border-radius: 4px;
        border: 0px;
        outline: none;
        cursor: pointer;
        overflow: hidden;
        height: 36px;
    }

    .but7:hover {
        background-color: #743AFE !important;
    }

    .but8 {
        position: relative;
        width: 120px;
        font-size: 16px;
        color: #0F1114;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #ccc;
        outline: none;
        cursor: pointer;
        overflow: hidden;
        height: 40px;
    }

    .but8:hover {
        // background-color: #a2a2a2;
    }

    // option选项 最外层
    ::v-deep .el-select-dropdown {
        border: none !important;
        background: #1C1C1C !important;
        z-index: 9999;
    }

    // option选项 文字样式
    ::v-deep .el-select-dropdown__item {
        color: rgba(255, 255, 255, 0.50) !important;
        z-index: 9999;
    }

    ::v-deep .el-select-dropdown__item.selected span {
        color: rgba(255, 255, 255, 0.80) !important;
        z-index: 9999;
    }

    ::v-deep .el-radio__label {
        font-size: 14px;
        color: #DEDDDE;
    }

    // 移入option选项 样式调整
    ::v-deep .el-select-dropdown__item.hover {
        background-color: rgba(255, 255, 255, 0.06);
        color: rgba(255, 255, 255, 0.80) !important;
        z-index: 9999;
    }

    // 下拉框垂直滚动条宽度
    ::v-deep .el-scrollbar__bar.is-vertical {
        width: 10px;
        top: 2px;
    }

    // 下拉框最大高度
    ::v-deep .el-select-dropdown__wrap {
        max-height: 200px;
    }

    ::v-deep .popper__arrow {
        display: none;
    }

    ::-webkit-scrollbar {
        // width: 4px;
        height: 1px;
        // background-color: #F5F5F5;
        background-color: #1C1C1C;
    }



    ::v-deep .el-textarea .el-textarea__inner {
        // 然后找到对应的类名，在这里将拉伸去掉即可
        resize: none;
    }

    ::v-deep .el-textarea__inner {
        border: none;
        // color: #fff;
        // background-color: #1C1C1C;
        border:1px solid #ccc;

    }

    ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: #8B5EF5;
    }

    ::v-deep .el-radio__inner {
        width: 16px;
        height: 16px;
    }

    ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border: #8B5EF5 !important;
        background-color: #8B5EF5 !important;
    }

    ::v-deep .el-form-item {
        margin-bottom: 0px;
    }

    ::v-deep .el-form-item__label {
        font-size: 14px;
        // color: #DEDDDE;
    }

    ::v-deep .el-button--primary:hover {
        background-color: #8B5EF5;
    }

    ::v-deep .el-input__inner {
        border: none;
        // color: #fff;
        // background-color: #1C1C1C;
        border:1px solid #ccc;
        height: 36px;
    }

}



.close {
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    -webkit-app-region: no-drag;
}

.close:hover {
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(232, 17, 35, 0.9);
}
</style>