<template>
  <div class="userStyle-fillInvoice">
    <div
      style="
        padding: 40px 15px;
        width: 100%;
        text-align: center;
        height: 420px;
        overflow: auto;
      "
    >
      <el-form
        autocomplete="on"
        :model="loginForm02"
        ref="loginFormRef01"
        class="fromStyle"
        label-width="60px"
        style="margin-left: 200px"
      >
        <div style="display: flex; align-items: center">
          <div style="font-size: 14px">发票类型：</div>
          <el-radio-group
            v-model="radio1"
            style="margin-left: 20px"
            @change="agreeChange"
          >
            <el-radio :label="1">普通发票</el-radio>
            <el-radio :label="2">增值税专用发票</el-radio>
          </el-radio-group>
        </div>
        <div
          style="display: flex; align-items: center; margin-top: 25px"
          @change="agreeChange1"
        >
          <div style="font-size: 14px">开具类型：</div>
          <el-radio-group v-model="radio" style="margin-left: 20px">
            <el-radio :label="1">企业</el-radio>
            <el-radio :label="2" v-show="radioShow == true">个人</el-radio>
          </el-radio-group>
        </div>
      </el-form>
      <el-form
        :model="ruleForm1"
        :rules="rules"
        ref="ruleForm1"
        label-width="150px"
        style="width: 70%; margin-left: 135px"
        v-show="inputShow == 1"
      >
        <el-form-item label="发票抬头：" prop="invoiceHeader" style="margin-top: 25px">
          <el-input v-model="ruleForm1.invoiceHeader"></el-input>
        </el-form-item>
        <el-form-item
          label="纳税人识别号："
          prop="taxpayerNumber"
          style="margin-top: 25px"
        >
          <el-input v-model="ruleForm1.taxpayerNumber"></el-input>
        </el-form-item>
      </el-form>
      <el-form
        :model="ruleForm2"
        :rules="rules1"
        ref="ruleForm2"
        label-width="150px"
        style="width: 70%; margin-left: 135px"
        v-show="inputShow == 2"
      >
        <el-form-item label="发票抬头：" prop="invoiceHeader" style="margin-top: 25px">
          <el-input v-model="ruleForm2.invoiceHeader"></el-input>
        </el-form-item>
        <el-form-item
          label="纳税人识别号："
          prop="taxpayerNumber"
          style="margin-top: 25px"
        >
          <el-input v-model="ruleForm2.taxpayerNumber"></el-input>
        </el-form-item>
        <el-form-item
          label="注册地址："
          prop="registrationAddress"
          style="margin-top: 25px"
        >
          <el-input v-model="ruleForm2.registrationAddress"></el-input>
        </el-form-item>
        <el-form-item
          label="注册电话："
          prop="registrationPhone"
          style="margin-top: 25px"
        >
          <el-input v-model="ruleForm2.registrationPhone"></el-input>
        </el-form-item>
        <el-form-item label="开户银行：" prop="depositBank" style="margin-top: 25px">
          <el-input v-model="ruleForm2.depositBank"></el-input>
        </el-form-item>
        <el-form-item label="开户银行账户：" prop="bankAccount" style="margin-top: 25px">
          <el-input v-model="ruleForm2.bankAccount"></el-input>
        </el-form-item>
      </el-form>
      <div style="margin-top: 40px; margin-left: -350px">
        <span style=""
          >已选择发票金额：</span
        >
        <span style="color: #EE5446; font-size: 20px; font-weight: 600"
          >￥{{ money }}</span
        >
      </div>
      <div style="position: absolute; top: 470px; left: 350px">
        <div style="margin-top: 20px" v-show="inputShow == 1">
          <el-button
            class="but7"
            type="primary"
            size="small"
            @click="submitForm('ruleForm1')"
            >下一步</el-button
          >
        </div>
        <div style="margin-top: 20px" v-show="inputShow == 2">
          <el-button
            class="but7"
            type="primary"
            size="small"
            @click="submitForm1('ruleForm2')"
            >下一步</el-button
          >
        </div>
        <div style="margin-top: 20px" v-show="inputShow == 3">
          <el-button class="but7" type="primary" size="small" @click="submitForm2()"
            >下一步</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInvoiceSave } from "@/api/invoice";
export default {
  name: "sourceP",
  props: ["money", "orderIds"],
  data() {
    return {
      loginForm02: {
        name: "",
      },
      radio: 1,
      radio1: 1,
      radioShow: true,
      inputShow: 1,
      //   money: "",
      //   orderIds: "",
      ruleForm1: {
        invoiceHeader: "",
        taxpayerNumber: "",
      },
      ruleForm2: {
        invoiceHeader: "",
        taxpayerNumber: "",
        registrationAddress: "",
        registrationPhone: "",
        depositBank: "",
        bankAccount: "",
      },
      rules: {
        invoiceHeader: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
        taxpayerNumber: [
          { required: true, message: "请输入纳税人识别号", trigger: "blur" },
        ],
      },
      rules1: {
        invoiceHeader: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
        taxpayerNumber: [
          { required: true, message: "请输入纳税人识别号", trigger: "blur" },
        ],
        registrationAddress: [
          { required: true, message: "请输入发票抬头", trigger: "blur" },
        ],
        registrationPhone: [
          { required: true, message: "请输入纳税人识别号", trigger: "blur" },
        ],
        depositBank: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
        bankAccount: [{ required: true, message: "请输入纳税人识别号", trigger: "blur" }],
      },
    };
  },
  created() {
  
    setTimeout(() => {
      // this.getGoods()
    }, 500);
  },
  mounted() {},
  methods: {
    agreeChange() {
      if (this.radio1 == 2) {
        this.radioShow = false;
      } else {
        this.radioShow = true;
      }
      if (this.radio == 2) {
        this.radio = 1;
      }
      if (this.radio1 == 1 && this.radio == 1) {
        this.inputShow = 1;
      }
      if (this.radio1 == 2 && this.radio == 1) {
        this.inputShow = 2;
      }
      if (this.radio1 == 1 && this.radio == 2) {
        this.inputShow = 3;
      }
    },
    agreeChange1() {
      if (this.radio1 == 1 && this.radio == 1) {
        this.inputShow = 1;
      }
      if (this.radio == 1 && this.radio1 == 2) {
        this.inputShow = 2;
      }
      if (this.radio1 == 1 && this.radio == 2) {
        this.inputShow = 3;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.ruleForm1.orderIds = this.orderIds;
          this.ruleForm1.invoiceType = this.radio1;
          this.ruleForm1.issuanceType = this.radio;
          this.ruleForm1.invoiceAmt = this.money;
          const res = await getInvoiceSave(this.ruleForm1);
          if (res.data.code == 200) {
           
            this.$emit("handel3");
          }
        } else {
        
          return false;
        }
      });
    },
    submitForm1(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.ruleForm2.orderIds = this.orderIds;
          this.ruleForm2.invoiceType = this.radio1;
          this.ruleForm2.issuanceType = this.radio;
          this.ruleForm2.invoiceAmt = this.money;
        
          const res = await getInvoiceSave(this.ruleForm2);
          if (res.data.code == 200) {
        
            this.$emit("handel3");
          }
        } else {
     
          return false;
        }
      });
    },
    async submitForm2() {
      let data = {
        invoiceType: this.radio1,
        issuanceType: this.radio,
        orderIds: this.orderIds,
        invoiceAmt: this.money,
      };
    
      const res = await getInvoiceSave(data);
      if (res.data.code == 200) {
   
        this.$emit("handel3");
      }
    },
    nextB() {
      // url: "/invoicingRecords",
    },
  },
};
</script>
<style lang="less" scoped>
.userStyle-fillInvoice {
  background-color: #ffffff;
  width: 100%;
  height: 560px;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  user-select: none;
  // border: 1px solid #515152;
  position: relative;
  overflow-x:scroll;

  .but7 {
    position: relative;
    width: 120px;
    font-size: 16px;
    color: white;
    background-color: #8b5ef5 !important;
    border-radius: 4px;
    border: 0px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    height: 36px;
  }

  .but7:hover {
    background-color: #743afe !important;
  }

  ::v-deep .el-radio__label {
    font-size: 14px;
    // color: #deddde;
    color: #444444;
  }

  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #444444;
  }

  ::v-deep .el-radio__inner {
    width: 16px;
    height: 16px;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border: #8b5ef5 !important;
    background-color: #8b5ef5 !important;
  }

  ::v-deep .el-form-item {
    margin-bottom: 0px;
  }

  ::v-deep .el-form-item__label {
    font-size: 14px;
    // color: #deddde;
  }

  ::v-deep .el-button--primary:hover {
    background-color: #8b5ef5;
  }

  ::v-deep .el-input__inner {
    border: none;
    // color: #fff;
    // background-color: #1c1c1c;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #e2e2ea;
    height: 36px;
  }
}

.close {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  -webkit-app-region: no-drag;
}

.close:hover {
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(232, 17, 35, 0.9);
}
</style>
