var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainStyle-seven",class:{ mainStyleSeven2: _vm.isActive }},[_c('div',{staticClass:"seven-box"},[_c('div',{staticClass:"seven-top",class:{ sevenTop2: _vm.isActive }},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"seven-main"},[_vm._m(2),_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"item-title"},[_vm._v("解决方案")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoDsdh}},[_vm._v("直播电商")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoBdsh}},[_vm._v("本地生活")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoYljk}},[_vm._v("医疗健康")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoJypx}},[_vm._v("教育培训")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoLy}},[_vm._v("旅游")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoZlzh}},[_vm._v("展览展会")])]),_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"item-title"},[_vm._v("流量研究中心")]),_c('div',{staticClass:"item-text",on:{"click":function($event){return _vm.gotoFlow()}}},[_vm._v("Nvwa使用教程")]),_c('div',{staticClass:"item-text"},[_vm._v("直播攻略")])]),_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"item-title"},[_vm._v("协议条款")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoUserProtocol}},[_vm._v("服务协议")]),_c('div',{staticClass:"item-text",on:{"click":_vm.gotoVipProtocol}},[_vm._v("隐私条款")])]),_vm._m(3),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/imgs/logo-z.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"weixin"},[_c('div',{staticClass:"code"},[_c('img',{staticStyle:{"width":"95px","height":"95px"},attrs:{"src":require("@/assets/imgs/weixin-q.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("专属客服")])]),_c('div',{staticClass:"douyin"},[_c('div',{staticClass:"code"},[_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("@/assets/imgs/douyin-q.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("关注抖音号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box"},[_c('div',{staticClass:"item-title"},[_vm._v("产品产品")]),_c('div',{staticClass:"item-text"},[_vm._v("Hello Nvwa")]),_c('div',{staticClass:"item-text",staticStyle:{"display":"none"}},[_vm._v("Hello 元小二")]),_c('div',{staticClass:"item-text",staticStyle:{"display":"none"}},[_vm._v("Hello 数字人向导")]),_c('div',{staticClass:"item-text",staticStyle:{"display":"none"}},[_vm._v("Hello 弹幕游戏")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box"},[_c('div',{staticClass:"item-title",staticStyle:{"display":"none"}},[_vm._v("商务合作")]),_c('div',{staticClass:"item-text",staticStyle:{"display":"none"}},[_vm._v("定制咨询：135 0000 0000")]),_c('div',{staticClass:"item-text",staticStyle:{"display":"none"}},[_vm._v("代理咨询：135 0000 0000")]),_c('div',{staticClass:"item-text",staticStyle:{"display":"none"}},[_vm._v("售前咨询：135 0000 0000")]),_c('div',{staticClass:"item-text",staticStyle:{"display":"none"}},[_vm._v("售后服务：400 - 000 - 000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-right"},[_c('div',{staticClass:"item-title"},[_vm._v("关于我们")]),_c('div',{staticClass:"item-text"},[_vm._v("公司名称:湖南河络信息科技有限公司")]),_c('div',{staticClass:"item-text"},[_vm._v("地址:长沙县天华中路79号明诚商务广场")]),_c('div',{staticClass:"item-text"},[_vm._v("联系电话：0731-86914481")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seven-footer"},[_c('div',{staticClass:"footer-box"},[_vm._v(" Copyright 2024 © yohelo.com | "),_c('a',{staticStyle:{"color":"#b5b5b5","text-decoration":"none"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("湘ICP备2023009258号")])])])
}]

export { render, staticRenderFns }