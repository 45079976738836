<template>
  <div class="userStyle-invoicingRecords">
    <div style="padding: 0 15px">
      <div style="height: 410px; background-color: #fff; margin: 20px 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; background-color: #ffffff"
          height=" 410px"
          :row-class-name="tableRowClassName"
          :header-cell-style="{
            background: '#F2ECFF',
            color: '#44444F',
            border: 'none',
            fontSize: '14px',
          }"
          :cell-style="{ color: '#444444' }"
        >
          <el-table-column label="申请时间" prop="createTime" width="200">
          </el-table-column>
          <el-table-column prop="id" label="开票单号" width="200"> </el-table-column>
          <el-table-column prop="invoiceAmt" label="发票金额" width="130">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">开票中</span>
              <span v-if="scope.row.status == 2">已开票</span>
              <span v-if="scope.row.status == 3">退票中</span>
              <span v-if="scope.row.status == 4">已驳回</span>
              <span v-if="scope.row.status == 5">已退票</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                style="color: #8b5ef5"
                v-if="scope.row.status == 2"
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >下载</el-button
              >
              <el-button
                style="color: #8b5ef5"
                v-if="
                  scope.row.status == 1 || scope.row.status == 2 || scope.row.status == 4
                "
                @click="returnTicket(scope.row)"
                type="text"
                size="small"
                >退票</el-button
              >
              <el-button
                style="color: #8b5ef5"
                @click="details(scope.row)"
                type="text"
                size="small"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getInvoiceList } from "@/api/invoice";
import Cookies from 'js-cookie'
export default {
  name: "sourceP",
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      ossUrl: localStorage.getItem("oss"),
    };
  },
  created() {
    setTimeout(() => {
      this.getGoods();
    }, 500);
  },
  mounted() {},
  methods: {
    //
    async getGoods() {
      const res = await getInvoiceList();

      this.tableData = res.data.data;
    },
    handleClick(row) {
      window.location.href = `${this.ossUrl}` + `${row.invoiceUrl}`;
    },
    returnTicket(row) {
      // url: "/returnTicket",
      const obj = {
          id : row.id
      }
      this.$emit("handel4", obj);
    },
    details(row) {
      // url: "/invoiceDetails",
      const obj = {
          id : row.id
      }
      this.$emit("handel5",obj);
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    nextB() {
      // url: "/fillInvoice",
    },
  },
};
</script>
<style lang="less" scope>
.userStyle-invoicingRecords {
  // background-color: #2b2b2b;
  width: 100%;
  height: 560px;
  overflow: hidden;
  user-select: none;
  overflow-x:scroll;
  // border: 1px solid #515152;

  .but7 {
    position: relative;
    width: 120px;
    font-size: 16px;
    color: white;
    background-color: #8b5ef5 !important;
    border-radius: 4px;
    border: 0px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    height: 36px;
  }

  .but7:hover {
    background-color: #743afe !important;
  }

  .el-table td.el-table__cell {
    border: none;
  }

  .el-table td.el-table__cell {
    background: none !important;
  }

  .el-table .hover-row {
    background-color: #3a3a3a !important;
  }

  .el-table .warning-row {
    // background: #3a3a3a !important;
    background: #FFFFFF!important;
  }

  .el-table .success-row {
    // background: #303030 !important;
    background: #F8F6FF!important;
  }

  .el-table__fixed-right-patch {
    background-color: #303030 !important;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(232, 17, 35, 0) !important;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #8b5ef5 !important;
    border: 1px solid #8b5ef5 !important;
  }

  .el-checkbox__inner:hover {
    border: 1px solid #8b5ef5 !important;
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #8b5ef5 !important;
    border: 1px solid #8b5ef5 !important;
  }
}

.close {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  -webkit-app-region: no-drag;
}

.close:hover {
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(232, 17, 35, 0.9);
}
</style>
