<template>
  <div id="centre">
    <!-- <div style="display: flex; margin-top: 15px; align-items: center;height: 160px;padding-left:50px;"> -->
    <el-row :gutter="20">
      <el-col :span="8">
        <div style="display: flex; height: 160px; padding-left: 50px; width: 100%; background-color: #fff; align-items: center; border-top-left-radius: 200px; border-bottom-left-radius: 200px">
          <div>
            <div style="width: 85px; height: 85px; background-color: #fff; border-radius: 43px; overflow: hidden">
              <img v-if="this.avatar == '' || this.avatar == undefined||this.avatar == null" src="@/assets/imgs/touxiang2.png" alt="" width="85px" height="85px" />
              <img v-if="this.avatar != ''&&this.avatar != undefined&&this.avatar != null" :src="avatar" alt="" width="85px" height="85px" />
            </div>
          </div>
          <div>
            <div style="margin-left: 20px; margin-top: 10px; display: flex">
              <div style="min-width: 154px; height: 30px; display: flex; align-items: center">
                <!-- <span class="ptStyle">昵称:</span> -->
                <span class="ptStyle" style="font-size: 16px; font-weight: 600">{{ name }}</span>
                <!-- <span class="ptStyle">{{ phone }}</span> -->
              </div>
            </div>
            <div style="margin-left: 20px; display: flex">
              <div style="min-width: 154px; height: 30px; display: flex; align-items: center">
                <span class="ptStyle">{{ teamNAme }}</span>
              </div>
            </div>
            <!-- voiceMenberType -->
            <div style="display: flex; align-items: center; margin-left: 20px; margin-top: 5px">
              <div style="width: 100%; display: flex; align-items: center" v-show="menberType == 0&&voiceMenberType == 0">
                <div style="color: #f1d6a4; font-weight: 600;display: flex;align-items: center;">
                  <el-tooltip content="数字人直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/weishuziren.png" alt="">
                  </el-tooltip>
                  <el-tooltip content="数字语音直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/weiyuyin.png" alt="">
                  </el-tooltip>
                </div>
                <p style="color: #6F4913;font-size: 12px;margin-left: 10px" @click="setBtn">立即开通></p>
              </div>
              <div style="width: 100%; display: flex; align-items: center" v-show="menberType != 0&&voiceMenberType == 0">
                <div style="color: #f1d6a4; font-weight: 600;display: flex;align-items: center;">
                  <el-tooltip content="数字人直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/kaishuziren.png" alt="">
                  </el-tooltip>
                  <el-tooltip content="数字语音直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/weiyuyin.png" alt="">
                  </el-tooltip>
                </div>
                <p style="color: #6F4913;font-size: 12px;margin-left: 10px;cursor: pointer;" @click="setBtn">续费></p>
              </div>
              <div style="width: 100%; display: flex; align-items: center" v-show="menberType == 0&&voiceMenberType != 0">
                <div style="color: #f1d6a4; font-weight: 600;display: flex;align-items: center;">
                  <el-tooltip content="数字人直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/weishuziren.png" alt="">
                  </el-tooltip>
                  <el-tooltip content="数字语音直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/kaiyuyin.png" alt="">
                  </el-tooltip>
                </div>
                <p style="color: #6F4913;font-size: 12px;margin-left: 10px;cursor: pointer;" @click="setBtn">续费></p>
              </div>
              <div style="width: 100%; display: flex; align-items: center" v-show="menberType != 0&&voiceMenberType != 0">
                <div style="color: #f1d6a4; font-weight: 600;display: flex;align-items: center;">
                  <el-tooltip content="数字人直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/kaishuziren.png" alt="">
                  </el-tooltip>
                  <el-tooltip content="数字语音直播会员" placement="bottom" effect="light">
                    <img src="@/assets/imgs/kaiyuyin.png" alt="">
                  </el-tooltip>
                </div>
                <p style="color: #6F4913;font-size: 12px;margin-left: 10px;cursor: pointer;" @click="setBtn">续费></p>
              </div>
              <!-- 普通 -->
              <!-- <div style="width: 100%; display: flex" v-if="menberType == 1">
                <div style="color: #120d0f; font-size: 16px; font-weight: 600">
                  <div>{{ ordinaryWord }}会员</div>
                </div>
                <p class="mmStyle" style="background-color: #f59a23" @click="setBtn">去续费></p>
              </div> -->
              <!-- 高级 -->
              <!-- <div style="width: 100%; display: flex" v-if="menberType == 2">
                <div style="color: #120d0f; font-size: 16px; font-weight: 600">
                  <div>{{ seniorWord }}会员</div>
                </div>
                <p class="mmStyle" style="background-color: #f59a23" @click="setBtn">去续费></p>
              </div> -->
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div style="display: flex; justify-content: center; height: 160px; width: 100%; background-color: #fff; align-items: center">
          <img src="@/assets/imgs/xiangmushu.png" alt="" />
          <div style="display: flex; flex-direction: column; align-items: center">
            <div style="color: #6e767f; font-size: 12px">项目数</div>
            <div style="color: #000000; font-size: 24px">{{ teamNum }}</div>
          </div>
          <img style="margin-left: 30px" src="@/assets/imgs/wodexiangmu.png" alt="" />
          <div style="display: flex; flex-direction: column; align-items: center; margin-left: 10px">
            <div style="color: #6e767f; font-size: 12px">我的项目</div>
            <div style="color: #000000; font-size: 24px">{{ userNum }}</div>
          </div>
          <!-- xiangmushu -->
          <!-- wodexiangmu -->
        </div>
      </el-col>
      <el-col :span="8">
        <div style="display: flex; justify-content: center; height: 160px; width: 100%; background-color: #fff; align-items: center">
          <img src="@/assets/imgs/yunkongjiantubiao.png" alt="" />
          <div style="color: #6e767f; font-size: 12px; margin-left: 10px">云空间</div>
          <div style="color: #000000; font-size: 24px; margin-left: 10px">{{ useCloudSpaceSize }}MB</div>
          <div style="color: #000000; font-size: 24px">/</div>
          <div style="color: #000000; font-size: 24px">{{ cloudSpaceSize }}GB</div>
          <!-- yunkongjiantubiao -->
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px;">
      <!-- <div style="display: flex; width: 100%"> -->
      <el-col :span="16" style="">
        <el-row :gutter="20">
          <el-col :span="8">
            <div style="background-color: #8B9EF4;width: 100%;height: 100%;display: flex;justify-content: center;border-radius: 10px;cursor: pointer;" @click="refresh">
              <img src="@/assets/imgs/dingzhishuziren.png" alt="" width="230px" height="140px" />
            </div>
          </el-col>
          <el-col :span="8">
            <div style="background-color: #fff;width: 100%;height: 100%;display: flex;justify-content: center;border-radius: 10px;cursor: pointer;" @click="helpCen">
              <div style="height: 140px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <img src="@/assets/imgs/bangzhuzhongxin.png" alt="" width="26px" height="26px" />
                <span style="color: #8B5EF5;font-size: 18px;margin-top: 15px;font-weight: 600;">帮助中心</span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="background-color: #9079F6;width: 100%;height: 100%;display: flex;justify-content: center;border-radius: 10px;cursor: pointer;" @click="upLode">
              <img src="@/assets/imgs/zhibozhushou.png" alt="" width="230px" height="140px" />
            </div>
          </el-col>
        </el-row>
        <!-- <img src="@/assets/imgs/dingzhishuziren.png" alt="" width="230px" height="140px" @click="refresh" />
        <img src="@/assets/imgs/bangzhuzhongxin.png" alt="" width="230px" height="140px" @click="helpCen" />
        <img src="@/assets/imgs/zhibozhushou.png" alt="" width="230px" height="140px" @click="upLode" /> -->
      </el-col>
      <el-col :span="8" style="height: 140px;position: relative;">
        <div style="width: 100%;height: 100%;border-radius: 10px;overflow: hidden;cursor: pointer;" @click="advertisingSpace">
          <div>
            <img src="@/assets/imgs/guanggaobeijing.png" alt="" width="100%" height="140px" />
          </div>
          <div style="position: absolute;top: 0px;left: 10px;">
            <img src="@/assets/imgs/gunaggaobiaoti.png" />
          </div>
          <div style="position: absolute;top: 45px;left: 40px;">
            <img src="@/assets/imgs/gunaggawonzi.png" width="180px" />
          </div>
          <div style="position: absolute;top: 15px;right: 40px;">
            <img src="@/assets/imgs/gunaggaotuviao.png" />
          </div>
        </div>
      </el-col>
      <!-- </div> https://jx0vothcok0.feishu.cn/wiki/C32uw3viLiUBJFkyWTAcqijsnCd -->
    </el-row>
    <div style="margin-top: 25px; margin-bottom: 10px; font-size: 16px;color: #0F1114;font-weight: 600;">最近项目</div>

    <div style="display: flex;flex-wrap: wrap;margin-bottom: 80px;margin-left: -10px;">
      <div v-for="(item, index) in teamList1" :key="item.index" class="projectManagement1" @click="projectEvn(item)">
        <div style="width: 100%; height: 260px;align-items: center; position: relative;display: flex;align-items: center;" v-show="item.coverDirection==1">
          <img :src="ossUrl + item.coverUrl" alt="" style="max-width: 100%;max-height: 100%; display: block;margin: auto;" />
        </div>
        <div style="width: 100%; height: 260px;align-items: center; position: relative;display: flex;align-items: center;overflow: hidden;" v-show="item.coverDirection==2">
          <img :src="ossUrl + item.coverUrl" alt="" style="width: 100%;" />
        </div>
        <div class="wordManagement">
          <div class="nameManagement" style="">
            <div style="font-size: 14px; font-weight: 600; cursor: pointer">
              <!-- <i class="el-icon-edit-outline"></i> -->
              {{ item.liveName }}
            </div>
            <div style="color: #92929d; cursor: pointer">
              <!-- <i class="el-icon-more"></i> -->
            </div>
          </div>
          <!-- <div class="nameManagement">
            <div style="font-size: 12px">
              {{ item.liveDescribe }}
            </div>
            <div style="cursor: pointer">
            </div>
          </div> -->
          <div style="width: 100%; height: 30px; display: flex;position: absolute;top: 225px">
            <div class="iconManagement" v-for="(list, index) in item.liveProjectPersonnelVOS" :key="list.index" v-show="item.liveProjectPersonnelVOS.length >= 2&&index < 3">
              <!-- <img :src="list.avatar" alt="" width="100%" height="100%" /> -->
              <div v-show="index == 0" class="box" style="background-color: #ffc542">{{ list.userNameZ }}</div>
              <div v-show="index == 1" class="box" style="background-color: #4287ff">{{ list.userNameZ }}</div>
              <div v-show="index == 2" class="box" style="background-color: #f56b6b">{{ list.userNameZ }}</div>
              <!-- <div v-show="index > 2" style="display: none;">{{ list.userNameZ }}</div> -->
            </div>
            <div v-if="item.liveProjectPersonnelVOS.length === 4 || item.liveProjectPersonnelVOS.length > 3" style="width: 24px;height: 24px;border-radius: 15px;overflow: hidden;">
              <img src="@/assets/imgs/dayu3.png" alt="" width="100%" height="100%" />
            </div>
          </div>
        </div>
        <!-- <div class="round1" v-show="item.id == showId" @blur="handleBlur">
          <div class="round-world">权限设置</div>
          <div class="round-world">删除</div>
        </div> -->
      </div>
    </div>
    <!-- <div :class="wrap" style="display: flex;justify-content: space-between;flex-wrap: wrap;grid-gap: 0 1px; padding-bottom: 50px;margin-top: 10px">
      <div v-for="(item, index) in teamList2" :key="item.index" class="projectManagement1" @click="projectEvn(item)">
        <div style="width: 100%; height: 260px;align-items: center; position: relative;display: flex;align-items: center;" v-show="item.coverDirection==1">
          <img :src="ossUrl + item.coverUrl" alt="" style="max-width: 100%;max-height: 100%; display: block;margin: auto;" />
        </div>
        <div style="width: 100%; height: 260px;align-items: center; position: relative;display: flex;align-items: center;overflow: hidden;" v-show="item.coverDirection==2">
          <img :src="ossUrl + item.coverUrl" alt="" style="width: 100%;" />
        </div>
        <div class="wordManagement">
          <div class="nameManagement" style="">
            <div style="font-size: 14px; font-weight: 600; cursor: pointer">
              {{ item.liveName }}
            </div>
            <div style="color: #92929d; cursor: pointer">
            </div>
          </div>
          <div class="nameManagement">
            <div style="font-size: 12px">
              {{ item.liveDescribe }}
            </div>
            <div style="cursor: pointer">
            </div>
          </div>
          <div style="width: 100%; height: 30px; display: flex">
            <div class="iconManagement" v-for="(list, index) in item.liveProjectPersonnelVOS" :key="list.index">
              <div class="box">{{ list.userNameZ }}</div>
            </div>
            <div v-if="item.liveProjectPersonnelVOS.length === 3 || item.liveProjectPersonnelVOS.length > 3" class="iconManagement">
              <img src="@/assets/imgs/dayu3.png" alt="" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- </div> -->
  </div>
</template>
  
<script>
import { informationId, getDictList, teamUserEquity, getCount, getOssPrefix } from '@/api/information';
import { grtMaterialList, getPut, getDel, liveProjectPersonnel, grtLiveProjectPersonnelt, liveProjectPersonnelPut, liveProjectPersonnelPost } from '@/api/project';
import Cookies from 'js-cookie'
export default {
  name: 'centre',
  data() {
    return {
      name: '',
      teamNAme: '',
      phone: '',
      nickname: '',
      freeWord: '',
      ordinaryWord: '',
      seniorWord: '',
      menberType: 0,
      voiceMenberType: "",
      avatar: localStorage.getItem('avatar'),
      cloudSpaceSize: '',
      useCloudSpaceSize: '',
      teamId: localStorage.getItem('teamId'),
      teamNum: '',
      userNum: '',
      teamList: [],
      liveName: '',
      describe: '',
      liveProjectPersonnelVOS: [],
      ossUrl: '',
      teamList1: [],
      teamList2: [],
      wrap: "wrap"
    };
  },
  created() {
    this.informationEvn();
    this.questionType();
    this.userEquity();
    this.getCountD();
    this.getTeamUser();
    this.getOss();

  },
  methods: {
    advertisingSpace() {
      window.open('https://jx0vothcok0.feishu.cn/wiki/C32uw3viLiUBJFkyWTAcqijsnCd', '_blank');
    },
    async getTeamUser() {
      let a = {
        teamId: localStorage.getItem('teamId'),
        current: this.page,
        size: this.limit
      };
      const res = await grtMaterialList(a);
   
      let list = res.data.data.records;
      if (list.length > 0 && list.length < 10) {
        for (let i = 0; i < list.length; i++) {
          this.teamList.push(list[i]);
        }
      }
      if (list.length >= 10) {
        for (let i = 0; i < 10; i++) {
          this.teamList.push(list[i]);
        }
      }
      this.teamList.forEach((item) => {
        item.liveProjectPersonnelVOS.forEach((v) => {
      
          if (v.userName) {
            v.userNameZ = v.userName.substring(0, 1);
          }
        });
      });
      if (this.teamList.length <= 10) {
        this.teamList1 = this.teamList
      } else {
        this.teamList2 = this.teamList.slice(0, this.teamList.length)
      }
   
      // if (this.teamList.length > 5 && this.teamList.length <= 10) {
      //   this.teamList1 = this.teamList.slice(0, 5)
      //   this.teamList2 = this.teamList.slice(5, this.teamList.length)
      // }
   
    },

    projectEvn(list) {
      
      list.coverUrl = window.localStorage.getItem("OssPrefix") + "/" + list.coverUrl;
      this.liveName = list.liveName;
      this.describe = list.liveDescribe;
      this.liveProjectPersonnelVOS = list.liveProjectPersonnelVOS;
      this.$router.push({ path: '/order/projectDetails', query: list });
    },
    async getOss() {
      const res = await getOssPrefix();
      let oss = res.data.data + '/';
      this.ossUrl = oss;
      localStorage.setItem('oss', oss);
    },
    async getCountD() {
      const res = await getCount(this.teamId);

      this.teamNum = res.data.data.teamNum;
      this.userNum = res.data.data.userNum;
    },
    upLode() {
      window.open('https://hello.yohelo.com/#/help/download', '_blank');
      // http://localhost:8080/#/help/download
    },
    refresh() {
      this.$router.push("/customized").catch((error) => error);
      // window.open('http://hello.yohelo.com/#/customized', '_blank');
    },
    helpCen() {

      window.open('https://jx0vothcok0.feishu.cn/docx/V32idJ3w4oh6pMxO3lgciMVbnbe', '_blank');
    },
    async userEquity() {
      let a = {
        teamId: localStorage.getItem('teamId'),
        userId: localStorage.getItem('userId')
      };
      const res = await teamUserEquity(a);
      this.isAllow = res.data.data.isAllow;
      // this.avatar = res.data.data.avatar
      this.remainingDuration = parseInt(res.data.data.remainingDuration / 60);
      this.voiceRemainingDuration = res.data.data.voiceRemainingDuration / 60;
      this.leavrNum = res.data.data.leavrNum;
      this.cloudSpaceSize = res.data.data.cloudSpaceSize;
      this.useCloudSpaceSize = res.data.data.useCloudSpaceSize
  
    },
    async questionType() {
      let data = {
        code: 'vip_type'
      };
      const res = await getDictList(data);
    
      this.freeWord = res.data.data[0].children[0].dictValue;
      this.ordinaryWord = res.data.data[0].children[1].dictValue;
      this.seniorWord = res.data.data[0].children[2].dictValue;
    },
    async informationEvn() {
      const res = await informationId(localStorage.getItem('userId'), localStorage.getItem('teamId'));
      this.avatar = res.data.data.avatar
      this.name = res.data.data.name;
      this.teamNAme = res.data.data.teamNAme;
      this.phone = res.data.data.phone;
      this.nickname = res.data.data.nickname;
      this.menberType = res.data.data.menberType;
      this.voiceMenberType = res.data.data.voiceMenberType;
    },
    setBtn() {
      this.$router.push('/member').catch((error) => error);
    }
  }
};
</script>
  
<style lang="less" scoped>
.wrap::after {
  // content: '';
  // width: 390px;
}
.projectManagement1 {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 195px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.16);
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 1px 1px 2px rgb(196, 196, 196);
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  // flex-shrink: 0;

  .roundStyle {
    width: 20px;
    padding-left: 15px;
    position: absolute;
    bottom: 0px;
    right: 12px;
    z-index: 9999;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .round {
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: #fff;
    margin-top: 2px;
    border-radius: 3px;
    margin-left: -2px;
  }

  .round1 {
    width: 120px;
    height: 60px;
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: 99;
    top: 290px;
    left: 95px;
    // padding: 10px 20px;
    overflow: hidden;
    border: 1px solid #c6c6c6;

    .round-world {
      color: #000;
      font-size: 12px;
      // margin-top: 10px;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
    }

    .round-world:hover {
      background-color: #c6c6c6;
    }
  }

  .wordManagement {
    width: 100%;
    height: 40px;
    background-color: #fff;
    padding: 5px;

    .nameManagement {
      width: 100%;
      height: 25px;
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }

    .iconManagement {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 15px;
      // background-color: #ffc542;
      color: #fff;
      margin-right: 5px;
      overflow: hidden;
      .box {
        width: 24px;
        height: 24px;
        background: #4287ff;
        border-radius: 25px;
        font-size: 14px;
      }
    }
  }
}
</style>