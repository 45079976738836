<template>
  <div class="agent">
    <div class="one-box">
      <div class="logo">
        <img src="@/assets/imgs/agent_logo_m.png" alt="" />
      </div>
      <div class="title"><img src="@/assets/imgs/agent_1-title_m.png" alt="" /></div>
      <div class="content">
        <div>将直播内容和直播经营权委托给我们专业直播运</div>
        <div>营团队，我们会代替主播或品牌负责直播节目的</div>
        <div>策划、制作、推广和运营等工作。</div>
      </div>
      <div class="main">
        <img style="width: 100vw" src="@/assets/imgs/agent_1-main_m.png" alt="" />
      </div>
    </div>
    <div class="two-box">
      <div class="title">
        <div class="text"><span style="color: #243042">行业</span>痛点</div>
      </div>
      <div class="icon">
        <div class="left-icon">
          <img style="width: 100px; height: 80px" src="@/assets/imgs/left_m.gif" alt="" />
        </div>
        <div class="right-icon">
          <img
            style="width: 100px; height: 80px"
            src="@/assets/imgs/right_m.gif"
            alt=""
          />
        </div>
      </div>

      <div class="swiper-box">
        <swiper ref="videoSwiper" :options="swiperOption" :allowTouchMove="false">
          <swiper-item class="swiper-slide">
            <div class="item-box1"></div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box2"></div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box3"></div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box4"></div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box5"></div>
          </swiper-item>
        </swiper>
      </div>
    </div>
    <div class="three-box">
      <div class="title">
        <div class="text">服务内容</div>
      </div>
      <div class="box">
        <div class="box-main">
          <div class="left">
            <div class="top">
              <div class="icon">
                <img src="@/assets/imgs/agent_3-left_m.png" alt="" />
              </div>
              <div class="text">基础服务</div>
            </div>
          </div>
          <div class="right">
            <div class="top">
              <div class="icon">
                <img src="@/assets/imgs/agent_3-right_m.png" alt="" />
              </div>
              <div class="text">增值服务</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="four-box">
      <div class="title">
        <div class="text">我们的优势</div>
      </div>
      <div class="content">
        保证我们的代运营数字人直播能够帮助企业和个人解决直播过程中的各种问题，提高直播效果和影响力
      </div>
      <div class="icon">
        <div class="left-icon">
          <img style="width: 100px; height: 80px" src="@/assets/imgs/left_m.gif" alt="" />
        </div>
        <div class="right-icon">
          <img
            style="width: 100px; height: 80px"
            src="@/assets/imgs/right_m.gif"
            alt=""
          />
        </div>
      </div>
      <div class="swiper-box2">
        <swiper ref="videoSwiper" :options="swiperOption2" :allowTouchMove="false">
          <swiper-item class="swiper-slide">
            <div class="item-box1">
              <div class="logo-box">
                <img
                  style="width: 28px; height: 28px"
                  src="@/assets/imgs/agent_4_1-1_m.png"
                  alt=""
                />
              </div>
              <div class="title-box">去增加曝光率</div>
              <div class="text-box">
                我们可以结合直播的特点和平台的优势，为客户提供多样化的营销手段。
                例如:可以进行直播促销、合作推广、产品推荐等，从而提高销售和转化效果。
              </div>
              <div class="img-box">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/imgs/agent_4_1-2_m.png"
                  alt=""
                />
              </div>
            </div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box2">
              <div class="logo-box">
                <img
                  style="width: 28px; height: 28px"
                  src="@/assets/imgs/agent_4_2-1_m.png"
                  alt=""
                />
              </div>
              <div class="title-box">专业的团队策划</div>
              <div class="text-box">
                我们拥有专业的直播团队，包括策划、运营、技术支持等人员，具有丰富的直播经验和专业知识，能够为客户提供全方位的直播服务。同时，还具备专业的直播技术，能够确保直播的稳定性和流畅性，提供高质量的直播体验。
              </div>
              <div class="img-box">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/imgs/agent_4_2-2_m.png"
                  alt=""
                />
              </div>
            </div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box3">
              <div class="logo-box">
                <img
                  style="width: 28px; height: 28px"
                  src="@/assets/imgs/agent_4_3-1_m.png"
                  alt=""
                />
              </div>
              <div class="title-box">提高直播质量和效果</div>
              <div class="text-box">
                我们能够提供高质量的直播内容和流畅的直播体验。同时，我们还会根据客户的需求和目标受众，制定合适的直播策路和推广方案，从而提高直播的效果和影响力。
              </div>
              <div class="img-box">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/imgs/agent_4_3-2_m.png"
                  alt=""
                />
              </div>
            </div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box4">
              <div class="logo-box">
                <img
                  style="width: 28px; height: 28px"
                  src="@/assets/imgs/agent_4_4-1_m.png"
                  alt=""
                />
              </div>
              <div class="title-box">节省人工、运营、硬件等成本</div>
              <div class="text-box">
                通过代运营直播，客户可以节省大量的人力、物力和时间成本。我们会负责直播的策划、执行和后期运营等工作，客户无需组建专业的直播团队，也无需投入大量的时间和精力进行直播准备和运营。
              </div>
              <div class="img-box">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/imgs/agent_4_4-2_m.png"
                  alt=""
                />
              </div>
            </div>
          </swiper-item>
        </swiper>
      </div>
    </div>
    <div class="five-box">
      <div class="title">
        <div class="text">客户案例</div>
      </div>
      <div class="item1">
        <div class="left">
          <img
            style="width: 70px; height: 70px"
            src="@/assets/imgs/agent_logo2_m.png"
            alt=""
          />
        </div>
        <div class="right">
          <div class="right-title">尚链通科技</div>
          <div class="right-text">
            <div>通过我们的代运营数字人直播批量定</div>
            <div>制搭建数字人直播间...</div>
          </div>
        </div>
      </div>
      <div class="item2">
        <div class="left">
          <div class="right-title">尚链通科技</div>
          <div class="right-text">
            <div>通过我们的代运营数字人直播批量定</div>
            <div>制搭建数字人直播间...</div>
          </div>
        </div>
        <div class="right">
          <img
            style="width: 70px; height: 70px"
            src="@/assets/imgs/agent_logo2_m.png"
            alt=""
          />
        </div>
      </div>
      <div class="item3">
        <div class="left">
          <img
            style="width: 70px; height: 70px"
            src="@/assets/imgs/agent_logo2_m.png"
            alt=""
          />
        </div>
        <div class="right">
          <div class="right-title">尚链通科技</div>
          <div class="right-text">
            <div>通过我们的代运营数字人直播批量定</div>
            <div>制搭建数字人直播间...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="six-box">
      <div class="six-title">咨询专业顾问</div>
      <div class="six-text">关于使用场景和产品方案的更多咨询，请联系我们的销售团队</div>
      <div class="six-qrcode">
        <img style="width: 70px" src="@/assets/imgs/qrcode_m.png" alt="" />
      </div>
      <div class="six-btn">
        <div class="btn">立即咨询</div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "agent",
  components: { swiper, swiperSlide },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination", // 与slot="pagination"处 class 一致
          clickable: true, // 轮播按钮支持点击
        },
        //点击事件

        // 自动播放
        // autoplay: {
        //   delay: 10000,
        //   disableOnInteraction: false
        // },
        // 循环
        loop: true,
        spaceBetween: 15, // 轮播图左右间距
        slidesPerView: 1.3, // 一屏显示的slide个数，图片宽度
        centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
        slideToClickedSlide: true, // 点击的slide会居中,
        allowTouchMove: true,
      },
      swiperOption2: {
        pagination: {
          el: ".swiper-pagination", // 与slot="pagination"处 class 一致
          clickable: true, // 轮播按钮支持点击
        },
        //点击事件

        // 自动播放
        // autoplay: {
        //   delay: 10000,
        //   disableOnInteraction: false
        // },
        // 循环
        loop: true,
        spaceBetween: 15, // 轮播图左右间距
        slidesPerView: 1, // 一屏显示的slide个数，图片宽度
        centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
        slideToClickedSlide: true, // 点击的slide会居中,
        allowTouchMove: true,
      },
    };
  },
};
</script>

<style lang="less" scoped>
.agent {
  width: 100vw;
  height: 100%;
  text-align: left;
  .one-box {
    width: 100vw;
    height: 529px;
    background-image: url("@/assets/imgs/agent_1-bg_m.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 11px;
    .logo {
      margin-left: 20px;
    }
    .title {
      margin-top: 30px;
      width: 100vw;
      display: flex;
      justify-content: center;
      img {
        width: 285px;
        height: 75px;
      }
    }
    .content {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 34px;
      text-align: center;
    }
    .main {
      text-align: center;
      margin-top: 41px;
    }
  }
  .two-box {
    width: 100vw;
    height: 320px;
    background-image: url("@/assets/imgs/agent_2-bg_m.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .title {
      width: 143px;
      height: 52px;
      background-image: url("@/assets/imgs/agent_2-title_m.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      margin-left: 30%;
      .text {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 30px;
        color: #8b5ef5;
        line-height: 42px;
        text-align: center;
      }
    }
    .swiper-box {
      margin-top: 45px;
      .swiper-container {
        width: 100vw;
        overflow: hidden;
        // margin-top: 200px;
        // height: 100%;
        // height: 164.95px;
        .swiper-slide {
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          //   -webkit-align-items: center;
          //   align-items: center;
          border-radius: 10px;
          transition: 300ms;
          transform: scale(1); //小图缩放比例
          overflow: hidden;
          .item-box1 {
            width: 300px;
            height: 165px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/demo11_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .item-box2 {
            width: 300px;
            height: 165px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/demo22_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .item-box3 {
            width: 300px;
            height: 165px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/demo33_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .item-box4 {
            width: 300px;
            height: 165px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/demo44_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .item-box5 {
            width: 300px;
            height: 165px;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/demo55_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
        .swiper-slide-active,
        .swiper-slide-duplicate-active {
          width: 600px;
          transform: scale(1);
        }
      }
    }
    .icon {
      width: 100vw;
      display: flex;
      position: relative;
      .left-icon {
        position: absolute;
        z-index: 5;
        top: 85px;
        left: -25px;
      }
      .right-icon {
        position: absolute;
        z-index: 5;
        top: 85px;
        right: -25px;
      }
    }
  }
  .three-box {
    width: 100vw;
    height: 350px;
    background-image: url("@/assets/imgs/agent_3-bg_m.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .title {
      width: 143px;
      height: 52px;
      background-image: url("@/assets/imgs/agent_2-title_m.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      margin-left: 30%;
      .text {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 30px;
        color: #243042;
        line-height: 42px;
        text-align: center;
      }
    }
    .box {
      display: flex;
      justify-content: center;
      width: 100vw;
      height: 221px;
      margin-top: 31px;
      .box-main {
        width: 337px;
        height: 221px;
        background-image: url("@/assets/imgs/agent_3-box_m.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        .left {
          width: 50%;
          height: 100%;
          .top {
            height: 45px;
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-left: 11px;
            .icon {
            }
            .text {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              font-size: 16px;
              color: #080808;
              line-height: 42px;
            }
          }
        }
        .right {
          width: 50%;
          height: 100%;
          .top {
            height: 45px;
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-left: 11px;
            .icon {
            }
            .text {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              font-size: 16px;
              color: #080808;
              line-height: 42px;
            }
          }
        }
      }
    }
  }
  .four-box {
    width: 100vw;
    height: 760px;
    background-image: url("@/assets/imgs/agent_4-bg_m.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .title {
      width: 179px;
      height: 52px;
      background-image: url("@/assets/imgs/agent_3-title_m.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      margin-left: 26%;
      .text {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 30px;
        color: #243042;
        line-height: 42px;
        text-align: center;
      }
    }
    .content {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 14px;
      color: #aaa8ab;
      line-height: 24px;
      text-align: center;
      margin-top: 13px;
    }
    .icon {
      width: 100vw;
      display: flex;
      position: relative;
      .left-icon {
        position: absolute;
        z-index: 5;
        top: 260px;
        left: -25px;
      }
      .right-icon {
        position: absolute;
        z-index: 5;
        top: 260px;
        right: -25px;
      }
    }
    .swiper-box2 {
      margin-top: 30px;
      .swiper-container {
        width: 100vw;
        overflow: hidden;
        // margin-top: 200px;
        // height: 100%;
        // height: 164.95px;
        .swiper-slide {
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          //   -webkit-align-items: center;
          //   align-items: center;
          border-radius: 10px;
          transition: 300ms;
          transform: scale(1); //小图缩放比例
          overflow: hidden;
          .item-box1 {
            width: 316.36px;
            height: 540px;
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/agent_4_bg_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .logo-box {
              display: flex;
              justify-content: center;
              margin-top: 25px;
            }
            .title-box {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 21px;
              color: #243042;
              line-height: 40px;
              text-align: center;
            }
            .text-box {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              line-height: 28px;
              text-align: center;
              padding: 0 25px;
              margin-top: 5px;
              position: relative;
              z-index: 9;
            }
            .img-box {
              width: 316px;
              height: 296px;
              position: absolute;
              bottom: 8px;
            }
          }
          .item-box2 {
            width: 316.36px;
            height: 540px;
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/agent_4_bg_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .logo-box {
              display: flex;
              justify-content: center;
              margin-top: 25px;
            }
            .title-box {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 21px;
              color: #243042;
              line-height: 40px;
              text-align: center;
            }
            .text-box {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              line-height: 28px;
              text-align: center;
              padding: 0 25px;
              margin-top: 5px;
              position: relative;
              z-index: 9;
            }
            .img-box {
              width: 316px;
              height: 296px;
              position: absolute;
              bottom: 8px;
            }
          }
          .item-box3 {
            width: 316.36px;
            height: 540px;
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/agent_4_bg_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .logo-box {
              display: flex;
              justify-content: center;
              margin-top: 25px;
            }
            .title-box {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 21px;
              color: #243042;
              line-height: 40px;
              text-align: center;
            }
            .text-box {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              line-height: 28px;
              text-align: center;
              padding: 0 25px;
              margin-top: 5px;
              position: relative;
              z-index: 9;
            }
            .img-box {
              width: 316px;
              height: 296px;
              position: absolute;
              bottom: 8px;
            }
          }
          .item-box4 {
            width: 316.36px;
            height: 540px;
            border-radius: 10px 10px 10px 10px;
            background-image: url("@/assets/imgs/agent_4_bg_m.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .logo-box {
              display: flex;
              justify-content: center;
              margin-top: 25px;
            }
            .title-box {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 21px;
              color: #243042;
              line-height: 40px;
              text-align: center;
            }
            .text-box {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              line-height: 28px;
              text-align: center;
              padding: 0 25px;
              margin-top: 5px;
              position: relative;
              z-index: 9;
            }
            .img-box {
              width: 316px;
              height: 296px;
              position: absolute;
              bottom: 8px;
            }
          }
        }
        .swiper-slide-active,
        .swiper-slide-duplicate-active {
          width: 600px;
          transform: scale(1);
        }
      }
    }
  }
  .five-box {
    width: 100vw;
    height: 420px;
    background: linear-gradient(360deg, #5145e3 0%, #cbd9fe 100%);
    margin-top: -2px;
    padding-top: 25px;
    .title {
      width: 143px;
      height: 52px;
      background-image: url("@/assets/imgs/agent_2-title_m.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      margin-left: 30%;
      .text {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 30px;
        color: #243042;
        line-height: 42px;
        text-align: center;
      }
    }
    .item1 {
      width: 361px;
      height: 108px;
      background-image: url("@/assets/imgs/agent-5-1_m.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      .left {
        margin-left: 32px;
      }
      .right {
        margin-left: 13px;
        .right-title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 600;
          font-size: 16px;
          color: #243042;
          line-height: 24px;
          text-align: left;
        }
        .right-text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 14px;
          color: #243042;
          line-height: 20px;
          text-align: left;
        }
      }
    }
    .item2 {
      width: 361px;
      height: 108px;
      background-image: url("@/assets/imgs/agent-5-2_m.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      .left {
        margin-left: 26px;
        .right-title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 600;
          font-size: 16px;
          color: #243042;
          line-height: 24px;
          text-align: right;
        }
        .right-text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 14px;
          color: #243042;
          line-height: 20px;
          text-align: right;
        }
      }
      .right {
        margin-left: 13px;
      }
    }
    .item3 {
      width: 361px;
      height: 108px;
      background-image: url("@/assets/imgs/agent-5-1_m.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      .left {
        margin-left: 32px;
      }
      .right {
        margin-left: 13px;
        .right-title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 600;
          font-size: 16px;
          color: #243042;
          line-height: 24px;
          text-align: left;
        }
        .right-text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 14px;
          color: #243042;
          line-height: 20px;
          text-align: left;
        }
      }
    }
  }
  .six-box {
    width: 100vw;
    height: 303px;
    background-image: url("@/assets/imgs/agent_6-bg_m.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -2px;
    padding-top: 48px;
    .six-title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 600;
      font-size: 30px;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
    }
    .six-text {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #f2f3ff;
      line-height: 28px;
      text-align: center;
      margin-top: 11px;
    }
    .six-qrcode {
      display: flex;
      justify-content: center;
      padding-top: 14px;
    }
    .six-btn {
      width: 100vw;
      height: 42px;
      display: flex;
      justify-content: center;
      padding-top: 27px;
      .btn {
        width: 144px;
        height: 42px;
        background: #ffffff;
        box-shadow: 0px 1px 3px 1px rgba(108, 45, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #8b5ef5;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}
</style>
