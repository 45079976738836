<template>
  <!-- 底部 -->
  <div class="mainStyle-seven" :class="{ mainStyleSeven2: isActive }">
    <div class="seven-box">
      <div class="seven-top" :class="{ sevenTop2: isActive }">
        <div class="left">
          <img src="@/assets/imgs/logo-z.png" alt="" />
        </div>
        <div class="right">
          <div class="weixin">
            <div class="code">
              <img
                style="width: 95px; height: 95px"
                src="@/assets/imgs/weixin-q.png"
                alt=""
              />
            </div>
            <div class="text">专属客服</div>
          </div>
          <div class="douyin">
            <div class="code">
              <img
                style="width: 100px; height: 100px"
                src="@/assets/imgs/douyin-q.png"
                alt=""
              />
            </div>
            <div class="text">关注抖音号</div>
          </div>
        </div>
      </div>
      <div class="seven-main">
        <div class="item-box">
          <div class="item-title">产品产品</div>
          <div class="item-text">Hello Nvwa</div>
          <div style="display: none" class="item-text">Hello 元小二</div>
          <div style="display: none" class="item-text">Hello 数字人向导</div>
          <div style="display: none" class="item-text">Hello 弹幕游戏</div>
        </div>

        <div class="item-box">
          <div class="item-title">解决方案</div>
          <div @click="gotoDsdh" class="item-text">直播电商</div>
          <div @click="gotoBdsh" class="item-text">本地生活</div>
          <div @click="gotoYljk" class="item-text">医疗健康</div>
          <div @click="gotoJypx" class="item-text">教育培训</div>
          <div @click="gotoLy" class="item-text">旅游</div>
          <div @click="gotoZlzh" class="item-text">展览展会</div>
        </div>

        <div class="item-box">
          <div class="item-title">流量研究中心</div>
          <div @click="gotoFlow()" class="item-text">Nvwa使用教程</div>
          <div class="item-text">直播攻略</div>
        </div>

        <div class="item-box">
          <div class="item-title">协议条款</div>
          <div class="item-text" @click="gotoUserProtocol">服务协议</div>
          <div class="item-text" @click="gotoVipProtocol">隐私条款</div>
        </div>

        <div class="item-box">
          <div style="display: none" class="item-title">商务合作</div>
          <div style="display: none" class="item-text">定制咨询：135 0000 0000</div>
          <div style="display: none" class="item-text">代理咨询：135 0000 0000</div>
          <div style="display: none" class="item-text">售前咨询：135 0000 0000</div>
          <div style="display: none" class="item-text">售后服务：400 - 000 - 000</div>
        </div>

        <div class="item-right">
          <div class="item-title">关于我们</div>
          <div class="item-text">公司名称:湖南河络信息科技有限公司</div>
          <div class="item-text">地址:长沙县天华中路79号明诚商务广场</div>
          <div class="item-text">联系电话：0731-86914481</div>
        </div>
      </div>
      <div class="seven-footer">
        <div class="footer-box">
          Copyright 2024 © yohelo.com |
          <a
            style="color: #b5b5b5; text-decoration: none"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >湘ICP备2023009258号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataColor"], // 定义props属性名称为"data"
  data() {
    return {
      isActive: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (this.dataColor === 2) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  },
  methods: {
    gotoDsdh() {
      this.$router.push({ path: "/solution/dsdh" });
    },
    gotoBdsh() {
      this.$router.push({ path: "/solution/bdsh" });
    },
    gotoYljk() {
      this.$router.push({ path: "/solution/yljk" });
    },
    gotoJypx() {
      this.$router.push({ path: "/solution/jypx" });
    },
    gotoLy() {
      this.$router.push({ path: "/solution/ly" });
    },
    gotoZlzh() {
      this.$router.push({ path: "/solution/zlzh" });
    },
    gotoFlow() {
      this.$router.push({ path: "/tutorial/nvwaLive" });
    },
    gotoUserProtocol() {
      // 链接地址
      var url = "https://hello.yohelo.com/protocol/user-privacy-protocol.html";
      // 打开链接
      window.open(url);
    },
    gotoVipProtocol() {
      // 链接地址
      var url = "https://hello.yohelo.com/protocol/member-vip-protocol.html";
      // 打开链接
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.mainStyle-seven {
  width: 100%;
  min-width: 1200px;
  height: 550px;
  display: flex;
  justify-content: center;
  background-color: #243042;
  .seven-box {
    width: 1200px;
    .seven-top {
      width: 100%;
      height: 177px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #384353;
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: right;
        .weixin {
          margin-right: 90px;
          .code {
            width: 100px;
            height: 100px;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .text {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 13px;
            color: #ffffff;
            line-height: 26px;
            text-align: center;
          }
        }
        .douyin {
          margin-right: 20px;
          .code {
            width: 100px;
            height: 100px;
            // background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .text {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 13px;
            color: #ffffff;
            line-height: 26px;
            text-align: center;
          }
        }
      }
    }
    .sevenTop2 {
      border-bottom: 2px solid #1c1d24;
    }
    .seven-main {
      width: 100%;
      height: 350px;
      display: flex;
      padding-top: 51px;
      .item-box {
        margin-right: 65px;
        .item-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 41px;
          text-align: left;
        }
        .item-text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #b5b5b5;
          line-height: 24px;
          cursor: pointer;
        }
      }
      .item-right {
        margin-left: 260px;
        .item-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 41px;
          text-align: left;
        }
        .item-text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #b5b5b5;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
    .seven-footer {
      width: 100%;
      height: 20px;
      display: flex;
      position: relative;
      top: -25px;
      .footer-box {
        width: 100%;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 12px;
        color: #b5b5b5;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.mainStyleSeven2 {
  background-color: #12141d;
}
</style>
