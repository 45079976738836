<template>
  <div class="nvwaLive">
    <div class="item-box" v-if="openVideo === 1">
      <div class="item" @click="openVideoHandel5"><img src="@/assets/imgs/nvwa-live-m_5.png" alt="" /></div>
      <div class="item" @click="openVideoHandel7"><img src="@/assets/imgs/nvwa-live-m_7.png" alt="" /></div>
      <div class="item" @click="openVideoHandel1"><img src="@/assets/imgs/nvwa-live-m_1.png" alt="" /></div>
      <div class="item" @click="openVideoHandel3"><img src="@/assets/imgs/nvwa-live-m_3.png" alt="" /></div>
      <div class="item" @click="openVideoHandel6"><img src="@/assets/imgs/nvwa-live-m_6.png" alt="" /></div>
      <div class="item" @click="openVideoHandel2"><img src="@/assets/imgs/nvwa-live-m_2.png" alt="" /></div>
      <div class="item" @click="openVideoHandel9"><img src="@/assets/imgs/nvwa-live-m_9.png" alt="" /></div>
      <!-- <div class="item" @click="openVideoHandel10"><img src="@/assets/imgs/nvwa-live-m_10.png" alt="" /></div>
      <div class="item" @click="openVideoHandel11"><img src="@/assets/imgs/nvwa-live-m_11.png" alt="" /></div>
      <div class="item" @click="openVideoHandel4"><img src="@/assets/imgs/nvwa-live-m_4.png" alt="" /></div> -->

      <!-- <div class="item" @click="openVideoHandel8"><img src="@/assets/imgs/nvwa-live-m_8.png" alt="" /></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "nvwaLive-m",
  components: {},
  data() {
    return {
      openVideo: 1,
      playerSum: 1,
      playTime: "00:00",
      totalTime: "",
      value1: 0,
      sum1: "",
      sum2: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
  },
  methods: {
    openVideoHandel1() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo1' });
    },
    openVideoHandel2() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo2' });
    },
    openVideoHandel3() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo3' });
    },
    openVideoHandel4() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo4' });
    },
    openVideoHandel5() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo5' });
    },
    openVideoHandel6() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo6' });
    },
    openVideoHandel7() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo7' });
    },
    openVideoHandel8() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo8' });
    },
    openVideoHandel9() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo9' });
    },
    openVideoHandel10() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo10' });
    },
    openVideoHandel11() {
      this.$router.push({ path: '/tutorial/viewBox/viewVideo11' });
    },
    playVideo() {
      this.$refs.videoPlayer.play();
      this.playerSum = 2;
    },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
      this.playerSum = 1;
    },
    changeValue(v) {
      console.log(this.$refs.videoPlayer.player);
      var video = document.getElementById("myVideo");
      video.currentTime = (v / 100) * this.sum1; // 设置播放位置为10秒
      video.play(); // 播放视频
      this.playerSum = 2;
    },
    // 视频时长转时分秒
    durationTrans(a) {
      if (!a || a < 1) {
        return "00:01";
      }
      let duration = "";
      let h = parseInt(a / 3600),
        m = parseInt((a % 3600) / 60),
        s = parseInt((a % 3600) % 60);
      if (h > 0) {
        h = h < 10 ? "0" + h : h;
        duration += h + ":";
      }
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      duration += m + ":" + s;
      return duration;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-slider__button {
  width: 12px;
  height: 12px;
  border: 0;
  margin-top: -6px;
}
::v-deep .el-slider__runway {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-color: #000000;
}
::v-deep .el-slider__bar {
  height: 1px;
  background-color: #ffffff;
}
.nvwaLive {
  width: 100%;
  //   padding-top: 20px;
  .item-box {
    width: 100%;
    // height: calc(100% - 54px);
    padding: 0px 20px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 20px;
    .item {
      position: relative;
      height: 300px;
      flex: 0 0 calc((100% - 20px) / 2);
      margin-top: 10px;
      margin-left: 10px;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item:nth-child(1) {
      margin-top: 0px;
    }
    .item:nth-child(2) {
      margin-top: 0px;
    }
  }
  .view-box {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .playBox {
      position: fixed;
      bottom: 0px;
      width: 100%;
      height: 60px;
      background: #111214;
      .box {
        height: 60px;
        display: flex;
        align-items: center;
        .iconPlay {
          margin-left: 10px;
        }
        .iconpause {
          margin-left: 10px;
        }
        .progress {
          width: 100%;
          height: 60px;
          padding: 0 15px;
          display: flex;
          align-items: center;
          .line {
            width: 100%;
            position: relative;
            top: -2px;
          }
        }
        .time-box {
          display: flex;
          margin-right: 10px;
          .playTime {
            color: #ffffff;
            font-size: 12px;
          }
          .icon {
            color: #ffffff;
            font-size: 12px;
            margin: 0 3px;
          }
          .totalTime {
            color: #747474;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
