<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent></headComponent>
    <div class="mainStyle">
      <div class="img-box">
        <img class="images" src="@/assets/imgs/flow-bg.png" alt="" />
      </div>
      <div class="mainStyle-box">
        <div class="left">
          <div>
            <img src="@/assets/imgs/flowCenter-left.png" alt="" />
          </div>
        </div>
        <div class="right">
          <img src="@/assets/imgs/flow-right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="push-tab">
      <div class="tab-box">
        <div class="title">如何获取推流码</div>
        <div class="item-box">
          <div @click="pushCode = 1" class="item">
            <div class="images">
              <img v-if="pushCode === 1" src="@/assets/imgs/code-douyin.png" alt="" />
              <img v-else src="@/assets/imgs/code-douyin-fill.png" alt="" />
            </div>
            <div class="text">抖音</div>
            <div v-show="pushCode === 1" class="border"></div>
          </div>
          <div @click="pushCode = 2" class="item">
            <div class="images">
              <img v-if="pushCode === 2" src="@/assets/imgs/code-bilibili.png" alt="" />
              <img v-else src="@/assets/imgs/code-bilibili-fill.png" alt="" />
            </div>
            <div class="text">哔哩哔哩</div>
            <div v-show="pushCode === 2" class="border"></div>
          </div>
          <div @click="pushCode = 3" class="item">
            <div class="images">
              <img v-if="pushCode === 3" src="@/assets/imgs/code-xiaohongshu.png" alt="" />
              <img v-else src="@/assets/imgs/code-xiaohongshu-fill.png" alt="" />
            </div>
            <div class="text">小红书</div>
            <div v-show="pushCode === 3" class="border"></div>
          </div>
          <div @click="pushCode = 4" class="item">
            <div class="images">
              <img v-if="pushCode === 4" src="@/assets/imgs/code-douyu.png" alt="" />
              <img v-else src="@/assets/imgs/code-douyu-fill.png" alt="" />
            </div>
            <div class="text">斗鱼</div>
            <div v-show="pushCode === 4" class="border"></div>
          </div>
          <div @click="pushCode = 5" class="item">
            <div class="images">
              <img v-if="pushCode === 5" src="@/assets/imgs/code-huya.png" alt="" />
              <img v-else src="@/assets/imgs/code-huya-fill.png" alt="" />
            </div>
            <div class="text">虎牙</div>
            <div v-show="pushCode === 5" class="border"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pushCode === 1" class="douyin">
      <div class="douyin-main">
        <div class="title">抖音官方不支持获取推流码，请在遵循抖音官方政策的情况下合理使用第三方合规软件 获取推流码（以下第三方工具获取推流码仅供研究学习使用）。 如：</div>
        <div class="one-box">
          <div class="one">
            <div class="one-img">
              <img src="@/assets/imgs/one.png" alt="" />
            </div>
            <div class="one-text">小迪推流</div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">(注意：使用小迪推流时，手机端抖音打开直播，获取推流码以后，需要开启飞行模式或者退出抖音登录。) 打开小迪推流助手，点击【提取推流码】</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy1-1.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">登录抖音账号</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy1-2.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">手机端打开抖音，点击【+】号，选择【开直播】，点击【开始视频直播】</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy1-3.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">4</div>
              <div class="text">分别点击【复制】，复制服务器地址以及推流码</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy1-4.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">5</div>
              <div class="text">打开hello数字人平台【设置】-【直播】，粘贴即可</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy1-5.png" alt="" />
            </div>
          </div>
        </div>
        <div class="two-box">
          <div class="two">
            <div class="two-img">
              <img src="@/assets/imgs/two.png" alt="" />
            </div>
            <div class="two-text">魔豆推流</div>
          </div>
          <div class="two-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">打开第三方软件，输入账号、密码后点击登录，登录后，选择抖音平台，然后点击提取</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy2-1.png" alt="" />
            </div>
          </div>
          <div class="two-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">弹出二维码提示框后，打开抖音抖音直播伴侣</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy2-2.png" alt="" />
            </div>
          </div>
          <div class="two-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">登录账号后，点击“ 开始直播 ”，开始直播后会自动关闭抖音直播伴侣</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy2-3.png" alt="" />
            </div>
          </div>
          <div class="two-one">
            <div class="one-box">
              <div class="text-num">4</div>
              <div class="text">分第三方平台已获取到推流地址，如图：</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy2-4.png" alt="" />
            </div>
          </div>
          <div class="two-one">
            <div class="one-box">
              <div class="text-num">5</div>
              <div class="text">打开hello数字人平台【设置】-【直播】，粘贴即可</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/dy2-5.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pushCode === 2" class="bilibili">
      <div class="bilibili-main">
        <div class="one-box">
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">
                <div>打开B站网页端直播中心https://live.bilibili.com/，登录需要直播的B站账号（注意选择已实名认证开通直播功能的B站账号）</div>
                <div>点击【用户头像】-【直播中心】</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/bili1-1.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">选择【开播设置】，填写基本信息后点击【开始直播】</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/bili1-2.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">复制推流地址以及推流码</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/bili1-3.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">4</div>
              <div class="text">粘贴至hello数字人主播助手</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/bili1-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pushCode === 3" class="xhs">
      <div class="xhs-main">
        <div class="one-box">
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">
                <div>开通小红书obs推流权限：进入电脑小红书直播助手网页版，登录后点击【去obs开播】</div>
                <div>https://www.xiaohongshu.com/zhibo/robs</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/xhs1-1.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">
                <div>获取小红书验证码，用手机访问小红书手机端。</div>
                <div>点击小红书手机端直播→电脑→复制验证码</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/xhs1-2.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">复制推流地址以及推流码</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/xhs1-3.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">4</div>
              <div class="text">点击串流密钥。复制推流地址及推流码</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/xhs1-4.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">5</div>
              <div class="text">粘贴至hello数字人助手</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/xhs1-5.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pushCode === 4" class="douyu">
      <div class="douyu-main">
        <div class="one-box">
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">
                <div>打开斗鱼官网进行登录。</div>
                <div>点击个人中心，最下面一栏【我要直播】</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/douyu-1.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">
                <div>【开启斗鱼直播之旅】（没有认证的先进行实名认证）</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/douyu-2.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">复制推流地址以及推流码</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/douyu-3.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">4</div>
              <div class="text">复制推流地址以及推流码粘贴至hello数字人直播助手即可。</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/douyu-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pushCode === 5" class="huya">
      <div class="huya-main">
        <div class="one-box">
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">
                <div>完成虎牙开播认证（共三步）</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/huya-1.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">
                <div>【开启虎牙直播之旅】（没有认证的先进行实名认证）</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/huya-2.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">复制推流地址以及推流码</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/huya-3.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">4</div>
              <div class="text">粘贴至hello数字人直播助手服务器及推流码处</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/huya-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerComponent></footerComponent>
  </div>
</template>

<script>
import headComponent from '@/views/component/headComponent.vue';
import footerComponent from '@/views/component/footerComponent.vue';
export default {
  name: 'HomeView',
  components: { headComponent, footerComponent },
  data() {
    return {
      pushCode: 1
    };
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
  .mainStyle {
    margin-top: 64px;
    flex-grow: 1;
    min-width: 1200px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    .img-box {
      position: absolute;
      height: 100%;
      width: 100%;
      .images {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        object-fit: cover;
      }
    }
    .mainStyle-box {
      z-index: 1;
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-family: DingTalk JinBuTi, DingTalk JinBuTi;
        font-weight: 400;
        font-size: 58px;
        color: #243042;
      }
    }
  }

  .push-tab {
    width: 100%;
    height: 362px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .tab-box {
      width: 1200px;
      height: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #243042;
        line-height: 28px;
        text-align: center;
        margin-top: 65px;
      }
      .item-box {
        width: 100%;
        height: 180px;
        margin-top: 89px;
        display: flex;
        justify-content: space-between;
        .item {
          width: 96px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .images {
            margin-bottom: 15px;
          }
          .text {
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: bold;
            font-size: 24px;
            color: #2b2b2b;
            text-align: center;
            margin-bottom: 15px;
          }
          .border {
            width: 47px;
            height: 4px;
            background: #8b5ef5;
            border-radius: 20px 20px 20px 20px;
          }
        }
      }
    }
  }

  .douyin {
    width: 100%;
    height: 7250px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .douyin-main {
      width: 1200px;
      .title {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: bold;
        font-size: 28px;
        color: #243042;
        text-align: center;
        margin: 0 15px;
        margin-top: 100px;
      }
      .one-box {
        margin-top: 60px;
        .one {
          display: flex;
          justify-content: center;
          align-items: center;
          .one-img {
            margin-right: 20px;
          }
          .one-text {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 50px;
            color: #243042;
          }
        }
        .one-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 69%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
      .two-box {
        margin-top: 60px;
        .two {
          display: flex;
          justify-content: center;
          align-items: center;
          .two-img {
            margin-right: 20px;
          }
          .two-text {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 50px;
            color: #243042;
          }
        }
        .two-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 69%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
  .bilibili {
    width: 100%;
    height: 2570px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .bilibili-main {
      width: 1200px;
      .one-box {
        margin-top: 60px;
        .one-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 85%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
  .xhs {
    width: 100%;
    height: 3520px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .xhs-main {
      width: 1200px;
      .one-box {
        margin-top: 60px;
        .one-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 69%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }

  .douyu {
    width: 100%;
    height: 2600px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .douyu-main {
      width: 1200px;
      .one-box {
        margin-top: 60px;
        .one-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 69%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
  .huya {
    width: 100%;
    height: 2900px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .huya-main {
      width: 1200px;
      .one-box {
        margin-top: 60px;
        .one-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 69%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
