// wetchat.js －－ 个人封装
import wx from 'weixin-js-sdk'; // 引入wxJS
import { getJsSdkConfig } from "@/api/login"; // 本项目的api （根据自己项目）
export function wxChatShare(param) {
    console.log(param,'paramparamparam1111111111111111111111111122222222222222222222');
    // let _url = "https://hello.yohelo.com/"; // 当前页面的url
    // let _url = encodeURIComponent(param.url.split('#')[0]);
    let _url = param.url.split('#')[0];
    console.log(_url, '_url_url_url_url_url');
    // alert('0000000')
    getJsSdkConfig(_url).then(res => {
            if (res.data.code == 200) {
                // 接口返回配置信息
                // alert('111111111111111111')
                wx.config({
                    debug: true,
                    appId: res.data.data.appId,
                    timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.data.signature, // 必填，签名
                    jsApiList: [
                        'updateAppMessageShareData',
                         'updateTimelineShareData',
                        // 'onMenuShareTimeline','onMenuShareAppMessage'
                    ]
                });
                wx.ready(function () {
                    // //分享给朋友
                    // alert('22222222222222222')
                    console.log(param, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
                    wx.updateAppMessageShareData({
                        title: param.title, // 分享标题
                        desc: param.desc, // 分享描述
                        link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: param.imgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                            console.log("分享到朋友成功返回的信息为:", res);
                            console.log(param, '成功后回调');
                            // this.$Message.message("设置成功!");
                            // alert('333333333333333333333333333')
                        }
                    })
                    // //分享到朋友圈
                    wx.updateTimelineShareData({
                        title: param.title, // 分享标题
                        link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: param.imgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                            console.log("分享到朋友圈成功返回的信息为:", res);
                            // this.$Message.message("设置成功!");
                        }
                    })
                    
                    // wx.onMenuShareTimeline({
                    //     title: param.title, // 分享标题
                    //     link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    //     imgUrl: param.imgUrl, // 分享图标
                    //     success: function () {
                    //         // 用户点击了分享后执行的回调函数
                    //     },
                    // })

                    // wx.onMenuShareAppMessage({
                    //     title: param.title, // 分享标题
                    //     desc: param.desc, // 分享描述
                    //     link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    //     imgUrl: param.imgUrl, // 分享图标
                    //     type: param.type, // 分享类型,music、video或link，不填默认为link
                    //     dataUrl: param.dataUrl, // 如果type是music或video，则要提供数据链接，默认为空
                    //     success: function () {
                    //         // 用户点击了分享后执行的回调函数
                    //     }
                    // });
                });
                wx.error(function (res) {
                    console.log('验证失败返回的信息:', res);
                    // alert('报错了22222')
                });
            } else {
                console.log(res.data.message);
                // alert('报错了1111')
            }
    })
}