import request from '@/utils/request';

export function getUserTeam() {
  return request({
    url: '/hello-user/team/getUserTeam',
    method: 'get'
  });
}
// 控制台团队列表查询
export function getTeamList(params) {
  return request({
    url: '/hello-user/team/teamList',
    method: 'get',
    params
  });
}

// 设置管理员
export const putTeamChange = (data) => {
  return request({
    url: `/hello-user/team/change?` + 'teamId' + '=' + data.teamId + '&' + 'userId' + '=' + data.userId,
    method: 'put'
  });
};

// 删除团队成员
export const deleteTeam = (id) => {
  return request({
    url: '/hello-user/team?' + 'id' + '=' + id,
    method: 'delete'
  });
};
// 分配资源
export const allocationTeam = (data) => {
  return request({
    url: `/hello-user/team/` + data.id,
    method: 'put',
    data
  });
};

// 获取团队剩余权益
export function getTeamEquity(teamId) {
  return request({
    url: '/hello-user/team/teamEquity?' + 'teamId' + '=' + teamId,
    method: 'get'
  });
}

// 邀请成员
export const saveTeamUser = (data) => {
  return request({
    url: `/hello-user/team/saveTeamUser?` + 'teamId' + '=' + data,
    method: 'POST'
  });
};

// 根据团队id获取团队信息
export function getInviteTeamById(params) {
  return request({
    url: '/hello-user/team/getInviteTeamById',
    method: 'get',
    params
  });
}

// 根据id获取信息
export function getInviteUserById(params) {
  return request({
    url: '/hello-user/getInviteUserById',
    method: 'get',
    params
  });
}

// 通知列表
export const getInformAllList = (params) => {
  return request({
    url: '/hello-business/inform/list',
    method: 'get',
    params
  });
};
// 通知列表
export const getInformDetails = (id) => {
  return request({
    url: '/hello-business/inform/' + id,
    method: 'get'
  });
};

// 未读通知数
export const getInformUnread = () => {
  return request({
    url: '/hello-business/inform/unread',
    method: 'get'
  });
};

// 获取oss地址前缀
export const getOssPrefix = () =>
  request({
    url: '/hello-system/oss/endpoint/get-oss-prefix',
    method: 'get'
  });

