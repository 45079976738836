<template>
  <div class="home">
    <headComponent></headComponent>
    <!-- 中部 -->
    <div class="mainStyle">
      <div style="width: 100%">
        <div class="main-one">
          <div class="img-box">
            <img class="images" src="@/assets/imgs/home-bg.png" alt="" />
          </div>
          <div class="middle">
            <div class="middle-text">
              <div @click="download" class="middle-download">
                <img style="width: 24.63px; height: 22px; margin-left: 31px" src="@/assets/imgs/windows.png" alt="" />
                <img style="width: 18.85px; height: 22px; margin: 0 9px" src="@/assets/imgs/android.png" alt="" />
                <span @click="download" class="text">立即下载</span>
              </div>
            </div>
            <div class="middle-images">
              <img src="@/assets/imgs/home-bg-right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mainStyle-one">
      <div class="box">
        <div class="title">
          <div class="text">打造数字虚拟化劳动力，降本增效</div>
        </div>
        <div class="item-box">
          <div class="item">
            <div class="zrkl">
              <img src="@/assets/imgs/zrkl.png" alt="" />
              <div class="title">真人形象克隆</div>
              <div class="text-1">1:1真人还原</div>
              <div class="text-2">数字分身终与使用</div>
            </div>
          </div>
          <div class="item">
            <div class="szyg">
              <img src="@/assets/imgs/szyg.png" alt="" />
              <div class="title">交互式数字员工</div>
              <div class="text-1">配置员工知识库</div>
              <div class="text-2">即时交互应答</div>
            </div>
          </div>
          <div class="item">
            <div class="xlcj">
              <img src="@/assets/imgs/xlcj.png" alt="" />
              <div class="title">沉浸式虚拟场景</div>
              <div class="text-1">360“虚拟实景</div>
              <div class="text-2">景、人、物深入融合</div>
            </div>
          </div>
          <div class="item">
            <div class="szzc">
              <img src="@/assets/imgs/szzc.png" alt="" />
              <div class="title">数字资产化</div>
              <div class="text-1">数字内容即资产登记</div>
              <div class="text-2">交易、维权</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mainStyle-two">
      <div class="box">
        <div class="box-main">
          <div class="title">直播&短视频</div>
          <div class="conter">让数字人短视频、直播增长更简单</div>
          <div class="images-list">
            <div class="zu0">
              <img src="@/assets/imgs/zu0.png" alt="" />
            </div>
            <div class="zu1">
              <div class="text">
                <div class="title">极速开播</div>
                <div class="conter">
                  内含丰富的直播场景模板，只需一键套用模板，上传产品图片，修改文案，即可开播
                </div>
              </div>
              <div class="images">
                <img src="@/assets/imgs/zu1.png" alt="" />
              </div>
            </div>
            <div class="zu2">
              <div class="text">
                <div class="title">智能文案</div>
                <div class="conter">根据关键词自动生成话术文案,省时省力</div>
              </div>
              <div class="images">
                <img src="@/assets/imgs/zu2.png" alt="" />
              </div>
            </div>
            <div class="zu3">
              <div class="text">
                <div class="title">7*24小时自动播</div>
                <div class="conter">
                  24小时不间断自动播，支持多种直播(数字人、语音等)方式
                </div>
              </div>
              <div class="images">
                <img src="@/assets/imgs/zu3.png" alt="" />
              </div>
            </div>

            <div class="zu11">
              <div class="images">
                <img src="@/assets/imgs/zu11.png" alt="" />
              </div>
              <div class="text">
                <div class="title">多平台同步直播</div>
                <div class="conter">
                  支持同时对多个直播进行推流或接入虚拟摄像头的直播软件同步开播
                </div>
              </div>
            </div>

            <div class="zu22">
              <div class="images">
                <img src="@/assets/imgs/zu22.png" alt="" />
              </div>
              <div class="text">
                <div class="title">直播用户实时互动</div>
                <div class="conter">
                  根据关键词自动回复直播间弹幕、礼物、欢迎观众，支持助播语音回复
                </div>
              </div>
            </div>

            <div class="zu33">
              <div class="images">
                <img src="@/assets/imgs/zu33.png" alt="" />
              </div>
              <div class="text">
                <div class="title">团队协作矩阵直播</div>
                <div class="conter">在线化管理、团队协作、矩阵式直播，节约90%成本</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="active === 1" class="mainStyle-three">
      <div class="three-box">
        <div class="three-item">
          <div class="title">一站式多场景短视频&直播解决方案</div>
          <div class="middle-box">
            <div class="box-left">
              <div class="images">
                <img src="@/assets/imgs/three1-1.png" alt="" />
              </div>
              <div class="text">虚实结合，直达店面</div>
              <div class="conter">
                本地实景与虚拟场景深度结合，虚拟主播自动讲解生活服务，让观众沉浸式体验商家服务。
              </div>
            </div>
            <div class="box-right">
              <img src="@/assets/imgs/three1-2.png" alt="" />
            </div>
            <div class="store">
              <img src="@/assets/imgs/gf-store.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-box">
        <div class="footer-item">
          <div @click="activeChange(1)" class="item-handel">
            <img src="@/assets/imgs/three1-3.png" alt="" />
            <div class="text current">本地生活</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(2)" class="item-handel">
            <img src="@/assets/imgs/three1-4.png" alt="" />
            <div class="text">电商带货</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(3)" class="item-handel">
            <img src="@/assets/imgs/three1-5.png" alt="" />
            <div class="text">产品推广</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(4)" class="item-handel">
            <img src="@/assets/imgs/three1-6.png" alt="" />
            <div class="text">私域引流</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="active === 2" class="mainStyle-three2">
      <div class="three-box">
        <div class="three-item">
          <div class="title">一站式多场景短视频&直播解决方案</div>
          <div class="middle-box">
            <div class="box-right">
              <img src="@/assets/imgs/three2-2.png" alt="" />
            </div>
            <div class="box-left">
              <div class="images">
                <img src="@/assets/imgs/three2-1.png" alt="" />
              </div>
              <div class="text">全天候稳定直播，创新高效带货</div>
              <div class="conter">低成本、高效率，团队协作矩阵直播，降本增效</div>
            </div>
            <div class="store">
              <img src="@/assets/imgs/gf-store.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-box">
        <div class="footer-item">
          <div @click="activeChange(1)" class="item-handel">
            <img src="@/assets/imgs/three2-3.png" alt="" />
            <div class="text">本地生活</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(2)" class="item-handel">
            <img src="@/assets/imgs/three2-4.png" alt="" />
            <div class="text current">电商带货</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(3)" class="item-handel">
            <img src="@/assets/imgs/three1-5.png" alt="" />
            <div class="text">产品推广</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(4)" class="item-handel">
            <img src="@/assets/imgs/three1-6.png" alt="" />
            <div class="text">私域引流</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="active === 3" class="mainStyle-three2">
      <div class="three-box">
        <div class="three-item">
          <div class="title">一站式多场景短视频&直播解决方案</div>
          <div class="middle-box">
            <div class="box-right">
              <img src="@/assets/imgs/three3-2.png" alt="" />
            </div>
            <div class="box-left">
              <div class="images">
                <img src="@/assets/imgs/three3-1.png" alt="" />
              </div>
              <div class="text">数字人视频制作，助力视频内容升级</div>
              <div class="conter">
                短视频、课件、宣传片...支持多种内容制作，一键成片、本地录制导出
              </div>
            </div>
            <div class="store">
              <img src="@/assets/imgs/gf-store.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-box">
        <div class="footer-item">
          <div @click="activeChange(1)" class="item-handel">
            <img src="@/assets/imgs/three2-3.png" alt="" />
            <div class="text">本地生活</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(2)" class="item-handel">
            <img src="@/assets/imgs/three1-4.png" alt="" />
            <div class="text">电商带货</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(3)" class="item-handel">
            <img src="@/assets/imgs/three3-5.png" alt="" />
            <div class="text current">产品推广</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(4)" class="item-handel">
            <img src="@/assets/imgs/three1-6.png" alt="" />
            <div class="text">私域引流</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="active === 4" class="mainStyle-three">
      <div class="three-box">
        <div class="three-item">
          <div class="title">一站式多场景短视频&直播解决方案</div>
          <div class="middle-box">
            <div class="box-left">
              <div class="images">
                <img src="@/assets/imgs/three4-1.png" alt="" />
              </div>
              <div class="text">直播新风尚，引流新引擎</div>
              <div class="conter">
                全天候直播互动、连接用户，获取实现流量高效导入与转化
              </div>
            </div>
            <div class="box-right">
              <img src="@/assets/imgs/three4-2.png" alt="" />
            </div>
            <div class="store">
              <img src="@/assets/imgs/gf-store.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-box">
        <div class="footer-item">
          <div @click="activeChange(1)" class="item-handel">
            <img src="@/assets/imgs/three2-3.png" alt="" />
            <div class="text">本地生活</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(2)" class="item-handel">
            <img src="@/assets/imgs/three1-4.png" alt="" />
            <div class="text">电商带货</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(3)" class="item-handel">
            <img src="@/assets/imgs/three1-5.png" alt="" />
            <div class="text">产品推广</div>
            <div class="bar"></div>
          </div>
          <div @click="activeChange(4)" class="item-handel">
            <img src="@/assets/imgs/three4-6.png" alt="" />
            <div class="text current">私域引流</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mainStyle-four">
      <div class="four-main">
        <div class="title">数字虚拟人客服</div>
        <div class="conter">轻松构建多模态交互式数字虚拟人客户服务技术应用</div>
        <div class="main-box">
          <div class="left">
            <div class="left-bg">
              <img src="@/assets/imgs/four-left-bg.png" alt="" />
            </div>
            <div class="four-1">
              <img src="@/assets/imgs/four-1.png" alt="" />
            </div>
            <div class="four-2">
              <img src="@/assets/imgs/four-2.png" alt="" />
            </div>
            <div class="four-3">
              <img src="@/assets/imgs/four-3.png" alt="" />
            </div>
            <div class="four-4">
              <img src="@/assets/imgs/four-4.png" alt="" />
            </div>
          </div>
          <div class="right">
            <img src="@/assets/imgs/four-right.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="mainStyle-five">
      <div class="five-box">
        <div class="title">致力于服务干行百业，为客户降本增效</div>
        <div class="item-box">
          <div class="item">
            <div class="top">
              <div class="logo">
                <img src="@/assets/imgs/five-1-1.png" alt="" />
              </div>
              <div class="text">直播电商</div>
            </div>
            <div class="main">
              <img src="@/assets/imgs/five-1-2.png" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="top">
              <div class="logo">
                <img src="@/assets/imgs/five-2-1.png" alt="" />
              </div>
              <div class="text">本地生活</div>
            </div>
            <div class="main">
              <img src="@/assets/imgs/five-2-2.png" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="top">
              <div class="logo">
                <img src="@/assets/imgs/five-3-1.png" alt="" />
              </div>
              <div class="text">医疗</div>
            </div>
            <div class="main">
              <img src="@/assets/imgs/five-3-2.png" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="top">
              <div class="logo">
                <img src="@/assets/imgs/five-4-1.png" alt="" />
              </div>
              <div class="text">教育</div>
            </div>
            <div class="main">
              <img src="@/assets/imgs/five-4-2.png" alt="" />
            </div>
          </div>
        </div>
        <div class="text-title">
          <div class="texts">了解更多行业解决方案</div>
          <div class="icon"></div>
        </div>
        <div class="five-footer">
          <div class="footer-text">合作伙伴</div>
          <div class="footer-left">
            <img src="@/assets/imgs/five-left.png" alt="" />
          </div>
          <div class="footer-item">
            <img src="@/assets/imgs/kuaishou.png" alt="" />
          </div>
          <div class="footer-item">
            <img src="@/assets/imgs/aliyun.png" alt="" />
          </div>
          <div class="footer-item">
            <img src="@/assets/imgs/bilibili.png" alt="" />
          </div>
          <div class="footer-item">
            <img src="@/assets/imgs/douyin.png" alt="" />
          </div>
          <div class="footer-item">
            <img src="@/assets/imgs/five-right.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="mainStyle-six">
      <div class="six-box">
        <div class="title">立即体验，在Hello开启数字虚拟化创造之旅</div>
        <div class="button-box">
          <div @click="handelLogin(1)" class="six-button">立即使用</div>
        </div>
      </div>
    </div>

    <div class="mainStyle-seven">
      <div class="seven-box">
        <div class="seven-top">
          <div class="left">
            <img src="@/assets/imgs/logo-z.png" alt="" />
          </div>
          <div class="right">
            <div class="weixin">
              <div class="code">
                <img style="width: 95px; height: 95px" src="@/assets/imgs/weixin-q.png" alt="" />
              </div>
              <div class="text">专属客服</div>
            </div>
            <div class="douyin">
              <div class="code">
                <img style="width: 100px; height: 100px" src="@/assets/imgs/douyin-q.png" alt="" />
              </div>
              <div class="text">关注抖音号</div>
            </div>
          </div>
        </div>
        <div class="seven-main">
          <div class="item-box">
            <div class="item-title">产品产品</div>
            <div class="item-text">Hello Nvwa</div>
            <div style="display: none" class="item-text">Hello 元小二</div>
            <div style="display: none" class="item-text">Hello 数字人向导</div>
            <div style="display: none" class="item-text">Hello 弹幕游戏</div>
          </div>

          <div class="item-box">
            <div class="item-title">解决方案</div>
            <div @click="gotoDsdh" class="item-text">直播电商</div>
            <div @click="gotoBdsh" class="item-text">本地生活</div>
            <div @click="gotoYljk" class="item-text">医疗健康</div>
            <div @click="gotoJypx" class="item-text">教育培训</div>
            <div @click="gotoLy" class="item-text">旅游</div>
            <div @click="gotoZlzh" class="item-text">展览展会</div>
          </div>

          <div class="item-box">
            <div class="item-title">流量研究中心</div>
            <div @click="gotoFlow()" class="item-text">Nvwa使用教程</div>
            <div class="item-text">直播攻略</div>
          </div>

          <div class="item-box">
            <div class="item-title">协议条款</div>
            <div class="item-text" @click="gotoUserProtocol">服务协议</div>
            <div class="item-text" @click="gotoVipProtocol">隐私条款</div>
          </div>

          <div class="item-box">
            <div style="display:none;" class="item-title">商务合作</div>
            <div style="display:none;" class="item-text">定制咨询：135 0000 0000</div>
            <div style="display:none;" class="item-text">代理咨询：135 0000 0000</div>
            <div style="display:none;" class="item-text">售前咨询：135 0000 0000</div>
            <div style="display:none;" class="item-text">售后服务：400 - 000 - 000</div>
          </div>

          <div class="item-right">
            <div class="item-title">关于我们</div>
            <div class="item-text">公司名称:湖南河络信息科技有限公司</div>
            <div class="item-text">地址:长沙县天华中路79号明诚商务广场</div>
            <div class="item-text">联系电话：0731-86914481</div>
          </div>
        </div>
        <div class="seven-footer">
          <div class="footer-box">
            Copyright 2024 © yohelo.com |
            <a style="color: #b5b5b5; text-decoration: none" href="https://beian.miit.gov.cn/">湘ICP备2023009258号</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <!-- <div class="footerStyle">
      <div>底部区域</div>
    </div> -->
    <div @mouseenter="qrcodeShow = true" @mouseleave="qrcodeShow = false" class="window-box">
      <div class="window-img">
        <img src="@/assets/imgs/客服.png" alt="" />
      </div>
      <div class="window-text">专属客服</div>
    </div>
    <div v-show="qrcodeShow" class="qrcode-box">
      <div class="qrcode-img">
        <img src="@/assets/imgs/fixed-wx.png" alt="" />
      </div>
    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="400px" :before-close="handleClose">
      <div style="position: absolute; right: 0px; top: 0px; cursor: pointer" v-show="forgetIshow == 1" @click="weixinEvn">
        <img src="@/assets/imgs/weixindengluerweima.png" alt="" />
      </div>
      <div style="position: absolute; right: 0px; top: 0px; cursor: pointer" v-show="forgetIshow == 3" @click="dengluEvn">
        <img src="@/assets/imgs/denglutouxiang.png" alt="" />
      </div>
      <div style="height: 330px" v-show="forgetIshow == 1">
        <div class="loginStyle">
          <span class="login-word" v-show="isShow == 1">验证码登录</span>
          <span class="login-word" v-show="isShow == 2">账号密码登录</span>
          <span class="login-switch" @click="switchBtn(1)" v-show="isShow == 1">
            账号密码登录
            <!-- <img style="margin-left: 4px;"
              src="@/assets/imgs/genghuan.png" alt="" width="6px" height="9px"> -->
          </span>
          <span class="login-switch" @click="switchBtn(2)" v-show="isShow == 2">
            手机验证码登录
            <!-- <img style="margin-left: 4px;"
              src="@/assets/imgs/genghuan.png" alt="" width="6px" height="9px"> -->
          </span>
        </div>
        <!-- 账号密码登录 -->
        <el-form class="login-form" autocomplete="on" :model="loginForm" ref="loginFormRef" label-position="left" v-if="isShow == 2">
          <div>
            <el-form-item prop="username" style="position: relative">
              <div class="icon-style">
                <!-- <img src="../../assets/imgs/shoujihao.png" alt=""> -->
              </div>
              <el-input name="username" type="text" v-model="loginForm.username" autocomplete="on" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item prop="password" style="position: relative">
              <div class="icon-style">
                <!-- <img src="../../assets/imgs/mima.png" alt=""> -->
              </div>
              <el-input name="password" :type="pwdType" @keyup.enter.native="handleLogin" v-model="loginForm.password" autocomplete="on" placeholder="请输入登录密码"></el-input>
            </el-form-item>
          </div>
          <div class="read-style">
            <div>
              <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
              <span class="agreement" @click="pdfFile">《服务协议和隐私条款》</span>
            </div>
            <span class="agreement-style" @click="forget">忘记密码？</span>
          </div>
          <div v-show="isShow1 == false" style="font-size: 10px; color: #f56c6c">
            如已知悉相关协议及条款，请勾选
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin">登录</button>
          </div>
        </el-form>
        <!-- 手机验证码登录 -->
        <el-form class="login-form" autocomplete="on" :model="loginForm1" ref="loginFormRef1" label-position="left" v-if="isShow == 1">
          <div>
            <el-form-item prop="phone" style="position: relative">
              <div class="icon-style">
                <!-- <img src="../../assets/imgs/shoujihao.png" alt=""> -->
              </div>
              <el-input name="phone" type="text" v-model="loginForm1.phone" autocomplete="on" placeholder="请输入手机号" />
            </el-form-item>
            <div v-show="isShow3 == true" style="
                font-size: 10px;
                color: #f56c6c;
                margin-top: -20px;
                margin-bottom: 6px;
              ">
              手机号格式不正确
            </div>
            <el-form-item prop="codeSms" style="position: relative">
              <div class="icon-style">
                <!-- <img src="../../assets/imgs/yanzheng.png" alt=""> -->
              </div>
              <el-input name="codeSms" @keyup.enter.native="handleLogin1" v-model="loginForm1.codeSms" autocomplete="on" placeholder="请输入短信验证码"></el-input>
              <span class="show-pwd">
                <span :class="grey ? 'greyStart' : 'greyEnd'" style="font-size: 11px" :disabled="isSend" @click="sendBtn01(1)">{{ codeName }}</span>
              </span>
            </el-form-item>
            <!-- <div v-show="isShow5 == true" style="font-size: 10px; color: #F56C6C; margin-top: -20px;margin-bottom: 10px;">
              验证码格式不正确</div> -->
          </div>
          <div class="read-style">
            <div>
              <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
              <span class="agreement" @click="pdfFile">《服务协议和隐私条款》</span>
            </div>
            <span class="agreement-style" @click="forget">忘记密码？</span>
          </div>
          <div v-show="isShow1 == false" style="font-size: 10px; color: #f56c6c">
            如已知悉相关协议及条款，请勾选
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin1">登录</button>
          </div>
        </el-form>
      </div>
      <div style="height: 330px" v-show="forgetIshow == 2">
        <el-form class="login-form" autocomplete="on" :model="loginForm2" ref="loginFormRef1" label-position="left">
          <div>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm2.findPhone" autocomplete="on" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item prop="codeSms" style="position: relative">
              <el-input name="codeSms" v-model="loginForm2.findCodeSms" autocomplete="on" placeholder="请输入短信验证码"></el-input>
              <span class="show-pwd">
                <span :class="grey ? 'greyStart' : 'greyEnd'" style="font-size: 11px" :disabled="isSend" @click="sendBtn01(2)">{{ codeName }}</span>
              </span>
            </el-form-item>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm2.findPassword" autocomplete="on" placeholder="请输入密码" />
            </el-form-item>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm2.findPassword1" autocomplete="on" placeholder="请输入密码" />
            </el-form-item>
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin2">确定</button>
          </div>
        </el-form>
      </div>
      <div style="height: 330px" v-show="forgetIshow == 3">
        <div class="login-from-left">
          <span class="code-scanning">微信扫码登录</span>
          <div id="weixin" class="wei-chat" style="margin-left: 150px">
            <wxlogin appid="wxedf5818b5ae7815c" scope="snsapi_login" :redirect_uri="redirect_uri" :href="href" :state="state"></wxlogin>
          </div>
          <div class="code-button">
            <div class="code-button-open">
              <img src="@/assets/imgs/wx.png" alt="" width="20px" />
              <span class="button-open1">打开微信扫码授权登录</span>
            </div>
            <div class="code-button-open1">
              <span class="button-open1-refresh">已扫码无反应？请点击</span>
              <span class="button-open1-refresh1" @click="refresh">刷新</span>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 330px" v-show="forgetIshow == 4">
        <el-form class="login-form" autocomplete="on" :model="loginForm3" ref="loginFormRef1" label-position="left" v-if="isShow == 1">
          <div>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm3.phone" autocomplete="on" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item prop="codeSms" style="position: relative">
              <el-input name="codeSms" v-model="loginForm3.codeSms" autocomplete="on" placeholder="请输入短信验证码"></el-input>
              <span class="show-pwd">
                <span :class="grey ? 'greyStart' : 'greyEnd'" style="font-size: 11px" :disabled="isSend" @click="sendBtn01(3)">{{ codeName }}</span>
              </span>
            </el-form-item>
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin3">确认</button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { send, login, getNext, getNextSmsCode, getDownload } from "@/api/login";
import wxlogin from "vue-wxlogin";
import headComponent from "@/views/component/headComponent.vue";
import Cookies from "js-cookie";
import md5 from "js-md5";
export default {
  name: "HomeView",
  components: { wxlogin, headComponent },
  data() {
    return {
      clickStatus: 1,
      active: 4,
      dialogVisible: false,
      isShow: 1,
      isShow3: false,
      qrcodeShow: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginForm1: {
        phone: "",
        codeSms: "",
      },
      grey: true,
      isSend: false,
      checked: false,
      isShow1: true,
      codeName: "获取验证码",
      isShow5: false,
      pwdType: "password",
      id: "",
      totalTime: "60",
      timer: "",
      grey: true,
      forgetIshow: 1,
      title: "登录",
      loginForm2: {
        findPhone: "",
        findCodeSms: "",
        findPassword: "",
        findPassword1: "",
      },
      loginForm3: {
        phone: "",
        codeSms: "",
      },
      redirect_uri: encodeURIComponent(
        "https://hello.yohelo.com/api/hello-auth/api/ucenter/wx/callback"
      ),
      href:
        "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg==",
      state: "pc",
      code: localStorage.getItem("code"),
      openid: localStorage.getItem("openid"),
      authCode: "",
      timer: "",
    };
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
    this.openTimer();
  },
  mounted() {
    // localStorage.removeItem("token")
    // localStorage.removeItem('code')
    // this.setWxerwma()
    // localStorage.removeItem('code')
    if (this.code == "false") {
      this.dialogVisible = true;
      this.forgetIshow = 4;
      this.title = "绑定手机号";
    }
  },
  // watch: {
  //   active: {
  //     immediate: true,
  //     handler(newValue) {
  //       if (this.timer) {
  //         clearInterval(this.timer);
  //       }
  //     },
  //   },
  // },
  methods: {
    openTimer() {
      this.timer = setInterval(() => {
        this.active++;
        if (this.active == 5) {
          this.active = 1;
        }
      }, 5000);
    },
    activeChange(val) {
      clearInterval(this.timer);
      this.active = val;
      this.openTimer();
    },
    switchBtn(val) {
      if (val == 1) {
        this.isShow = 2;
      }
      if (val == 2) {
        this.isShow = 1;
      }
    },
    refresh() {
      // this.setWxerwma()
    },
    setWxerwma() {
      // var obj = new WxLogin({
      //   self_redirect: true,
      //   id: 'weixin',
      //   appid: 'wxedf5818b5ae7815c',
      //   // appid: 'wxe19e3e2415e89353',
      //   scope: 'snsapi_login',
      //   redirect_uri: encodeURIComponent('https://hello.yohelo.com/api/hello-auth/api/ucenter/wx/callback'),
      //   state: 'pc',
      //   style: 'black',
      //   href: 'data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg==',
      // })
    },
    forget() {
      this.forgetIshow = 2;
      this.title = "找回密码";
    },
    weixinEvn() {
      this.forgetIshow = 3;
      this.title = "微信登录";
    },
    dengluEvn() {
      this.forgetIshow = 1;
      this.title = "登录";
    },
    handelLogin(val) {
      this.dialogVisible = true;
      this.clickStatus = val;
    },
    async handleLogin3() {
      const res = await getNextSmsCode(
        this.loginForm3.phone,
        this.id,
        this.loginForm3.codeSms
      );
      if (res.data.code == 200) {
        this.authCode = res.data.msg;
        let params = {
          tenantId: "000000",
          openid: this.openid,
          authCode: this.authCode,
          grant_type: "wechat",
          scope: "all",
        };
        login(params)
          .then((res) => {
            localStorage.removeItem("code");
            this.dialogVisible = false;
            this.forgetIshow = 0;
            this.title = "";
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("userId", res.data.user_id);
            localStorage.setItem("nickName", res.data.nick_name);
            localStorage.setItem("avatar", res.data.avatar);

            if (this.clickStatus === 1) {
              this.$router.push("/control").catch((error) => error);
              location.reload();
            } else {
              this.dialogVisible = false;
            }
            location.reload();
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "登录失败",
            });
          });
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async handleLogin2() {
      let passwordData = md5(this.loginForm2.findPassword);
      let passwordData1 = md5(this.loginForm2.findPassword1);
      let a = {
        phone: this.loginForm2.findPhone,
        id: this.id,
        value: this.loginForm2.findCodeSms,
        newPassword: passwordData,
        newPassword1: passwordData1,
      };

      const res = await getNext(
        this.loginForm2.findPhone,
        this.id,
        this.loginForm2.findCodeSms,
        passwordData,
        passwordData1
      );
      if (res.data.code == 200) {
        this.$message({
          type: "success",
          message: "重置成功",
        });
        this.forgetIshow = 1;
        this.title = "登录";
        this.isShow = 2;
        // this.$router.push({ path: '/password', query: { msg: res.data.msg } }).catch(error => error);
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async sendBtn01(val) {
      // if (loginForm1.value.phone.length > 11 || loginForm1.value.phone.length < 11) {
      //   isShow3.value = true
      //   return
      // } else {
      this.isShow3 = false;
      if (this.isSend) return;
      this.isSend = true;
      this.codeName = this.totalTime + "s后重新发送";
      this.grey = false;
      this.timer = setInterval(() => {
        this.totalTime--;
        this.codeName = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          clearInterval(this.timer);
          this.grey = true;
          this.codeName = "重新获取验证码";
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
      if (val == 1) {
        const res = await send(this.loginForm1.phone);
        this.id = res.data.data.id;
      } else if (val == 2) {
        const res = await send(this.loginForm2.findPhone);
        this.id = res.data.data.id;
      } else if (val == 3) {
        const res = await send(this.loginForm3.phone);
        this.id = res.data.data.id;
      }
    },
    handleLogin() {
   
      if (this.checked == false) {
        this.$message({
          type: "warning",
          message: "请勾选用户协议",
          duration: 1000,
        });
        // this.isShow1 = false
        return
      }
      // if (checked.value == false) {
      //   isShow1.value = false
      //   return
      // } else {
      //   isShow1.value = true
      //   loginFormRef.value.validate((valid) => {
      //     if (valid) {
      //       // 账号密码
      let params = {
        tenantId: "000000",
        password: md5(this.loginForm.password),
        username: this.loginForm.username,
        grant_type: "password",
        scope: "all",
      };
  
      login(params)
        .then((res) => {
  
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          localStorage.setItem("avatar", res.data.avatar);
          if (this.clickStatus === 1) {
            this.$router.push("/control").catch((error) => error);
            location.reload();
          } else {
            this.dialogVisible = false;
          }
        })
        .catch(() => { });
    },
    handleLogin1() {
      // if (loginForm1.value.phone.length > 11 || loginForm1.value.phone.length < 11) {
      //   isShow3.value = true
      //   return
      // }
      // if (loginForm1.value.codeSms.length > 6 || loginForm1.value.codeSms.length < 6) {
      //   isShow5.value = true
      //   return
      // }
      // if (checked.value == false) {
      //   isShow1.value = false
      //   return
      // } else {
      //   isShow3.value = false
      //   isShow5.value = false
      //   isShow1.value = true
      let params = {
        tenantId: "000000",
        phone: this.loginForm1.phone,
        codeSms: this.loginForm1.codeSms,
        grant_type: "sms_code",
        scope: "all",
        id: this.id,
      };
      login(params)
        .then((res) => {
     
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          localStorage.setItem("avatar", res.data.avatar);
          if (this.clickStatus === 1) {
            this.$router.push("/control").catch((error) => error);
            location.reload();
          } else {
            this.dialogVisible = false;
          }
          // getId(res.data.user_id).then((res) => {
          //   if (res.data.data.avatar == "" || res.data.data.avatar == undefined || res.data.data.avatar == null) {
          //     localStorage.setItem('avatar', avatarImg.value);
          //   } else {
          //     localStorage.setItem('avatar', res.data.data.avatar);
          //   }
          // })
        })
        .catch(() => { });
    },
    pdfFile() {
      window.open('https://hello.yohelo.com/protocol/user-privacy-protocol.html', '_blank');
      // let data = {
      //   url: "/pdfFile",
      //   width: 1061,
      //   height: 730,
      //   resizable: false,
      //   minWidth: 1061,
      //   parents: remote.BrowserWindow.getFocusedWindow().id,
      // };
      // ipcRenderer.invoke("open-win", data);
    },
    handleClose(done) {
      done();
      this.forgetIshow = 1;
      this.title = "登录";
    },
    gotoHome() {
      this.$router.push({ path: "/" });
    },
    gotoDsdh() {
      this.$router.push({ path: "/solution/dsdh" });
    },
    gotoBdsh() {
      this.$router.push({ path: "/solution/bdsh" });
    },
    gotoYljk() {
      this.$router.push({ path: "/solution/yljk" });
    },
    gotoJypx() {
      this.$router.push({ path: "/solution/jypx" });
    },
    gotoLy() {
      this.$router.push({ path: "/solution/ly" });
    },
    gotoZlzh() {
      this.$router.push({ path: "/solution/zlzh" });
    },
    gotoFlow() {
      this.$router.push({ path: "/tutorial/nvwaLive" });
    },
    async download() {
      // const res = await getDownload();
   
      // window.open(res.data.data.packetUrl);
      this.$router.push({ path: "/help/download" });
    },
    gotoUserProtocol() {
      // 链接地址
      var url = "https://hello.yohelo.com/protocol/user-privacy-protocol.html";
      // 打开链接
      window.open(url);
    },
    gotoVipProtocol() {
      // 链接地址
      var url = "https://hello.yohelo.com/protocol/member-vip-protocol.html";
      // 打开链接
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
}

.headerStyle {
  min-width: 1200px;
  width: 100%;
  height: 64px;
  padding-left: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  position: fixed;
  z-index: 999;
  box-shadow: 0px 2px 4px 1px rgba(165, 165, 165, 0.3);
  .headerStyle-left {
    display: flex;
    .headerStyle-text {
      display: flex;
      align-items: center;
      margin-left: 35px;
      .headerStyle-cp {
        margin-right: 20px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 14px;
        color: #3f4959;
      }
    }
  }
  .headerStyle-top {
    display: flex;
    float: right;
    color: #fff;
    line-height: 35px;
    .headerStyle-kongzhi {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-size: 14px;
      color: #3f4959;
      line-height: 65px;
      font-weight: 400;
    }
    .headerStyle-login {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-size: 14px;
      color: #3f4959;
      margin: 0 20px;
      line-height: 65px;
      font-weight: 400;
    }
    .headerStyle-console {
      width: 120px;
      background-color: #8b5ef5;
      height: 65px;
      line-height: 65px;
      text-align: center;
      cursor: pointer;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}

.mainStyle {
  margin-top: 64px;
  flex-grow: 1;
  min-width: 1200px;
  background-color: #e0dcf8;
  .main-one {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    position: relative;
    .img-box {
      position: absolute;
      height: 100%;
      width: 100%;
      .images {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        object-fit: cover;
      }
    }

    .middle {
      z-index: 1;
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .middle-text {
        background-image: url('@/assets/imgs/home-text.png');
        background-size: 100% 100%;
        width: 50%;
        height: 251px;
        .middle-download {
          cursor: pointer;
          width: 205px;
          height: 64px;
          background: #895af6;
          border-radius: 4px 4px 4px 4px;
          margin-top: 153px;
          display: flex;
          align-items: center;
          .text {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
      .middle-images {
      }
    }
  }
}
.mainStyle-one {
  min-width: 1200px;
  height: 735px;
  display: flex;
  justify-content: center;
  .box {
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      .text {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #080808;
        margin-top: 28px;
        width: 1200px;
        text-align: center;
      }
    }
    .item-box {
      display: flex;
      .item {
        width: 290px;
        height: 520px;
        border-radius: 8px 8px 8px 8px;
        margin-top: 68px;
        margin-right: 13px;
        box-shadow: 0px 2px 4px 1px rgba(20, 20, 20, 0.2);
        border-radius: 8px 8px 8px 8px;
        background-image: url('@/assets/imgs/beijin.png');
        background-size: 100% 100%;
        .zrkl {
          width: 100%;
          height: 100%;
          position: relative;
          img {
            position: absolute;
            top: -40px;
            width: 100%;
            height: 100%;
          }
          .title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 30px;
            color: #243042;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 20px;
          }
          .text-1 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
          .text-2 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
        }
        .szyg {
          width: 100%;
          height: 100%;
          position: relative;
          img {
            position: absolute;
            top: -40px;
            width: 100%;
            height: 100%;
          }
          .title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 30px;
            color: #243042;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 20px;
          }
          .text-1 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
          .text-2 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
        }

        .xlcj {
          width: 100%;
          height: 100%;
          position: relative;
          img {
            position: absolute;
            top: -40px;
            width: 100%;
            height: 100%;
          }
          .title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 30px;
            color: #243042;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 20px;
          }
          .text-1 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
          .text-2 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
        }

        .szzc {
          width: 100%;
          height: 100%;
          position: relative;
          img {
            position: absolute;
            top: -40px;
            width: 100%;
            height: 100%;
          }
          .title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 30px;
            color: #243042;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 20px;
          }
          .text-1 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
          .text-2 {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 22px;
            color: #243042;
            line-height: 36px;
            text-align: center;
          }
        }
      }
    }
  }
}

.mainStyle-two {
  min-width: 1200px;
  .box {
    width: 100%;
    height: 735px;
    background-image: url('@/assets/imgs/home-bg2.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .box-main {
      width: 1200px;
      height: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #243042;
        text-align: center;
        margin-top: 24px;
      }
      .conter {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 22px;
        color: #243042;
        text-align: center;
        margin-top: 7px;
      }
      .images-list {
        margin-top: 60px;
        width: 100%;
        height: 573px;
        position: relative;
        .zu0 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .zu1 {
          position: absolute;
          left: 9%;
          top: 8%;
          width: 400px;
          height: 117px;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            width: 300px;
            margin-right: 30px;
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #243042;
              margin-top: 0;
              text-align: right;
              margin-bottom: 4px;
            }
            .conter {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              text-align: right;
            }
          }
          .images {
          }
        }

        .zu11 {
          position: absolute;
          left: 57.5%;
          top: 8%;
          width: 400px;
          height: 117px;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            width: 300px;
            margin-left: 30px;
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #243042;
              margin-top: 0;
              text-align: left;
              margin-bottom: 4px;
            }
            .conter {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              text-align: left;
            }
          }
          .images {
          }
        }

        .zu2 {
          position: absolute;
          left: 1.7%;
          top: 50%;
          transform: translate(0%, -50%);
          width: 330px;
          height: 117px;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            width: 300px;
            margin-right: 30px;
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #243042;
              margin-top: 0;
              text-align: right;
              margin-bottom: 4px;
            }
            .conter {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              text-align: right;
            }
          }
          .images {
          }
        }

        .zu22 {
          position: absolute;
          left: 71%;
          top: 50%;
          transform: translate(0%, -50%);
          width: 340px;
          height: 117px;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            width: 300px;
            margin-left: 30px;
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #243042;
              margin-top: 0;
              text-align: left;
              margin-bottom: 4px;
            }
            .conter {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              text-align: left;
            }
          }
          .images {
          }
        }

        .zu3 {
          position: absolute;
          left: 9%;
          top: 71%;
          width: 400px;
          height: 117px;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            width: 300px;
            margin-right: 30px;
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #243042;
              margin-top: 0;
              text-align: right;
              margin-bottom: 4px;
            }
            .conter {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              text-align: right;
            }
          }
          .images {
          }
        }

        .zu33 {
          position: absolute;
          left: 57.5%;
          top: 71%;
          width: 400px;
          height: 117px;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            width: 300px;
            margin-left: 30px;
            .title {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #243042;
              margin-top: 0;
              text-align: left;
              margin-bottom: 4px;
            }
            .conter {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              text-align: left;
            }
          }
          .images {
          }
        }
      }
    }
  }
}

.mainStyle-three {
  width: 100%;
  min-width: 1200px;
  height: 740px;
  background-image: url('@/assets/imgs/three-bg.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .three-box {
    width: 1200px;
    height: 100%;
    .three-item {
      width: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 34px;
        color: #243042;
        text-align: center;
        margin-top: 30px;
      }
      .middle-box {
        width: 100%;
        height: 570px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .box-left {
          margin-left: 29px;
          .text {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 51px;
            color: #243042;
            line-height: 60px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
          .conter {
            width: 570px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 24px;
            color: #243042;
            line-height: 50px;
          }
        }
        .box-right {
        }
        .store {
          position: absolute;
          left: -8px;
          top: 350px;
        }
      }
    }
  }
  .footer-box {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100px;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    .footer-item {
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .item-handel {
        display: flex;
        align-items: center;
        margin-left: 56px;
        cursor: pointer;
        img {
        }
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 20px;
          color: #243042;
          margin: 0 51px 0 18px;
        }
        .current {
          color: #8b5ef5;
        }
        .bar {
          width: 1px;
          height: 30px;
          border: 1px solid #243042;
        }
      }
    }
  }
}

.mainStyle-three2 {
  width: 100%;
  min-width: 1200px;
  height: 740px;
  background-image: url('@/assets/imgs/three-bg.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .three-box {
    width: 1200px;
    height: 100%;
    .three-item {
      width: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 34px;
        color: #243042;
        text-align: center;
        margin-top: 30px;
      }
      .middle-box {
        width: 100%;
        height: 570px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .box-left {
          .text {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 51px;
            color: #243042;
            line-height: 60px;
            margin-top: 10px;
            margin-bottom: 20px;
            width: 408px;
          }
          .conter {
            width: 570px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 24px;
            color: #243042;
            line-height: 50px;
          }
        }
        .box-right {
        }
        .store {
          position: absolute;
          left: 590px;
          top: 345px;
        }
      }
    }
  }
  .footer-box {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100px;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    .footer-item {
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .item-handel {
        display: flex;
        align-items: center;
        margin-left: 56px;
        cursor: pointer;
        img {
        }
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 20px;
          color: #243042;
          margin: 0 51px 0 18px;
        }
        .current {
          color: #8b5ef5;
        }
        .bar {
          width: 1px;
          height: 30px;
          border: 1px solid #243042;
        }
      }
    }
  }
}

.mainStyle-four {
  width: 100%;
  min-width: 1200px;
  height: 740px;
  background-color: #f2f3ff;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .four-main {
    width: 1200px;
    height: 100%;
    .title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 34px;
      line-height: 28px;
      color: #243042;
      text-align: center;
      margin-top: 24px;
    }
    .conter {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 20px;
      color: #8f93a9;
      text-align: center;
      line-height: 35px;
      margin-top: 15px;
    }
    .main-box {
      height: 636px;
      width: 100%;
      display: flex;
      .left {
        width: 50%;
        height: 100%;
        position: relative;
        .left-bg {
          position: absolute;
          top: -80px;
          left: -120px;
        }
        .four-1 {
          position: absolute;
          top: 170px;
          left: 30px;
        }
        .four-2 {
          position: absolute;
          top: 15px;
          left: 390px;
        }
        .four-3 {
          position: absolute;
          top: 275px;
          left: 495px;
        }
        .four-4 {
          position: absolute;
          top: 427px;
          left: 379px;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        margin-top: 50px;
      }
    }
  }
}

.mainStyle-five {
  width: 100%;
  min-width: 1200px;
  height: 740px;
  display: flex;
  justify-content: center;
  .five-box {
    width: 1200px;
    height: 100%;
    .title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 34px;
      color: #243042;
      line-height: 28px;
      text-align: center;
      margin-top: 42px;
    }
    .item-box {
      width: 100%;
      height: 250px;
      margin-top: 80px;
      display: flex;
      .item {
        width: 290px;
        height: 250px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 20px 20px 20px 20px;
        margin-right: 12px;
        .top {
          display: flex;
          margin-left: 19px;
          margin-top: 24px;
          .logo {
            margin-right: 4px;
          }
          .text {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 20px;
            color: #243042;
          }
        }
        .main {
          float: right;
        }
      }
    }
    .text-title {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .texts {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: bold;
        font-size: 20px;
        color: #243042;
        margin-right: 5px;
      }
      .icon {
        width: 20px;
        height: 20px;
        background: #243042;
        border-radius: 100px;
        color: #ffffff;
      }
    }
    .five-footer {
      width: 100%;
      height: 250px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .footer-text {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 34px;
        color: #243042;
        line-height: 28px;
      }
      .footer-left {
      }
    }
  }
}

.mainStyle-six {
  width: 100%;
  min-width: 1200px;
  height: 223px;
  background-image: url('@/assets/imgs/six-bg.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  .six-box {
    width: 1200px;
    .title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 600;
      font-size: 34px;
      color: #ffffff;
      line-height: 28px;
      margin-top: 48px;
      text-align: center;
    }
    .button-box {
      display: flex;
      justify-content: center;
      .six-button {
        width: 144px;
        height: 42px;
        background: linear-gradient(256deg, #8b5ef5 0%, #6d2fff 97%, #6c2dff 100%);
        box-shadow: 0px 1px 3px 1px rgba(108, 45, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        margin-top: 42px;
        cursor: pointer;
      }
    }
  }
}

.mainStyle-seven {
  width: 100%;
  min-width: 1200px;
  height: 550px;
  display: flex;
  justify-content: center;
  background-color: #243042;
  .seven-box {
    width: 1200px;
    .seven-top {
      width: 100%;
      height: 177px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #384353;
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: right;
        .weixin {
          margin-right: 90px;
          .code {
            width: 100px;
            height: 100px;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .text {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 13px;
            color: #ffffff;
            line-height: 26px;
            text-align: center;
            margin-top: 10px;
          }
        }
        .douyin {
          margin-right: 20px;
          .code {
            width: 100px;
            height: 100px;
            // background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .text {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 13px;
            color: #ffffff;
            line-height: 26px;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
    .seven-main {
      width: 100%;
      height: 350px;
      display: flex;
      padding-top: 51px;
      .item-box {
        margin-right: 65px;
        .item-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 41px;
          text-align: left;
        }
        .item-text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #b5b5b5;
          line-height: 24px;
          cursor: pointer;
        }
      }
      .item-right {
        margin-left: 260px;
        .item-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 41px;
          text-align: left;
        }
        .item-text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #b5b5b5;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
    .seven-footer {
      width: 100%;
      height: 20px;
      display: flex;
      position: relative;
      top: -25px;
      .footer-box {
        width: 100%;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 12px;
        color: #b5b5b5;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.window-box {
  cursor: pointer;
  position: fixed;
  right: 0px;
  top: 50%;
  width: 50px;
  height: 145px;
  background: linear-gradient(154deg, #a680ff 0%, #8b5ef5 62%, #a680ff 100%);
  box-shadow: 0px 1px 3px 1px rgba(108, 45, 255, 0.3);
  border-radius: 25px 25px 25px 25px;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  .window-img {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 25px 25px 25px 25px;
    margin: 5px 0 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
    }
  }
  .window-text {
    writing-mode: vertical-lr;
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    line-height: 18px;
  }
}

.qrcode-box {
  width: 169.99px;
  height: 160px;
  background-image: url('@/assets/imgs/qrcode-box.png');
  background-size: 100% 100%;
  position: fixed;
  right: 52px;
  top: 49%;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  .qrcode-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
}

.footerStyle {
  width: 100%;
  height: 60px;
  background-color: rgb(143, 191, 251);
  text-align: center;
  flex: 0 0 auto;
}

.loginStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.login-word {
  font-weight: 600;
  color: #5e5e5e;
  font-size: 16px;
  border-bottom: 1px solid #fff;
  height: 30px;
}

.login-switch {
  color: #8b5ef5;
  font-size: 13px;
  cursor: pointer;
  margin-right: 10px;
}

.login-form {
  width: 350px;
}

.icon-style {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}

.read-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 310px;
  margin-top: -10px;
}

.agreement {
  font-size: 10px;
  color: #8b5ef5;
  cursor: pointer;
}

.agreement-style {
  color: #6f757f;
  font-size: 10px;
  cursor: pointer;
}

.login-btn {
  margin-top: 15px;
}

.btn {
  position: relative;
  width: 350px;
  padding: 6px 0;
  margin: 10px 0 36px 0;
  font-size: 1.2em;
  color: white;
  background-color: #8b5ef5;
  border-radius: 5px;
  border: 0px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  height: 47px;
}

.greyStart {
  color: #8b5ef5;
  font-size: 11px;
}

.greyEnd {
  color: #6f757f;
  font-size: 11px;
}

.show-pwd {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.login-from-left {
  width: 300px;
  padding-left: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.code-scanning {
  font-weight: 600;
  margin-bottom: 20px;
  color: #6f757f;
  font-size: 16px;
  margin-left: 47px;
}

.vertical-line {
  width: 0.6px;
  height: 200px;
  background-color: #404143;
  position: absolute;
  left: 285px;
  top: 35px;
}

.code-button {
  position: absolute;
  top: 200px;
  margin-left: 10px;
}

.code-button-open {
  margin: 40px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.button-open1 {
  color: #6f757f;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600px;
}

.code-button-open1 {
  display: flex;
  margin-top: 10px;
}

.button-open1-refresh {
  color: #6f757f;
  font-size: 14px;
}

.button-open1-refresh1 {
  color: #8b5ef5;
  font-size: 14px;
  cursor: pointer;
}
</style>
