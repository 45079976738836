import request from '@/utils/request';

// 验证码
export function send(phone) {
  return request({
    url: '/hello-system/sms/endpoint/send-validate?phone=' + phone,
    method: 'post'
  });
}
// 登录
export function login(data) {
  return request({
    url: '/hello-auth/oauth/token',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
}
// 忘记密码
export function getNext(phone, id, value, newPassword, newPassword1) {
  return request({
    url: '/hello-user/resetting-password',
    method: 'post',
    params: {
      phone,
      id,
      value,
      newPassword,
      newPassword1
    }
  });
}
// 绑定手机号
export function getNextSmsCode(phone, id, value) {
  return request({
    url: '/hello-system/sms/endpoint/validate-SmsCode',
    method: 'post',
    params: {
      phone,
      id,
      value
    }
  });
}

// 下载桌面端包
export function getDownload(val) {
  return request({
    url: '/update/upgradePacket/checkUpdate?terminalTyp=' + val,
    method: 'get'
  });
}

//
export function addDownload(value) {
  return request({
    url: '/hello-system/download?clientId=' + value,
    method: 'post'
  });
}

// 获取JSSDKConfig
export function getJsSdkConfig(url) {
  return request({
    url: '/hello-system/wx/getJsSdkConfig?url=' + url,
    method: 'get'
  });
}
