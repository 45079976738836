<template>
    <div class="userStyle-invoicingRecords">
        <div class="invoicingRecords">
            <div>
                <div>
                    <span>发票号：</span>
                    <span>{{ list.invoiceNumber }}</span>
                </div>
                <div style="margin-top: 10px;">
                    <span>发票金额：</span>
                    <span>{{ list.invoiceAmt }}</span>
                </div>
                <div style="margin-top: 10px;">
                    <span>开票单号：</span>
                    <span>{{ list.id }}</span>
                </div>
                <div style="margin-top: 10px;"
                    v-show="(list.invoiceType == 2 || list.invoiceType == 1) && list.issuanceType == 1">
                    <span>纳税人识别号：</span>
                    <span>{{ list.taxpayerNumber }}</span>
                </div>
                <div style="margin-top: 10px;" v-show="list.invoiceType == 2 && list.issuanceType == 1">
                    <span>注册电话：</span>
                    <span>{{ list.registrationPhone }}</span>
                </div>
                <div style="margin-top: 10px;" v-show="list.invoiceType == 2 && list.issuanceType == 1">
                    <span>开户银行账户：</span>
                    <span>{{ list.bankAccount }}</span>
                </div>
                <div style="margin-top: 10px;">
                    <span>申请时间：</span>
                    <span>{{ list.createTime }}</span>
                </div>
            </div>
            <div>
                <div>
                    <span>发票类型：</span>
                    <span v-show="list.invoiceType == 1"> 普通发票</span>
                    <span v-show="list.invoiceType == 2"> 增值税专用发票</span>
                </div>
                <div style="margin-top: 10px;"
                    v-show="(list.invoiceType == 2 || list.invoiceType == 1) && list.issuanceType == 1">
                    <span>发票抬头：</span>
                    <span>{{ list.invoiceHeader }}</span>
                </div>
                <div style="margin-top: 10px;">
                    <span>开具类型：</span>
                    <span v-show="list.issuanceType == 1">企业</span>
                    <span v-show="list.issuanceType == 2">个人</span>
                </div>
                <div style="margin-top: 10px;" v-show="list.invoiceType == 2 && list.issuanceType == 1">
                    <span>注册地址：</span>
                    <span>{{ list.registrationAddress }}</span>
                </div>
                <div style="margin-top: 10px;" v-show="list.invoiceType == 2 && list.issuanceType == 1">
                    <span>开户银行：</span>
                    <span>{{ list.depositBank }}</span>
                </div>
                <div style="margin-top: 10px;" v-show="list.status == 4">
                    <span>驳回原因：</span>
                    <span style="color: #FF5A5A;">{{ list.rejectionReason }}</span>
                </div>
                <div style="margin-top: 10px;">
                    <span>发票状态：</span>
                    <span style="color: #FFBD5A;" v-show="list.status == 1">开票中</span>
                    <span style="color: #FFBD5A;" v-show="list.status == 2">已开票</span>
                    <span style="color: #FFBD5A;" v-show="list.status == 3">退票中</span>
                    <span style="color: #FFBD5A;" v-show="list.status == 4">已驳回</span>
                    <span style="color: #FFBD5A;" v-show="list.status == 5">已退票</span>
                </div>
            </div>
        </div>
        <div style="color: #8B5EF5; font-size: 14px;height: 10px;margin-left: 15px;">
            关联订单信息
        </div>
        <div style="padding: 0 15px;">
            <div style="height: 190px;background-color: #fff;margin: 20px 0;">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark"
                    style="width: 100%;" height="190px" :row-class-name="tableRowClassName"
                    :header-cell-style="{
            background: '#F2ECFF',
            color: '#44444F',
            border: 'none',
            fontSize: '14px',
          }"
          :cell-style="{ color: '#444444' }">
                    <el-table-column label="订单名称" prop="orderName" width="250">
                    </el-table-column>
                    <el-table-column prop="orderCode" label="订单号" width="180">
                    </el-table-column>
                    <el-table-column prop="payTime" label="订单支付时间" width="180">
                    </el-table-column>
                    <el-table-column label="金额">
                        <template slot-scope="scope">￥{{ scope.row.totalprice }}</template>
                    </el-table-column>
                </el-table>
            </div>

        </div>
    </div>
</template>
  
<script>
import { getInvoiceId, getUserOrdersA } from "@/api/invoice"
import Cookies from 'js-cookie'
export default {
    name: 'sourceP',
    props:['id'],
    data() {
        return {
            teamId:'',
            tableData: [],
            multipleSelection: [],
            ids: "",
            list: {
                invoiceNumber: "",
                invoiceAmt: "",
                // id: "",
                taxpayerNumber: "",
                registrationPhone: "",
                bankAccount: "",
                createTime: "",
                invoiceType: "",
                invoiceHeader: "",
                registrationAddress: "",
                depositBank: "",
                rejectionReason: "",
                status: "",
            }
        }
    },
    created() {
        this.teamId = localStorage.getItem("teamId");
        setTimeout(() => {
            this.getGoods1()
            this.getGoods()
        }, 500);
    },
    mounted() {

    },
    methods: {
        async getGoods1() {
            let data = {
                invoiceId: this.id,
                teamId:this.teamId
            }
            const res = await getUserOrdersA(data)
            this.tableData = res.data.data;
        },
        async getGoods() {
            const res = await getInvoiceId(this.id)
            this.list = res.data.data
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 == 0) {
                return "warning-row";
            } else {
                return "success-row";
            }
        },
        nextB() {
                // url: "/fillInvoice",
        },
    }
}
</script>
<style lang='less' scoped>
.userStyle-invoicingRecords {
    // background-color: #2B2B2B;
    width: 100%;
    height: 460px;
    overflow: hidden;
    user-select: none;
    overflow-x:scroll;
    // border: 1px solid #515152;

    .invoicingRecords {
        width: 100%;
        height: 180px;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        span {
            // color: #fff;
        }
    }

    .but7 {
        position: relative;
        width: 120px;
        font-size: 16px;
        color: white;
        // background-color: #8B5EF5 !important;
        border-radius: 4px;
        border: 0px;
        outline: none;
        cursor: pointer;
        overflow: hidden;
        height: 36px;
    }

    .but7:hover {
        background-color: #743AFE !important;
    }

    .el-table td.el-table__cell {
        background-color: rgba(232, 17, 35, 0) !important;
    }

    .el-table th.el-table__cell {
        // background-color: rgba(232, 17, 35, 0) !important;
    }

    .el-table td.el-table__cell {
        background: none !important;
    }

    .el-table .hover-row {
        background-color: #3A3A3A !important;
    }

    .el-table .warning-row {
        background: #3A3A3A !important;
    }

    .el-table .success-row {
        background: #303030 !important;
    }

    .el-table__fixed-right-patch {
        background-color: #303030 !important;
    }

    .el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: rgba(232, 17, 35, 0) !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #8B5EF5 !important;
        border: 1px solid #8B5EF5 !important;
    }

    .el-checkbox__inner:hover {
        border: 1px solid #8B5EF5 !important;
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #8B5EF5 !important;
        border: 1px solid #8B5EF5 !important;
    }

}



.close {
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    -webkit-app-region: no-drag;
}

.close:hover {
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(232, 17, 35, 0.9);
}
</style>