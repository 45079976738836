<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent></headComponent>
    <div class="mainStyle">
      <div class="img-box">
        <img class="images" src="@/assets/imgs/flow-bg.png" alt="" />
      </div>
      <div class="mainStyle-box">
        <div class="left">
          <div>
            <img src="@/assets/imgs/flowCenter-left.png" alt="" />
          </div>
        </div>
        <div class="right">
          <img src="@/assets/imgs/flow-right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="camera">
      <div class="camera-main">
        <div class="camera-top">
          <div class="title">如何获取直播间房间号</div>
          <div class="camera-conter">
            <div class="conter">
              在Hello
              Nvwa智播的数字语音直播模式下，可以开启自动回复功能。能设置直播中的弹幕，礼物回复，以及欢迎语等。首次使用需要填写直播房间号，具体流程如下：
            </div>
            <div class="ps">
              注：以抖音为例。大家可以先在桌面端登录自己要开直播的账号，因为试播不是所有人可见，不登录自己的账号，是搜不到直播间的。
            </div>
          </div>
          <div class="operate">操作说明</div>
        </div>
        <div class="one-box">
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">
                <div>
                    在抖音APP点击【+】，选择开直播，点击【所有人可见】，下划选择【试播】（试播不会涉及封号），试播时需要选择至少一个观众可见。
                </div>
                <div>
                    选择好以后点击【开始直播】
                </div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/live-1.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">
                <div>打开抖音桌面端，搜索自己开了直播的账号，点击进入直播间，在浏览器会出现直播间房间号。只需要需要复制后面那一串数字即可。</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/live-2.png" alt="" />
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/live-22.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">
                粘贴至Hello Nvwa智播即可。
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/live-3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerComponent></footerComponent>
  </div>
</template>

<script>
import headComponent from "@/views/component/headComponent.vue";
import footerComponent from "@/views/component/footerComponent.vue";
export default {
  name: "HomeView",
  components: { headComponent, footerComponent },
  data() {
    return {};
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
  .mainStyle {
    margin-top: 64px;
    flex-grow: 1;
    min-width: 1200px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    .img-box {
      position: absolute;
      height: 100%;
      width: 100%;
      .images {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        object-fit: cover;
      }
    }
    .mainStyle-box {
      z-index: 1;
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-family: DingTalk JinBuTi, DingTalk JinBuTi;
        font-weight: 400;
        font-size: 58px;
        color: #243042;
      }
    }
  }

  .camera {
    width: 100%;
    height: 3333px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .camera-main {
      width: 1200px;
      .camera-top {
        margin-top: 65px;
        .title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 40px;
          color: #243042;
          line-height: 28px;
          text-align: center;
        }
        .camera-conter {
          .conter {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            font-size: 28px;
            color: #243042;
            margin-top:68px;
            text-align:center;
          }
          .ps {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 18px;
            color: #e33f2d;
            line-height:41px;
            margin-top:10px;
            text-align:center;
          }
        }
        .operate {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 40px;
          color: #243042;
          line-height: 0px;
          text-align: center;
          margin-top: 100px;
        }
      }
      .one-box {
        margin-top: 60px;
        .one-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 85%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
