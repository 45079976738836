var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"App"},[(_vm.isWeixin)?_c('div',{staticClass:"mask"},[_vm._m(0),_vm._m(1)]):_vm._e(),_vm._m(2),_c('div',{staticClass:"title"},[_vm._v("Hello Nvwa 智播")]),_vm._m(3),_c('div',{staticClass:"details"},[_vm._v("详情")]),_c('div',{staticClass:"bar"}),_vm._m(4),_vm._m(5),_c('div',{staticClass:"text-item"},[_c('div',{staticClass:"banben"},[_vm._v("版本："+_vm._s(_vm.version))]),_c('div',{staticClass:"beian"},[_vm._v("备案号：湘ICP备2023009258号-3A")])]),_c('div',{staticClass:"btn-box"},[_c('div',{staticClass:"btn-app",on:{"click":_vm.download}},[_vm._m(6),_c('div',{staticClass:"btn-text"},[_vm._v("立即下载")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"left-one"},[_c('div',{staticClass:"num"},[_vm._v("1")]),_c('div',{staticClass:"text"},[_vm._v("点击右上角")]),_c('div',{staticClass:"dot"},[_c('img',{attrs:{"src":require("@/assets/imgs/app-dot.png"),"alt":""}})])]),_c('div',{staticClass:"left-two"},[_c('div',{staticClass:"num"},[_vm._v("2")]),_c('div',{staticClass:"text"},[_vm._v("选择“"),_c('span',{staticStyle:{"color":"#ff4444"}},[_vm._v("在浏览器打开")]),_vm._v("”")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("@/assets/imgs/jiantou.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('div',{staticClass:"image"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/imgs/icon.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-box"},[_c('div',{staticClass:"item1"},[_vm._v("数字人")]),_c('div',{staticClass:"item2"},[_vm._v("短视频")]),_c('div',{staticClass:"item3"},[_vm._v("虚拟直播")]),_c('div',{staticClass:"item4"},[_vm._v("虚拟场景")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lunbotu"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/app-1.png"),"alt":""}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/app-2.png"),"alt":""}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/app-3.png"),"alt":""}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/app-4.png"),"alt":""}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/imgs/app-5.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("@/assets/imgs/android2.png"),"alt":""}})])
}]

export { render, staticRenderFns }