import { render, staticRenderFns } from "./nvwa.vue?vue&type=template&id=7f6434c0&scoped=true&"
import script from "./nvwa.vue?vue&type=script&lang=js&"
export * from "./nvwa.vue?vue&type=script&lang=js&"
import style0 from "./nvwa.vue?vue&type=style&index=0&id=7f6434c0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f6434c0",
  null
  
)

export default component.exports