<template>
  <div style="margin-top: 10px" @click="dianji">
    <el-container>
      <el-aside width="auto" class="header-logo tap">
        <img class="logo" src="@/assets/imgs/logo.png" alt="Logo" @click.stop="loHo" />
      </el-aside>
      <el-aside width="auto" class="header-logo tap">
        <div style="cursor: pointer; width: 140px; overflow-y: auto; display: flex; margin-top: 5px; align-items: center; justify-content: end; position: relative; margin-right: 20px" @click.stop="teamNameEvn()">
          <div style="color: #171725; font-size: 12px">
            {{ teamName }}
          </div>
          <div style="margin-left: 5px">
            <img src="@/assets/imgs/tuanduiqiehuan.png" alt="" />
          </div>
        </div>
        <!-- teamList  @mouseleave="teamNameShow = false"-->
        <div class="yicang" style="width: 170px; height: 100px; background-color: #ffffff; border: 1px solid rgba(0, 0, 0, 0.12); position: absolute; z-index: 9999; top: 50px; right: 350px; overflow-y: auto" v-show="teamNameShow" @mouseleave="teamNameShow = false">
          <div v-for="(list, index) in teamList" :key="list.index" class="exitStyleXia1" @click.stop="teamNameEvn1(list)">
            {{ list.teamName }}
          </div>
        </div>
        <div style="display: flex; margin-right: 30px; cursor: pointer" @click.stop="setBtn">
          <img src="@/assets/imgs/huiyuantubiao.png" alt="" />
        </div>
        <div style="display: flex; margin-right: 30px; cursor: pointer" @click.stop="dingDan">
          <img src="@/assets/imgs/fapiaotubiao.png" alt="" />
        </div>

        <el-badge v-if="unread > 0" :value="unread" style="margin-right: 30px">
          <div style="display: flex; cursor: pointer" @click.stop="noticeBtn">
            <img src="@/assets/imgs/tongzhitubiao.png" alt="" />
          </div>
        </el-badge>
        <div v-else style="margin-right: 30px">
          <div style="display: flex; cursor: pointer" @click.stop="noticeBtn">
            <img src="@/assets/imgs/tongzhitubiao.png" alt="" />
          </div>
        </div>
        <div style="display: flex; margin-right: 30px; cursor: pointer">
          <img src="@/assets/imgs/fankuitubiao.png" alt="" />
        </div>
        <div @click.stop="dialogShow = true" style="display: flex; align-items: center">
          <div style="width: 30px; height: 30px; border-radius: 15px; overflow: hidden; cursor: pointer">
            <img v-if="this.avatar != '' && this.avatar != undefined && this.avatar != null" :src="avatar" alt="" width="30px" height="30px" />
            <img v-if="this.avatar == '' || this.avatar == undefined || this.avatar == null" src="@/assets/imgs/touxiang3.png" alt="" width="30px" height="30px" />
            <!--  -->
          </div>
          <div style="color: #575964; font-size: 12px; margin-left: 10px; width: 73px">
            {{ nickName }}
          </div>
        </div>

        <div v-show="dialogShow" @mouseleave="dialogShow = false" class="avatar-dialog">
          <div class="dialog-top">
            <div class="avatar">
              <img v-if="this.avatar != '' && this.avatar != undefined && this.avatar != null" :src="avatar" alt="" width="50px" height="50px" />
              <img v-if="this.avatar == '' || this.avatar == undefined || this.avatar == null" src="@/assets/imgs/touxiang3.png" alt="" width="50px" height="50px" />
            </div>
            <div class="user-box">
              <div class="user-top">
                <div class="phone">{{ nickName }}</div>
                <div class="vip-icon">
                  <div class="icon">
                    <img v-if="menberType === 1" src="@/assets/imgs/red.png" alt="" />
                    <img v-else src="@/assets/imgs/red-fill.png" alt="" />
                  </div>
                  <div class="icon">
                    <img v-if="voiceMenberType === 1" src="@/assets/imgs/purple.png" alt="" />
                    <img v-else src="@/assets/imgs/purple-fill.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="user-text">开通会员享更多权益</div>
            </div>
          </div>
          <div class="dialog-main">
            <div class="main-item" @click.stop="gotoInformation()">个人信息</div>
            <div class="main-item" @click.stop="gotoGoods()">订单管理</div>
            <div v-if="menberType === 1 || voiceMenberType === 1" class="main-item" @click.stop="gotoMember()">会员续费</div>
            <div v-else class="main-item" @click.stop="gotoMember()">开通会员</div>
          </div>
          <div class="dialog-footer">
            <div @click.stop="exitEvn" class="text">退出登录</div>
          </div>
        </div>

        <el-dialog title="系统通知" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
          <div style="height: 1px; width: 96%; background-color: #f1f1f1; margin-left: 10px"></div>
          <div class="notice-box">
            <div v-for="(item, index) in InformList" :key="index">
              <div v-show="item.noticeStatus === 1" class="notice-item">
                <div class="notice-title">
                  <div class="left">
                    <div v-html="item.title" style="white-space: pre-wrap"></div>
                  </div>
                  <div class="right" @click.stop="openNotice(item)">
                    <div class="time">{{ item.createTime }}</div>
                    <div class="icon">
                      <i class="el-icon-arrow-down"></i>
                    </div>
                    <div class="text">展开</div>
                  </div>
                </div>
              </div>
              <div v-show="item.noticeStatus === 2" class="notice-item2">
                <div class="notice-conter">
                  <div v-html="item.content" style="white-space: pre-wrap"></div>
                </div>
                <div class="notice-footer">
                  <div class="box">
                    <div class="left">
                      <div class="time">{{ item.createTime }}</div>
                    </div>
                    <div class="right" @click.stop="closeNotice(item)">
                      <div class="icon">
                        <i class="el-icon-arrow-up"></i>
                      </div>
                      <div class="text">收起</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import { getUserTeam, getInformAllList, getInformDetails, getInformUnread, getOssPrefix } from '@/api/team';
import { informationId } from '@/api/information';
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      menberType: 0,
      voiceMenberType: 0,
      dialogShow: false,
      dialogVisible: false,
      noticeStatus: 1,
      activeIndex: '1',
      teamList: [],
      InformList: [],
      avatar: localStorage.getItem('avatar'),
      nickName: localStorage.getItem('nickName'),
      teamName: localStorage.getItem('teamName'),
      i: parseInt(localStorage.getItem('i')),
      avatar1: 1,
      teamNameShow: false,
      unread: 0
    };
  },
  created() {
    this.teamEvn();
    this.getInformAllListApi();
    getOssPrefix().then((res) => {
      const data = res.data.data;
      window.localStorage.setItem('OssPrefix', data);
    });
  },
  mounted() {
    // this.getAvatar();
  },
  // require('')
  methods: {
    handleMouseLeave() {
      this.teamNameShow = false;
    },
    dianji() {
      this.teamNameShow = false;
    },
    loHo() {
      this.$router.push({ path: '/control/' });
    },
    dingDan() {
      this.$router.push({ path: '/Goods' });
    },
    // getAvatar() {

    //   if (
    //     localStorage.getItem("avatar") == "" ||
    //     localStorage.getItem("avatar") == undefined ||
    //     localStorage.getItem("avatar") == "null"
    //   ) {
    //     this.avatar1 = 2;
    //   }

    // },
    handleClose() {
      this.dialogVisible = false;
    },
    setBtn() {
      this.$router.push('/member').catch((error) => error);
    },
    noticeBtn() {
      this.dialogVisible = true;
    },
    getInformAllListApi() {
      const params = {
        current: 1,
        size: 9999
      };
      getInformAllList(params).then((res) => {
        res.data.data.records.forEach((item) => {
          item.noticeStatus = 1;
          item.title = decodeURIComponent(item.title);
          item.content = decodeURIComponent(item.content);
          // if (item.status === 1) {
          //   this.InformList.push(item)
          // }
        });
        this.InformList = res.data.data.records;
      });
      getInformUnread().then((res) => {
        this.unread = res.data.data;
      });
    },
    openNotice(row) {
      getInformDetails(row.id).then((res) => {
        getInformUnread().then((res) => {
          this.unread = res.data.data;
        });
      });
      this.InformList.forEach((i) => {
        if (i.id === row.id) {
          i.noticeStatus = 2;
          this.$forceUpdate();
        }
      });
      // row.noticeStatus = 2;
    },
    closeNotice(row) {
      row.noticeStatus = 1;
    },
    teamNameEvn() {
      this.teamNameShow = true;
    },
    teamNameEvn1(item) {
      this.teamNameShow = false;
      // this.i += 1;
      // if (this.i == this.teamList.length) {
      //   this.i = 0;
      // }
      this.teamName = item.teamName;
      // localStorage.setItem('i', this.i);
      localStorage.setItem('teamId', item.id);
      localStorage.setItem('teamName', item.teamName);
      // setTimeout(() => {
      location.reload();
      // }, 500);
    },
    async teamEvn() {
      const res = await getUserTeam();
      this.teamList = res.data.data;
      const teamName = localStorage.getItem('teamName');
      if (teamName != '' && teamName != undefined && teamName != null) {
      } else {
        localStorage.setItem('i', 1);
        this.teamName = res.data.data[0].teamName;
        localStorage.setItem('teamName', res.data.data[0].teamName);
        localStorage.setItem('teamId', res.data.data[0].id);
        location.reload();
      }
      informationId(localStorage.getItem('userId'), localStorage.getItem('teamId')).then((res) => {
        localStorage.setItem('isTeamAdmin', res.data.data.isTeamAdmin);
        this.menberType = res.data.data.menberType;
        this.voiceMenberType = res.data.data.voiceMenberType;
        this.avatar = res.data.data.avatar;
        this.nickName = res.data.data.name;
      });
    },
    handleSelect(key, keyPath) {},
    exitEvn() {
      this.$confirm('确认退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          localStorage.clear();
          this.$router.push('/').catch((error) => error);
          location.reload();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          });
        });
    },
    gotoInformation() {
      this.dialogShow = false;
      this.$router.push({ path: '/information', query: { r: Math.random() } });
    },
    gotoGoods() {
      this.dialogShow = false;
      this.$router.push({ path: '/goods', query: { r: Math.random() } });
    },
    gotoMember() {
      this.dialogShow = false;
      this.$router.push({ path: '/member', query: { r: Math.random() } });
    }
  }
};
</script>
<style lang="less" scoped>
.exitStyleXia1 {
  font-size: 12px;
  color: #171725;
  // margin-top: 8px;
  padding-left: 25px;
  height: 26px;
  line-height: 26px;
}

.exitStyleXia1:hover {
  cursor: pointer;
  // color: #8b5ef5;
  background-color: #f0f1f2;
}
// .yicang:hover {
//   display: none;
// }

.avatar-dialog {
  z-index: 9;
  position: absolute;
  top: 59px;
  right: 50px;
  width: 301px;
  height: 289px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px 4px 4px 4px;

  .dialog-top {
    width: 100%;
    height: 102px;
    background: rgba(232, 232, 232, 0.4);
    display: flex;
    align-items: center;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      overflow: hidden;
      cursor: pointer;
      margin-left: 17px;
      margin-right: 7px;
    }
    .user-box {
      .user-top {
        display: flex;
        align-items: center;
        align-items: center;
        .phone {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 16px;
          color: #243042;
          line-height: 25px;
          margin-top: 4px;
        }
        .vip-icon {
          margin-left: 2px;
          display: flex;
          .icon {
            width: 21px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .user-text {
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 14px;
        color: #e17800;
      }
    }
  }
  .dialog-main {
    width: 100%;
    height: 132px;
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .main-item {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 14px;
      color: #243042;
      margin-left: 18px;
      cursor: pointer;
      line-height: 35px;
    }
  }
  .dialog-footer {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      cursor: pointer;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 14px;
      color: #e40000;
    }
  }
}
::v-deep .el-dialog__header {
  background: linear-gradient(to bottom, #f2ecff, #ffffff);
}
::v-deep .el-dialog__body {
  padding-top: 0px;
}

.notice-box {
  height: 345px;
  overflow-y: auto;
  margin: 10px 0;
  .notice-item {
    height: 50px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
    margin: 12px;
    .notice-title {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        margin-left: 20px;
        width: 50%;
      }
      .right {
        display: flex;
        .time {
          font-size: 14px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          color: #6e6e6e;
        }
        .icon {
          margin-left: 5px;
          background: #ffffff;
          cursor: pointer;
        }
        .text {
          margin-left: 5px;
          margin-right: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          color: #171725;
          cursor: pointer;
        }
      }
    }
  }
  .notice-item2 {
    margin: 10px 10px;
    height: 250px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
    .notice-title {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        margin-left: 20px;
      }
    }
    .notice-conter {
      height: 200px;
      margin: 0 20px;
      overflow-y: auto;
      padding-top: 10px;
    }
    .notice-footer {
      height: 50px;
      .box {
        width: 94%;
        height: 100%;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #ccc;
        .left {
          .time {
          }
        }
        .right {
          display: flex;
          .icon {
            cursor: pointer;
          }
          .text {
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.el-aside {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

section {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.reddd {
  background-color: #fff;
}

.reddd:hover {
  background-color: #626262;
}

.logo {
  /* width: 40px; */
}

.headerLogo,
.logo {
  cursor: pointer;
}

.container::-webkit-scrollbar {
  width: 2px;
  background-color: red;
  border-radius: 2px;
}
</style>
