import request from "@/utils/request";

export function getUserOrders(query) {
  return request({
    url: "/hello-trade/orders/getUserOrder/"+query.teamId,
    method: "get",
    params: query,
  });
}

export function getUserOrdersA(params) {
  return request({
    url: "/hello-trade/orders/getUserOrders",
    method: "get",
    params: params,
  });
}

export function getInvoiceSave(data) {
    return request({
      url: "/hello-trade/invoice/save",
      method: "post",
      data: data,
    });
  }

  export function getInvoiceList() {
    return request({
      url: "/hello-trade/invoice/invoicelist",
      method: "get",
    //   params: query,
    });
  }

  export function getInvoiceUp( data) {
    return request({
      url: "/hello-trade/invoice/up",
      method: "PUT",
      data: data,
    });
  }

  export function getInvoiceId(id) {
    return request({
      url: "/hello-trade/invoice/" +id,
      method: "get",
    //   params: query,
    });
  }

  export function getCustomizedList() {
    return request({
      url: "/hello-trade/customized/customizedList",
      method: "get",
    //   params: query,
    });
  }

