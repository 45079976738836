import request from '@/utils/request';

export function informationId(userId, teamId) {
  return request({
    url: '/hello-user/' + userId + '?teamId=' + teamId,
    method: 'get'
  });
}
export function teamUserEquity(params) {
  return request({
    url: '/hello-user/team/teamUserEquity',
    method: 'get',
    params: params
  });
}
export function getDictList(query) {
  return request({
    url: '/hello-system/dict/list',
    method: 'get',
    params: query
  });
}
export const getChildListZZ = (params) => {
  return request({
    url: '/hello-system/dict/child-list',
    method: 'get',
    params
  })
}
export function getInfo(data) {
  return request({
    url: '/hello-user/update-info',
    method: 'post',
    data: data
  });
}
export function changeTeamName(data) {
  return request({
    url: '/hello-user/team/changeTeamName',
    method: 'put',
    data: data
  });
}
export function getUnbinding(id) {
  return request({
    url: '/hello-user/' + id,
    method: 'DELETE'
  });
}
export function getCount(teamId) {
  return request({
    url: '/hello-material/live/count/' + teamId,
    method: 'get'
  });
}
export function getOssPrefix() {
  return request({
    url: "/hello-system/oss/endpoint/get-oss-prefix",
    method: "get",
  });
}
// /hello-material/live/count/{teamId}
export function getsSaveId(query) {
  return request({
    url: "/hello-trade/orders/save",
    method: "POST",
    params: query,
  });
}
export function getToPay(query) {
  return request({
    url: "/hello-trade/wx/payment/toPay",
    method: "POST",
    params: query,
  });
}
export function getSuccess(orderId) {
  return request({
    url: "/hello-trade/wx/payment/order/" + orderId,
    method: "get",
    // data: data,
  });
}

export function getRefuelBagList(type) {
  return request({
    url: "/hello-trade/refuel_bag/refuelBagList?type=" + type,
    method: "get",
    // data: data,
  });
}
// /hello-trade/cloud_space/refuelBagList
export function spaceRefuelBagList(val) {
  return request({
    url: "hello-trade/cloud_space/" + val,
    method: "get",
    // params: query,
  });
}
export function logout(id) {
  return request({
    url: "hello-user/logout/" + id,
    method: "put",
    // params: query,
  });
}

