import request from '@/utils/request';

export function getGoodsList(type, goodsType) {
  return request({
    url: '/hello-trade/goods/goodsList?type=' + type + '&goodsType=' + goodsType,
    method: 'get'
    // data: data,
  });
}
export function getsSaveId(query) {
  return request({
    url: '/hello-trade/orders/save',
    method: 'POST',
    params: query
  });
}
export function getToPay(query) {
  return request({
    url: '/hello-trade/wx/payment/toPay',
    method: 'POST',
    params: query
  });
}
export function getSuccess(orderId) {
  return request({
    url: '/hello-trade/wx/payment/order/' + orderId,
    method: 'get'
    // data: data,
  });
}

export function addOrder(query) {
  return request({
    url: '/hello-trade/orders/addOrder',
    method: 'POST',
    data: query
  });
}


export function getRefuelBagList(type) {
  return request({
    url: '/hello-trade/refuel_bag/refuelBagList?type=' + type,
    method: 'get'
    // data: data,
  });
}
export function getDictList(query) {
  return request({
    url: "/hello-system/dict/list",
    method: "get",
    params: query,
  });
}
export function getCustomizedList() {
  return request({
    url: "/hello-trade/customized/customizedList",
    method: "get",
  //   params: query,
  });
}
export function getPCToPayByAlipay(query) {
  return request({
    url: '/hello-trade/wx/payment/getPCToPayByAlipay',
    method: 'get',
    params: query,
  });
}
// /hello-trade/wx/payment/getPCToPayByAlipay


