<template>
  <div class="editBox">
    <div class="left">
      <div
        class="left-item"
        v-for="(item, index) in leftList"
        :key="index"
        @click="addMain(item)"
        :style="`color:${item.color};`"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="main">
      <div class="main-box">
        <div
          class="main-item"
          v-for="(item, index) in mainList"
          :key="index"
          @click="editText(item)"
          :style="`color:${item.color};width:${item.width};height:${item.height};`"
        >
          {{ item.text }}
        </div>
        <h1 class="img-text1">这是一个文字加背景图叠加</h1>
        <h1 class="img-text2">这是一个文字加背景图叠加</h1>
        <h1 class="img-text3">这是一个文字加背景图叠加</h1>
      </div>
    </div>
    <div class="right">
      <div class="right-box">
        内容:<el-input type="text" v-model="value1" @change="changeValue1" />
        颜色:<el-input type="text" v-model="value2" @change="changeValue2" /> 宽:<el-input
          type="text"
          v-model="value3"
          @change="changeValue3"
        />
        高:<el-input type="text" v-model="value4" @change="changeValue4" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",

  data() {
    return {
      leftList: [
        {
          id: 1,
          name: "花体",
          text: "默认字体",
          width: "200px",
          height: "100px",
          color: "red",
        },
        {
          id: 2,
          name: "花体",
          text: "默认字体",
          width: "120px",
          height: "70px",
          color: "blue",
        },
        {
          id: 3,
          name: "花体",
          text: "默认字体",
          width: "190px",
          height: "150px",
          color: "pink",
        },
        {
          id: 4,
          name: "花体",
          text: "默认字体",
          width: "190px",
          height: "170px",
          color: "green",
        },
        {
          id: 5,
          name: "花体",
          text: "默认字体",
          width: "180px",
          height: "150px",
          color: "purple",
        },
      ],
      mainList: [
        {
          id: 1,
          name: "花体",
          text: "默认字体",
          width: "350px",
          height: "150px",
          color: "red",
        },
      ],
      form: {},
      value1: "",
      value2: "",
      value3: "",
      value4: "",
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    addMain(row) {
      this.mainList.push(row);
      this.form = row;
      console.log(this.mainList, "1111111111");
    },
    editText(row) {
      console.log(row.text);
      this.value1 = row.text;
    },
    changeValue1() {
      this.form.text = this.value1;
    },
    changeValue2() {
      this.form.color = this.value2;
    },
    changeValue3() {
      this.form.width = this.value3 + "px";
    },
    changeValue4() {
      this.form.height = this.value4 + "px";
    },
  },
};
</script>

<style lang="less" scoped>
.editBox {
  display: flex;
  width: 100%;
  height: 100%;
  .left {
    width: 20%;
    height: 100%;
    background: #ccc;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .left-item {
      cursor: pointer;
      background-color: #ffffff;
      width: 150px;
      height: 100px;
      display: inline-block;
      border: 1px solid #333333;
      margin: 20px;
      text-align: center;
      line-height: 90px;
    }
  }
  .main {
    width: 60%;
    height: 100%;
    .main-box {
      width: 60%;
      height: 100%;
      padding: 20px;
      display: flex;
      .main-item {
        background-color: #ccc;
        width: 100px;
        height: 100px;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: 800;
        -webkit-text-stroke: 2px #000;
      }
      .img-text1 {
        width: 200px;
        height:200px;
        background-image: url('@/assets/imgs/img-demo.png');
        background-repeat: no-repeat;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-size: 26px;
      }
      .img-text2{
        width: 350px;
        height:200px;
        background-image: url('@/assets/imgs/img-demo.png');
        background-repeat: no-repeat;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-size: 36px;
      }
      .img-text3{
        width: 350px;
        height:300px;
        background-image: url('@/assets/imgs/img-demo2.png');
        background-repeat: no-repeat;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-size: 56px;
      }
    }
  }
  .right {
    width: 20%;
    height: 100%;
    background: #ccc;
    .right-box {
      padding: 10px;
    }
  }
}
</style>
