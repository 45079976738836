<template>
  <div class="nvwaLive">
    <div class="view-box">
      <video
        id="myVideo"
        ref="videoPlayer"
        width="100%"
        height="100%"
        src="https://yohelo-hello.oss-cn-shenzhen.aliyuncs.com/public/tutorial/build_scen.mp4"
      ></video>
      <div class="playImg" @click="playImg" v-if="playerSum === 1">
        <img src="@/assets/imgs/play.png" alt="" />
      </div>
      <div class="playBox">
        <div class="box">
          <div v-if="playerSum === 1" class="iconPlay" @click="playVideo">
            <img width="16px" height="16px" src="@/assets/imgs/iconPlay.png" alt="" />
          </div>
          <div v-else class="iconpause" @click="pauseVideo">
            <img width="16px" height="16px" src="@/assets/imgs/iconPause.png" alt="" />
          </div>
          <div class="progress">
            <div class="line">
              <el-slider
                :show-tooltip="false"
                v-model="value1"
                @change="changeValue"
              ></el-slider>
            </div>
          </div>
          <div class="time-box">
            <div class="playTime">
              {{ playTime }}
            </div>
            <div class="icon">/</div>
            <div class="totalTime">
              {{ totalTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nvwaLive-m",
  components: {},
  data() {
    return {
      playerSum: 1,
      playTime: "00:00",
      totalTime: "",
      value1: 0,
      sum1: "",
      sum2: "",
    };
  },
  computed: {},
  created() {
    
  },
  mounted() {
    // if (this.$refs.videoPlayer) {
    const videoPlayer = this.$refs.videoPlayer;
    videoPlayer.addEventListener("timeupdate", () => {
      console.log("当前播放时间", parseInt(videoPlayer.currentTime));
      this.playTime = this.durationTrans(videoPlayer.currentTime);
      this.sum2 = parseInt(videoPlayer.currentTime);
      // console.log(parseInt((this.sum2 / this.sum1) * 100), "11111");
      this.value1 = parseInt((this.sum2 / this.sum1) * 100);
      console.log(this.sum2, "this.playTimethis.playTimethis.playTime");
      if (this.sum2 === this.sum1) {
        this.playerSum = 1;
      }
    });
    videoPlayer.addEventListener("loadedmetadata", () => {
      // console.log("视频总长度为", parseInt(videoPlayer.duration));
      this.totalTime = this.durationTrans(parseInt(videoPlayer.duration));
      this.sum1 = parseInt(videoPlayer.duration);
    });
    // }
    setTimeout(()=>{
      this.playVideo();
    },500)
  },
  methods: {
    playVideo() {
      this.$refs.videoPlayer.play();
      this.playerSum = 2;
    },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
      this.playerSum = 1;
    },
    changeValue(v) {
      console.log(this.$refs.videoPlayer.player);
      var video = document.getElementById("myVideo");
      video.currentTime = (v / 100) * this.sum1; // 设置播放位置为10秒
      video.play(); // 播放视频
      this.playerSum = 2;
    },
    // 视频时长转时分秒
    durationTrans(a) {
      if (!a || a < 1) {
        return "00:01";
      }
      let duration = "";
      let h = parseInt(a / 3600),
        m = parseInt((a % 3600) / 60),
        s = parseInt((a % 3600) % 60);
      if (h > 0) {
        h = h < 10 ? "0" + h : h;
        duration += h + ":";
      }
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      duration += m + ":" + s;
      return duration;
    },
    playImg(){
        this.$refs.videoPlayer.play();
        this.playerSum = 2;
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-slider__button {
  width: 12px;
  height: 12px;
  border: 0;
  margin-top: -6px;
}
::v-deep .el-slider__runway {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-color: #000000;
}
::v-deep .el-slider__bar {
  height: 1px;
  background-color: #ffffff;
}
.nvwaLive {
  width: 100%;
  .view-box {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #111214;
    .playImg {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 60px;
        height: 60px;
      }
    }
    .playBox {
      position: fixed;
      bottom: 0px;
      width: 100%;
      height: 60px;
      background: #111214;
      .box {
        height: 60px;
        display: flex;
        align-items: center;
        .iconPlay {
          margin-left: 10px;
        }
        .iconpause {
          margin-left: 10px;
        }
        .progress {
          width: 100%;
          height: 60px;
          padding: 0 15px;
          display: flex;
          align-items: center;
          .line {
            width: 100%;
            position: relative;
            top: -2px;
          }
        }
        .time-box {
          display: flex;
          margin-right: 10px;
          .playTime {
            color: #ffffff;
            font-size: 12px;
          }
          .icon {
            color: #ffffff;
            font-size: 12px;
            margin: 0 3px;
          }
          .totalTime {
            color: #747474;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
