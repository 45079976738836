<template>
  <div class="home">
    <headComponent></headComponent>
    <div class="one-box">
      <div class="one-main">
        <div class="title">
          <img src="@/assets/imgs/agent_1-1.png" alt="" />
        </div>
        <div class="content">
          <div>将直播内容和直播经营权委托给我们专业直播运营团队，我们会代替主播</div>
          <div>或品牌负表直播节目的策划、制作、推广和运营等工作。</div>
        </div>
        <div class="images">
          <img src="@/assets/imgs/agent_1-2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="two-box">
      <div class="two-main">
        <div class="title">
          <div class="left">行业</div>
          <div class="right">痛点</div>
        </div>
        <div class="D_line">
          <div class="line">
            <img src="@/assets/imgs/agent-bg_3.png" alt="" />
          </div>
          <div class="man">
            <img src="@/assets/imgs/man.png" alt="" />
          </div>
          <div class="image1_1">
            <img src="@/assets/imgs/agent-2_1_1.png" alt="" />
          </div>
          <div class="image1_2">
            <!-- <img src="@/assets/imgs/agent-2_1_2.png" alt="" /> -->
            <div class="image_title">人工和硬件成本高</div>
            <div class="image_content">
              <div>直播时需要策划、后期、拍摄、计算机、服务</div>
              <div>器、摄像头等这些都是较高的人工和硬件成本。</div>
            </div>
          </div>
          <div class="image2_1">
            <img src="@/assets/imgs/agent-2_2_1.png" alt="" />
          </div>
          <div class="image2_2">
            <!-- <img src="@/assets/imgs/agent-2_2_2.png" alt="" /> -->
            <div class="image_title">内容复杂</div>
            <div class="image_content">
              <div>直播策划是具有多样性、专业性、互动性、</div>
              <div>创新性、合规性和技术性的较负责创作内容。</div>
            </div>
          </div>
          <div class="image3_1">
            <img src="@/assets/imgs/agent-2_3_1.png" alt="" />
          </div>
          <div class="image3_2">
            <!-- <img src="@/assets/imgs/agent-2_3_2.png" alt="" /> -->
            <div class="image_title">规则繁多</div>
            <div class="image_content">
              <div>各个平台对于用户行为规范、社交互动规</div>
              <div>范、特定行为限制等合规性要求严格。</div>
            </div>
          </div>
          <div class="image4_1">
            <img src="@/assets/imgs/agent-2_4_1.png" alt="" />
          </div>
          <div class="image4_2">
            <!-- <img src="@/assets/imgs/agent-2_4_2.png" alt="" /> -->
            <div class="image_title">运营难度高</div>
            <div class="image_content">
              <div>直播的运营成本通常较高,包括技术研发成</div>
              <div>本、内容创作成本、营销推广成本等。</div>
            </div>
          </div>
          <div class="image5_1">
            <img src="@/assets/imgs/agent-2_5_1.png" alt="" />
          </div>
          <div class="image5_2">
            <!-- <img src="@/assets/imgs/agent-2_5_2.png" alt="" /> -->
            <div class="image_title">流量转化难</div>
            <div class="image_content">
              <div>新号直播如何起号、如何突破流量阀值、流量如何转化?</div>
              <div>如何解决这些问题是提高流量转化率的关键所在。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="three-box">
      <div class="three-main">
        <div class="title">服务内容</div>
        <div class="three-card">
          <div class="left">
            <div class="title">
              <div class="images">
                <img src="@/assets/imgs/service-1.png" alt="" />
              </div>
              <div class="text">基础服务</div>
            </div>
          </div>
          <div class="right">
            <div class="title">
              <div class="images">
                <img src="@/assets/imgs/service-2.png" alt="" />
              </div>
              <div class="text">增值服务</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="four-box">
      <div class="four-main">
        <div class="four-title">我们的优势</div>
        <div class="four-conter">
          我们的代运营数字人直播能够帮助企业和个人解决直播过程中的各种问题，提高直播效果和影响力
        </div>
        <div class="four-carousel">
          <el-carousel
            :autoplay="false"
            indicator-position="none"
            @change="carouselChange"
          >
            <el-carousel-item>
              <div class="carousel-item-one">
                <div class="one-left">
                  <div class="title-box">
                    <div class="logo">
                      <img src="@/assets/imgs/agent-4-1.png" alt="" />
                    </div>
                    <div class="text">增加曝光率</div>
                  </div>
                  <div class="text-box">
                    <div>我们可以结合直播的特点和平台的优势，为客户</div>
                    <div>提供多样化的营销手段，提高销售和转化效果。</div>
                  </div>
                </div>
                <div class="one-right">
                  <img src="@/assets/imgs/agent-4-2.png" alt="" />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carousel-item-two">
                <div class="two-left">
                  <img src="@/assets/imgs/agent-5-2.png" alt="" />
                </div>
                <div class="two-right">
                  <div class="title-box">
                    <div class="logo">
                      <img src="@/assets/imgs/agent-5-1.png" alt="" />
                    </div>
                    <div class="text">专业的团队策划</div>
                  </div>
                  <div class="text-box">
                    <div>我们拥有为客户提供全方位的直播服务，具备专</div>
                    <div>业的直播技术，确保直播的稳定性和流畅性，提</div>
                    <div>供高质量的直播体验</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carousel-item-three">
                <div class="three-left">
                  <div class="title-box">
                    <div class="logo">
                      <img src="@/assets/imgs/agent-6-1.png" alt="" />
                    </div>
                    <div class="text">提高直播质量和效果</div>
                  </div>
                  <div class="text-box">
                    <div>我们还会根据客户的需求和目标受众，制定合适</div>
                    <div>的直播策略和推广方案，从而提高直播的效果和</div>
                    <div>影响力。</div>
                  </div>
                </div>
                <div class="three-right">
                  <img src="@/assets/imgs/agent-6-2.png" alt="" />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carousel-item-four">
                <div class="four-left">
                  <img src="@/assets/imgs/agent-7-2.png" alt="" />
                </div>
                <div class="four-right">
                  <div class="title-box">
                    <div class="logo">
                      <img src="@/assets/imgs/agent-7-1.png" alt="" />
                    </div>
                    <div class="text">节省人工、运营、硬件等成本</div>
                  </div>
                  <div class="text-box">
                    <div>我们会负责直播的策划、执行和后期运营等工</div>
                    <div>作，无需组建专业的直播团队，也无需投入大量</div>
                    <div>的时间和精力进行直播准备和运营</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="five-box">
      <div class="five-main">
        <div class="five-title">客户案例</div>
        <swiper ref="videoSwiper" :options="swiperOption" :allowTouchMove="false">
          <swiper-item class="swiper-slide">
            <div class="item-box1">
              <div class="logo">
                <img src="@/assets/imgs/agent-five-logo.png" alt="" />
              </div>
              <div class="text">
                <div class="title">尚链通科技</div>
                <div class="content">
                  <div>
                    通过我们的代运营数字人直播批量定制搭建数字人直播间，解决过去人工、
                  </div>
                  <div>硬件成本过高的问题，为企业将本增效。</div>
                </div>
              </div>
            </div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box2">
              <div class="logo">
                <img src="@/assets/imgs/agent-five-logo.png" alt="" />
              </div>
              <div class="text">
                <div class="title">尚链通科技</div>
                <div class="content">
                  <div>
                    通过我们的代运营数字人直播批量定制搭建数字人直播间，解决过去人工、
                  </div>
                  <div>硬件成本过高的问题，为企业将本增效。</div>
                </div>
              </div>
            </div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box3">
              <div class="logo">
                <img src="@/assets/imgs/agent-five-logo.png" alt="" />
              </div>
              <div class="text">
                <div class="title">尚链通科技</div>
                <div class="content">
                  <div>
                    通过我们的代运营数字人直播批量定制搭建数字人直播间，解决过去人工、
                  </div>
                  <div>硬件成本过高的问题，为企业将本增效。</div>
                </div>
              </div>
            </div>
          </swiper-item>
          <swiper-item class="swiper-slide">
            <div class="item-box4">
              <div class="logo">
                <img src="@/assets/imgs/agent-five-logo.png" alt="" />
              </div>
              <div class="text">
                <div class="title">尚链通科技</div>
                <div class="content">
                  <div>
                    通过我们的代运营数字人直播批量定制搭建数字人直播间，解决过去人工、
                  </div>
                  <div>硬件成本过高的问题，为企业将本增效。</div>
                </div>
              </div>
            </div>
          </swiper-item>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="six-box">
      <div class="six-main">
        <div class="six-title">咨询专业顾问</div>
        <div class="six-conter">
          关于使用场景和产品方案的更多咨询，请联系我们的销售团队，
        </div>
        <div class="six-btn">立即咨询</div>
      </div>
    </div>
    <footerComponent></footerComponent>
  </div>
</template>
<script>
import headComponent from "@/views/component/headComponent.vue";
import footerComponent from "@/views/component/footerComponent.vue";
import "swiper/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "HomeView",
  components: { headComponent, footerComponent, swiper, swiperSlide },
  data() {
    return {
      topStatus: 1,
      colorStatus: 1,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination", // 与slot="pagination"处 class 一致
          clickable: true, // 轮播按钮支持点击
        },
        //点击事件

        // 自动播放
        // autoplay: {
        //   delay: 10000,
        //   disableOnInteraction: false
        // },
        // 循环
        loop: true,
        spaceBetween: -100, // 轮播图左右间距
        slidesPerView: 1.5, // 一屏显示的slide个数，图片宽度
        centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
        slideToClickedSlide: true, // 点击的slide会居中,
        allowTouchMove: true,
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    carouselChange(inx) {
      console.log(inx);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  .one-box {
    margin-top: 64px;
    width: 100%;
    height: 750px;
    background-image: url("@/assets/imgs/agent-bg.png");
    background-size: 100% 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .one-main {
      width: 1200px;
      height: 100%;
      .title {
        display: flex;
        justify-content: center;
        margin-top: 90px;
      }
      .content {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 20px;
        color: #f2f3ff;
        line-height: 40px;
        text-align: center;
        margin-top: 8px;
      }
      .images {
        display: flex;
        justify-content: center;
        margin-top: 5px;
      }
    }
  }
  .two-box {
    width: 100%;
    height: 750px;
    background-image: url("@/assets/imgs/agent-bg_2.png");
    background-size: 100% 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .two-main {
      width: 1200px;
      height: 100%;
      .title {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 48px;
        line-height: 28px;
        text-align: center;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        .left {
          color: #020235;
        }
        .right {
          color: #8b5ef5;
        }
      }
      .D_line {
        margin-top: 30px;
        width: 1200px;
        height: 662px;
        position: relative;
        .line {
          position: absolute;
          left: 0px;
          top: 0px;
        }
        .man {
          position: absolute;
          left: 475px;
          top: 290px;
        }
        .image1_1 {
          position: absolute;
          left: 75px;
          top: 489px;
        }
        .image1_2 {
          position: absolute;
          left: 0px;
          top: 358px;
          opacity: 0.95;
          width: 378px;
          height: 129px;
          background-image: url("@/assets/imgs/agent-2_1_2.png");
          background-size: 100% 100%;
          .image_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 550;
            font-size: 18px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
            margin-top: 18px;
          }
          .image_content {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 15px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
          }
        }
        .image2_1 {
          position: absolute;
          left: 130px;
          top: 245px;
        }
        .image2_2 {
          position: absolute;
          left: 57px;
          top: 133px;
          opacity: 0.95;
          width: 378px;
          height: 129px;
          background-image: url("@/assets/imgs/agent-2_2_2.png");
          background-size: 100% 100%;
          .image_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 550;
            font-size: 18px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
            margin-top: 18px;
          }
          .image_content {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 15px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
          }
        }
        .image3_1 {
          position: absolute;
          left: 380px;
          top: 20px;
        }
        .image3_2 {
          position: absolute;
          left: 464px;
          top: -2px;
          opacity: 0.95;
          width: 379px;
          height: 108px;
          background-image: url("@/assets/imgs/agent-2_3_2.png");
          background-size: 100% 100%;
          .image_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 550;
            font-size: 18px;
            color: #243042;
            line-height: 24px;
            margin-left: 50px;
            margin-top: 18px;
          }
          .image_content {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 15px;
            color: #243042;
            line-height: 24px;
            margin-left: 50px;
          }
        }
        .image4_1 {
          position: absolute;
          right: 158px;
          top: 239px;
        }
        .image4_2 {
          position: absolute;
          right: 144px;
          top: 133px;
          opacity: 0.95;
          width: 378px;
          height: 129px;
          background-image: url("@/assets/imgs/agent-2_4_2.png");
          background-size: 100% 100%;
          .image_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 550;
            font-size: 18px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
            margin-top: 18px;
          }
          .image_content {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 15px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
          }
        }
        .image5_1 {
          position: absolute;
          right: 113px;
          top: 461px;
        }
        .image5_2 {
          position: absolute;
          right: 20px;
          top: 323px;
          opacity: 0.95;
          width: 415px;
          height: 129px;
          background-image: url("@/assets/imgs/agent-2_5_2.png");
          background-size: 100% 100%;
          .image_title {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 550;
            font-size: 18px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
            margin-top: 18px;
          }
          .image_content {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 15px;
            color: #243042;
            line-height: 24px;
            margin-left: 25px;
          }
        }
      }
    }
  }
  .three-box {
    width: 100%;
    height: 750px;
    background-image: url("@/assets/imgs/agent-bg_4.png");
    background-size: 100% 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .three-main {
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 48px;
        line-height: 28px;
        text-align: center;
        margin-top: 30px;
        color: #020235;
      }
      .three-card {
        width: 1074px;
        height: 482px;
        background-image: url("@/assets/imgs/agent-bg_5.png");
        // background-size: 100% 100%;
        margin-top: 140px;
        display: flex;
        .left {
          width: 50%;
          height: 100%;
          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            .images {
            }
            .text {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              font-size: 30px;
            }
          }
        }
        .right {
          width: 50%;
          height: 100%;
          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            .images {
            }
            .text {
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
  .four-box {
    width: 100%;
    height: 750px;
    background-image: url("@/assets/imgs/agent-bg-5.png");
    background-size: 100% 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .four-main {
      width: 1200px;
      height: 100%;
      .four-title {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 48px;
        color: #243042;
        line-height: 28px;
        text-align: center;
        margin-top: 25px;
      }
      .four-conter {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 24px;
        color: #878787;
        line-height: 65px;
        text-align: center;
      }
      .four-carousel {
        margin-top: 20px;
        width: 100%;
        height: 532px;
        ::v-deep .el-carousel__container {
          height: 555px;
          .el-carousel__arrow {
            display: block !important;
            width: 50px;
            height: 50px;
            border: 1px solid #707070;
            background-color: transparent;
          }
          .el-carousel__arrow:hover {
            border: 1px solid #8b5ef5;
          }
          //   #8B5EF5
          .el-carousel__arrow--left {
            position: relative;
            top: 290px;
          }

          .el-icon-arrow-left:before {
            content: "\e6de";
            font-size: 18px;
            color: #707070;
          }
          .el-carousel__arrow--right {
            margin-top: 12px;
          }
          .el-icon-arrow-right:before {
            font-size: 18px;
            color: #707070;
          }
        }
        .el-carousel__item {
          //   background-color: transparent;
          .carousel-item-one {
            display: flex;
            .one-left {
              width: 50%;
              height: 532px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .title-box {
                display: flex;
                align-items: center;
                margin-left: 310px;
                .logo {
                  margin-right: 10px;
                }
                .text {
                  font-family: Microsoft YaHei, Microsoft YaHei;
                  font-weight: bold;
                  font-size: 40px;
                  color: #243042;
                  line-height: 60px;
                  text-align: center;
                }
              }
              .text-box {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 24px;
                color: #243042;
                line-height: 50px;
                text-align: right;
                margin-right: 5px;
                margin-top: 10px;
              }
            }
            .one-right {
              width: 50%;
              height: 532px;
              img {
                margin-left: 55px;
              }
            }
          }
          .carousel-item-two {
            display: flex;
            .two-left {
              width: 50%;
              height: 532px;
              img {
                margin-left: 60px;
              }
            }
            .two-right {
              width: 50%;
              height: 532px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .title-box {
                display: flex;
                align-items: center;
                // margin-left: 310px;
                .logo {
                  margin-right: 10px;
                }
                .text {
                  font-family: Microsoft YaHei, Microsoft YaHei;
                  font-weight: bold;
                  font-size: 40px;
                  color: #243042;
                  line-height: 60px;
                  text-align: center;
                }
              }
              .text-box {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 24px;
                color: #243042;
                line-height: 50px;
                text-align: left;
                // margin-left: 5px;
                margin-top: 10px;
              }
            }
          }
          .carousel-item-three {
            display: flex;
            .three-left {
              width: 50%;
              height: 532px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .title-box {
                display: flex;
                align-items: center;
                margin-left: 125px;
                .logo {
                  margin-right: 10px;
                }
                .text {
                  font-family: Microsoft YaHei, Microsoft YaHei;
                  font-weight: bold;
                  font-size: 40px;
                  color: #243042;
                  line-height: 60px;
                  text-align: center;
                }
              }
              .text-box {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 24px;
                color: #243042;
                line-height: 50px;
                text-align: right;
                margin-right: 5px;
                margin-top: 10px;
              }
            }
            .three-right {
              width: 50%;
              height: 532px;
              img {
                margin-left: 25px;
              }
            }
          }
          .carousel-item-four {
            display: flex;
            .four-left {
              width: 50%;
              height: 532px;
              img {
                margin-left: 120px;
              }
            }
            .four-right {
              width: 50%;
              height: 532px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .title-box {
                display: flex;
                align-items: center;
                // margin-left: 310px;
                .logo {
                  margin-right: 10px;
                }
                .text {
                  font-family: Microsoft YaHei, Microsoft YaHei;
                  font-weight: bold;
                  font-size: 40px;
                  color: #243042;
                  line-height: 60px;
                  text-align: center;
                }
              }
              .text-box {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 24px;
                color: #243042;
                line-height: 50px;
                text-align: left;
                // margin-left: 5px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  .five-box {
    width: 100%;
    height: 516px;
    background-image: url("@/assets/imgs/agent-five-bg.png");
    background-size: 100% 100%;
    // min-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .five-main {
      width: 100%;
      height: 100%;
      .five-title {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 48px;
        color: #243042;
        line-height: 28px;
        text-align: center;
        margin-top: 30px;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
        .swiper-slide {
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          //   -webkit-align-items: center;
          //   align-items: center;
          margin-top: 100px;
          border-radius: 10px;
          transition: 300ms;
          transform: scale(0.9); //小图缩放比例
          overflow: hidden;
          .item-box1 {
            width: 860px;
            height: 230px;
            background-image: url("@/assets/imgs/agent-five-bg-2.png");
            background-size: 100% 100%;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
            border-radius: 30px 30px 30px 30px;
            display: flex;
            align-items: center;
            .logo {
              margin-left: 50px;
              margin-right: 30px;
            }
            .text {
              .title {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 24px;
                color: #243042;
                line-height: 60px;
              }
              .content {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 18px;
                color: #243042;
                line-height: 40px;
              }
            }
          }
          .item-box2 {
            width: 860px;
            height: 230px;
            background-image: url("@/assets/imgs/agent-five-bg-2.png");
            background-size: 100% 100%;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
            border-radius: 30px 30px 30px 30px;
            display: flex;
            align-items: center;
            .logo {
              margin-left: 50px;
              margin-right: 30px;
            }
            .text {
              .title {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 24px;
                color: #243042;
                line-height: 60px;
              }
              .content {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 18px;
                color: #243042;
                line-height: 40px;
              }
            }
          }
          .item-box3 {
            width: 860px;
            height: 230px;
            background-image: url("@/assets/imgs/agent-five-bg-2.png");
            background-size: 100% 100%;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
            border-radius: 30px 30px 30px 30px;
            display: flex;
            align-items: center;
            .logo {
              margin-left: 50px;
              margin-right: 30px;
            }
            .text {
              .title {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 24px;
                color: #243042;
                line-height: 60px;
              }
              .content {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 18px;
                color: #243042;
                line-height: 40px;
              }
            }
          }
          .item-box4 {
            width: 860px;
            height: 230px;
            background-image: url("@/assets/imgs/agent-five-bg-2.png");
            background-size: 100% 100%;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
            border-radius: 30px 30px 30px 30px;
            display: flex;
            align-items: center;
            .logo {
              margin-left: 50px;
              margin-right: 30px;
            }
            .text {
              .title {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 24px;
                color: #243042;
                line-height: 60px;
              }
              .content {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 18px;
                color: #243042;
                line-height: 40px;
              }
            }
          }
        }
        .swiper-slide-active,
        .swiper-slide-duplicate-active {
          width: 600px;
          transform: scale(1);
        }
      }
      .swiper-pagination {
        position: relative;
        top: -155px;
        ::v-deep .swiper-pagination-bullet {
          width: 40px;
          height: 8px;
          background: #ABA6EC;
          border-radius: 7px 7px 7px 7px;
          margin:0 8px;
        }
        ::v-deep .swiper-pagination-bullet-active {
          width: 40px;
          height: 8px;
          background: #ffffff;
          border-radius: 7px 7px 7px 7px;
        }
      }
    }
  }
  .six-box {
    min-width: 1200px;
    height: 220px;
    background-image: url("@/assets/imgs/agent-bg-6.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .six-main {
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .six-title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 34px;
        color: #ffffff;
        line-height: 28px;
        text-align: center;
        margin: 45px 0 7px 0;
      }
      .six-conter {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #f2f3ff;
        line-height: 28px;
        text-align: center;
      }
      .six-btn {
        width: 144px;
        height: 42px;
        background: #ffffff;
        box-shadow: 0px 1px 3px 1px rgba(108, 45, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        margin-top: 20px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #8b5ef5;
        text-align: center;
        line-height: 40px;
      }
    }
  }
}
</style>
