<template>
  <div class="userStyle-invoice">
    <div style="padding: 0 15px">
      <div style="height: 379px; background-color: #fff; margin: 20px 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; background-color: #ffffff"
          height="379"
          @selection-change="handleSelectionChange"
          :row-class-name="tableRowClassName"
          :header-cell-style="{
            background: '#F2ECFF',
            color: '#44444F',
            border: 'none',
            fontSize: '14px',
          }"
          :cell-style="{ color: '#444444' }"
        >
          <el-table-column type="selection" width="60"> </el-table-column>
          <el-table-column label="订单号" prop="orderCode"> </el-table-column>
          <el-table-column prop="orderName" label="订单名称" >
          </el-table-column>
          <el-table-column label="订单金额">
            <template slot-scope="scope">￥{{ scope.row.totalprice }}</template>
          </el-table-column>
          <el-table-column prop="payTime" label="支付时间"> </el-table-column>
        </el-table>
      </div>
      <div>
        <span style="color: #444444; font-size: 17px">总价：</span>
        <span style="color: #EE5446; font-size: 17px; font-weight: 600"
          >￥{{ money }}</span
        >
      </div>
      <div style="margin-top: 5px; width: 100%; text-align: center">
        <el-button class="but7" type="primary" size="small" @click="nextB"
          >下一步</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getUserOrders } from "@/api/invoice";
import Cookies from 'js-cookie'
export default {
  name: "sourceP",
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      money: 0,
      orderIds: "",
      teamId:'',
    };
  },
  created() {

    this.teamId = localStorage.getItem("teamId");
    setTimeout(() => {
      this.getGoods();
    }, 500);
  },
  mounted() {},
  methods: {
    async getGoods() {
      // let data = {
      //     current: 1,
      //     size: 10000
      // }
      const params = {
        teamId:this.teamId
      }
      const res = await getUserOrders(params);

      this.tableData = res.data.data.records;
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    handleSelectionChange(val) {
  
      this.money = 0;
      this.orderIds = "";
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        this.money = this.money + Number(item.totalprice);
        this.orderIds = this.orderIds + "," + item.id;
      });
      this.money = this.money.toFixed(2);
      this.orderIds = this.orderIds.substr(1);
    },
    nextB() {
      if (this.multipleSelection.length > 0) {
        const params = {
          money: this.money,
          orderIds: this.orderIds
        }
        this.$emit('handel',params)
        
        // url: "/fillInvoice",
        // money: this.money,
        // orderIds: this.orderIds
      } else {
        this.$message({
          type: "warning",
          message: "请选择订单!!!",
          duration: 1000,
        });
      }
    },
  },
};
</script>
<style lang="less">
.userStyle-invoice {
  width: 100%;
  height: 500px;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  user-select: none;
  overflow-x:scroll;

  .but7 {
    position: relative;
    width: 120px;
    font-size: 16px;
    color: white;
    background-color: #8b5ef5 !important;
    border-radius: 4px;
    border: 0px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    height: 36px;
  }

  .but7:hover {
    background-color: #743afe !important;
  }

  .el-table td.el-table__cell {
    border: none;
  }

  .el-table .warning-row {
    background: #FFFFFF;
  }

  .el-table .success-row {
    background: #F8F6FF;
  }

  .el-table th.el-table__cell {
    // background-color: #303030;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(232, 17, 35, 0) !important;
  }

  // ::v-deep .el-table__body {
  //     background-color: #2B2B2B !important;
  // }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #8b5ef5;
    border: 1px solid #8b5ef5;
  }

  .el-checkbox__inner:hover {
    border: 1px solid #8b5ef5;
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #8b5ef5;
    border: 1px solid #8b5ef5;
  }
}

.close {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  -webkit-app-region: no-drag;
}

.close:hover {
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(232, 17, 35, 0.9);
}
</style>
