<template>
  <div>
    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
      <div style="color: red;font-size: 20px;">
        认证中..........
      </div>
    </div>
  </div>
</template>

<script>
// import { getCheckToken } from "@/api/cloudSpace"
// import Cookies from 'js-cookie'
export default {
  name: 'centre',
  data() {
    return {

    };
  },
  created() {
    this.checkToken()
  },
  mounted() {

  },
  methods: {
    async checkToken() {


      let number = this.$route.query.number
      let teamName = this.$route.query.teamName
      localStorage.setItem('token', this.$route.query.token)
      localStorage.setItem('teamId', this.$route.query.teamId)
      localStorage.setItem('userId', this.$route.query.userId)
      localStorage.setItem("avatar", this.$route.query.avatar)
      localStorage.setItem("teamName", this.$route.query.teamName)

      // number
      let t = {
        token: this.$route.query.token
      }
      // const res = await getCheckToken(t)

      // if (res.data.exp > 0) {
      if (number == 1) {
        this.$router.push("/member").catch((error) => error);
      } else if (number == 2) {
        this.$router.push("/cloudSpace").catch((error) => error);
      } else if (number == 3) {
        // this.$router.push("/member").catch((error) => error);
        this.$router.push({ path: '/member', query: { numberData: 1 } }).catch(error => error);
      } else if (number == 4) {
        this.$router.push("/customized").catch((error) => error);
      }else if (number == 5) {
        this.$router.push({ path: '/member', query: { numberData: 2 } }).catch(error => error);
      }

      location.reload();
      // }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-input__inner {
  height: 30px;
  border-radius: 20px;
}

.teamStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
  margin-left: 20px;
  cursor: pointer;
}

.button-img {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-right: 15px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  .round1 {
    width: 100px;
    height: 90px;
    background-color: #ffffff;
    border-radius: 8px;
    position: absolute;
    z-index: 999;
    top: 30px;
    left: 50px;
    overflow: hidden;
    // padding: 10px 20px;

    .round1-name {
      color: #000000;
      font-size: 10px;
      cursor: pointer;
    }

    .round1-name-two {
      color: #000000;
      font-size: 10px;
      padding-left: 10px;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
    }

    .round-name:hover {
      background-color: #a0a0a0;
    }

    .round1-name-two:hover {
      background-color: #a0a0a0;
    }
  }

  .beijingStyle {
    position: absolute;
    top: 40px;
    left: 80px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 20px;
    background-color: rgba(128, 128, 128, 0.5);
  }

  .button-big {
    width: 135px;
    height: 180px;
    border-radius: 8px;
    overflow: hidden;
  }

  .button-main {
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    ::v-deep .el-input__inner {
      height: 30px;
      border-radius: 0px;
      width: 100px;
    }

    // .button-main1 {
    //     font-size: 12px;
    //     color: #fff;
    //     width: 60px;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    // }

    .button-main2 {
      font-size: 12px;
      color: #9a979e;
    }
  }

  .roundStyle {
    // .round {
    //     width: 3px;
    //     height: 3px;
    //     background-color: #0307fc;
    //     border-radius: 3px;
    //     margin-top: 2px;
    // }
  }
}
</style>