<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent></headComponent>
    <div class="mainStyle">
      <div class="img-box">
        <img class="images" src="@/assets/imgs/flow-bg.png" alt="" />
      </div>
      <div class="mainStyle-box">
        <div class="left">
          <div>
            <img src="@/assets/imgs/flowCenter-left.png" alt="" />
          </div>
        </div>
        <div class="right">
          <img src="@/assets/imgs/flow-right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="camera">
      <div class="camera-main">
        <div class="camera-top">
          <div class="title">如何使用虚拟摄像头直播</div>
          <div class="camera-video">
            <div class="video-box">
              <video style="width: 100%; height: 100%; border-radius: 10px" controls src="https://hello.yohelo.com/tutorial/virtualCam.mp4"></video>
            </div>
          </div>
          <div class="operate">操作说明</div>
        </div>
        <div class="one-box">
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">1</div>
              <div class="text">
                <div>Hello数字人平台默认启动虚拟摄像头，可在【设置】-【直播】里确认为开启状态。</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-1.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">2</div>
              <div class="text">
                <div>在工作台点击【开始直播】</div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-2.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">3</div>
              <div class="text">直播窗口打开，这里默认未开始播放，需要手动选择【开始按钮】，同时需要确认左上角是否是虚拟摄像头直播。</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-3.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">4</div>
              <div class="text">
                <div>以抖音直播伴侣为例添加虚拟摄像机（其他直播平台操作步骤似）。</div>
                <div>
                  <span style="color: #8b5ef5; position: relative; top: -2.5px">①</span>
                  打开直播伴侣，进入主页面。点击添加素材-选择摄像头
                </div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-4.png" alt="" />
            </div>
            <div class="one-box">
              <div class="text-num" style="width: 32px; height: 58px"></div>
              <div class="text">
                <div>
                  <span style="color: #8b5ef5; position: relative; top: -2.5px">②</span>
                  或者直接点击摄像头
                </div>
              </div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-4.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">5</div>
              <div class="text">选择【Hello Virtual Camera 】。建议在【美颜设置】里点击关闭美颜，美颜占用资源较多。</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-5.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">6</div>
              <div class="text">完成设置以后，关闭页面。直播伴侣直播框展示如下。</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-6.png" alt="" />
            </div>
          </div>
          <div class="one-one">
            <div class="one-box">
              <div class="text-num">7</div>
              <div class="text">在Hello数字人平台点击【开始】按钮即可。</div>
            </div>
            <div class="one-img">
              <img src="@/assets/imgs/camera-7.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerComponent></footerComponent>
  </div>
</template>

<script>
import headComponent from '@/views/component/headComponent.vue';
import footerComponent from '@/views/component/footerComponent.vue';
export default {
  name: 'HomeView',
  components: { headComponent, footerComponent },
  data() {
    return {};
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
  .mainStyle {
    margin-top: 64px;
    flex-grow: 1;
    min-width: 1200px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    .img-box {
      position: absolute;
      height: 100%;
      width: 100%;
      .images {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        object-fit: cover;
      }
    }
    .mainStyle-box {
      z-index: 1;
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-family: DingTalk JinBuTi, DingTalk JinBuTi;
        font-weight: 400;
        font-size: 58px;
        color: #243042;
      }
    }
  }

  .camera {
    width: 100%;
    height: 5730px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    .camera-main {
      width: 1200px;
      .camera-top {
        margin-top: 65px;
        .title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 40px;
          color: #243042;
          line-height: 28px;
          text-align: center;
        }
        .camera-video {
          display: flex;
          justify-content: center;
          margin-top: 70px;
          .video-box {
            width: 580px;
            height: 318px;
            background: #000000;
            box-shadow: 0px 0px 6px 1px rgba(255, 186, 186, 0.2);
            border-radius: 10px 10px 10px 10px;
          }
        }
        .operate {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 40px;
          color: #243042;
          line-height: 0px;
          text-align: center;
          margin-top: 120px;
        }
      }
      .one-box {
        margin-top: 60px;
        .one-one {
          margin-top: 70px;
          .one-box {
            display: flex;
            align-items: center;
            .text-num {
              font-family: DingTalk JinBuTi, DingTalk JinBuTi;
              font-weight: 500;
              font-size: 48px;
              color: #243042;
              margin-right: 20px;
            }
            .text {
              width: 85%;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 18px;
              color: #243042;
              margin-top: 28px;
              line-height: 30px;
            }
          }
          .one-img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
