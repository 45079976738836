<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent :dataColor="colorStatus"></headComponent>
    <div id="animate_1" class="nvwa-one">
      <!-- <div class="one-bg"> -->
      <!-- <img style="width:100%;" src="@/assets/imgs/nvwa-one-bg.jpg" alt="" /> -->
      <!-- </div> -->
      <div class="one-box">
        <div class="box-top"><img src="@/assets/imgs/nvwa-one-title.png" alt="" /></div>
        <div class="box-text">
          <div>构建数字人、语音及虚拟场景等短视频&直播</div>
          <div>多场景、多平台同步智能直播</div>
        </div>
        <div @click="gotoDownload()" class="middle-download">
          <img style="width: 24.63px; height: 22px; margin-left: 31px" src="@/assets/imgs/windows.png" alt="" />
          <img style="width: 18.85px; height: 22px; margin: 0 9px" src="@/assets/imgs/android.png" alt="" />
          <span class="text">立即下载</span>
        </div>
      </div>
    </div>
    <div ref="nvwaTwo" class="nvwa-two">
      <div class="two-box">
        <div class="two-title">多元、开放的数字虚拟化创作</div>
        <div class="two-conter">
          支持数字人、语音、虚拟场景VR视频录制&直播，让流量获取更简单
        </div>
        <div class="two-main">
          <el-carousel :autoplay="false" indicator-position="none" @change="carouselChange">
            <el-carousel-item>
              <div class="carousel-item-one" :style="scrollHeight < 570 || changeIndex !== 0 ? 'display:none' : ''">
                <div class="one-left">
                  <div class="title" :class="
                      scrollHeight > 570 ? 'animate__animated animate__fadeInDownBig' : ''
                    ">
                    数字人直播
                  </div>
                  <div class="conter" :class="
                      scrollHeight > 570 ? 'animate__animated animate__fadeInLeftBig' : ''
                    ">
                    <div>1:1真人形象克隆，内含丰富的数字人形象，</div>
                    <div>支持形象定制，只需可根据行业及喜好选择</div>
                    <div>主播，即可让数字人为你讲解内容!</div>
                  </div>
                </div>
                <div class="one-right">
                  <div class="character" :class="
                      scrollHeight > 570
                        ? 'animate__animated animate__fadeInBottomRight one'
                        : ''
                    ">
                    <img src="@/assets/imgs/character1.png" alt="" />
                  </div>
                  <div class="round">
                    <img class="round1" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInTopRight'
                          : ''
                      " src="@/assets/imgs/round1.png" alt="" />
                    <img class="round2" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInBottomLeft'
                          : ''
                      " src="@/assets/imgs/round2.png" alt="" />
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div :style="changeIndex !== 1 ? 'display:none' : ''" class="carousel-item-two">
                <div class="one-left">
                  <div class="title" :class="
                      scrollHeight > 570 ? 'animate__animated animate__fadeInDownBig' : ''
                    ">
                    数字语音直播
                  </div>
                  <div class="conter" :class="
                      scrollHeight > 570 ? 'animate__animated animate__fadeInLeftBig' : ''
                    ">
                    <div>本地实景与虚拟场景深度结合，语音主播自</div>
                    <div>动讲解生活服务，让观众沉浸式体验商家服</div>
                    <div>务！</div>
                  </div>
                </div>
                <div class="one-right">
                  <div class="character">
                    <img class="character1" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInBottomRight'
                          : ''
                      " src="@/assets/imgs/character2.png" alt="" />
                    <img class="character2" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInTopRight'
                          : ''
                      " src="@/assets/imgs/character2-1.png" alt="" />
                  </div>
                  <div class="round">
                    <img class="round1" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInTopRight'
                          : ''
                      " src="@/assets/imgs/round1.png" alt="" />
                    <img class="round2" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInBottomLeft'
                          : ''
                      " src="@/assets/imgs/round2.png" alt="" />
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carousel-item-three" :style="changeIndex !== 2 ? 'display:none' : ''">
                <div class="one-left">
                  <div class="title" :class="
                      scrollHeight > 570 ? 'animate__animated animate__fadeInDownBig' : ''
                    ">
                    视频录制
                  </div>
                  <div class="conter" :class="
                      scrollHeight > 570 ? 'animate__animated animate__fadeInLeftBig' : ''
                    ">
                    <div>短视频、课件、宣传片...支持多种内容制</div>
                    <div>作，一键成片、本地录制导出。</div>
                  </div>
                </div>
                <div class="one-right">
                  <div class="character">
                    <img :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInRightBig'
                          : ''
                      " src="@/assets/imgs/character3.png" alt="" />
                  </div>
                  <div class="round">
                    <img class="round1" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInTopRight'
                          : ''
                      " src="@/assets/imgs/round1.png" alt="" />
                    <img class="round2" :class="
                        scrollHeight > 570
                          ? 'animate__animated animate__fadeInBottomLeft'
                          : ''
                      " src="@/assets/imgs/round2.png" alt="" />
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="left-bg">
        <img src="@/assets/imgs/nvwa-two.png" alt="" />
      </div>
      <div class="right-bg">
        <img src="@/assets/imgs/nvwa-two.png" alt="" />
      </div>
    </div>

    <div class="nvwa-three">
      <div class="three-box">
        <div class="three-title">直播&短视频</div>
        <div class="three-conter">让数字人短视频、直播增长更简单</div>
        <div class="three-main">
          <div class="mian-0">
            <img src="@/assets/imgs/nvwa-three-main-0.gif" alt="" />
          </div>
          <div class="mian-1">
            <div class="left">
              <div class="title">急速开播</div>
              <div class="conter">
                <div>内容丰富的直播场景模板，只需一键套用模</div>
                <div>板,上传产品图片,修改文案,即可开播</div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/imgs/nvwa-three-main-1.png" alt="" />
            </div>
          </div>
          <div class="mian-2">
            <div class="left">
              <div class="title">智能文案</div>
              <div class="conter">
                <div>根据关键词自动生成话术文案,省时省力</div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/imgs/nvwa-three-main-2.png" alt="" />
            </div>
          </div>
          <div class="mian-3">
            <div class="left">
              <div class="title">7*24小时自动播</div>
              <div class="conter">
                <div>24小时不间断自动播，支持多种直播(数字</div>
                <div>人、语音等)方式</div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/imgs/nvwa-three-main-3.png" alt="" />
            </div>
          </div>
          <div class="mian-4">
            <div class="left">
              <img src="@/assets/imgs/nvwa-three-main-4.png" alt="" />
            </div>
            <div class="right">
              <div class="title">多平台同步直播</div>
              <div class="conter">
                <div>支持同时对多个直播进行推流或接入虚拟</div>
                <div>摄像头的直播软件同步开播</div>
              </div>
            </div>
          </div>
          <div class="mian-5">
            <div class="left">
              <img src="@/assets/imgs/nvwa-three-main-5.png" alt="" />
            </div>
            <div class="right">
              <div class="title">直播用户实时互动</div>
              <div class="conter">
                <div>根据关键词自动回复直播间弹幕、礼</div>
                <div>物、欢迎观众，支持助播语音回复</div>
              </div>
            </div>
          </div>
          <div class="mian-6">
            <div class="left">
              <img src="@/assets/imgs/nvwa-three-main-6.png" alt="" />
            </div>
            <div class="right">
              <div class="title">团队协作矩阵直播</div>
              <div class="conter">
                <div>在线化管理、团队协作、矩阵式直播，节</div>
                <div>约90%成本</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="nvwaFour" class="nvwa-four">
      <div class="four-box">
        <div class="four-title">跨平台同步直播</div>
        <div class="four-conter">
          支持同时对多个直播媒体平台进行推流、接入虚拟摄像头的直播软件同步开播
        </div>
        <div class="four-main" :style="scrollHeight < 2000 ? 'display:none' : ''">
          <div class="dy">
            <img src="@/assets/imgs/dy.png" :class="scrollHeight > 570 ? 'animate__animated animate__flipInX' : ''" alt="" />
          </div>
          <div class="ks">
            <img src="@/assets/imgs/ks.png" :class="
                scrollHeight > 570 ? 'animate__animated animate__fadeInTopLeft' : ''
              " alt="" />
          </div>
          <div class="sph">
            <img src="@/assets/imgs/sph.png" :class="
                scrollHeight > 570 ? 'animate__animated animate__fadeInTopRight' : ''
              " alt="" />
          </div>
          <div class="blbl">
            <img src="@/assets/imgs/blbl.png" :class="
                scrollHeight > 570 ? 'animate__animated animate__fadeInBottomLeft' : ''
              " alt="" />
          </div>
          <div class="tb">
            <img src="@/assets/imgs/tb.png" :class="
                scrollHeight > 570 ? 'animate__animated animate__fadeInBottomRight' : ''
              " alt="" />
          </div>
          <div class="dyu">
            <img src="@/assets/imgs/dyu.png" :class="scrollHeight > 570 ? 'animate__animated animate__fadeInUp' : ''" alt="" />
          </div>
          <div class="xhs">
            <img src="@/assets/imgs/xhs.png" :class="scrollHeight > 570 ? 'animate__animated animate__fadeInLeft' : ''" alt="" />
          </div>
          <div class="hy">
            <img src="@/assets/imgs/hy.png" :class="scrollHeight > 570 ? 'animate__animated animate__fadeInRight' : ''" alt="" />
          </div>
        </div>
      </div>
      <div class="left-bg">
        <img src="@/assets/imgs/nvwa-four-left.png" alt="" />
      </div>
      <div class="right-bg">
        <img src="@/assets/imgs/nvwa-four-right.png" alt="" />
      </div>
    </div>

    <div class="nvwa-five">
      <div class="five-box">
        <div class="five-title">行业直播案例</div>
        <div class="five-main">
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/case7.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/case6.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/case5.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/case4.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
        </div>
      </div>
    </div>

    <div class="nvwa-six">
      <div class="six-box">
        <div class="six-title">短视频案例</div>
        <div class="six-main">
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/short_case1.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/short_case2.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/short_case3.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
          <div class="item">
            <video
              src="https://hello.yohelo.com/case/short_case4.mp4"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
        </div>
      </div>
    </div>

    <div ref="nvwaSeven" class="nvwa-seven">
      <div class="seven-box">
        <div class="seven-title">Hello · Nvwa数字虚拟化智能直播</div>
        <div class="seven-conter">
          <div>构建数字人、语音及虚拟场景等短视频&直播</div>
          <div>多场景、多平台同步智能直播</div>
        </div>
        <div
          @click="gotoDownload()"
          class="middle-download"
          :style="scrollHeight < 4200 ? 'display:none' : ''"
          :class="scrollHeight > 4000 ? 'animate__animated animate__fadeInUpBig' : ''"
        >
          <img
            style="width: 24.63px; height: 22px; margin-left: 31px"
            src="@/assets/imgs/windows.png"
            alt=""
          />
          <img
            style="width: 18.85px; height: 22px; margin: 0 9px"
            src="@/assets/imgs/android.png"
            alt=""
          />
          <span class="text">立即下载</span>
        </div>
      </div>
    </div>

    <footerComponent :dataColor="colorStatus"></footerComponent>
  </div>
</template>

<script>
import headComponent from "@/views/component/headComponent.vue";
import footerComponent from "@/views/component/footerComponent.vue";
export default {
  name: "HomeView",
  components: { headComponent, footerComponent },
  data() {
    return {
      colorStatus: 2,
      scrollHeight: "",
      changeIndex: 0,
      observer: null,
      observer2: null,
      observer3: null,
    };
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    // 动画
    window.addEventListener("scroll", this.handleScroll, true);
    this.handleScroll();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.$refs.nvwaFour);
    }
    if (this.observer2) {
      this.observer2.unobserve(this.$refs.nvwaTwo);
    }
    if (this.observer2) {
      this.observer2.unobserve(this.$refs.nvwaSeven);
    }
  },
  methods: {
    handleScroll: function () {
      // const clientHeight =  document.documentElement.clientHeight || document.body.clientHeight;
      // const scrollObj = document.getElementById("animate_1"); // 滚动区域
      // if (scrollObj) {
      //   const scrollTop = $(scrollObj).offset().top; // animate_1 到头部的距离
      //   const scrollHeight = $(window).scrollTop(); // 滚动条的滚动行程
    
      //   // this.scrollHeight = scrollHeight;
      // }
      var that = this;
      if (this.$refs.nvwaFour) {
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (this.isCenter1(entry)) {
                // 元素在屏幕中央时的处理逻辑
              
                that.scrollHeight = 2100;
              }
            });
          },
          {
            root: null, // 监听全屏
            rootMargin: "0px",
            threshold: [0.3], // 当元素50%出现在视窗时触发
          }
        );
        this.observer.observe(this.$refs.nvwaFour);
      }

      if (this.$refs.nvwaTwo) {
        this.observer2 = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (this.isCenter2(entry)) {
                // 元素在屏幕中央时的处理逻辑
                
                that.scrollHeight = 600;
              }
            });
          },
          {
            root: null, // 监听全屏
            rootMargin: "0px",
            threshold: [0.3], // 当元素50%出现在视窗时触发
          }
        );
        this.observer2.observe(this.$refs.nvwaTwo);
      }

      if (this.$refs.nvwaSeven) {
        this.observer3 = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (this.isCenter3(entry)) {
                // 元素在屏幕中央时的处理逻辑
                that.scrollHeight = 4300;
              }
            });
          },
          {
            root: null, // 监听全屏
            rootMargin: "0px",
            threshold: [0.5], // 当元素50%出现在视窗时触发
          }
        );
        this.observer3.observe(this.$refs.nvwaSeven);
      }
    },
    isCenter1(entry) {
      const rect = entry.boundingClientRect;
      const root = entry.rootBounds;
      const middle = root.height / 2 + root.top;
      return rect.top < middle && rect.bottom > middle;
    },
    isCenter2(entry) {
      const rect = entry.boundingClientRect;
      const root = entry.rootBounds;
      const middle = root.height / 2 + root.top;
      return rect.top < middle && rect.bottom > middle;
    },
    isCenter3(entry) {
      const rect = entry.boundingClientRect;
      const root = entry.rootBounds;
      const middle = root.height / 2 + root.top;
      return rect.top < middle && rect.bottom > middle;
    },
    carouselChange(inx) {
      this.changeIndex = inx;
    },
    gotoDownload() {
      this.$router.push({ path: "/help/download" });
    },
  },
};
</script>

<style lang="less" scoped>
.animate__animated.animate__fadeInRightBig.one {
  --animate-duration: 2.5s;
  --animate-delay: 1s;
}

.animate__animated.animate__fadeInBottomRight {
  --animate-duration: 2.5s;
  --animate-delay: 1s;
}
.animate__animated.animate__fadeInTopRight {
  --animate-duration: 2s;
  --animate-delay: 1s;
}
.animate__animated.animate__fadeInBottomLeft {
  --animate-duration: 2s;
  --animate-delay: 1s;
}
.home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .nvwa-one {
    // margin-top: 64px;
    min-width: 1200px;
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-image: url('@/assets/imgs/nvwa-one-bg1.gif'); /* 替换为你的图片路径 */
    background-size: cover;
    // background-position: center;
    background-repeat: no-repeat;
    .one-bg {
      width: 100%;
    }
    .one-box {
      position: absolute;
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      // justify-content:center;
      align-items: center;
      .box-top {
        margin-top: 270px;
      }
      .box-text {
        margin-top: 60px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 24px;
        color: #c2c2c2;
        line-height: 40px;
        text-align: center;
      }
      .middle-download {
        cursor: pointer;
        width: 205px;
        height: 64px;
        background: #895af6;
        border-radius: 4px 4px 4px 4px;
        margin-top: 61px;
        display: flex;
        align-items: center;
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 20px;
          color: #ffffff;
        }
      }
    }
  }
  .nvwa-two {
    min-width: 1200px;
    width: 100%;
    height: 735px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #0a0c11;
    .two-box {
      width: 1200px;
      height: 100%;
      .two-title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #f2f3ff;
        text-align: center;
        margin-top: 60px;
      }
      .two-conter {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 24px;
        color: #c2c2c2;
        text-align: center;
        margin-top: 25px;
      }
      .two-main {
        margin-top: 20px;
        width: 100%;
        height: 555px;
        ::v-deep .el-carousel__container {
          height: 555px;
          .el-carousel__arrow {
            display: block !important;
            width: 50px;
            height: 50px;
            border: 1px solid #707070;
            background-color: transparent;
          }
          .el-carousel__arrow--left {
            position: relative;
            top: 250px;
          }

          .el-icon-arrow-left:before {
            content: '\e6de';
            font-size: 18px;
            color: #707070;
          }
          .el-carousel__arrow--right {
            margin-top: -28px;
          }
          .el-icon-arrow-right:before {
            font-size: 18px;
            color: #707070;
          }
        }
        .el-carousel__item {
          background-color: transparent;
          .carousel-item-one {
            display: flex;
            .one-left {
              margin-left: 175px;
              margin-top: 156px;
              .title {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 76px;
                color: #ffffff;
                line-height: 87px;
                text-align: left;
              }
              .conter {
                position: absolute;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 24px;
                color: #c2c2c2;
                line-height: 44px;
                text-align: left;
                margin-top: 15px;
                z-index: 2;
              }
            }
            .one-right {
              position: relative;
              margin-top: 12px;
              left: 40px;
              .character {
                position: absolute;
                left: 0px;
                z-index: 1;
              }
              .round {
                position: absolute;
                left: 0px;
                top: 120px;
                .round1 {
                  position: absolute;
                  left: 60px;
                  z-index: 0;
                }
                .round2 {
                  position: absolute;
                  left: -7px;
                  top: 42px;
                  z-index: -1;
                }
              }
            }
          }
          .carousel-item-two {
            display: flex;
            .one-left {
              margin-left: 175px;
              margin-top: 156px;
              .title {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 76px;
                color: #ffffff;
                line-height: 87px;
                text-align: left;
              }
              .conter {
                position: absolute;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 24px;
                color: #c2c2c2;
                line-height: 44px;
                text-align: left;
                margin-top: 15px;
                z-index: 2;
              }
            }
            .one-right {
              position: relative;
              margin-top: 12px;
              left: -5px;
              .character {
                position: absolute;
                left: 0px;
                z-index: 1;
                .character1 {
                  position: absolute;
                  left: 3px;
                  top: 85px;
                  z-index: 0;
                }
                .character2 {
                  position: absolute;
                  left: 100px;
                  top: 12px;
                  z-index: -1;
                }
              }
              .round {
                position: absolute;
                left: -45px;
                top: 120px;
                .round1 {
                  position: absolute;
                  left: 60px;
                  z-index: 0;
                }
                .round2 {
                  position: absolute;

                  left: -5px;
                  top: 38px;
                  z-index: -1;
                }
              }
            }
          }
          .carousel-item-three {
            display: flex;
            .one-left {
              margin-left: 175px;
              margin-top: 156px;
              .title {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 76px;
                color: #ffffff;
                line-height: 87px;
                text-align: left;
              }
              .conter {
                position: absolute;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                font-size: 24px;
                color: #c2c2c2;
                line-height: 44px;
                text-align: left;
                margin-top: 15px;
                z-index: 2;
              }
            }
            .one-right {
              position: relative;
              margin-top: 48px;
              left: 67px;
              .character {
                position: absolute;
                left: 0px;
                z-index: 1;
              }
              .round {
                position: absolute;
                left: 28px;
                top: 101px;
                .round1 {
                  position: absolute;
                  left: 60px;
                  z-index: 0;
                }
                .round2 {
                  position: absolute;
                  left: -5px;
                  top: 38px;
                  z-index: -1;
                }
              }
            }
          }
        }
      }
    }
    .left-bg {
      position: absolute;
      left: 0px;
      width: 735px;
      transform: translateX(-65%) translateY(50%);
      img {
        width: 100%;
        height: 100%;
        opacity: 0.2;
      }
    }
    .right-bg {
      position: absolute;
      right: 0px;
      width: 735px;
      transform: translateX(65%) translateY(50%);
      img {
        width: 100%;
        height: 100%;
        opacity: 0.2;
      }
    }
  }
  .nvwa-three {
    min-width: 1200px;
    width: 100%;
    height: 735px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-image: url('@/assets/imgs/nvwa-three.png');
    background-size: 100% 100%;
    .three-box {
      width: 1200px;
      height: 735px;
      .three-title {
        margin-top: 61px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #f2f3ff;
        text-align: center;
      }
      .three-conter {
        margin-top: 15px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 22px;
        color: #c2c2c2;
        text-align: center;
      }
      .three-main {
        width: 100%;
        height: 588px;
        position: relative;
        .mian-0 {
          position: absolute;
          left: 50%;
          margin-left: -297.5px;
        }
        .mian-1 {
          position: absolute;
          top: 74px;
          left: 86px;
          display: flex;
          .left {
            .title {
              text-align: right;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #c7d4f9;
              line-height: 41px;
            }
            .conter {
              margin-top: 5px;
              text-align: right;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #f2f3ff;
              line-height: 24px;
            }
          }
          .right {
            margin-top: 12px;
            margin-left: 30px;
          }
        }
        .mian-2 {
          position: absolute;
          top: 255px;
          left: 60px;
          display: flex;
          .left {
            .title {
              text-align: right;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #fddfdf;
              line-height: 41px;
            }
            .conter {
              margin-top: 5px;
              text-align: right;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #f2f3ff;
              line-height: 24px;
            }
          }
          .right {
            margin-top: 7px;
            margin-left: 30px;
          }
        }
        .mian-3 {
          position: absolute;
          top: 440px;
          left: 128px;
          display: flex;
          .left {
            .title {
              text-align: right;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #d0f1e8;
              line-height: 41px;
            }
            .conter {
              margin-top: 5px;
              text-align: right;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #f2f3ff;
              line-height: 24px;
            }
          }
          .right {
            margin-top: 12px;
            margin-left: 30px;
          }
        }
        .mian-4 {
          position: absolute;
          top: 74px;
          left: 706px;
          display: flex;
          .left {
            margin-top: 12px;
            margin-right: 30px;
          }
          .right {
            .title {
              text-align: left;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #c7d4f9;
              line-height: 41px;
            }
            .conter {
              margin-top: 5px;
              text-align: left;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #f2f3ff;
              line-height: 24px;
            }
          }
        }
        .mian-5 {
          position: absolute;
          top: 255px;
          left: 786px;
          display: flex;
          .left {
            margin-top: 12px;
            margin-right: 30px;
          }
          .right {
            .title {
              text-align: left;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #fddfdf;
              line-height: 41px;
            }
            .conter {
              margin-top: 5px;
              text-align: left;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #f2f3ff;
              line-height: 24px;
            }
          }
        }
        .mian-6 {
          position: absolute;
          top: 440px;
          left: 688px;
          display: flex;
          .left {
            margin-top: 12px;
            margin-right: 30px;
          }
          .right {
            .title {
              text-align: left;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              font-size: 28px;
              color: #fddfdf;
              line-height: 41px;
            }
            .conter {
              margin-top: 5px;
              text-align: left;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              font-size: 16px;
              color: #f2f3ff;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  .nvwa-four {
    min-width: 1200px;
    width: 100%;
    height: 735px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #0a0c11;
    background-size: 100% 100%;
    .four-box {
      width: 1200px;
      height: 735px;
      .four-title {
        margin-top: 61px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #f2f3ff;
        text-align: center;
      }
      .four-conter {
        margin-top: 15px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 22px;
        color: #c2c2c2;
        text-align: center;
      }
      .four-main {
        width: 100%;
        height: 588px;
        position: relative;
        z-index: 1;
        .animate__animated.animate__fadeInTopRight {
          --animate-duration: 1s;
          --animate-delay: 1s;
        }
        .animate__animated.animate__fadeInBottomLeft {
          --animate-duration: 1s;
          --animate-delay: 1s;
        }
        .animate__animated.animate__flipInX {
          --animate-duration: 1s;
          --animate-delay: 2s;
        }
        .dy {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 158px;
        }
        .ks {
          position: absolute;
          left: 323px;
          top: 58px;
        }
        .sph {
          position: absolute;
          left: 665px;
          top: 58px;
        }
        .blbl {
          position: absolute;
          left: 323px;
          top: 258px;
        }
        .tb {
          position: absolute;
          left: 665px;
          top: 258px;
        }
        .dyu {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 358px;
        }
        .xhs {
          position: absolute;
          left: 258px;
          transform: translateX(-50%);
          top: 158px;
        }
        .hy {
          position: absolute;
          left: 943px;
          transform: translateX(-50%);
          top: 158px;
        }
      }
    }
    .left-bg {
      position: absolute;
      left: 0px;
      img {
        opacity: 0.2;
      }
    }
    .right-bg {
      position: absolute;
      right: 0px;
      img {
        opacity: 0.2;
      }
    }
  }
  .nvwa-five {
    min-width: 1200px;
    width: 100%;
    height: 735px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #11131b;
    background-image: url('@/assets/imgs/nvwa-five.png');
    background-size: 100% 100%;
    .five-box {
      width: 1200px;
      height: 735px;
      .five-title {
        margin-top: 61px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #f2f3ff;
        text-align: center;
      }
      .five-main {
        width: 100%;
        height: 628px;
        display: flex;
        justify-content: center;
        align-items: center;
        .item {
          width: 293px;
          height: 520px;
          border-radius: 25px;
          // background-color: #9e9e9e;
          margin: 0 5px;
          video {
            border-radius: 25px;
          }
        }
      }
    }
  }
  .nvwa-six {
    min-width: 1200px;
    width: 100%;
    height: 735px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #11131b;
    background-image: url('@/assets/imgs/nvwa-six.png');
    background-size: 100% 100%;
    .six-box {
      width: 1200px;
      height: 735px;
      .six-title {
        margin-top: 61px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #f2f3ff;
        text-align: center;
      }
      .six-main {
        width: 100%;
        height: 628px;
        display: flex;
        justify-content: center;
        align-items: center;
        .item {
          width: 293px;
          height: 520px;
          border-radius: 25px;
          // background-color: #9e9e9e;
          margin: 0 5px;
          video {
            border-radius: 25px;
          }
        }
      }
    }
  }

  .nvwa-seven {
    min-width: 1200px;
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #11131b;
    background-image: url('@/assets/imgs/nvwa-seven.png');
    background-size: 100% 100%;
    .seven-box {
      width: 1200px;
      height: 735px;
      display: flex;
      flex-direction: column;
      // justify-content:center;
      align-items: center;
      .seven-title {
        margin-top: 269px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #f2f3ff;
        text-align: center;
      }
      .seven-conter {
        margin-top: 60px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 24px;
        color: #c2c2c2;
        line-height: 44px;
        text-align: center;
      }
      .middle-download {
        cursor: pointer;
        width: 205px;
        height: 64px;
        background: #895af6;
        border-radius: 4px 4px 4px 4px;
        margin-top: 61px;
        display: flex;
        align-items: center;
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          font-size: 20px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
