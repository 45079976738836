<template>
  <div>
    <!-- 头部 -->
    <div class="headerStyle" :class="{ headerStyle2: isActive }">
      <div class="headerStyle-left">
        <img style="cursor: pointer" @click="gotoHome()" src="@/assets/imgs/logo.png" alt="" height="40px" />
        <div v-show="textShow" class="headerStyle-text">
          <div @mouseenter="productDropdown" class="headerStyle-cp" :class="{ 'headerStyle-cp2': isActive }">
            产品
          </div>
          <div @mouseenter="solveDropdown" class="headerStyle-cp" :class="{ 'headerStyle-cp2': isActive }">
            解决方案
          </div>
          <div @mouseenter="flowDropdown" class="headerStyle-cp" :class="{ 'headerStyle-cp2': isActive }">
            流量研究中心
          </div>
          <div @mouseenter="helpDropdown" class="headerStyle-cp" :class="{ 'headerStyle-cp2': isActive }">
            帮助下载
          </div>
          <div @click="gotoAbout()" class="headerStyle-cp" :class="{ 'headerStyle-cp2': isActive }">
            了解Hello
          </div>
        </div>
      </div>
      <div v-if="loginStatus === 0" class="headerStyle-top">
        <div class="headerStyle-kongzhi" @click="handelLogin(1)">控制台</div>
        <div class="headerStyle-login" @click="handelLogin(2)">登录</div>
        <div class="headerStyle-console" @click="handelLogin(1)">免费试用Nvwa</div>
      </div>

      <div v-else class="headerStyle-top">
        <div class="headerStyle-kongzhi" :class="{ 'headerStyle-kongzhi2': isActive }" @click="gotoControl()">
          控制台
        </div>
        <div @click="dialogShow = true" style="
            width: 30px;
            height: 30px;
            border-radius: 100px;
            overflow: hidden;
            cursor: pointer;
            margin-left: 24px;
            margin-right: 40px;
          ">
          <img v-if="avatar" :src="avatar" alt="" width="100%" height="100%" />
          <img v-else width="100%" height="100%" src="@/assets/imgs/touxiang3.png" alt="" />
        </div>

        <div v-show="dialogShow" @mouseleave="dialogShow = false" class="avatar-dialog">
          <div class="dialog-top">
            <div class="avatar">
              <img v-if="avatar" style="width: 50px; height: 50px" :src="avatar" alt="" />
              <img v-else src="@/assets/imgs/touxiang3.png" alt="" style="width: 50px; height: 50px" />
            </div>
            <div class="user-box">
              <div class="user-top">
                <div class="phone">{{ nickName }}</div>
                <div class="vip-icon">
                  <div class="icon">
                    <img v-if="menberType === 1" src="@/assets/imgs/red.png" alt="" />
                    <img v-else src="@/assets/imgs/red-fill.png" alt="" />
                  </div>
                  <div class="icon">
                    <img v-if="voiceMenberType === 1" src="@/assets/imgs/purple.png" alt="" />
                    <img v-else src="@/assets/imgs/purple-fill.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="user-text">开通会员享更多权益</div>
            </div>
          </div>
          <div class="dialog-main">
            <div class="main-item" @click="gotoInformation()">个人信息</div>
            <div class="main-item" @click="gotoGoods()">订单管理</div>
            <div v-if="menberType === 1 || voiceMenberType === 1" class="main-item" @click="gotoMember()">
              会员续费
            </div>
            <div v-else class="main-item" @click="gotoMember()">开通会员</div>
          </div>
          <div class="dialog-footer">
            <div @click="exitEvn" class="text">退出登录</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dropdownShow === 1" @mouseleave="productDropdownOut" class="product-dropdown" :class="{ productDropdown2: isActive }">
      <div class="product-box">
        <div @click="gotoNvwa" class="product-item">
          <div class="item-logo" style="cursor: pointer;">
            <img src="@/assets/imgs/product-logo.png" alt="" />
          </div>
          <div class="item-main" style="cursor: pointer;">
            <div class="title" :class="{ titleActive: isActive }">Hello · Nvwa</div>
            <div class="text" :class="{ textActive: isActive }">
              数字人、虚拟化智能直播
            </div>
          </div>
        </div>
        <div @click="gotoDyy" class="product-item">
          <div class="item-logo" style="cursor: pointer;">
            <img src="@/assets/imgs/product-logo2.png" alt="" />
          </div>
          <div class="item-main" style="cursor: pointer;">
            <div class="title" :class="{ titleActive: isActive }">Hello · 代运营服务</div>
            <div class="text" :class="{ textActive: isActive }">
              专业直播策划、内容制作、推广运营
            </div>
          </div>
        </div>
        <div class="product-item">
          <div style="display: none" class="item-logo">
            <img src="@/assets/imgs/product-logo.png" alt="" />
          </div>
          <div style="display: none" class="item-main">
            <div class="title" :class="{ titleActive: isActive }">Hello · 数字人向导</div>
            <div class="text" :class="{ textActive: isActive }">
              交互式数字虚拟人智能向导
            </div>
          </div>
        </div>
        <div class="product-item">
          <div style="display: none" class="item-logo">
            <img src="@/assets/imgs/product-logo.png" alt="" />
          </div>
          <div style="display: none" class="item-main">
            <div class="title" :class="{ titleActive: isActive }">Hello · 弹幕游戏</div>
            <div class="text" :class="{ textActive: isActive }">
              工具型弹幕小游戏，直播氛围拉满
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="dropdownShow === 2" @mouseleave="solveDropdownOut" class="solve-dropdown" :class="{ productDropdown2: isActive }">
      <div class="solve-box">
        <div @click="gotoDsdh" class="solve-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              直播电商
            </div>
            <div class="text" :class="{ textActive: isActive }">
              面向丰富的电商直播场景
            </div>
          </div>
        </div>
        <div @click="gotoBdsh" class="solve-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              本地生活
            </div>
            <div class="text" :class="{ textActive: isActive }">
              短视&智能直播，轻松获取客户流量
            </div>
          </div>
        </div>
        <div @click="gotoYljk" class="solve-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              医疗健康
            </div>
            <div class="text" :class="{ textActive: isActive }">面向丰富的电商场景</div>
          </div>
        </div>
        <div @click="gotoJypx" class="solve-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              教育培训
            </div>
            <div class="text" :class="{ textActive: isActive }">
              面向丰富的电商直播场景
            </div>
          </div>
        </div>
        <div @click="gotoLy" class="solve-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              旅游
            </div>
            <div class="text" :class="{ textActive: isActive }">
              面向丰富的电商直播场景
            </div>
          </div>
        </div>
        <div @click="gotoZlzh" class="solve-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              展览展会
            </div>
            <div class="text" :class="{ textActive: isActive }">
              面向丰富的电商直播场景
            </div>
          </div>
        </div>
        <div style="display: none" class="solve-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              客户服务
            </div>
            <div class="text" :class="{ textActive: isActive }">
              短视&智能直播，轻松获取客户流量
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="dropdownShow === 3" @mouseleave="flowDropdownOut" class="flow-dropdown" :class="{ productDropdown2: isActive }">
      <div class="flow-box">
        <div class="flow-item">
          <div @click="gotoOperations()" class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              Nvwa 直播运营教程
            </div>
            <div class="text" :class="{ textActive: isActive }">
              使用平台丰富的高级功能,开亲精彩直播
            </div>
          </div>
        </div>
        <div @click="gotoFlow()" class="flow-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              Nvwa 直播视频教程
            </div>
            <div class="text" :class="{ textActive: isActive }">
              短视频&直播内容制作、开播
            </div>
          </div>
        </div>
        <div style="background-color: transparent" class="flow-item"></div>
        <div @click="gotoPushcode()" class="flow-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              如何获取推流码
            </div>
            <div class="text" :class="{ textActive: isActive }">
              获取各直播平台推流码、开播
            </div>
          </div>
        </div>
        <div @click="gotoVirtualCamer()" class="flow-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              如何使用虚拟摄像头开播
            </div>
            <div class="text" :class="{ textActive: isActive }">
              使用虚拟摄像头方式开播
            </div>
          </div>
        </div>
        <div @click="gotoLiveNumber()" class="flow-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              如何获取直播间房间号
            </div>
            <div class="text" :class="{ textActive: isActive }">
              自动回复获取直播间房间号
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="dropdownShow === 4" @mouseleave="helpDropdownOut" class="help-dropdown" :class="{ productDropdown2: isActive }">
      <div class="help-box">
        <div @click="gotoDownload()" class="help-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              下载Hello Nvwa 客户端
            </div>
            <div class="text" :class="{ textActive: isActive }">
              支持Windows 、Android等设备
            </div>
          </div>
        </div>
        <div @click="gotoGuide()" class="help-item">
          <div class="item-box">
            <div class="title" :class="{ titleActive: isActive }">
              <img v-if="!isActive" src="@/assets/imgs/arrow.png" alt="" />
              <img v-else src="@/assets/imgs/arrow2.png" alt="" />
              Hello Nvwa 使用指南
            </div>
            <div class="text" :class="{ textActive: isActive }">
              Nvwa直播产品介绍、使用指南、常见问题等
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="400px" :before-close="handleClose" class="loginStyle1">
      <div style="position: absolute; right: 0px; top: 0px; cursor: pointer" v-show="forgetIshow == 1" @click="weixinEvn">
        <img src="@/assets/imgs/weixindengluerweima.png" alt="" />
      </div>
      <div style="position: absolute; right: 40px; top: 30px; cursor: pointer" v-show="forgetIshow == 1" @click="weixinEvn">
        <img src="@/assets/imgs/saomademg.png" alt="" />
      </div>
      <div style="position: absolute; right: 0px; top: 0px; cursor: pointer" v-show="forgetIshow == 3" @click="dengluEvn">
        <img src="@/assets/imgs/denglutouxiang.png" alt="" />
      </div>
      <div style="height: 330px" v-show="forgetIshow == 1">
        <div style="color: #0f1114; font-size: 19px; font-weight: 600; margin-bottom: 30px">
          数字媒体平台
        </div>
        <div class="loginStyle">
          <span class="login-word" v-show="isShow == 1">验证码登录</span>
          <span class="login-switch" @click="switchBtn(2)" v-show="isShow == 2">
            验证码登录
            <!-- <img style="margin-left: 4px;"
                src="@/assets/imgs/genghuan.png" alt="" width="6px" height="9px"> -->
          </span>
          <span class="login-word" v-show="isShow == 2">账号密码登录</span>
          <div style="
              width: 60px;
              height: 3px;
              border-radius: 10px;
              background-color: #8b5ef5;
              position: absolute;
              top: 30px;
              left: 7px;
            " v-show="isShow == 1"></div>
          <div style="
              width: 60px;
              height: 3px;
              border-radius: 10px;
              background-color: #8b5ef5;
              position: absolute;
              top: 30px;
              left: 115px;
            " v-show="isShow == 2"></div>
          <span class="login-switch" @click="switchBtn(1)" v-show="isShow == 1">
            账号密码登录
            <!-- <img style="margin-left: 4px;"
                src="@/assets/imgs/genghuan.png" alt="" width="6px" height="9px"> -->
          </span>
        </div>
        <!-- 账号密码登录 -->
        <el-form class="login-form" autocomplete="on" :model="loginForm" ref="loginFormRef" label-position="left" v-if="isShow == 2">
          <div>
            <el-form-item prop="username" style="position: relative">
              <div class="icon-style">
                <!-- <img src="../../assets/imgs/shoujihao.png" alt=""> -->
              </div>
              <el-input name="username" type="text" v-model="loginForm.username" autocomplete="on" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item prop="password" style="position: relative">
              <div class="icon-style">
                <!-- <img src="../../assets/imgs/mima.png" alt=""> -->
              </div>
              <el-input name="password" :type="pwdType" @keyup.enter.native="handleLogin" v-model="loginForm.password" autocomplete="on" placeholder="请输入登录密码"></el-input>
            </el-form-item>
          </div>
          <div class="read-style">
            <div>
              <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
              <span class="agreement" @click="pdfFile">《服务协议和隐私条款》</span>
            </div>
            <span class="agreement-style" @click="forget">忘记密码？</span>
          </div>
          <div v-show="isShow1 == false" style="font-size: 10px; color: #f56c6c">
            如已知悉相关协议及条款，请勾选
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin">登录</button>
          </div>
        </el-form>
        <!-- 手机验证码登录 -->
        <el-form class="login-form" autocomplete="on" :model="loginForm1" ref="loginFormRef1" label-position="left" v-if="isShow == 1">
          <div>
            <el-form-item prop="phone" style="position: relative">
              <div class="icon-style"></div>
              <el-input name="phone" type="text" v-model="loginForm1.phone" autocomplete="on" placeholder="请输入手机号" />
            </el-form-item>
            <div v-show="isShow3 == true" style="
                font-size: 10px;
                color: #f56c6c;
                margin-top: -20px;
                margin-bottom: 6px;
              ">
              手机号格式不正确
            </div>
            <el-form-item prop="codeSms" style="position: relative">
              <div class="icon-style"></div>
              <el-input name="codeSms" @keyup.enter.native="handleLogin1" v-model="loginForm1.codeSms" autocomplete="on" placeholder="请输入短信验证码"></el-input>
              <span class="show-pwd">
                <span :class="grey ? 'greyStart' : 'greyEnd'" style="font-size: 11px" :disabled="isSend" @click="sendBtn01(1)">{{ codeName }}</span>
              </span>
            </el-form-item>
          </div>
          <div class="read-style">
            <div>
              <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
              <span class="agreement" @click="pdfFile">《服务协议和隐私条款》</span>
            </div>
            <span class="agreement-style" @click="forget">忘记密码？</span>
          </div>
          <div v-show="isShow1 == false" style="font-size: 10px; color: #f56c6c">
            如已知悉相关协议及条款，请勾选
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin1">登录</button>
          </div>
        </el-form>
      </div>
      <div style="height: 330px" v-show="forgetIshow == 2">
        <div style="
            color: #0f1114;
            font-size: 19px;
            font-weight: 600;
            margin-bottom: 10px;
            margin-left: 10px;
          ">
          找回密码
        </div>
        <el-form class="login-form" autocomplete="on" :model="loginForm2" ref="loginFormRef1" label-position="left">
          <div>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm2.findPhone" autocomplete="on" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item prop="codeSms" style="position: relative">
              <el-input name="codeSms" v-model="loginForm2.findCodeSms" autocomplete="on" placeholder="请输入短信验证码"></el-input>
              <span class="show-pwd">
                <span :class="grey ? 'greyStart' : 'greyEnd'" style="font-size: 11px" :disabled="isSend" @click="sendBtn01(2)">{{ codeName }}</span>
              </span>
            </el-form-item>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm2.findPassword" autocomplete="on" placeholder="请输入密码" />
            </el-form-item>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm2.findPassword1" autocomplete="on" placeholder="请输入密码" />
            </el-form-item>
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin2">找回密码</button>
          </div>
        </el-form>
      </div>
      <div style="height: 330px" v-show="forgetIshow == 3">
        <div class="login-from-left">
          <span class="code-scanning">微信扫码登录</span>
          <div id="weixin" class="wei-chat" style="margin-left: 150px">
            <wxlogin appid="wxedf5818b5ae7815c" scope="snsapi_login" :redirect_uri="redirect_uri" :href="href" :state="state"></wxlogin>
          </div>
          <div class="code-button">
            <div class="code-button-open">
              <img src="@/assets/imgs/wx.png" alt="" width="20px" />
              <span class="button-open1">打开微信扫码授权登录</span>
            </div>
            <div class="code-button-open1">
              <span class="button-open1-refresh">已扫码无反应？请点击</span>
              <span class="button-open1-refresh1" @click="refresh">刷新</span>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 330px" v-show="forgetIshow == 4">
        <el-form class="login-form" autocomplete="on" :model="loginForm3" ref="loginFormRef1" label-position="left" v-if="isShow == 1">
          <div>
            <el-form-item prop="phone" style="position: relative">
              <el-input name="phone" type="text" v-model="loginForm3.phone" autocomplete="on" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item prop="codeSms" style="position: relative">
              <el-input name="codeSms" v-model="loginForm3.codeSms" autocomplete="on" placeholder="请输入短信验证码"></el-input>
              <span class="show-pwd">
                <span :class="grey ? 'greyStart' : 'greyEnd'" style="font-size: 11px" :disabled="isSend" @click="sendBtn01(3)">{{ codeName }}</span>
              </span>
            </el-form-item>
          </div>
          <div class="login-btn">
            <button type="button" class="btn" @click="handleLogin3">确认</button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>

<script>
import { send, login, getNext, getNextSmsCode, getOssPrefix } from "@/api/login";
import { getUserTeam } from "@/api/team";
import { informationId } from "@/api/information";
import Cookies from "js-cookie";
import wxlogin from "vue-wxlogin";

import md5 from "js-md5";
export default {
  name: "HomeView",
  components: { wxlogin },
  props: ["dataColor", "dataTop"], // 定义props属性名称为"data"
  data() {
    return {
      isActive: false,
      textShow: true,
      avatar: "",
      nickName: "",
      dialogShow: false,
      dialogVisible: false,
      clickStatus: 1, //1进入控制台 2停留当前页
      loginStatus: 0,
      isShow: 1,
      isShow3: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginForm1: {
        phone: "",
        codeSms: "",
      },
      grey: true,
      isSend: false,
      checked: false,
      isShow1: true,
      codeName: "获取验证码",
      isShow5: false,
      pwdType: "password",
      id: "",
      totalTime: "60",
      timer: "",
      grey: true,
      forgetIshow: 1,
      title: "登录",
      loginForm2: {
        findPhone: "",
        findCodeSms: "",
        findPassword: "",
        findPassword1: "",
      },
      loginForm3: {
        phone: "",
        codeSms: "",
      },
      redirect_uri: encodeURIComponent(
        "https://hello.yohelo.com/api/hello-auth/api/ucenter/wx/callback"
      ),
      href:
        "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg==",
      state: "pc",
      code: localStorage.getItem("code"),
      openid: localStorage.getItem("openid"),
      authCode: "",
      dropdownShow: 0,
      menberType: 0,
      voiceMenberType: 0,
    };
  },
  computed: {},
  created() {
    this.avatar = localStorage.getItem("avatar");
    if (this.avatar === "null" || this.avatar === "undefined") {
      this.avatar = "";
    }
    this.nickName = "" + localStorage.getItem("nickName");


    if (localStorage.getItem("nickName")) {
      this.loginStatus = 1;
    }

    if (localStorage.getItem("userId")) {
      this.loginStatus = 1;
    }

    if (localStorage.getItem("loginStatus")) {
      this.loginStatus = localStorage.getItem("loginStatus");
    }

    let token = localStorage.getItem("token");
    if (token != "" && token != undefined && token != null) {
      this.teamEvn();
    }
  },
  mounted() {

    if (this.dataColor === 2) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    if (this.dataTop === 2) {
      this.textShow = false;
    } else {
      this.textShow = true;
    }
  
    if (this.code == "false") {
      this.dialogVisible = true;
      this.forgetIshow = 4;
      this.title = "绑定手机号";
    }
  },
  methods: {
    handelLogin(val) {
      this.dialogVisible = true;
      this.clickStatus = val;
      localStorage.setItem("clickStatus", val);
    },
    gotoControl() {
      // this.$router.push("/control").catch((error) => error);
      let routeData = this.$router.resolve({ path: "/control", query: { id: 1 } });
      window.open(routeData.href, "_blank");
      location.reload();
    },
    switchBtn(val) {
      if (val == 1) {
        this.isShow = 2;
      }
      if (val == 2) {
        this.isShow = 1;
      }
    },
    refresh() {
      // this.redirect_uri = encodeURIComponent("https://hello.yohelo.com/api/hello-auth/api/ucenter/wx/callback")
    },
    setWxerwma() { },
    forget() {
      this.forgetIshow = 2;
      this.title = "找回密码";
    },
    weixinEvn() {
      this.forgetIshow = 3;
      this.title = "微信登录";

      // var obj = new WxLogin({
      //   self_redirect: true,
      //   id: "weixin",
      //   appid: "wxedf5818b5ae7815c",
      //   // appid: 'wxe19e3e2415e89353',
      //   scope: "snsapi_login",
      //   redirect_uri: encodeURIComponent(
      //     "https://hello.yohelo.com/api/hello-auth/api/ucenter/wx/callback"
      //   ),
      //   state: "pc",
      //   style: "black",
      //   href: "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg==",
      // });
    },
    dengluEvn() {
      this.forgetIshow = 1;
      this.title = "登录";
    },

    async sendBtn01(val) {
      this.isShow3 = false;
      if (this.isSend) return;
      this.isSend = true;
      this.codeName = this.totalTime + "s后重新发送";
      this.grey = false;
      this.timer = setInterval(() => {
        this.totalTime--;
        this.codeName = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          clearInterval(this.timer);
          this.grey = true;
          this.codeName = "重新获取验证码";
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
      if (val == 1) {
        const res = await send(this.loginForm1.phone);
        this.id = res.data.data.id;
      } else if (val == 2) {
        const res = await send(this.loginForm2.findPhone);
        this.id = res.data.data.id;
      } else if (val == 3) {
        const res = await send(this.loginForm3.phone);
        this.id = res.data.data.id;
      }
    },
    handleLogin() {
      if (this.checked == false) {
        this.$message({
          type: "warning",
          message: "请勾选用户协议",
          duration: 1000,
        });
        // this.isShow1 = false
        return
      }
      let params = {
        tenantId: "000000",
        password: md5(this.loginForm.password),
        username: this.loginForm.username,
        grant_type: "password",
        scope: "all",
      };

  
      login(params)
        .then((res) => {
   
          this.loginStatus = 1;
          localStorage.setItem("loginStatus", 1);
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          localStorage.setItem("avatar", res.data.avatar);
          // localStorage
          this.avatar = res.data.avatar;
          if (this.avatar === "null" || this.avatar === "undefined") {
            this.avatar = "";
          }
          this.teamEvn1();

          setTimeout(() => {
            if (this.clickStatus === 1) {
              let routeData = this.$router.resolve({
                path: "/control",
                query: { id: 1 },
              });
              window.open(routeData.href, "_blank");
            } else {
              this.dialogVisible = false;
            }
            location.reload();
          }, 500);
        })
        .catch(() => { });
    },
    handleLogin1() {
      if (this.checked != true) {
        this.$message({
          type: "warning",
          message: "请勾选用户协议",
          duration: 1000,
        });
        return
      }
      let params = {
        tenantId: "000000",
        phone: this.loginForm1.phone,
        codeSms: this.loginForm1.codeSms,
        grant_type: "sms_code",
        scope: "all",
        id: this.id,
      };
      login(params)
        .then((res) => {
          this.loginStatus = 1;
          localStorage.setItem("loginStatus", 1);
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("nickName", res.data.nick_name);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("refreshNum", true);
          localStorage.setItem("avatar", res.data.avatar);
          this.avatar = res.data.avatar;
          if (this.avatar === "null" || this.avatar === "undefined") {
            this.avatar = "";
          }
          this.teamEvn1();

          setTimeout(() => {
            if (this.clickStatus === 1) {
              let routeData = this.$router.resolve({
                path: "/control",
                query: { id: 1 },
              });
              window.open(routeData.href, "_blank");
            } else {
              this.dialogVisible = false;
            }
            location.reload();
          }, 500);
        })
        .catch(() => { });
    },
    teamEvn() {
      getUserTeam().then((res) => {
        let userId = localStorage.getItem("userId");
        let teamId = res.data.data[0].id;
        informationId(userId, teamId).then((res) => {
          this.menberType = res.data.data.menberType;
          this.voiceMenberType = res.data.data.voiceMenberType;
        });
      });
    },
    async handleLogin2() {
      let passwordData = md5(this.loginForm2.findPassword);
      let passwordData1 = md5(this.loginForm2.findPassword1);
      let a = {
        phone: this.loginForm2.findPhone,
        id: this.id,
        value: this.loginForm2.findCodeSms,
        newPassword: passwordData,
        newPassword1: passwordData1,
      };

      const res = await getNext(
        this.loginForm2.findPhone,
        this.id,
        this.loginForm2.findCodeSms,
        passwordData,
        passwordData1
      );
      if (res.data.code == 200) {
        this.$message({
          type: "success",
          message: "重置成功",
        });
        this.forgetIshow = 1;
        this.title = "登录";
        this.isShow = 2;
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async handleLogin3() {
      const res = await getNextSmsCode(
        this.loginForm3.phone,
        this.id,
        this.loginForm3.codeSms
      );
      if (res.data.code == 200) {
        this.authCode = res.data.msg;
        let params = {
          tenantId: "000000",
          openid: this.openid,
          authCode: this.authCode,
          grant_type: "wechat",
          scope: "all",
        };
        login(params)
          .then((res) => {
            this.loginStatus = 1;
            localStorage.setItem("loginStatus", 1);
            localStorage.removeItem("code");
            this.dialogVisible = false;
            this.forgetIshow = 0;
            this.title = "";
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("userId", res.data.user_id);
            localStorage.setItem("nickName", res.data.nick_name);
            localStorage.setItem("avatar", res.data.avatar);
            this.teamEvn1();
            this.avatar = res.data.avatar;
            if (this.avatar === "null" || this.avatar === "undefined") {
              this.avatar = "";
            }
            setTimeout(() => {
              if (this.clickStatus === 1) {
                let routeData = this.$router.resolve({
                  path: "/control",
                  query: { id: 1 },
                });
                window.open(routeData.href, "_blank");
              } else {
                this.dialogVisible = false;
              }
              location.reload();
            }, 500);
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "登录失败",
            });
          });
      } else {
        this.$message({
          type: "error",
          message: "验证码错误！",
        });
      }
    },
    async teamEvn1() {
      const res = await getUserTeam();
      // this.teamList = res.data.data;
      // let teamName = localStorage.getItem("teamName");
      // if (teamName != "" && teamName != undefined && teamName != null) {
      // } else {
      localStorage.setItem("i", 1);
      // this.teamName = res.data.data[0].teamName;
      localStorage.setItem("teamName", res.data.data[0].teamName);
      localStorage.setItem("teamId", res.data.data[0].id);
      // }
    },
    pdfFile() {
      window.open('https://hello.yohelo.com/protocol/user-privacy-protocol.html', '_blank');
    },
    handleClose(done) {
      done();
      this.forgetIshow = 1;
      this.title = "登录";
    },
    gotoHome() {
      this.$router.push({ path: "/" });
    },
    gotoDownload() {
      this.$router.push({ path: "/help/download" });
    },
    gotoGuide() {
      // 链接地址
      var url = "https://jx0vothcok0.feishu.cn/wiki/Q0C6w463MiUAJekFN7nch8YDnGe";
      // 打开链接的窗口名称
      var windowName = "newWindow";
      // 打开链接
      window.open(url, windowName);
    },
    productDropdown() {
      this.dropdownShow = 1;
    },
    productDropdownOut() {
      this.dropdownShow = 0;
    },
    solveDropdown() {
      this.dropdownShow = 2;
    },
    solveDropdownOut() {
      this.dropdownShow = 0;
    },
    flowDropdown() {
      this.dropdownShow = 3;
    },
    flowDropdownOut() {
      this.dropdownShow = 0;
    },
    helpDropdown() {
      this.dropdownShow = 4;
    },
    helpDropdownOut() {
      this.dropdownShow = 0;
    },
    gotoNvwa() {
      this.$router.push({ path: "/product/nvwa" });
    },
    gotoDyy(){
      this.$router.push({ path: "/service/dyy" });
    },
    gotoDsdh() {
      this.$router.push({ path: "/solution/dsdh" });
    },
    gotoBdsh() {
      this.$router.push({ path: "/solution/bdsh" });
    },
    gotoYljk() {
      this.$router.push({ path: "/solution/yljk" });
    },
    gotoJypx() {
      this.$router.push({ path: "/solution/jypx" });
    },
    gotoLy() {
      this.$router.push({ path: "/solution/ly" });
    },
    gotoZlzh() {
      this.$router.push({ path: "/solution/zlzh" });
    },
    gotoOperations() {
      // 链接地址
      var url = "https://jx0vothcok0.feishu.cn/docx/V32idJ3w4oh6pMxO3lgciMVbnbe"
      // 打开链接
      window.open(url);
    },
    gotoAbout() {
      this.$router.push({ path: "/about" });
    },
    gotoFlow() {
      this.$router.push({ path: "/tutorial/nvwaLive" });
    },
    gotoPushcode() {
      this.$router.push({ path: "/tutorial/pushcode" });
    },
    gotoVirtualCamer() {
      this.$router.push({ path: "/tutorial/virtualCamer" });
    },
    gotoLiveNumber() {
      this.$router.push({ path: "/tutorial/liveNumber" });
    },
    gotoInformation() {
      this.$router.push({ path: "/information" });
    },
    gotoGoods() {
      this.$router.push({ path: "/goods" });
    },
    gotoMember() {
      this.$router.push({ path: "/member" });
    },
    exitEvn() {
      this.$confirm("确认退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          localStorage.clear();
          this.$router.push("/").catch((error) => error);
          location.reload();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>

<style lang="less">
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
}
// .wei-chat {
//   margin-left: 10px;
//   width: 173px;
//   height: 173px;
//   overflow: hidden;
//   border-radius: 11px;
// }
.loginStyle1 {
  .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #8b5ef5;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #8b5ef5;
    border-color: #8b5ef5;
  }
  .el-checkbox__inner:hover {
    border-color: #8b5ef5;
  }
  .btn {
    position: relative;
    width: 312px;
    padding: 6px 0;
    margin: 10px 0 36px 0;
    font-size: 16px;
    color: white;
    background-color: #8b5ef5;
    border-radius: 5px;
    border: 0px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    height: 52px;
    margin-left: 20px;
  }
}

.headerStyle {
  min-width: 1200px;
  width: 100%;
  height: 64px;
  padding-left: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  position: fixed;
  z-index: 999;
  box-shadow: 0px 2px 4px 1px rgba(165, 165, 165, 0.3);
  .headerStyle-left {
    display: flex;
    .headerStyle-text {
      display: flex;
      align-items: center;
      margin-left: 35px;
      .headerStyle-cp {
        cursor: pointer;
        margin-right: 20px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 14px;
        color: #3f4959;
      }
      .headerStyle-cp2 {
        color: #f2f3ff;
      }
    }
  }
  .headerStyle-top {
    display: flex;
    align-items: center;
    float: right;
    color: #fff;
    line-height: 35px;
    position: relative;
    .headerStyle-kongzhi {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-size: 14px;
      color: #3f4959;
      line-height: 65px;
      font-weight: 400;
      cursor: pointer;
    }
    .headerStyle-kongzhi2 {
      color: #f2f3ff;
    }
    .headerStyle-login {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-size: 14px;
      color: #3f4959;
      margin: 0 20px;
      line-height: 65px;
      font-weight: 400;
      cursor: pointer;
    }
    .headerStyle-console {
      width: 120px;
      background-color: #8b5ef5;
      height: 65px;
      line-height: 65px;
      text-align: center;
      cursor: pointer;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
    .avatar-dialog {
      position: absolute;
      top: 63px;
      right: 50px;
      width: 301px;
      height: 289px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px 4px 4px 4px;
      .dialog-top {
        width: 100%;
        height: 102px;
        background: rgba(232, 232, 232, 0.4);
        display: flex;
        align-items: center;
        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          overflow: hidden;
          cursor: pointer;
          margin-left: 17px;
          margin-right: 7px;
        }
        .user-box {
          .user-top {
            display: flex;
            align-items: center;
            align-items: center;
            .phone {
              font-family: Microsoft YaHei UI, Microsoft YaHei UI;
              font-weight: 400;
              font-size: 16px;
              color: #243042;
              line-height: 25px;
              margin-top: 4px;
            }
            .vip-icon {
              margin-left: 2px;
              display: flex;
              .icon {
                width: 21px;
                height: 20px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .user-text {
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: 400;
            font-size: 14px;
            color: #e17800;
          }
        }
      }
      .dialog-main {
        width: 100%;
        height: 132px;
        border-bottom: 1px solid #cccccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .main-item {
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 14px;
          color: #243042;
          margin-left: 18px;
          cursor: pointer;
        }
      }
      .dialog-footer {
        width: 100%;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        .text {
          cursor: pointer;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: 400;
          font-size: 14px;
          color: #e40000;
        }
      }
    }
  }
}
.headerStyle2 {
  background-color: #0d0e13;
  opacity: 0.99;
  box-shadow: none;
  // opacity: 0.1;
}
.product-dropdown {
  min-width: 1200px;
  width: 100%;
  height: 112px;
  background: #ffffff;
  position: fixed;
  top: 64px;
  z-index: 99;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px 1px rgba(165, 165, 165, 0.3);
  .product-box {
    margin-left: 210px;
    // margin-right: 190px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    .product-item {
      width: 25%;
      height: 100%;
      display: flex;
      // justify-content: center;
      align-items: center;
      .item-logo {
        margin-right: 10px;
      }
      .item-main {
        .title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 16px;
          color: #243042;
        }
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #243042;
          line-height: 22px;
        }
      }
    }
  }
}
.productDropdown2 {
  box-shadow: none !important;
  background-color: #0d0e13 !important;
  opacity: 0.99;
}
.titleActive {
  color: #ffffff !important;
}
.textActive {
  color: #f2f3ff !important;
}

.solve-dropdown {
  min-width: 1200px;
  width: 100%;
  height: 200px;
  background: #ffffff;
  position: fixed;
  top: 64px;
  z-index: 99;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px 1px rgba(165, 165, 165, 0.3);
  padding-top: 20px;
  padding-bottom: 20px;
  .solve-box {
    margin-left: 210px;
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    flex-flow: wrap;
    .solve-item {
      cursor: pointer;
      width: 20%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item-box {
        position: relative;
        .title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 16px;
          color: #243042;
          display: flex;
          align-items: center;
          img {
            margin-top: -2px;
          }
        }
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #243042;
          line-height: 22px;
          text-align: left;
        }
      }
    }
  }
}

.flow-dropdown {
  min-width: 1200px;
  width: 100%;
  height: 200px;
  background: #ffffff;
  position: fixed;
  top: 64px;
  z-index: 99;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px 1px rgba(165, 165, 165, 0.3);
  padding-top: 20px;
  padding-bottom: 20px;
  .flow-box {
    margin-left: 210px;
    // margin-right: 110px;
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    flex-flow: wrap;
    .flow-item {
      cursor: pointer;
      width: 33%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item-box {
        position: relative;
        .title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 16px;
          color: #243042;
          display: flex;
          align-items: center;
          img {
            margin-top: -2px;
          }
        }
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #243042;
          line-height: 22px;
          text-align: left;
        }
      }
    }
  }
}
.help-dropdown {
  min-width: 1200px;
  width: 100%;
  height: 112px;
  background: #ffffff;
  position: fixed;
  top: 64px;
  z-index: 99;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px 1px rgba(165, 165, 165, 0.3);
  .help-box {
    margin-left: 215px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .help-item {
      cursor: pointer;
      width: 25%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item-box {
        position: relative;
        // left: 25%;
        .title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          font-size: 16px;
          color: #243042;
          display: flex;
          align-items: center;
          img {
            margin-top: -2px;
          }
        }
        .text {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 12px;
          color: #243042;
          line-height: 22px;
          text-align: left;
        }
      }
    }
  }
}
.loginStyle {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.login-word {
  font-weight: 600;
  color: #8b5ef5;
  font-size: 16px;
  margin-right: 20px;
  border-bottom: 1px solid #fff;
  height: 30px;
  font-weight: 600;
}

.login-switch {
  color: #5e5e5e;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  height: 30px;
  font-weight: 600;
}

.login-form {
  width: 350px;
}

.icon-style {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}

.read-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 310px;
  margin-top: -10px;
}

.agreement {
  font-size: 10px;
  color: #8b5ef5;
  cursor: pointer;
}

.agreement-style {
  color: #6f757f;
  font-size: 10px;
  cursor: pointer;
}

.login-btn {
  margin-top: 15px;
}

.greyStart {
  color: #8b5ef5;
  font-size: 11px;
}

.greyEnd {
  color: #6f757f;
  font-size: 11px;
}

.show-pwd {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.login-from-left {
  width: 300px;
  padding-left: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.code-scanning {
  font-weight: 600;
  margin-bottom: 20px;
  color: #6f757f;
  font-size: 16px;
  margin-left: 47px;
}

.vertical-line {
  width: 0.6px;
  height: 200px;
  background-color: #404143;
  position: absolute;
  left: 285px;
  top: 35px;
}

.code-button {
  position: absolute;
  top: 200px;
  margin-left: 10px;
}

.code-button-open {
  margin: 40px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.button-open1 {
  color: #6f757f;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600px;
}

.code-button-open1 {
  display: flex;
  margin-top: 10px;
}

.button-open1-refresh {
  color: #6f757f;
  font-size: 14px;
}

.button-open1-refresh1 {
  color: #8b5ef5;
  font-size: 14px;
  cursor: pointer;
}
</style>
