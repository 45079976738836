<template>
  <div>
    <el-header>
      <navtop></navtop>
    </el-header>
    <div class="userStyle-cloudSpace">
      <div style="position: relative">
        <div style="position: absolute; top: 0px; z-index: 9999">
          <img src="@/assets/imgs/xszk.png" alt="" />
        </div>
      </div>
      <div>
        <img src="@/assets/imgs/ykjb.png" alt="" width="100%" />
      </div>
      <div style="margin-top: -140px; position: relative">

      </div>
      <!-- <div style="width: 100%; display: flex; justify-content: center">
        <div class="qieStyle" v-show="isShow == false">
          <div style="cursor: pointer">
            <div style="color: #fff; font-size: 16px; width: 200px; height: 52px; font-weight: 600; background: linear-gradient(to right, #8b5ef5, #6d2fff); border-radius: 30px; display: flex; align-items: center; justify-content: center">
              <div style="padding-top: 5px">
              </div>
              <div>{{ ordinaryWord }}会员</div>
            </div>
          </div>
          <div style="cursor: pointer">
            <div @click="senior(true, 2)" style="color: #888888; font-size: 16px; font-weight: 600; width: 200px; height: 52px; background-color: #fff; border-radius: 30px; display: flex; align-items: center; justify-content: center">
              <div style="padding-top: 10px">
              </div>
              <div>{{ seniorWord }}会员</div>
            </div>
          </div>
        </div>
        <div class="qieStyle" v-show="isShow == true">
          <div style="cursor: pointer">
            <div @click="senior(false, 1)" style="color: #888888; font-size: 16px; width: 200px; height: 52px; font-weight: 600; border-radius: 30px; background-color: #fff; display: flex; align-items: center; justify-content: center">
              <div style="padding-top: 10px">
              </div>
              <div>{{ ordinaryWord }}会员</div>
            </div>
          </div>
          <div style="cursor: pointer">
            <div style="color: #fff; font-size: 16px; width: 200px; height: 52px; font-weight: 600; background: linear-gradient(to right, #8b5ef5, #6d2fff); border-radius: 30px; display: flex; align-items: center; justify-content: center">
              <div style="padding-top: 5px">
              </div>
              <div>{{ seniorWord }}会员</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="userStyle-refueling1">
        <!-- <div style="display: flex; justify-content: space-between; -webkit-app-region: drag;">
          <div style="width: 20px; height: 20px;">
          </div>
          <p style="color: #fff;font-weight: 600;font-size: 18px;margin-left: 30px;"></p>
          <div style="display: flex; margin-top: 15px; margin-right: 10px;">
            <div class="close" @click="closeBtn">
              <img src="@/assets/imgs/guanbi.png" alt="">
            </div>
          </div>
        </div> -->
        <div style="width: 100%; display: flex; justify-content: center">
          <div class="qieStyle1" style="background-color: #fff;width: 390px;height: 56px;border-radius: 50px;">
            <div :class="spaceId == item.id ? 'spaceListStyle1' : 'spaceListStyle'" v-for=" (  item, index  ) in spaceList" :key="item.index" @click="getSpace(item.dictKey, item.id)">
              {{ item.dictValue }}
            </div>
          </div>
        </div>
        <div class="person10">
          <div style="display: flex;justify-content: center;">
            <div style="display: flex; justify-content: space-evenly; width: 1100px;">
              <div :class="item.id == listId ? 'personStyle21' : 'personStyle12'" v-for=" (  item, index  ) in   spaceBuyList  " :key="item.index" @click="buyRefueling(item, 2)">
                <div style="font-size: 16px;font-weight: 600;">
                  {{ item.timeType }}个月
                </div>
                <div style="">
                  <div>
                    <span style="font-size: 12px;font-weight: 600;">￥</span>
                    <span style="font-size: 24px;">{{ item.price }}</span>
                  </div>
                  <div>
                    <div style=" font-size: 12px;text-decoration:line-through;margin-left: 10px;">
                      ￥{{ item.price * 2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: center;margin-top: 25px;">
            <div style="width: 1100px;">
              <div style="color: #171725;font-size: 16px;font-weight: 600;margin-left: 15px;">
                规则说明
              </div>
              <div style="color: #171725;font-size: 14px;margin-left: 15px;margin-top: 15px;">
                1.同空间大小叠加购买，有效期延长（若同时购买两个10G1个月和3个月的云空间，可用有效期为4个月）
              </div>
              <div style="color: #171725;font-size: 14px;margin-left: 15px;margin-top: 5px;">
                2.不同空间叠加购买，优先使用大空间，小空间有效期延长；大空间到期后，则使用小空间（若同时购买1个月10G空间和1个月100G空间，优先使用100G空间，100G空间
              </div>
              <div style="color: #171725;font-size: 14px;margin-left: 15px;margin-top: 5px;">
                使用1个月到期后，继续使用1个月有效期的10G空间）
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: center;">
            <div class="personStyle18">
              <div style="display: flex;align-items: center;margin-top: 10px;">
                <div style="width: 160px;height: 160px;background-color: #fff;border-radius: 11px;">
                  <div v-show="paymentShow1 == 1">
                    <img src="@/assets/imgs/zferweima.png" alt="" width="100%" height="100%">
                  </div>
                  <div class="qrcode1Style" style="" id="qrcode1" v-show="paymentShow1 == 2">
                  </div>
                  <div v-show="paymentShow1 == 3">
                    <img src="@/assets/imgs/cg.png" alt="">
                    <div style="font-size: 14px;color: #262626;margin-left: 60px;">支付成功</div>
                  </div>
                </div>
                <div>
                  <div style="margin-left: 20px;display: flex;align-items: baseline;margin-bottom: 15px;width: 500px">
                    <div>
                      <img src="@/assets/imgs/wxsmzf.png" alt="" style="margin-right: 8px;">
                    </div>
                    <div style="font-size: 16px;color: #262626;">微信扫码支付，实付：</div>
                    <div style="font-size: 41px;color: #E33F2D;">￥{{ money }}</div>
                  </div>
                  <div v-show="buySueShow == 1" style="margin-left: 20px;font-size: 22px;background-color: #8B5EF5;width: 200px;height: 60px;border-radius: 8px;color: #fff;
                            line-height: 60px;text-align: center;cursor: pointer;" @click="buySue">
                    立即购买
                  </div>
                  <div v-show="buySueShow == 2" style="margin-left: 20px;font-size: 22px;background-color: #8B5EF5;width: 200px;height: 60px;border-radius: 8px;color: #fff;
                            line-height: 60px;text-align: center;cursor: not-allowed;">
                    请扫码支付
                  </div>
                  <div style="margin-left: 20px;margin-top: 15px;">
                    <span style="color: #A6A6A6;">支付即视为同意</span>
                    <span style="color: #8B5EF5;cursor: pointer;" @click="pdfFile">《用户协议条例》</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import navtop from '@/components/nav-top.vue';
import QRCode from 'qrcodejs2';
import Cookies from 'js-cookie'
import { teamUserEquity, getToPay, getsSaveId, getSuccess, spaceRefuelBagList, getDictList } from "@/api/information"
import { getMaterialList, getMaterialDel, getPut } from "@/api/cloudSpace";
// import Cookies from 'js-cookie'
export default {
  name: 'member',
  components: {
    navtop
  },
  data() {
    return {
      title: "",
      dialogVisible1: false,
      goodsList: [],
      type: '',
      goodId: "",
      id: "",
      paymentShow: 1,
      money: "",
      paymentShow1: 1,
      qrcode: null,
      qrcode1: null,
      tmeri: null,
      tmeri1: null,
      value: "",
      isShowEr: "",
      isShowEr1: "",
      listId: "",
      erweimaData: "",
      spaceList: [],
      spaceId: "",
      spaceBuyList: [],
      valNmu: "",
      buySueShow: 1,
      teamId: localStorage.getItem("teamId")
    };
  },
  created() {
    setTimeout(() => {
      //   this.getGoods(1);
    }, 500);
    this.questionType();
  },
  mounted() { },
  methods: {
    pdfFile() {
      window.open('https://hello.yohelo.com/protocol/member-vip-protocol.html', '_blank');
    },
    async questionType() {
      let data = {
        code: 'cloud_space_size'
      }
      const res = await getDictList(data)
      this.spaceList = res.data.data[0].children
      this.getSpace(this.spaceList[0].dictKey, this.spaceList[0].id)
    },
    async getSpace(val, id) {
      this.money = 0.00
      this.spaceId = id
      const res = await spaceRefuelBagList(val)
      this.spaceBuyList = res.data.data
  
      this.buyRefueling(this.spaceBuyList[0], val)
    },
    async buyRefueling(list, val) {
      this.paymentShow1 = 1
      // this.paymentShow = 1
      this.buySueShow = 1
      this.valNmu = val
      this.listId = list.id

      // this.payment(val)
      this.money = list.price
    },
    async buySue() {
      this.buySueShow = 2
      let goodId = {
        goodIds: this.listId,
        teamId: this.teamId
      }
      const res = await getsSaveId(goodId)
      this.id = res.data.data
      this.payment(this.valNmu)
    },
    async payment(val) {
      let idData = {
        orderId: this.id
      }
      if (val == 1) {
        this.tmeri1 = null
        this.tmeri = null
        const codeList = document.getElementById('qrcode')
        codeList.innerHTML = ''
        const res = await getToPay(idData)

        this.qrcode = new QRCode('qrcode', {
          width: 140,  // 宽  
          height: 140, // 高  
          text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
          qrCodeSuccess: function (canvas) {
     
            // 将二维码转为图片
          },
        })

        this.paymentShow = 2
        if (res.data.code == 200) {
          this.tmeri = setInterval(() => {
            this.successBuy(1)
          }, 3000);
        }
      } else {
        this.tmeri = null
        this.tmeri1 = null
        const codeList = document.getElementById('qrcode1')
        codeList.innerHTML = ''
        const res = await getToPay(idData)
        this.qrcode1 = new QRCode('qrcode1', {
          width: 140,  // 宽  
          height: 140, // 高
          text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
          render: 'canvas',     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          qrCodeSuccess: function (canvas) {
       
            // 将二维码转为图片
          },
        })

        this.paymentShow1 = 2
        if (res.data.code == 200) {
          this.tmeri1 = setInterval(() => {
            this.successBuy(2)
          }, 3000);
        }
      }
    },
    async successBuy(val) {
      let orderId = this.id
  
      if (val == 1) {
        this.tmeri1 = null
      } else {
        this.tmeri = null
      }
      const res = await getSuccess(orderId)
      this.successData == res.data.data
      if (res.data.data == "NOTPAY") {

      } else if (res.data.data == "SUCCESS") {
        this.tmeri = null
        this.tmeri1 = null
        this.paymentShow1 = 3
        this.paymentShow = 3
        clearInterval(this.tmeri1);
        clearInterval(this.tmeri);
        // this.dialogVisible1 = false
        // this.userEquity()
        this.$router.push('/control').catch((error) => error);
        location.reload();
      }
    },
  }
};
  </script>
  <style lang="less">
.userStyle-cloudSpace {
  /* background-color: #1A1A1A; */
  width: 100%;
  height: 100%;
  /* background-image: url("@/assets/imgs/hybj.png"); */
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  user-select: none;
  /* border: 1px solid #515152; */
  .userStyle-refueling1 {
    // background-color: #1a1a1a;
    width: 100%;
    height: 630px;
    // background-image: url('@/assets/imgs/beijing2.png');
    // background-size: 100% 100%;
    // background-attachment: fixed;
    overflow: hidden;
    user-select: none;
    // border: 1px solid #515152;

    .qrcode1Style {
      border-radius: 11px;
      overflow: hidden;
      border: 10px solid #fff !important;
      //
    }

    #qrcode1 {
      img {
        border: 10px solid #fff !important;
      }
    }
    .close {
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 30px;
      -webkit-app-region: no-drag;
    }

    .close:hover {
      border-radius: 5px;
      cursor: pointer;
      background-color: rgba(232, 17, 35, 0.9);
    }

    .btStyle {
      color: #fff;
      font-size: 18px;
      font-weight: 600px;
    }

    .yuanStyle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 5px;
      background-color: #9598a7;
    }

    .qieStyle1 {
      position: relative;
      margin-top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.16);

      .spaceListStyle {
        width: 140px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 30px;
        background-color: #ffff;
        color: #888888;
        font-size: 16px;
        font-weight: 600;
        margin: 0 10px;
        cursor: pointer;
      }

      .spaceListStyle1 {
        width: 140px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 30px;
        background-color: #8b5ef5;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        margin: 0 10px;
        cursor: pointer;
      }
    }

    .person10 {
      margin-top: 30px;
      width: 90%;
      margin-left: 50px;
      position: relative;
    }

    .personStyle12 {
      width: 260px;
      height: 100px;
      background: linear-gradient(to right, #efecff, #ded1ff);
      // background-image: url('@/assets/imgs/soacebj.png');
      border-radius: 8px;
      text-align: center;
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      padding: 25px 10px 10px 10px;
      cursor: pointer;
      color: #171725;
    }

    .personStyle21 {
      width: 260px;
      height: 100px;
      background-color: #8b5ef5;
      border: solid #8b5ef5 1px;
      border-radius: 8px;
      text-align: center;
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      padding: 25px 10px 10px 10px;
      cursor: pointer;
      color: #fff;
      // margin-top: 5px;
    }

    .personStyle18 {
      width: 1100px;
      //   margin-left: 25px;
      margin-top: 10px;
    }

    .btn {
      width: 190px;
      padding: 6px 0;
      margin: 10px 0 10px 0;
      font-size: 12px;
      color: white;
      font-weight: 600;
      background-color: #8b5ef5;
      border-radius: 8px;
      border: 0px;
      outline: none;
      cursor: pointer;
      overflow: hidden;
      height: 38px;
    }

    .personWorld {
      font-size: 12px;
      color: #9598a7;
    }
  }
}
</style>