import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import Cookies from 'js-cookie';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css';
import './assets/css/reset.css';
import './router/router-config'; // 路由守卫，做动态路由的地方
import { login } from '@/api/login';
Vue.use(ElementUI);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  if (to.name === from.name) {
    location.reload(true);
  }

  const code = to.query.isTrue;
  // if (to.name === 'index') {
  // code = to.query.isTrue || null;
  // }
  // 微信授权登陆
  // return
  if (code == 'true') {
    // if (to.query.isTrue == true) {
    const params = {
      tenantId: '000000',
      openid: to.query.openid,
      grant_type: 'wechat',
      scope: 'all'
    };
    login(params).then((res) => {
      localStorage.setItem('token', res.data.access_token);
      localStorage.setItem('userId', res.data.user_id);
      localStorage.setItem('nickName', res.data.nick_name);
      localStorage.setItem('refresh_token', res.data.refresh_token);
      localStorage.setItem('refreshNum', true);
      localStorage.setItem('avatar', res.data.avatar);
      if (localStorage.getItem('clickStatus') === 1) {
        router.push('/control').catch((error) => error);
        location.reload();
      } else {
        router.push('/').catch((error) => error);
      }
    });
  } else if (code == 'false') {
    localStorage.setItem('code', code);
    localStorage.setItem('openid', to.query.openid);
    router.push('/').catch((error) => error);
    location.reload();
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
