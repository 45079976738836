<template>
  <div class="userStyle">
    <div style="display: flex; align-items: center;">
      <span class="btStyle">个人信息</span>
    </div>
    <div style="display: flex; margin-top: 10px; align-items: center;background-color:#fff; width: 1100px;height: 160px;padding-left:50px;border-top-left-radius: 80px;border-bottom-left-radius: 80px;">
      <div>
        <div style="width: 85px; height: 85px; background-color: #fff;border-radius: 43px; overflow: hidden;">
          <img v-if="this.avatar == '' || this.avatar == undefined||this.avatar == null" src="@/assets/imgs/touxiang2.png" alt="" width="85px" height="85px">
          <img v-if="this.avatar != ''&&this.avatar != undefined&&this.avatar != null" :src="avatar" alt="" width="85px" height="85px">
        </div>
      </div>
      <div>
        <div style="margin-left: 20px;margin-top: 10px;display: flex;">
          <div style="min-width: 220px;height: 30px;display: flex;align-items: center;justify-content: space-between; padding: 2px 8px;background-color: #F1EDF4;border-radius: 15px;">
            <div style="display: flex;align-items: center;">
              <img style="" src="@/assets/imgs/xinxinicheng.png" alt="" width="20px" height="20px">
              <span class="ptStyle">昵称:</span>
              <span class="ptStyle">{{
                            name }}</span>
            </div>
            <div>
              <img style="margin-left: 5px;cursor: pointer;" src="@/assets/imgs/xiugai.png" alt="" width="12px" height="12px" @click="nameData">
            </div>
          </div>
          <div style="margin-left: 20px;width: 220px;height: 30px;background-color: #F1EDF4;border-radius: 15px;display: flex;align-items: center;padding: 2px 8px;">
            <img style="" src="@/assets/imgs/xinxizhanghoa.png" alt="" width="20px" height="20px">
            <span class="ptStyle" style="">手机号/账号:</span>
            <span class="ptStyle">{{ phone }}</span>
            <!-- <img style="margin-left: 5px;cursor: pointer;" src="@/assets/imgs/xiugai.png" alt=""
                            @click="nameData"> -->
          </div>
          <div style="margin-left: 20px;min-width: 220px;height: 30px;background-color: #F1EDF4;border-radius: 15px;display: flex;align-items: center;padding: 2px 8px;">
            <img style="" src="@/assets/imgs/xinxiweixin.png" alt="" width="20px" height="20px">
            <span class="ptStyle" style="">微信号:</span>
            <span class="ptStyle" v-if="nickname == ''">- -</span>
            <span class="ptStyle" v-if="nickname != ''">{{ nickname }}</span>
            <span class="zsStyle" style="margin-left: 5px;" v-if="nickname" @click="unbinding">解绑微信号></span>
          </div>
          <p class="mmStyle" style="background-color: #fff;color: #E33F2D;font-weight: 500;" @click="logOff">注销账户</p>
        </div>
        <div style="display: flex; align-items: center;margin-left: 20px;margin-top: 10px;">
          <!-- <div
                        style="min-width: 154px;height: 30px;background-color: #F1EDF4;border-radius: 15px;display: flex;align-items: center;padding: 2px 8px;">
                        <img style="" src="@/assets/imgs/zhnc.png" alt="" width="16px" height="16px">
                        <span class="ptStyle">账号:</span>
                        <span class="ptStyle">{{
                            phone }}</span>
                    </div> -->
          <div style="min-width: 220px;height: 30px;display: flex;align-items: center;justify-content: space-between; padding: 2px 8px;background-color: #F1EDF4;border-radius: 15px;">
            <div style="display: flex;align-items: center;">
              <img style="" src="@/assets/imgs/xinxituandui.png" alt="" width="20px" height="20px">
              <span class="ptStyle">团队昵称:</span>
              <span class="ptStyle">{{
                            teamNAme }}</span>
            </div>
            <img style="margin-left: 5px;cursor: pointer;" src="@/assets/imgs/xiugai.png" alt="" width="12px" height="12px" @click="nameData1">
          </div>
          <div style="margin-left:20px;min-width: 240px;height: 30px;display: flex;align-items: center;justify-content: space-between; padding: 2px 8px;background-color: #F1EDF4;border-radius: 15px;">
            <div style="display: flex;align-items: center;">
              <img style="" src="@/assets/imgs/xinxituandui.png" alt="" width="20px" height="20px">
              <span class="ptStyle">团队ID:</span>
              <span class="ptStyle">{{
                            teamId }}</span>
            </div>
            <!-- <img style="margin-left: 5px;cursor: pointer;" src="@/assets/imgs/xiugai.png" alt="" width="12px" height="12px" @click="nameData1"> -->
          </div>
          <p class="mmStyle" @click="miMaBtn">设置密码</p>

        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center;margin-top: 15px">
      <span class="btStyle">我的会员</span>
    </div>
    <div style="display: flex; margin-top: 10px; align-items: center;background-color:#fff;  width: 1100px;height: 220px;padding-left:50px;">
      <div style="width: 180px;height: 180px;margin-left: -50px;" v-show="menberType != 0 || voiceMenberType != 0">
        <img src="@/assets/imgs/xinxihuiyuan.png" alt="">
      </div>
      <div style="width: 180px;height: 180px;" v-show="menberType == 0 && voiceMenberType == 0">
        <img src="@/assets/imgs/xinxihuiyuan1.png" alt="">
      </div>
      <div style="width: 180px;height: 180px;text-align: center;margin-top: 15px;margin-left: -15px;">
        <div style="color:#120D0F;font-size:22px;font-weight: 600;">
          数字人直播会员
        </div>
        <!--免费  -->
        <div style="width: 100%;" v-if="menberType == 0">
          <div style="color:#F1D6A4;margin-top: 20px;font-weight: 600;">
            未开通
          </div>
          <p class="mmStyle" style="background-color:#F59A23;margin-top: 20px;margin-left: 40px;" @click="setBtn">
            立即开通</p>
        </div>
        <!-- 普通 -->
        <div style="width: 100%;" v-if="menberType == 1">
          <div style="color:#120D0F;font-size:22px;font-weight: 600;">
            <div>{{ ordinaryWord }}会员</div>
          </div>
          <div style="margin-top: 10px;width: 100%;height: 30px;border-radius: 15px;display: flex;align-items: center;justify-content: space-around;padding: 2px 5px;">
            <span style="color:#FF7D3B">({{ expirationTime }}到期)</span>
            <!-- <span v-show="diffDate <= 7 && diffDate > 0" style="color: #FE2828;font-size: 14px;margin-left: 0px;">剩余{{
                                diffDate
                            }}天到期</span>
            <span v-show="diffDate <= 0" style="color: #FE2828;font-size: 14px;margin-left: 8px;">已到期</span> -->
          </div>
          <p class="mmStyle" style="background-color:#F59A23;margin-top: 20px;margin-left: 40px;" @click="setBtn">
            去续费</p>
        </div>
        <!-- 高级 -->
        <div style="width: 100%;" v-if="menberType == 2">
          <div style="color:#120D0F;font-size:22px;font-weight: 600;">
            <div>{{ seniorWord }}会员</div>
          </div>
          <div style="margin-top: 10px;width: 100%;height: 30px;border-radius: 15px;display: flex;align-items: center;justify-content: space-around;padding: 2px 5px;">
            <span style="color:#FF7D3B">({{ expirationTime }}到期)</span>
            <!-- <span v-show="diffDate <= 7 && diffDate > 0" style="color: #FE2828;font-size: 14px;margin-left: 0px;">剩余{{
                                diffDate
                            }}天到期</span>
            <span v-show="diffDate <= 0" style="color: #FE2828;font-size: 14px;margin-left: 8px;">已到期</span> -->
          </div>
          <p class="mmStyle" style="background-color:#F59A23;margin-top: 20px;margin-left: 40px;" @click="setBtn">
            去续费</p>
        </div>
        <!-- <p class="mmStyle" style="background-color:#F59A23;margin-top: 15px;" @click="setBtn">去续费</p> -->
      </div>
      <div style="width: 1px;height: 114px;background-color: #C2C2C2;margin-left: 20px">

      </div>
      <div style="width: 180px;height: 180px;text-align: center;margin-top: 15px;margin-left: 20px;">
        <div style="color:#120D0F;font-size:22px;font-weight: 600">
          数字语音会员
        </div>
        <div style="width: 100%;" v-if="voiceMenberType == 0">
          <div style="color:#F1D6A4;margin-top: 20px;font-weight: 600;">
            未开通
          </div>
          <p class="mmStyle" style="background-color:#F59A23;margin-top: 20px;margin-left: 40px;" @click="setBtn">
            立即开通</p>
        </div>
        <!-- 普通 -->
        <div style="width: 100%;" v-if="voiceMenberType == 1">
          <div style="color:#120D0F;font-size:22px;font-weight: 600;">
            <div>{{ ordinaryWord }}会员</div>
          </div>
          <div style="margin-top: 10px;width: 100%;height: 30px;border-radius: 15px;display: flex;align-items: center;justify-content: space-around;padding: 2px 5px;">
            <span style="color:#FF7D3B">({{ voiceExpirationTime }}到期)</span>
            <!-- <span v-show="diffDate1 <= 7 && diffDate1 > 0" style="color: #FE2828;font-size: 14px;margin-left: 0px;">剩余{{
                                diffDate1
                            }}天到期</span>
            <span v-show="diffDate1 <= 0" style="color: #FE2828;font-size: 14px;margin-left: 8px;">已到期</span> -->
          </div>
          <p class="mmStyle" style="background-color:#F59A23;margin-top: 20px;margin-left: 40px;" @click="setBtn">
            去续费</p>
        </div>
        <!-- 高级 -->
        <div style="width: 100%;" v-if="voiceMenberType == 2">
          <div style="color:#120D0F;font-size:22px;font-weight: 600;">
            <div>{{ seniorWord }}会员</div>
          </div>
          <div style="margin-top: 10px;width: 100%;height: 30px;border-radius: 15px;display: flex;align-items: center;justify-content: space-around;padding: 2px 5px;">
            <span style="color:#FF7D3B">({{ voiceExpirationTime }}到期)</span>
            <!-- <span v-show="diffDate1 <= 7 && diffDate1 > 0" style="color: #FE2828;font-size: 14px;margin-left: 0px;">剩余{{
                                diffDate1
                            }}天到期</span>
            <span v-show="diffDate1 <= 0" style="color: #FE2828;font-size: 14px;margin-left: 8px;">已到期</span> -->
          </div>
          <p class="mmStyle" style="background-color:#F59A23;margin-top: 20px;margin-left: 40px;" @click="setBtn">
            去续费</p>
        </div>
      </div>
      <div style="width: 180px;height: 180px;position: relative;margin-left: 50px" v-show="menberType != 0 || voiceMenberType != 0">
        <img src="@/assets/imgs/xinxibeijing.png" alt="" />
        <div style="margin-top: 15px;position: absolute;top: 0px;left: 55px;width: 320px;display: flex;align-items: center;">
          <img src="@/assets/imgs/xinxivip.png" alt="" />
          <span style="font-size: 21px;color: #313131;font-weight: 600;">
            &nbsp;数字人直播&nbsp;&nbsp;-&nbsp;&nbsp;
          </span>
          <span style="font-size: 21px;color: #8B5EF5;font-weight: 600;">
            专业会员
          </span>
        </div>
        <div style="margin-top: 15px;position: absolute;bottom:95px;left: 60px;width: 320px;font-size: 16px;color: #313131;font-weight: 600;">
          专享每月1000分钟数字人合成时间、
        </div>
        <div style="margin-top: 15px;position: absolute;bottom: 65px;left: 115px;width: 320px;font-size: 16px;color: #313131;font-weight: 600;">
          大存储云空间等权限
        </div>
        <div style="margin-top: 15px;position: absolute;bottom: 5px;left: 110px;cursor: pointer;" @click="setBtn">
          <img src="@/assets/imgs/shengji.png" alt="" width="160px">
        </div>

      </div>
    </div>
    <div style="display: flex; align-items: center;margin-top: 15px" v-show="menberType != 0 || voiceMenberType != 0">
      <span class="btStyle">专享权益</span>
    </div>
    <div style="display: flex; margin-top: 10px; align-items: center;height: 220px;">
      <!-- -->
      <div style="display: flex;margin-top: 20px;" v-show="menberType != 0">
        <div style="width: 480px; height: 190px;background-color: #F1D6A4;border-radius: 10px;padding: 20px;position: relative;">
          <div style="width: 30px;height: 30px;border-radius: 25px;background-color: #F1D6A4;position: absolute;top: 90px;left: 10px;">

          </div>
          <div style="width: 30px;height: 30px;border-radius: 25px;background-color: #F1D6A4;position: absolute;top: 90px;right: 10px;">

          </div>
          <div style="display: flex;">
            <div style="color: #A47739;font-size: 14px;font-weight: 600;margin-left: 5px;">
              数字人直播
            </div>
          </div>
          <div style="display: flex;margin-top: 10px;width: 100%;justify-content: space-evenly;background-color: #FAF9EE;border-radius: 10px 10px 0px 0px;">
            <div style="display: flex; align-items: center;justify-content: space-evenly;width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxizhbo.png" alt="" width="30px" height="30px">
              <div>

                <div style="font-size: 12px;color: #B0885F;">直播时间</div>
                <div style="font-size: 12px;color: #fff;font-weight: 600;">
                  <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                    <span>不限</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; align-items: center;justify-content: space-evenly;width:150px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxihecheng.png" alt="" width="30px" height="30px">
              <div style="margin-left: 20px">

                <div style="font-size: 12px;color: #B0885F;">数字人合成视频</div>
                <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                  <span>{{ remainingDuration }}分钟</span>
                </div>
              </div>
            </div>

            <div style="display: flex; align-items: center;justify-content: space-evenly; width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxixuniren.png" alt="" width="30px" height="30px">
              <div>
                <div style="font-size: 12px;color: #B0885F;">数字人形象&nbsp;</div>
                <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                  <span>70+</span>
                </div>
              </div>
            </div>

          </div>
          <div style="display: flex;width: 100%;justify-content: space-evenly;background-color: #FAF9EE;border-radius: 0px 0px 10px 10px;">
            <div style="display: flex; align-items: center;justify-content: space-evenly; width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxiyunkongjian.png" alt="" width="30px" height="30px">
              <div>

                <div style="font-size: 12px;color: #B0885F;">云空间&nbsp;&nbsp;&nbsp;</div>
                <div style="font-size: 12px;color: #B0885F;font-weight: 600;">
                  <!-- <span v-if="gb >= 1">{{ gb }}GB </span> -->
                  <!-- <span>{{ useCloudSpaceSize }}MB </span>
                                    <span> / </span> -->
                  <span> {{ cloudSpaceSize }}G</span>
                </div>
              </div>
            </div>
            <div style="display: flex; align-items: center;justify-content: space-evenly;width:150px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxiwenan.png" alt="" width="30px" height="30px">
              <div style="margin-left: 18px">

                <div style="font-size: 12px;color: #B0885F;">智能文案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                  <span v-show="leavrNum != -1">{{ leavrNum }}次</span>
                  <span v-show="leavrNum == -1">次数不限</span>
                  <span></span>
                </div>
              </div>
            </div>
            <div style="display: flex; align-items: center;justify-content: space-evenly; width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxisucai.png" alt="" width="30px" height="30px">
              <div>
                <div style="font-size: 12px;color: #B0885F;">VIP素材&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                  <span>不限</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--  -->
      <div style="display: flex;margin-top: 20px;margin-left: 20px" v-show="voiceMenberType != 0">
        <div style="width: 480px; height: 190px;background-color: #F1D6A4;border-radius: 10px;padding: 20px;position: relative">
          <div style="width: 30px;height: 30px;border-radius: 25px;background-color: #F1D6A4;position: absolute;top: 90px;left: 10px;">

          </div>
          <div style="width: 30px;height: 30px;border-radius: 25px;background-color: #F1D6A4;position: absolute;top: 90px;right: 10px;">

          </div>
          <div style="display: flex;">
            <div style="color: #A47739;font-size: 14px;font-weight: 600;margin-left: 5px;">
              数字语音
            </div>
          </div>
          <div style="display: flex;margin-top: 10px;width: 100%;justify-content: space-evenly;background-color: #FAF9EE;border-radius: 10px 10px 0px 0px;">
            <div style="display: flex; align-items: center;justify-content: space-evenly;width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xiwei.png" alt="" width="30px" height="30px">
              <div>

                <div style="font-size: 12px;color: #B0885F;">直播时间</div>
                <div style="font-size: 12px;color: #B0885F;font-weight: 600;">
                  <span>不限</span>
                </div>
              </div>
            </div>

            <div style="display: flex; align-items: center;justify-content: space-evenly;width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxizhbo.png" alt="" width="30px" height="30px">
              <div>

                <div style="font-size: 12px;color: #B0885F;">直播席位</div>
                <div style="font-size: 12px;color: #B0885F;font-weight: 600;">
                  <span>{{ voiceSeatSize }}位</span>
                </div>
              </div>
            </div>
            <div style="display: flex; align-items: center;justify-content: space-evenly; width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxishengin.png" alt="" width="30px" height="30px">
              <div>
                <div style="font-size: 12px;color: #B0885F;">语音音色&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                  <span>100+</span>
                </div>
              </div>
            </div>

          </div>
          <div style="display: flex;width: 100%;justify-content: space-evenly;background-color: #FAF9EE;border-radius: 0px 0px 10px 10px;">
            <div style="display: flex; align-items: center;justify-content: space-evenly; width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxiyunkongjian.png" alt="" width="30px" height="30px">
              <div>

                <div style="font-size: 12px;color: #B0885F;">云空间&nbsp;&nbsp;&nbsp;</div>
                <div style="font-size: 12px;color: #B0885F;font-weight: 600;">
                  <!-- <span v-if="gb >= 1">{{ gb }}GB </span> -->
                  <!-- v-else -->
                  <!-- <span>{{ useCloudSpaceSize }}MB </span>
                                    <span> / </span> -->
                  <span> {{ cloudSpaceSize }}G</span>
                </div>
              </div>
            </div>
            <div style="display: flex; align-items: center;justify-content: space-evenly;width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxiwenan.png" alt="" width="30px" height="30px">
              <div>

                <div style="font-size: 12px;color: #B0885F;">智能文案</div>
                <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                  <span v-show="leavrNum != -1">{{ leavrNum }}次</span>
                  <span v-show="leavrNum == -1">次数不限</span>
                  <span></span>
                </div>
              </div>
            </div>
            <div style="display: flex; align-items: center;justify-content: space-evenly; width:140px;height: 60px;  border-radius: 8px;">
              <img style="cursor: pointer;" src="@/assets/imgs/xinxisucai.png" alt="" width="30px" height="30px">
              <div>
                <div style="font-size: 12px;color: #B0885F;">VIP素材&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div style="font-size: 14px;color: #B0885F;font-weight: 600;">
                  <span>不限</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div style="width: 50px ;height: 100px;">

    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="400px" :before-close="handleClose">
      <el-form autocomplete="on" :model="loginForm02" ref="loginFormRef01" class="fromStyle">
        <el-form-item prop="name">
          <el-input name="name" type="text" v-model="loginForm02.name" placeholder="请输入昵称" />
        </el-form-item>
        <!-- <div v-show="verify == true" style="font-size: 12px; color: #F56C6C;margin-top: -20px;">长度在 2 到 10 个字符</div> -->
        <div class="login-btn">
          <el-button class="btn3" type="primary" @click="nextPassword">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible1" width="400px" :before-close="handleClose1">
      <el-form autocomplete="on" :model="loginForm03" ref="loginFormRef01" class="fromStyle">
        <el-form-item prop="name">
          <el-input name="name" type="text" v-model="loginForm03.name" placeholder="请输入昵称" />
        </el-form-item>
        <!-- <div v-show="verify == true" style="font-size: 12px; color: #F56C6C;margin-top: -20px;">长度在 2 到 10 个字符</div> -->
        <div class="login-btn">
          <el-button class="btn3" type="primary" @click="nextPassword1">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible2" width="400px" :before-close="handleClose2">
      <el-form class="login-form" autocomplete="on" :model="loginForm4" ref="loginFormRef1" label-position="left">
        <div>
          <el-form-item prop="phone" style="position: relative;">
            <el-input name="phone" type="text" v-model="loginForm4.findPhone" autocomplete="on" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item prop="codeSms" style="position: relative;">
            <el-input name="codeSms" v-model="loginForm4.findCodeSms" autocomplete="on" placeholder="请输入短信验证码"></el-input>
            <span class="show-pwd">
              <span :class="grey ? 'greyStart' : 'greyEnd'" style=" font-size: 11px;" :disabled="isSend" @click="sendBtn01()">{{ codeName }}</span>
            </span>
          </el-form-item>
          <el-form-item prop="phone" style="position: relative;">
            <el-input name="phone" type="text" v-model="loginForm4.findPassword" autocomplete="on" placeholder="请输入密码" />
          </el-form-item>
          <el-form-item prop="phone" style="position: relative;">
            <el-input name="phone" type="text" v-model="loginForm4.findPassword1" autocomplete="on" placeholder="请输入密码" />
          </el-form-item>
        </div>
        <div class="login-btn">
          <button type="button" class="btn" @click="handleLogin2">确定</button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="注销账户" :visible.sync="dialogVisible3" width="480px" :before-close="handleClose3">
      <div style="color: #171725;font-size: 14px;">
        尊敬的{{name}}用户，您的账号{{phone}}当前加入了{{ teamList.length }}个团队，{{userNum}}个项目
      </div>
      <div style="color: #171725;font-size: 14px;margin-top: 40px">
        注销账号后您将失去这些数据的访问权限，如果您需要在注销前将重要
      </div>
      <div style="color: #171725;font-size: 14px;margin-top: 5px">
        数据进行下载备份或迁移， 请完成后再执行账号注销操作。
      </div>
      <div style="width: 437px;height: 40px;line-height: 40px; background-color: #FFFAE8; color: #D26900;font-size: 14px;margin-top: 30px;border-radius: 4px;padding-left: 10px;">
        账号注销后不可恢复，请谨慎操作！
      </div>
      <div style="display: flex;width: 100%;justify-content: space-evenly;">
        <div class="login-btn">
          <button type="button" class="btn" style="width: 140px;height: 48px;background-color: #fff;border: 1px solid #E9E9E9;color: #120D0F;" @click="notYet">暂不注销</button>
        </div>
        <div class="login-btn">
          <button type="button" class="btn" style="width: 140px;height: 48px;background-color: #E33F2D;" @click="loggedOut">立即注销</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { informationId, teamUserEquity, getDictList, getInfo, changeTeamName, getUnbinding, logout, getCount } from "@/api/information"
import { getNext } from "@/api/login"
import { send } from "@/api/login"
import { getUserTeam } from '@/api/team';
import Cookies from 'js-cookie'
import md5 from 'js-md5';
export default {
  name: 'centre',
  data() {
    return {
      informationData: {
        expirationTime: "",
        menberType: "",
        name: "",
        nickname: "",
        phone: "",
        remainingDuration: ""
      },
      cloudSpaceSize: "",
      gb: "",
      mb: "",
      isAllow: "",
      times: "",
      name: "",
      teamNAme: "",
      phone: "",
      nickname: "",
      remainingDuration: "",
      voiceSeatSize: "",
      leavrNum: "",
      cloudSpaceSize: "",
      useCloudSpaceSize: "",
      menberType: "",
      voiceMenberType: "",
      expirationTime: "",
      voiceExpirationTime: "",
      freeWord: "",
      ordinaryWord: "",
      seniorWord: "",
      diffDate: "",
      diffDate1: "",
      title: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      loginForm02: {
        name: ""
      },
      loginForm03: {
        name: ""
      },
      loginForm4: {
        findPhone: "",
        findCodeSms: "",
        findPassword: "",
        findPassword1: ""
      },
      id: "",
      isShow3: "",
      isSend: false,
      codeName: "获取验证码",
      totalTime: '60',
      grey: true,
      timer: '',
      avatar: localStorage.getItem('avatar'),
      dialogVisible3: false,
      userNum: "",
      teamList: [],
      teamId:localStorage.getItem("teamId"),
    };
  },
  created() {
    this.informationEvn()
    this.userEquity()
    this.questionType()
    this.getCountD();
    this.teamEvn();
  },
  methods: {
    async teamEvn() {
      const res = await getUserTeam();
      this.teamList = res.data.data;
    },
    async getCountD() {
      const res = await getCount(localStorage.getItem("teamId"));
      this.userNum = res.data.data.userNum;
    },
    notYet() {
      this.dialogVisible3 = false
    },
    logOff() {
      this.dialogVisible3 = true
    },
    async loggedOut() {
      const res = await logout(localStorage.getItem("userId"))
      this.dialogVisible3 = false
      localStorage.clear()
      this.$router.push('/').catch((error) => error);
      location.reload();
    },
    handleClose(done) {
      done();
    },
    handleClose1(done) {
      done();
    },
    handleClose2(done) {
      done();
    },
    handleClose3(done) {
      done();
    },

    async handleLogin2() {
      let passwordData = md5(this.loginForm4.findPassword)
      let passwordData1 = md5(this.loginForm4.findPassword1)
      let a = {
        phone: this.loginForm4.findPhone,
        id: this.id,
        value: this.loginForm4.findCodeSms,
        newPassword: passwordData,
        newPassword1: passwordData1
      }
      const res = await getNext(this.loginForm4.findPhone, this.id, this.loginForm4.findCodeSms, passwordData, passwordData1)
      if (res.data.code == 200) {
        this.$message({
          type: 'success',
          message: '重置成功'
        });
        this.dialogVisible2 = false
      } else {
        this.$message({
          type: 'error',
          message: '验证码错误！'
        });
      }

    },
    async sendBtn01() {
      // if (loginForm1.value.phone.length > 11 || loginForm1.value.phone.length < 11) {
      //   isShow3.value = true
      //   return
      // } else {
      this.isShow3 = false
      if (this.isSend) return
      this.isSend = true
      this.codeName = this.totalTime + 's后重新发送'
      this.grey = false
      this.timer = setInterval(() => {
        this.totalTime--
        this.codeName = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {
          clearInterval(this.timer)
          this.grey = true
          this.codeName = '重新获取验证码'
          this.totalTime = 60
          this.isSend = false
        }
      }, 1000)
      const res = await send(this.loginForm4.findPhone)
      this.id = res.data.data.id
    },
    miMaBtn() {
      this.dialogVisible2 = true
    },
    async nextPassword() {
      let dataS = {
        id: localStorage.getItem("userId"),
        name: this.loginForm02.name
      }
      const res = await getInfo(dataS)
      this.dialogVisible = false
      localStorage.setItem("nickName", this.loginForm02.name);
      this.informationEvn()
      location.reload();
    },
    async nextPassword1() {
      let dataS = {
        id: localStorage.getItem("teamId"),
        teamName: this.loginForm03.name
      }
      const res = await changeTeamName(dataS)
      localStorage.setItem('teamName', this.loginForm03.name)
      this.dialogVisible1 = false
      this.informationEvn()
      location.reload();
    },
    async informationEvn() {
  
      const res = await informationId(localStorage.getItem("userId"), localStorage.getItem("teamId"))
      this.name = res.data.data.name
      this.teamNAme = res.data.data.teamNAme
      this.avatar = res.data.data.avatar
      this.phone = res.data.data.phone
      this.nickname = res.data.data.nickname
      this.menberType = res.data.data.menberType
      this.voiceMenberType = res.data.data.voiceMenberType
      this.expirationTime = res.data.data.expirationTime.split(" ")[0]
      this.voiceExpirationTime = res.data.data.voiceExpirationTime.split(" ")[0]
      this.dateDiffer(res.data.data.expirationTime)
      this.dateDiffer1(res.data.data.voiceExpirationTime)
  
    },
    async userEquity() {
      let a = {
        teamId: localStorage.getItem("teamId"),
        userId: localStorage.getItem("userId")
      }
      const res = await teamUserEquity(a)
      this.isAllow = res.data.data.isAllow
      this.remainingDuration = parseInt(res.data.data.remainingDuration / 60)
      this.voiceRemainingDuration = res.data.data.voiceRemainingDuration / 60
      this.leavrNum = res.data.data.leavrNum
      this.cloudSpaceSize = res.data.data.cloudSpaceSize
      this.useCloudSpaceSize = (res.data.data.useCloudSpaceSize / 1024 / 1024).toFixed(2)
      this.voiceSeatSize = res.data.data.voiceSeatSize
    
    },
    async questionType() {
      let data = {
        code: 'vip_type'
      }
      const res = await getDictList(data)
     
      this.freeWord = res.data.data[0].children[0].dictValue
      this.ordinaryWord = res.data.data[0].children[1].dictValue
      this.seniorWord = res.data.data[0].children[2].dictValue
    },
    dateDiffer(Date_end) {
     
      //date1结束时间
      let date1 = new Date(Date_end);
      //date2当前时间
      let date2 = new Date();
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
      this.diffDate = diff / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
   
    },
    dateDiffer1(Date_end) {
    
      //date1结束时间
      let date1 = new Date(Date_end);
      //date2当前时间
      let date2 = new Date();
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
      this.diffDate1 = diff / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
     
    },
    nameData() {
      this.dialogVisible = true

    },
    nameData1() {
      this.dialogVisible1 = true
    },
    async unbinding() {
      const res = await getUnbinding(localStorage.getItem("userId"))
      this.informationEvn()
    },
    setBtn() {
      this.$router.push("/member").catch(error => error);
    },
    invoice() {

    },
    setBtn1() {

    },
  }
}
</script>
<style lang="less">
.userStyle {
  /* background-color: #737375; */
  min-width: 800px;
  height: 100%;
  overflow-y: auto;
  /* border-radius: 20px; */
  /* padding: 30px 30px; */
  /* user-select: none; */
  .btStyle {
    color: #444444;
    font-size: 16px;
    font-weight: 600;
  }
}

.ptStyle {
  color: #444444;
  font-size: 14px;
  margin-left: 3px;
}

.zsStyle {
  color: #b291ff;
  font-size: 12px;
  cursor: pointer;
}

.zsStyle2 {
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.greyStart {
  color: #8b5ef5;
  font-size: 11px;
}

.greyEnd {
  color: #6f757f;
  font-size: 11px;
}

.mmStyle {
  width: 88px;
  height: 32px;
  background-color: #8b5ef5;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600px;
  line-height: 32px;
  border-radius: 16px;
  margin-left: 20px;
  font-weight: 600;
  cursor: pointer;
}

.mmStyle1 {
  width: 88px;
  height: 32px;
  background-color: #f4b572;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600px;
  line-height: 32px;
  border-radius: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.mmStyle:hover {
  background-color: #743afe;
}
</style>