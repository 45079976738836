<template>
  <div id="current-box">
    <div class="top-box">
      <el-form
        style="margin-left: 85px;"
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="订单名称">
          <el-input
            v-model="formInline.orderName"
            placeholder="请输入关键词进行搜索"
          >   <template #prefix>
              <i
                style="margin-left: 10px"
                class="el-icon-search"
                @click="handelSearch"
              ></i>
            </template></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            v-model="formInline.orderCode"
            placeholder="请输入关键词进行搜索"
          >   <template #prefix>
              <i
                style="margin-left: 10px"
                class="el-icon-search"
                @click="handelSearch"
              ></i>
            </template></el-input>
        </el-form-item>

        <el-form-item style="float: right; margin-right: 20px">
          <el-button
            @click="dialogVisible3 = true"
            style="
              width: 110px;
              height: 38px;
              border-radius: 6px 6px 6px 6px;
              border: 1px solid #8b5ef5;
              color: #8b5ef5;
            "
            >
            <div style="display:flex;justify-content:center;">
              <div>
                <img style="width:16px;height:16px;position:relative;margin-right:5px;" src="@/assets/imgs/kpjl.png" alt="">
              </div>
              <div>
                开票记录
              </div>
            </div>
            </el-button
          >
        </el-form-item>
        <el-form-item style="float: right; margin-right: 20px">
          <el-button
            @click="dialogVisible1 = true"
            style="
              width: 110px;
              height: 38px;
              background: #8b5ef5;
              border-radius: 6px 6px 6px 6px;
              color: #ffffff;
            "
            >
            <div style="display:flex;justify-content:center;">
              <div>
                <img style="width:16px;height:16px;position:relative;margin-right:5px;" src="@/assets/imgs/sqkp.png" alt="">
              </div>
              <div>
                申请开票
              </div>
            </div>
            </el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="main-box">
      <ul class="item-box">
        <li v-for="(item, index) in tableData" :key="index">
          <div class="item-top">
            <div class="left">
              <span class="status status1" v-if="item.status === 1"></span>
              <span class="status status2" v-else-if="item.status === 2"></span>
              <span class="status status3" v-else-if="item.status === 3"></span>
              <span class="status status4" v-else></span>
              <span>订单号:</span><span>{{ item.orderCode }}</span>
            </div>
            <div class="right">
              <span class="status status1" v-if="item.status === 1">待支付</span>
              <span class="status status2" v-else-if="item.status === 2">已取消</span>
              <span class="status status3" v-else-if="item.status === 3">已支付</span>
              <span class="status status4" v-else>已退款</span>
            </div>
          </div>
          <div class="item-main">
            <div class="left">订单名称</div>
          </div>
          <div class="item-total">
            <div class="left">
              <span>{{ item.orderName }}</span>
            </div>
            <div class="right">
              <span style="margin-left: 10px"
                ><span class="icon">￥</span
                ><span class="num">{{ item.totalprice }}</span></span
              >
            </div>
          </div>
          <div class="item-pay">
            <div class="left">
              <span>创建时间:</span><span>{{ item.createTime }}</span>
            </div>
            <div class="right">
              <span>支付时间:</span
              ><span style="margin-left: 10px">{{ item.payTime }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 申请开票弹窗 -->
    <el-dialog title="选择开票订单" :visible.sync="dialogVisible1" width="830px">
      <Invoice @handel="handelVisible1" />
    </el-dialog>

    <!-- 填写发票信息弹窗 -->
    <el-dialog title="填写发票信息" :visible.sync="dialogVisible2" width="830px">
      <FillInvoice @handel3="handelVisible3" :money="money" :orderIds="orderIds" />
    </el-dialog>

    <!-- 开票记录弹窗 -->
    <el-dialog title="开票记录" :visible.sync="dialogVisible3" width="830px">
      <InvoicingRecords @handel4="handelVisible4" @handel5="handelVisible5" />
    </el-dialog>

    <!-- 退票弹窗 -->
    <el-dialog title="退票" :visible.sync="dialogVisible4" width="830px">
      <ReturnTicket @handel6="handelVisible6" :id="id" />
    </el-dialog>

    <!-- 发票详情弹窗 -->
    <el-dialog title="发票详情" :visible.sync="dialogVisible5" width="830px">
      <InvoiceDetails :id="id" />
    </el-dialog>
  </div>
</template>

<script>
import { getUserOrders } from "@/api/invoice";
import Invoice from "../goods/invoice.vue";
import FillInvoice from "../goods/fillInvoice.vue";
import InvoicingRecords from "../goods/invoicingRecords.vue";
import ReturnTicket from "../goods/returnTicket.vue";
import InvoiceDetails from "../goods/invoiceDetails.vue";
import Cookies from 'js-cookie'
export default {
  name: "goods",
  components: {
    Invoice,
    FillInvoice,
    InvoicingRecords,
    ReturnTicket,
    InvoiceDetails,
  },
  data() {
    return {
      tableData: [],
      formInline: {
        current: 1,
        size: 10,
      },
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      money: "",
      orderIds: "",
      id: "",
      teamId: "",
    };
  },
  created() {
    this.teamId = localStorage.getItem("teamId");
    this.getList();
  },
  methods: {
    handelSearch(){
      const params = {
        teamId: this.teamId,
        orderName: this.formInline.orderName,
        orderCode: this.formInline.orderCode,
      };
      getUserOrders(params).then((res) => {
       
        this.tableData = res.data.data.records;
      });
    },
    getList() {
      const params = {
        teamId: this.teamId,
      };
      getUserOrders(params).then((res) => {
    
        this.tableData = res.data.data.records;
      });
    },
    keyUp() {
      this.getList();
    },
    handelVisible1(row) {
  
      this.money = row.money;
      this.orderIds = row.orderIds;
      this.dialogVisible1 = false;
      this.dialogVisible2 = true;
    },
    handelVisible3() {
      this.dialogVisible2 = false;
      this.dialogVisible3 = true;
    },
    handelVisible4(row) {
      this.id = row.id;
      this.dialogVisible3 = false;
      this.dialogVisible4 = true;
    },
    handelVisible5(row) {
  
      this.id = row.id;
      this.dialogVisible3 = false;
      this.dialogVisible5 = true;
    },
    handelVisible6() {
      this.dialogVisible4 = false;
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background: linear-gradient(to bottom, #F2ECFF, #ffffff);
}
#current-box {
  //   background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  .top-box {
    margin-left: 20px;
    height: 50px;
    ::v-deep .el-input--prefix .el-input__inner {
      width: 350px;
      height: 38px;
      border-radius: 19px 19px 19px 19px;
    }
  }

  .main-box {
    // background-color:pink;
    width: 100%;
    // height: 90%;
    height: calc(100vh - 190px);
    overflow-y: scroll;
    margin-left:87px;

    .item-box {
      width: 90%;
      // height:90%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // margin: 0 auto;

      li {
        width: 45%;
        height: 200px;
        background-color: #ffffff;
        margin: 20px;
        padding: 20px;
        border-radius: 10px;

        .item-top {
          width: 100%;
          height: 20%;
          border-bottom: 1px solid #f2f2f2;

          .left {
            float: left;
            font-size: 18px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #101620;
            .status {
              width: 6px;
              height: 6px;
              border-radius: 10px;
              display: inline-block;
              margin-right: 11px;
              position: relative;
              top: -3px;
            }
            .status1 {
              background: #f78b1d;
            }
            .status2 {
              background: #ccc;
            }
            .status3 {
              background: #00ba00;
            }
            .status4 {
              background: #0f1114;
            }
          }

          .right {
            float: right;
            .status {
              font-size: 18px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
            }
            .status1 {
              color: #f78b1d;
            }
            .status2 {
              color: #ccc;
            }
            .status3 {
              color: #00ba00;
            }
            .status4 {
              color: #0f1114;
            }
          }
        }

        .item-main {
          width: 100%;
          height: 20%;
          margin-top: 20px;

          .left {
            float: left;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #171725;
          }

          .right {
            float: right;
            font-size: 24px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ee5446;
          }
        }

        .item-total {
          width: 100%;
          height: 20%;

          .left {
            float: left;
            margin-top: 10px;
            font-size: 14px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #171725;
          }

          .right {
            float: right;
            .icon {
              font-size: 16px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ee5446;
            }
            .num {
              font-size: 24px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ee5446;
            }
          }
        }
        .item-pay {
          width: 100%;
          height: 20%;
          margin-top: 20px;

          .left {
            float: left;
            font-size: 14px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #656771;
          }

          .right {
            float: right;
            font-size: 14px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #656771;
          }
        }
      }
    }
  }
}
</style>
