<template>
  <div class="projectDetails">
    <div style="display: flex">
      <div style="width: 330px; height: 100px">
        <div style="width: 330px; height: 132px; background-color: #fff; box-shadow: 1px 1px 2px rgb(196, 196, 196); padding: 15px">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div style="color: #171725; font-size: 16px; font-weight: 600">
              {{ liveName }}
            </div>
            <div style="color: #696974; font-size: 12px; margin-top: 10px">创建时间：{{ createTime }}</div>
          </div>
          <div style="color: #696974; font-size: 12px; margin-top: 10px">
            {{ liveDescribe }}
          </div>
        </div>
        <div style="width: 330px; height: 558px; background-color: #fff; box-shadow: 1px 1px 2px rgb(196, 196, 196); padding: 15px; margin-top: 20px; display: flex; flex-wrap: wrap; align-content: flex-start">
          <div class="user-item" v-for="(item, index) in liveProjectPersonnelVOS">
            <div class="item-box">
              <div class="avatar">
                <img v-if="item.avatar" :src="item.avatar" alt="" width="60px" height="60px" />
                <img v-else src="@/assets/imgs/touxiang3.png" alt="" width="60px" height="60px" />
              </div>
              <div v-if="item.isAdmin == 1" class="btn-admin">管理员</div>
              <div v-else class="btn-admin1"></div>
              <div class="name">{{ item.userName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="flex: 1; height: 710px; margin-left: 20px; background-color: #fff; box-shadow: 1px 1px 2px rgb(196, 196, 196); padding: 15px">
        <div style="color: #171725; font-size: 16px; font-weight: 600">封面</div>
        <div style="margin-top: 10px">
          <!-- <img :src="coverUrl" alt="" width="67px" height="120px" /> -->
          <div v-show="coverDirection == 1" style="width: 180px; height: 101px; align-items: center; position: relative; display: flex; align-items: center">
            <img :src="coverUrl" alt="" style="max-width: 100%; max-height: 100%; display: block; margin: auto" />
          </div>
          <div style="width: 67.5px; height: 125px; align-items: center; position: relative; display: flex; align-items: center; overflow: hidden" v-show="coverDirection == 2">
            <img :src="coverUrl" alt="" style="width: 100%" />
          </div>
        </div>
        <div style="color: #171725; font-size: 16px; font-weight: 600; margin-top: 10px">合成历史</div>
        <div style="display: flex">
          <div v-show="contentWidth1 < -100" @click="arrowHandelL()" class="jiantou-left">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div id="synthesis" class="synthesis">
            <div :style="moveStyle" id="synthesis-item" class="synthesis-item">
              <div class="item" v-for="(item, index) in compoundList" :key="index">
                <div class="item-box">
                  <video id="myVideo" ref="videoPlayer" :src="item.liveUrl" width="100px" height="150px"></video>
                  <div class="icon">
                    <img style="cursor: pointer" @click="viewVideo(item)" class="view" src="@/assets/imgs/view-Video.png" alt="" />
                    <img style="cursor: pointer" @click="downloadHandel(item)" class="download" src="@/assets/imgs/download-Video.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="isShow" @click="arrowHandelR()" class="jiantou-right">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>

        <el-dialog title="预览" :visible.sync="dialogVisible" width="40%">
          <div class="dialog-box">
            <div class="video">
              <video :src="dialogUrl" width="100%" height="100%" controls></video>
            </div>
          </div>
        </el-dialog>

        <div style="color: #171725; font-size: 16px; font-weight: 600; margin-top: 10px">编辑历史</div>
        <div class="edit-box">
          <div class="edit-item" v-for="(item, index) in liveEditList" :key="index">
            <div class="date">{{ item.editTime }}</div>
            <div class="dot-box">
              <div class="dot"></div>
              <div class="line"></div>
            </div>
            <div class="right">
              <div class="icon">
                <img src="@/assets/imgs/date.png" alt="" />
              </div>
              <div class="time">{{ item.editTimeDate }}</div>
              <div class="box">
                <div class="avatar">
                  <img v-if="item.avatar" :src="item.avatar" alt="" width="20px" height="20px" />
                  <img v-else src="@/assets/imgs/touxiang3.png" alt="" width="20px" height="20px" />
                </div>
                <div class="name">{{ item.userName }}</div>
                <div class="text">编辑了</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompoundList, getLiveEdit } from '@/api/project';
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      id: '',
      liveName: '',
      liveDescribe: '',
      createTime: '',
      coverUrl: '',
      coverDirection: '',
      liveProjectPersonnelVOS: [],
      compoundList: [],
      liveEditList: [],
      dialogVisible: false,
      dialogUrl: '',
      contentWidth1: '',
      synthesisWidth: '',
      itemWidth: 0,
      isShow: true
    };
  },
  computed: {
    moveStyle() {
      return {
        left: `${this.contentWidth1}px`
      };
    }
  },
  created() {

    this.id = this.$route.query.id;
    this.liveName = this.$route.query.liveName;
    this.liveDescribe = this.$route.query.liveDescribe;
    this.createTime = this.$route.query.createTime.substring(0, 11);
    this.coverUrl = this.$route.query.coverUrl;

    this.coverDirection = this.$route.query.coverDirection;
    this.liveProjectPersonnelVOS = this.$route.query.liveProjectPersonnelVOS;
  },
  mounted() {
    setTimeout(() => {
      this.synthesisWidth = document.getElementById('synthesis').offsetWidth;
      this.itemWidth = document.getElementById('synthesis-item').offsetWidth;
      if (this.synthesisWidth > this.itemWidth) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    }, 500);
    // this.itemWidth = document.getElementById("synthesis-item").offsetWidth;

    this.getCompoundListApi();
    this.getLiveEditApi();
  },
  methods: {
    getCompoundListApi() {
      // 直播合成历史列表查询
      const params1 = {
        liveId: this.id
      };
      getCompoundList(params1).then((res) => {

        this.compoundList = res.data.data.records;
        this.compoundList.forEach((item) => {
          item.liveUrl = window.localStorage.getItem('OssPrefix') + '/' + item.liveUrl;
        });
      });
    },
    getLiveEditApi() {
      // 获取直播编辑记录
      const params3 = {
        liveId: this.id,
        current: 1,
        size: 10
      };
      getLiveEdit(params3).then((res) => {

        this.liveEditList = res.data.data.records;
        this.liveEditList.forEach((item) => {
          item.editTimeDate = item.editTime;
          item.editTime = item.editTime.substring(0, 11);
          item.editTimeDate = item.editTimeDate.substring(11, 20);
        });
      });
    },
    viewVideo(item) {
      this.dialogVisible = true;
      this.dialogUrl = item.liveUrl;
    },
    downloadHandel(item) {
      window.open(item.liveUrl);
    },
    arrowHandelL() {
      this.contentWidth1 = this.contentWidth1 + 115;
      this.itemWidth = this.itemWidth + 115;
      if (this.itemWidth > this.synthesisWidth) {
        this.isShow = true;
      }
    },
    arrowHandelR() {
      this.contentWidth1 = this.contentWidth1 - 115;
      // 父亲盒子
      // this.synthesisWidth
      this.itemWidth = this.itemWidth - 115;
      if (this.itemWidth < this.synthesisWidth) {
        this.isShow = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog__header {
  background: linear-gradient(to bottom, #f2ecff, #ffffff);
}
.dialog-box {
  width: 100%;
  height: 450px;
  .video {
    width: 100%;
    height: 100%;
  }
}
.user-item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .item-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .avatar {
      border-radius: 20px;
      img {
        border-radius: 50px;
      }
    }
    .btn-admin {
      width: 52px;
      height: 17px;
      background: #56d48f;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #3ccd7e;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      position: relative;
      top: -20px;
    }
    .btn-admin1 {
      width: 52px;
      height: 17px;
    }
    .name {
      overflow: hidden; /* 确保超出容器的文本被裁剪 */
      white-space: nowrap; /* 确保文本在一行内显示 */
      text-overflow: ellipsis;
      position: relative;
      top: -10px;
    }
  }
}
.jiantou-left {
  width: 26px;
  height: 26px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  position: relative;
  top: 75px;
  left: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
}
.jiantou-right {
  width: 26px;
  height: 26px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  position: relative;
  top: 75px;
  left: -10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.synthesis {
  margin-top: 10px;
  height: 150px;
  width: 500px;
  position: relative;
  overflow: hidden;

  .synthesis-item {
    display: flex;
    position: absolute;
    .item {
      width: 100px;
      height: 150px;
      margin: 0 10px;
      background: #ccc;
      .item-box {
        width: 100px;
        height: 150px;
        position: relative;
        .icon {
          position: absolute;
          bottom: 0px;
          right: 0px;
          .view {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
          .download {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
.edit-box {
  margin-top: 10px;
  width: 99%;
  height: 300px;

  overflow-x: scroll;
  .edit-item {
    height: 60px;
    width: 90%;
    // background-color: #f8fafb;
    display: flex;
    align-items: center;
    padding-left: 10px;
    .date {
      width: 120px;
    }
    .dot-box {
      position: relative;
      top: 25px;
      .dot {
        width: 5px;
        height: 5px;
        background: #eaedf0;
        border-radius: 25px;
      }
      .line {
        width: 1px;
        height: 55px;
        background: #eaedf0;
        position: relative;
        left: 2px;
      }
    }
    .right {
      flex: 1;
      margin-left: 50px;
      display: flex;
      background-color: #f8fafb;
      width: 200px;
      height: 33px;
      align-items: center;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #eaedf0;
      .icon {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        margin-right: 10px;
        img {
          width: 15px;
          height: 15px;
        }
      }
      .time {
        padding-top: 5px;
        height: 25px;
        padding-right: 15px;
        // background-color:red;
        border-right: 1px solid #eaedf0;
      }
      .box {
        display: flex;
        margin-left: 20px;
        .avatar {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          img {
            border-radius: 20px;
          }
        }
        .name {
          margin-left: 10px;
          color: #8b5ef5;
        }
        .text {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
