<template>
  <div id="current-box">
    <div>
      <el-form
        style="height: 40px"
        ref="form"
        :model="form"
        label-width="20px"
        class="projectTop"
      >
        <div style="color: #444444; font-size: 16px; font-weight: 600; margin-top: 10px">
          共{{ tablePage.total }}个项目
        </div>
        <el-form-item label="">
          <el-input
            style="width: 300px"
            v-model="form.liveName"
            placeholder="输入项目名称进行搜索"
          >
            <template #prefix>
              <i
                style="margin-left: 10px"
                class="el-icon-search"
                @click="handelSearch"
              ></i>
            </template>
          </el-input>
          <el-input
            v-show="false"
            style="width: 300px"
            suffix-icon="el-icon-search"
            placeholder="输入关键词进行搜索"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="main-box">
        <div class="box">
          <div
            v-for="(item, index) in teamList"
            :key="item.index"
            class="projectManagement"
          >
            <div
              style="
                width: 100%;
                height: 260px;
                align-items: center;
                position: relative;
                display: flex;
                align-items: center;
              "
              v-show="item.coverDirection == 1"
            >
              <img
                :src="item.coverUrl"
                alt=""
                style="max-width: 100%; max-height: 100%; display: block; margin: auto"
              />
            </div>
            <div
              @click="projectEvn(item)"
              style="
                width: 100%;
                height: 260px;
                align-items: center;
                position: relative;
                display: flex;
                align-items: center;
                overflow: hidden;
              "
              v-show="item.coverDirection == 2"
            >
              <img :src="item.coverUrl" alt="" style="width: 100%" />
            </div>
            <div class="wordManagement">
              <div class="nameManagement" style="">
                <div v-if="isTeamAdmin === '1'">
                  <div
                    style="font-size: 14px; font-weight: 600; cursor: pointer"
                    @click.stop="rename(1, item.id, item)"
                  >
                    <i class="el-icon-edit-outline"></i>
                    {{ item.liveName }}
                  </div>
                </div>
                <div v-else>
                  <div v-for="(vtem, index) in item.liveProjectPersonnelVOS" :key="index">
                    <div v-if="vtem.userId === userId">
                      <div v-if="vtem.power === '1'">
                        <div
                          style="font-size: 14px; font-weight: 600; cursor: pointer"
                          @click.stop="rename(1, item.id, item)"
                        >
                          <i class="el-icon-edit-outline"></i>
                          {{ item.liveName }}
                        </div>
                      </div>
                      <div v-else-if="vtem.power === '0'">
                        <div
                          style="font-size: 14px; font-weight: 600; cursor: pointer"
                          @click.stop="rename(1, item.id, item)"
                        >
                          <i class="el-icon-edit-outline"></i>
                          {{ item.liveName }}
                        </div>
                      </div>
                      <div v-else>
                        <div style="font-size: 14px; font-weight: 600; cursor: pointer">
                          {{ item.liveName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="color: #92929d; cursor: pointer" @click.stop="showMore(item)">
                  <i class="el-icon-more"></i>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 30px;
                  display: flex;
                  position: absolute;
                  top: 225px;
                "
              >
                <div
                  class="iconManagement"
                  :style="index > 2 ? 'display:none' : ''"
                  v-if="item.liveProjectPersonnelVOS.length >= 2"
                  v-for="(list, index) in item.liveProjectPersonnelVOS"
                  :key="list.index"
                >
                  <div
                    v-if="index === 0"
                    :style="index > 2 ? 'display:none' : ''"
                    class="box-icon"
                  >
                    {{ list.userNameZ }}
                  </div>
                  <div
                    v-else-if="index === 1"
                    :style="index > 2 ? 'display:none' : ''"
                    class="box-icon2"
                  >
                    {{ list.userNameZ }}
                  </div>
                  <div
                    v-else-if="index === 2"
                    :style="index > 2 ? 'display:none' : ''"
                    class="box-icon3"
                  >
                    {{ list.userNameZ }}
                  </div>
                </div>
                <div
                  v-if="item.liveProjectPersonnelVOS.length > 3"
                  class="iconManagement"
                >
                  <img src="@/assets/imgs/dayu3.png" alt="" width="100%" height="100%" />
                </div>
              </div>
            </div>
            <div v-if="isTeamAdmin === '1'" class="round1" v-show="item.id == showId" @blur="handleBlur">
              <div class="round-world" @click.stop="authority(item)">权限设置</div>
              <div class="round-world" @click.stop="projectDel(item.id)">删除</div>
            </div>
            <div v-else class="round1" v-show="item.id == showId" @blur="handleBlur">
              <div v-for="(vtem, index) in item.liveProjectPersonnelVOS" :key="index">
                <div v-if="vtem.userId === userId">
                  <div
                    v-if="vtem.power === '1' "
                    class="round-world"
                    @click.stop="authority(item)"
                  >
                    权限设置
                  </div>
                  <div
                    v-else-if="vtem.power === '0'"
                    class="round-world"
                    @click.stop="authority(item)"
                  >
                    权限设置
                  </div>
                  <div v-else class="round-world" style="color: #ccc">权限设置</div>
                  <div
                    v-if="vtem.power === '1'"
                    class="round-world"
                    @click.stop="projectDel(item.id)"
                  >
                    删除
                  </div>
                  <div
                    v-else-if="vtem.power === '0'"
                    class="round-world"
                    @click.stop="projectDel(item.id)"
                  >
                    删除
                  </div>
                  <div v-else class="round-world" style="color: #ccc">删除</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="tablePage.current"
          :page-size="tablePage.size"
          :page-sizes="pageSizes"
          :total="tablePage.total"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        />
      </div>

      <el-dialog :title="title" width="480px" :visible.sync="dialogFormVisible">
        <el-form :model="form1">
          <el-form-item>
            <el-input
              v-model="form1.name1"
              autocomplete="off"
              :placeholder="'请' + title"
            ></el-input>
            <el-input
              v-show="false"
              v-model="form1.name1"
              autocomplete="off"
              :placeholder="'请' + title"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm()">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 项目成员弹窗 -->
      <el-dialog
        title="项目成员"
        width="680px"
        :visible.sync="dialogFormVisible1"
        @close="dialogFormVisibleClose"
      >
        <div class="memberProject">
          <div class="left-box">
            <div class="input-top">
              <el-input
                @change="changeQueryAll"
                v-model="userName"
                placeholder="搜索项目成员"
                prefix-icon="el-icon-search"
              ></el-input>
            </div>
            <div class="all-user">
              <div class="text">全部用户</div>
              <div class="checkbox">
                <el-checkbox @change="changeCheckeAll" v-model="checkedAll">
                  <div
                    v-show="liveProjectPersonnelVOS.length > 0"
                    class="checkbox-false"
                    :style="checkedAll ? 'border:none;' : ''"
                  ></div>
                </el-checkbox>
              </div>
            </div>

            <div class="user-item">
              <div class="item" v-for="(item, index) in teamUserList" :key="index">
                <div class="left">
                  <div class="avatar">
                    <img
                      v-if="item.avatar"
                      style="width: 32px; height: 32px"
                      :src="item.avatar"
                      alt=""
                    />
                    <img
                      v-else
                      style="width: 32px; height: 32px"
                      src="@/assets/imgs/touxiang3.png"
                      alt=""
                    />
                  </div>
                  <div class="king" v-if="index === 0">
                    <img
                      style="width: 26px; height: 26px"
                      src="@/assets/imgs/king.png"
                      alt=""
                    />
                  </div>
                  <div class="userName">{{ item.userName }}</div>
                </div>

                <div class="right">
                  <div v-if="isTeamAdmin === '1'" class="checkbox">
                    <el-checkbox
                      @change="changeChecke(item)"
                      v-model="item.checkedAll"
                    ></el-checkbox>
                  </div>
                  <div v-else class="checkbox">
                    <el-checkbox
                      :disabled="item.createStatus == 0 || item.userId === userId"
                      @change="changeChecke(item)"
                      v-model="item.checkedAll"
                    ></el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-box">
            <div class="selected">已选择{{ liveProjectPersonnelVOS.length }}人</div>
            <div
              class="selected-item"
              v-for="(item, index) in liveProjectPersonnelVOS"
              :key="index"
            >
              <div class="left">
                <div class="avatar">
                  <img
                    v-if="item.avatar"
                    style="width: 32px; height: 32px"
                    :src="item.avatar"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 32px; height: 32px"
                    src="@/assets/imgs/touxiang3.png"
                    alt=""
                  />
                  <div class="king" v-if="item.userId === createId && item.power == 0">
                    <img
                      style="width: 26px; height: 26px"
                      src="@/assets/imgs/king.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="userName">{{ item.userName }}</div>
              </div>

              <div v-if="isTeamAdmin === '1'" class="right">
                <div style="xiala">
                  <span v-show="item.power == 0">可管理</span>
                  <span v-show="item.power == 1">可管理</span>
                  <span v-show="item.power == 2">可编辑</span>
                  <span v-show="item.power == 3">可查看</span>
                  <img
                    @click.stop="view(item)"
                    style="margin-left: 5px; cursor: pointer"
                    src="@/assets/imgs/xialatubiao.png"
                    alt=""
                  />
                </div>
                <div class="bar">
                  <div
                    v-show="item.id == viewId"
                    @blur="handleBlur"
                    style="
                      font-size: 12px;
                      color: #2b2b2b;
                      width: 90px;
                      height: 90px;
                      border: 1px solid rgba(0, 0, 0, 0.12);
                      position: absolute;
                      right: -8px;
                      top: 21px;
                      background-color: #ffffff;
                    "
                  >
                    <div
                      class="viewStyle"
                      @click="kView(item, v)"
                      v-for="(v, index) in projectPermissions"
                      :key="index"
                    >
                      {{ v.dictValue }}
                    </div>
                  </div>
                </div>
                <div @click="kDel(item)" class="del">
                  <img
                    style="margin-left: 5px; cursor: pointer"
                    src="@/assets/imgs/del.png"
                    alt=""
                  />
                </div>
              </div>

              <div v-else class="right">
                <div style="xiala">
                  <span
                    v-show="item.power == 0"
                    >可管理</span
                  >
                  <span v-show="item.power == 1">可管理</span>
                  <span v-show="item.power == 2">可编辑</span>
                  <span v-show="item.power == 3">可查看</span>
                  <img
                    @click.stop="view(item)"
                    style="margin-left: 5px; cursor: pointer"
                    src="@/assets/imgs/xialatubiao.png"
                    alt=""
                    v-show="item.power != 0 && item.userId !== userId"
                  />
                </div>
                <div class="bar"  v-show="item.power != 0 && item.userId !== userId">
                  <div
                    v-show="item.id == viewId"
                    @blur="handleBlur"
                    style="
                      font-size: 12px;
                      color: #2b2b2b;
                      width: 90px;
                      height: 90px;
                      border: 1px solid rgba(0, 0, 0, 0.12);
                      position: absolute;
                      right: -8px;
                      top: 21px;
                      background-color: #ffffff;
                    "
                  >
                    <div
                      class="viewStyle"
                      @click="kView(item, v)"
                      v-for="(v, index) in projectPermissions"
                      :key="index"
                    >
                      {{ v.dictValue }}
                    </div>
                  </div>
                </div>
                <div @click="kDel(item)" class="del" v-show="item.power != 0 && item.userId !== userId">
                  <img
                    style="margin-left: 5px; cursor: pointer"
                    src="@/assets/imgs/del.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div slot="footer" class="member-footer">
          <el-button class="quxiao" @click="dialogFormVisible1 = false">取 消</el-button>
          <el-button class="queding" @click="confirmAuthority()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  grtMaterialList,
  getPut,
  getDel,
  liveProjectPersonnel,
  grtLiveProjectPersonnelt,
  liveProjectPersonnelPut,
  liveProjectPersonnelPost,
} from "@/api/project";
import { getChildListZZ } from "@/api/information";
import { teamUser, grtLiveProjectId } from "@/api/cloudSpace";
import Cookies from "js-cookie";
export default {
  name: "centre",
  data() {
    return {
      tablePage: {
        current: 1, // 第几页
        size: 10, // 每页多少条
        total: 0, // 总记录数
      },
      pageSizes: [10, 20, 30],
      teamList: [],
      teamList222: [],
      form: {
        liveName: "",
      },
      form1: {
        name1: "",
      },
      dialogFormVisible: false,
      dialogFormVisible1: false,
      id: "",
      showId: 0,
      title: "",
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      val: 0,
      liveProjectPersonnelVOS: [],
      teamUserList: [],
      liveName: "",
      describe: "",
      teamList1: [],
      viewId: "",
      allViewId: 0,
      checkedAll: false,
      id: "",
      userName: "",
      projectPermissions: [],
      isTeamAdmin: "",
      userId: "",
      createId: "",
    };
  },
  created() {
    this.userId = localStorage.getItem("userId");
   
    this.isTeamAdmin = localStorage.getItem("isTeamAdmin");
    this.getTeamUser();
    this.teamUserApi();
    this.getDictListApi();
  },
  mounted() {
    document.addEventListener("click", this.handleBlur);
  },
  methods: {
    getDictListApi() {
      getChildListZZ({ parentCode: "project_permissions" }).then((res) => {
        this.projectPermissions = res.data.data;
      });
    },
    allView() {
      this.allViewId = 1;
    },
    view(list) {
      this.viewId = list.id;
    },
    kView(list, val) {
  
      list.power = val.dictKey;
    },
    kEit(list) {
      list.power = "可编辑";
    },
    kGuan(list) {
      list.power = "可管理";
    },
    async kDel(list) {
      this.teamUserList.forEach((v) => {
        if (list.userId === v.userId) {
          v.checkedAll = false;
        }
      });
      const res = await liveProjectPersonnel(list.id);

      this.authority();
    },
    projectEvn(list) {
    
      this.liveName = list.liveName;
      this.describe = list.liveDescribe;
      this.liveProjectPersonnelVOS = list.liveProjectPersonnelVOS;
      this.$router.push({ path: "/order/projectDetails", query: list });
    },
    dialogFormVisibleClose() {
      this.teamUserApi();
    },
    // 项目成员搜索调用
    changeQueryAll() {
      const a = {
        userName: this.userName,
        teamId: localStorage.getItem("teamId"),
      };
      teamUser(a).then((res) => {
        this.teamUserList = res.data.data;
        this.teamUserList.forEach((item) => {
          this.liveProjectPersonnelVOS.forEach((v) => {
            if (item.userId === v.userId) {
              item.checkedAll = true;
            }
          });
        });
      });
    },
    teamUserApi() {
      const a = {
        teamId: localStorage.getItem("teamId"),
      };
      teamUser(a).then((res) => {
        this.teamUserList = res.data.data;
        this.teamUserList.forEach((item) => {
          item.checkedAll = false;
        });
      });
    },
    //项目成员全选
    changeCheckeAll() {
      if (this.isTeamAdmin == 1) {
        if (this.checkedAll === true) {
          this.teamUserList.forEach((val) => {
            val.checkedAll = true;
          });
          let arr = this.teamUserList.filter(
            (x) => !this.liveProjectPersonnelVOS.some((y) => y.userId === x.userId)
          );
          arr.forEach((item) => {
            item.liveId = this.id;
            item.id = "";
            item.power = "3";
            if (item.isAdmin == false) {
              item.isAdmin = 0;
            }
            if (item.isAdmin == true) {
              item.isAdmin = 1;
            }
          });
          liveProjectPersonnelPost(arr).then((res) => {
            this.authority();
          });
        } else {
          this.teamUserList.forEach((val) => {
            val.checkedAll = false;
            this.liveProjectPersonnelVOS.forEach((item) => {
              this.kDel(item);
            });
          });
        }
      } else {
        if (this.checkedAll === true) {
          this.teamUserList.forEach((val) => {
            if (val.createStatus == 0 || val.userId == this.userId) {
             
              val.checkedAll = val.checkedAll;
            } else {
              val.checkedAll = true;
            }
          });
          let arr = this.teamUserList.filter(
            (x) => !this.liveProjectPersonnelVOS.some((y) => y.userId === x.userId)
          );
          arr.forEach((item) => {
           
            item.liveId = this.id;
            item.id = "";
            item.power = "3";
            if (item.isAdmin == false) {
              item.isAdmin = 0;
            }
            if (item.isAdmin == true) {
              item.isAdmin = 1;
            }
          });
          arr = arr.filter((item) => item.createStatus !== '0');
          arr = arr.filter((item) => item.userId !== this.userId);
        
          liveProjectPersonnelPost(arr).then((res) => {
            this.authority();
          });
        } else {
          this.teamUserList.forEach((val) => {
            if (val.userId == this.createId || val.userId == this.userId) {
             
              val.checkedAll = val.checkedAll;
            } else {
              val.checkedAll = false;
            }
            // return
            this.liveProjectPersonnelVOS.forEach((item) => {
             
              if (item.power !== "0" && item.userId !== this.userId) {
                this.kDel(item);
              }
            });
          });
        }
      }
    },
    changeChecke(item) {
      this.teamUserList.forEach((val) => {
        if (item.userId === val.userId) {
          if (val.checkedAll === false) {
            val.checkedAll = false;
            this.liveProjectPersonnelVOS.forEach((item) => {
              if (item.userId === val.userId) {
                this.kDel(item);
              }
            });
          } else {
            val.checkedAll = true;
            val.power = "3";
            const arr = [];
            arr.push(val);
            arr.forEach((item) => {
              item.liveId = this.id;
              item.id = "";
              if (item.isAdmin == false) {
                item.isAdmin = 0;
              }
              if (item.isAdmin == true) {
                item.isAdmin = 1;
              }
            });
            liveProjectPersonnelPost(arr).then((res) => {
              this.authority();
            });
          }
        }
      });
      this.checkedAll = this.teamUserList.every((x) => x.checkedAll == true);
      this.$forceUpdate();
    },
    async getTeamUser() {
      const a = {
        teamId: localStorage.getItem("teamId"),
        current: this.tablePage.current,
        size: this.tablePage.size,
      };
      const res = await grtMaterialList(a);
      this.tablePage.total = res.data.data.total;
      this.teamList = res.data.data.records;
      this.teamList.forEach((item) => {
        item.power = "";
        item.imageUrl = window.localStorage.getItem("OssPrefix") + "/" + item.imageUrl;
        item.coverUrl = window.localStorage.getItem("OssPrefix") + "/" + item.coverUrl;
        item.liveProjectPersonnelVOS.forEach((v) => {
          if (v.userName) {
            v.userNameZ = v.userName.substring(0, 1);
          }
        });
      });
    },
    async handelSearch() {
      const a = {
        liveName: this.form.liveName,
        teamId: localStorage.getItem("teamId"),
        current: this.page,
        current: this.tablePage.current,
        size: this.tablePage.size,
      };
      const res = await grtMaterialList(a);
  
      this.tablePage.total = res.data.data.total;
      this.teamList = res.data.data.records;
      this.teamList.forEach((item) => {
        item.imageUrl = window.localStorage.getItem("OssPrefix") + "/" + item.imageUrl;
        item.coverUrl = window.localStorage.getItem("OssPrefix") + "/" + item.coverUrl;
        item.liveProjectPersonnelVOS.forEach((v) => {
          v.userNameZ = v.userName.substring(0, 1);
        });
      });
    },
    rename(val, id, item) {
      this.dialogFormVisible = true;
      this.val = val;
      this.id = id;
      if (val == 1) {
        this.title = "修改项目名称";
        this.form1.name1 = item.liveName;
      } else {
        this.title = "修改项目描述";
        this.form1.name1 = item.liveDescribe;
      }
    },
    async confirm() {
      let liveData = {};
      if (this.val == 1) {
        liveData = {
          liveName: this.form1.name1,
        };
      } else {
        liveData = {
          liveDescribe: this.form1.name1,
        };
      }
  
      const res = await getPut(this.id, liveData);

      this.form1.name1 = "";
      this.dialogFormVisible = false;
      this.getTeamUser();
    },
    showMore(list) {
      this.showId = list.id;
      this.createId = list.createUser;
      var element = null;
      var indexZ = "";
      this.teamUserList.forEach((item) => {
        if (list.createUser === item.userId) {
          item.createStatus = 0;
          indexZ = this.teamUserList.indexOf(item);
          element = item;
        }
      });
      const item = this.teamUserList.splice(indexZ, 1)[0]; // 从数组中移除该项并获得它
      this.teamUserList.unshift(element);
    },
    handleBlur() {
      // 对失去焦点的监听
      this.showId = 1;
      this.viewId = 1;
      this.allViewId = 0;
    },
    async projectDel(id) {
      const res = await getDel(id);
      this.getTeamUser();
    },
    async authority(list) {
      if (list) {
        this.id = list.id;
        this.dialogFormVisible1 = true;
      }
      const a = {
        liveId: this.id,
      };
      const res = await grtLiveProjectPersonnelt(a);
      this.liveProjectPersonnelVOS = res.data.data;
      if (this.liveProjectPersonnelVOS.length !== this.teamUserList.length) {
        this.checkedAll = false;
      }

      if (this.liveProjectPersonnelVOS.length === this.teamUserList.length) {
        this.checkedAll = true;
      }
      if (this.liveProjectPersonnelVOS.length === 0) {
        this.checkedAll = false;
      }

      this.teamUserList.forEach((item) => {
        this.liveProjectPersonnelVOS.forEach((v) => {
          if (item.userId === v.userId) {
            item.checkedAll = true;
          }
          if (v.userId === this.createId ) {
  
            if (item.userId === this.createId) {
              item.createStatus = v.power
            }
          }
        });
      });
  
    },
    async confirmAuthority() {

      const res = await liveProjectPersonnelPut(this.liveProjectPersonnelVOS);
      this.dialogFormVisible1 = false;

      this.getTeamUser();
    },
    handlePageChange(currentPage) {
      this.tablePage.current = currentPage;

      // 在此刷新数据
      this.getTeamUser();
    },
    handleSizeChange(pageSize) {
      this.tablePage.size = pageSize;
      // 在此刷新数据
      this.getTeamUser();
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 2px;
  background: rgba(#758b95fa, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #d5d5dc;
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #758b95fa;
}
</style>

<style lang="less" scoped>
#current-box {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  .projectTop {
    margin-left: 12px;
    display: flex;
    ::v-deep .el-input__inner {
      height: 30px;
      border-radius: 20px;
    }
  }
  .main-box {
    height: 75vh;
    display: flex;
    overflow-y: auto;

    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      border-radius: 4px 4px 4px 4px;
    }
  }
}

.viewStyle {
  height: 26px;
  line-height: 26px;
  text-align: center;
}

.viewStyle:hover {
  background-color: #f0f1f2;
  cursor: pointer;
}

.memberProject {
  width: 630px;
  height: 340px;
  border: 1px solid #dedede;
  display: flex;
  .left-box {
    width: 303px;
    height: 100%;
    border-right: 1px solid #f5f5f5;
    .input-top {
      margin-left: 8px;
      margin-top: 15px;
      ::v-deep .el-input__inner {
        width: 240px;
        height: 32px;
        border-radius: 20px;
      }
      ::v-deep .el-input__icon {
        line-height: 35px;
      }
    }
    .all-user {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-left: 8px;
      .text {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 14px;
        color: #444444;
      }
      .checkbox {
        margin-right: 47px;
        .checkbox-false {
          position: absolute;
          top: 1px;
          left: 0px;
          width: 14px;
          height: 14px;
          border: 4px solid #8b5ef5;
          box-sizing: border-box;
          z-index: 2;
        }
      }
    }
    .user-item {
      margin-top: 29px;
      margin-left: 8px;
      .item {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .left {
          display: flex;
          align-items: center;
          .avatar {
            width: 32px;
            height: 32px;
            img {
              border-radius: 20px;
            }
          }
          .king {
            position: absolute;
            top: 188px;
            left: 45px;
          }
          .userName {
            margin-left: 15px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          .checkbox {
            margin-right: 57px;
          }
        }
      }
    }
  }
  .right-box {
    width: 317px;
    height: 100%;
    .selected {
      margin-left: 8px;
      margin-top: 15px;
    }
    .selected-item {
      display: flex;
      margin-top: 25px;
      margin-left: 8px;
      .left {
        display: flex;
        align-items: center;
        width: 160px;
        .avatar {
          width: 32px;
          height: 32px;
          img {
            border-radius: 20px;
          }
          .king {
            position: relative;
            top: -45px;
            left: 15px;
          }
        }

        .userName {
          margin-left: 15px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        margin-left:25px;
        .xiala {
        }
        .bar {
          width: 1px;
          height: 20px;
          border: 1px solid #dedede;
          margin: 0px 10px;
          margin-right: 5px;
          position: relative;
        }
        .del {
          margin-right: 30px;
        }
      }
    }
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #8b5ef5;
    border: 1px solid #8b5ef5;
  }

  ::v-deep .el-checkbox__inner:hover {
    border: 1px solid #8b5ef5;
  }

  ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #8b5ef5;
    border: 1px solid #8b5ef5;
  }
}
.member-footer {
  display: flex;
  justify-content: center;
  .quxiao {
    width: 140px;
    height: 48px;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #e9e9e9;
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #0f1114;
    margin-right: 20px;
  }
  .queding {
    width: 140px;
    height: 48px;
    background: #8b5ef5;
    border-radius: 6px 6px 6px 6px;
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
  }
}
.projectManagement {
  width: 195px;
  height: 300px;
  // background-color: antiquewhite;
  background-color: rgba(0, 0, 0, 0.16);
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 1px 1px 2px rgb(196, 196, 196);
  position: relative;
  cursor: pointer;
  margin: 10px 14px;
  border-radius: 5px;
  // overflow: hidden;

  .roundStyle {
    width: 20px;
    padding-left: 15px;
    position: absolute;
    bottom: 0px;
    right: 12px;
    z-index: 9999;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .round {
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: #fff;
    margin-top: 2px;
    border-radius: 3px;
    margin-left: -2px;
  }

  .round1 {
    width: 120px;
    height: 60px;
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: 99;
    top: 290px;
    left: 95px;
    // padding: 10px 20px;
    overflow: hidden;
    border: 1px solid #c6c6c6;

    .round-world {
      color: #000;
      font-size: 12px;
      // margin-top: 10px;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
    }

    .round-world:hover {
      background-color: #c6c6c6;
    }
  }

  .wordManagement {
    width: 100%;
    height: 40px;
    background-color: #fff;
    padding: 5px;
    border-radius: 0px 0px 5px 5px;
    .nameManagement {
      width: 100%;
      height: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .iconManagement {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 15px;
      background-color: #ffc542;
      color: #fff;
      margin-right: 5px;
      overflow: hidden;
      .box-icon {
        width: 24px;
        height: 24px;
        background: #ffc542;
        border-radius: 25px;
        font-size: 14px;
      }
      .box-icon2 {
        width: 24px;
        height: 24px;
        border-radius: 25px;
        font-size: 14px;
        background: #4287ff;
      }
      .box-icon3 {
        width: 24px;
        height: 24px;
        border-radius: 25px;
        font-size: 14px;
        background: #f56b6b;
      }
    }
  }
}
.pagination {
  margin-top: 1.7%;
  margin-left: 35%;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #6440ff;
}
</style>
