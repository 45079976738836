<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent></headComponent>
    <div class="mainStyle">
      <div class="img-box">
        <img class="images" src="@/assets/imgs/flow-bg.png" alt="" />
      </div>
      <div class="mainStyle-box">
        <div class="left">
          <div>
            <img src="@/assets/imgs/flowCenter-left.png" alt="" />
          </div>
        </div>
        <div class="right">
          <img src="@/assets/imgs/flow-right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="flow-one">
      <div class="one-box">
        <div class="title">Nvwa桌面端 直播教程</div>
        <div class="item-box">
          <div class="item">
            <div class="video-box">
              <video
                style="width: 100%; height: 100%; border-radius: 20px"
                src="https://hello.yohelo.com/tutorial/tutorial_v1.mp4"
                controls
              ></video>
            </div>
          </div>
          <div style="margin: 0 29px" class="item">
            <div class="video-box">
              <video
                style="width: 100%; height: 100%; border-radius: 20px"
                src="https://hello.yohelo.com/tutorial/digitalHuman_v1.mp4"
                controls
              ></video>
            </div>
          </div>
          <div class="item">
            <div class="video-box">
              <video
                style="width: 100%; height: 100%; border-radius: 20px"
                src="https://hello.yohelo.com/tutorial/fragment_v1.mp4"
                controls
              ></video>
            </div>
          </div>
        </div>
        <div class="item-box">
          <div class="item">
            <div class="video-box">
              <video
                style="width: 380px; border-radius: 20px"
                src="https://hello.yohelo.com/tutorial/virtualCam.mp4"
                controls
              ></video>
            </div>
          </div>
          <div style="margin: 0 29px" class="item">
            <div class="video-box" @click="gotoPushcode()" style="">
              <!-- <video
                style="width: 100%; height: 100%; border-radius: 20px"
                src="@/assets/imgs/demo11.jpg"
                controls
              ></video> -->
              <img style="width: 100%; height: 100%; border-radius: 20px"
                src="@/assets/imgs/demo11.png" alt="">
            </div>
          </div>
          <div class="item">
            <div class="video-box" @click="gotoLiveNumber()">
              <!-- <video
                style="width: 100%; height: 100%; border-radius: 20px"
                src="@/assets/imgs/demo11.jpg"
                controls
              ></video> -->
              <img style="width: 100%; height: 100%; border-radius: 20px"
                src="@/assets/imgs/demo22.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flow-two">
      <div class="two-box">
        <div class="title">Nvwa APP端 直播教程</div>
      </div>
    </div> -->

    <div class="dsdh-four">
      <div class="four-box">
        <div class="title">咨询专业顾问</div>
        <div class="conter">关于使用场景和产品功能的更多咨询，请联系我们的客服团队。</div>
        <div class="btn">立即咨询</div>
      </div>
    </div>

    <footerComponent></footerComponent>
  </div>
</template>

<script>
import headComponent from "@/views/component/headComponent.vue";
import footerComponent from "@/views/component/footerComponent.vue";
export default {
  name: "HomeView",
  components: { headComponent, footerComponent },
  data() {
    return {};
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {},
  methods: {
    gotoPushcode() {
      this.$router.push({ path: "/tutorial/pushcode" });
    },
    gotoLiveNumber() {
      this.$router.push({ path: "/tutorial/liveNumber" });
    },
  }
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
  .mainStyle {
    margin-top: 64px;
    flex-grow: 1;
    min-width: 1200px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    .img-box {
      position: absolute;
      height: 100%;
      width: 100%;
      .images {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        object-fit: cover;
      }
    }
    .mainStyle-box {
      z-index: 1;
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-family: DingTalk JinBuTi, DingTalk JinBuTi;
        font-weight: 400;
        font-size: 58px;
        color: #243042;
      }
    }
  }
  .flow-one {
    min-width: 1200px;
    width: 100%;
    height: 860px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    .one-box {
      width: 1200px;
      height: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #080808;
        text-align: center;
        margin-top: 28px;
      }
      .item-box {
        width: 100%;
        margin-top: 70px;
        display: flex;
        .item {
          flex: 1;
          width: 380px;
          height: 270px;
          background: #d1d1d1;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 12px 12px 12px 12px;
          opacity: 0.9;
          .video-box {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            video {
              object-fit: fill;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .flow-two {
    min-width: 1200px;
    width: 100%;
    height: 534px;
    background: #f2f3ff;
    display: flex;
    justify-content: center;
    .two-box {
      width: 1200px;
      height: 100%;
      .title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 40px;
        color: #080808;
        text-align: center;
        margin-top: 28px;
      }
      .item-box {
        width: 100%;
        margin-top: 87px;
        display: flex;
        .item {
          flex: 1;
          width: 380px;
          height: 270px;
          background: #d1d1d1;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 12px 12px 12px 12px;
          opacity: 0.9;
          .video-box {
            height: 100%;
            display: flex;
            align-items: center;
            video {
              object-fit: fill;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .dsdh-four {
    min-width: 1200px;
    height: 220px;
    background-image: url("@/assets/imgs/four-bg.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .four-box {
      width: 1200px;
      height: 100%;
      .title {
        margin-top: 44px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 600;
        font-size: 34px;
        color: #ffffff;
        line-height: 28px;
      }
      .conter {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #f2f3ff;
        line-height: 28px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .btn {
        width: 144px;
        height: 42px;
        background: #ffffff;
        box-shadow: 0px 1px 3px 1px rgba(108, 45, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 18px;
        color: #8b5ef5;
        line-height: 28px;
      }
    }
  }
}
</style>
