var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"userStyle-invoicingRecords"},[_c('div',{staticStyle:{"padding":"0 15px"}},[_c('div',{staticStyle:{"height":"410px","background-color":"#fff","margin":"20px 0"}},[_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%","background-color":"#ffffff"},attrs:{"data":_vm.tableData,"tooltip-effect":"dark","height":" 410px","row-class-name":_vm.tableRowClassName,"header-cell-style":{
          background: '#F2ECFF',
          color: '#44444F',
          border: 'none',
          fontSize: '14px',
        },"cell-style":{ color: '#444444' }}},[_c('el-table-column',{attrs:{"label":"申请时间","prop":"createTime","width":"200"}}),_c('el-table-column',{attrs:{"prop":"id","label":"开票单号","width":"200"}}),_c('el-table-column',{attrs:{"prop":"invoiceAmt","label":"发票金额","width":"130"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 1)?_c('span',[_vm._v("开票中")]):_vm._e(),(scope.row.status == 2)?_c('span',[_vm._v("已开票")]):_vm._e(),(scope.row.status == 3)?_c('span',[_vm._v("退票中")]):_vm._e(),(scope.row.status == 4)?_c('span',[_vm._v("已驳回")]):_vm._e(),(scope.row.status == 5)?_c('span',[_vm._v("已退票")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 2)?_c('el-button',{staticStyle:{"color":"#8b5ef5"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleClick(scope.row)}}},[_vm._v("下载")]):_vm._e(),(
                scope.row.status == 1 || scope.row.status == 2 || scope.row.status == 4
              )?_c('el-button',{staticStyle:{"color":"#8b5ef5"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.returnTicket(scope.row)}}},[_vm._v("退票")]):_vm._e(),_c('el-button',{staticStyle:{"color":"#8b5ef5"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.details(scope.row)}}},[_vm._v("详情")])]}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }