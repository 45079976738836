import request from '@/utils/request';

export function teamUser(teamId) {
  return request({
    url: '/hello-user/team/teamUser',
    method: 'get',
    params: teamId
  });
}

//获取团队所有成员
export function grtLiveProjectId(query) {
  return request({
    url: "/hello-user/team/getTeamUserInProject",
    method: "get",
    params: query,
  });
}

export function grtMaterialList(query) {
  return request({
    url: '/hello-material/live/list',
    method: 'get',
    params: query
  });
}
export function getMaterialList(query) {
  return request({
    url: '/hello-material/user/material/list',
    method: 'get',
    params: query
  });
}
export function getMaterialDel(id) {
  return request({
    url: '/hello-material/user/material',
    method: 'DELETE',
    data: id
  });
}
export function getPut(id, data) {
  return request({
    url: '/hello-material/user/material/' + id,
    method: 'PUT',
    data: data
  });
}
//
export function getCheckToken(data) {
  return request({
    url: '/hello-auth/oauth/check_token',
    method: 'post',
    params: data
  });
}
