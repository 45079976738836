import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router/index';
import Cookies from 'js-cookie'
// import { ipcRenderer } from "electron";
let token = localStorage.getItem('token');
console.log(process.env.VUE_APP_URL,'>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
const serves = axios.create({
  baseURL: process.env.VUE_APP_URL + '/api',
  // baseURL: 'https://192.168.101.223'+'/api',
  // baseURL: process.env.VUE_APP_URL, 
  // baseURL: process.env.userConfig.API_HOST,
  timeout: 20000,
  headers: {
    'Tenant-Id': '000000',
    // aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=
    Authorization: 'Basic aGVsbG9fd2ViOmhlbGxvX3dlYl9zZWNyZXQ=',
    // "Content-Type": "application/x-www-form-urlencoded",
    'Blade-Auth': `bearer ${token}`
  }
});
serves.interceptors.request.use(
  (config) => {
    // 设置发送之前数据需要做什么处理
    return config;
  },
  (err) => Promise.reject(err)
);

// 设置请求接受拦截器
serves.interceptors.response.use(
  (res) => {
    // 设置接受数据之后，做什么处理
    if (res.data.code === 50000) {
      Message.error(res.data.data);
    }
    return res;
  },
  (err) => {
    // 令牌失效跳转页面
    // if (err.response.data.code == 401) {
    //   // Message.error("登录过期");
    //   ipcRenderer.invoke("window-close");
    //   let data = {
    //     url: "/login",
    //     width: 740,
    //     height: 430,
    //     resizable: false,
    //   };
    //   ipcRenderer.invoke("open-win", data);
    //   // router.push("/").catch((error) => error);
    // }
    if (err.response.data.error == 1001) {
      Message.error('验证码已过期');
    }
    if (err.response.data.error == 1002) {
      Message.error('验证码错误');
    }
    if (err.response.data.error == 1003) {
      Message.error('用户已被冻结');
    }
    if (err.response.data.error == 1004) {
      Message.error('用户名或密码错误');
    }
    if (err.response.data.error == 1005) {
      Message.error('登录错误次数过多,请稍后再试');
    }
    if (err.response.data.error == 1006) {
      Message.error('用户已注销');
    }
    if (err.response.data.code == 500) {
      Message.error(err.response.data.msg);
    }
    if (err.response.data.code == 503) {
      Message.error(err.response.data.msg);
    }
    if (err.response.data.code == 400) {
      console.log(router.history.current.name,'routerrouterrouterrout111111111111111111111111111er');
      if (router.history.current.name === 'invite-m') {
       console.log(err.response.data.msg);
      }else{
        Message.error(err.response.data.msg);
      }
     
    }
    if (err.response.data.code == 404) {
      Message.error(err.response.data.msg);
    }

    // 判断请求异常信息中是否含有超时timeout字符串
    if (err.message.includes('timeout')) {
      console.log('错误回调', err);
      Message.error('网络超时');
    }
    if (err.message.includes('Network Error')) {
      console.log('错误回调', err);
      Message.error('服务端未启动，或网络连接错误');
    }
    return Promise.reject(err);
  }
);

// 将serves抛出去
export default serves;
