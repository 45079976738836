

<template>
    <div id="user">
        <h1>微信登录</h1>
    </div>
</template>
  
<script>
export default {
    name: 'weChat'
}
</script>
<style></style>