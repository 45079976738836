import request from '@/utils/request';

export function grtMaterialList(query) {
  return request({
    url: '/hello-material/live/console/list',
    method: 'get',
    params: query
  });
}

export function getPut(id, data) {
  return request({
    url: '/hello-material/live/' + id,
    method: 'PUT',
    data: data
  });
}
export function getDel(id) {
  return request({
    url: '/hello-material/live/' + id,
    method: 'DELETE'
    // params: query,
  });
}
export function grtLiveProjectPersonnelt(query) {
  return request({
    url: '/hello-material/liveProjectPersonnel/list',
    method: 'get',
    params: query
  });
}
export function liveProjectPersonnel(data) {
  return request({
    url: '/hello-material/liveProjectPersonnel',
    method: 'DELETE',
    data: data
  });
}
export function liveProjectPersonnelPut(data) {
  return request({
    url: '/hello-material/liveProjectPersonnel/batch',
    method: 'PUT',
    data: data
  });
}
export function liveProjectPersonnelPost(data) {
  return request({
    url: '/hello-material/liveProjectPersonnel/batch',
    method: 'POST',
    data: data
  });
}

// 直播合成历史列表查询
export const getCompoundList = (params) => {
  return request({
      url: '/hello-material/live/compound/list',
      method: 'get',
      params
  })
}

//获取直播编辑记录
export const getLiveEdit = (params) => {
  return request({
    url: `/hello-material/live/edit/` + params.liveId,
    method: 'get',
    params
  })
}
// /hello-material/liveProjectPersonnel/batch
// /hello-material/liveProjectPersonnel
// /hello-material/liveProjectPersonnel
// /hello-material/liveProjectPersonnel/list
