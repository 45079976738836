<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { wxChatShare } from "@/utils/weixin.js"; // 引入wechat.js
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    this.wxChatShareUtils();
  },
  methods: {
    wxChatShareUtils() {
      // 写在方法中调用，或者在生命周期中调用
      let _param = {};
      console.log(window.location.href.split("#")[1], "window.location.href");
      if (window.location.href.split("#")[1] === "/help/androidApp") {
        console.log("androidApp");
        _param = {
          url: window.location.href, // 当前页面url
          title:"一二三四五六七八九十", // 分享数据配置
          desc:"壹貳叁肆伍陸柒捌玖拾", // 分享数据配置
          link: "https://2973j78g54.vicp.fun/#/help/androidApp",
          imgUrl: "https://hello.yohelo.com/img/icon.e742518b.png", // 分享数据配置 －－ 全路径
          type: "link", // 分享类型,music、video或link，不填默认为link
          dataUrl: " ", // 如果type是music或video，则要提供数据链接，默认为空
        };
      }
      // wxChatShare(_param);
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

#app {
  height: 100%;
  //overflow: hidden;
}
</style>
