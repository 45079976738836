
<template>
  <el-aside width="200px">
    <el-row class="tac">
      <el-col>
        <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
          <el-menu-item index="1" @click="goPage('home')">
            <!-- <i class="el-icon-menu"></i> -->
            <img style="margin-right: 20px;" src="@/assets/imgs/shouyelogo .png" alt="">
            <span slot="title" :style="colorData1">首页</span>
          </el-menu-item>

          <el-submenu index="3">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <img style="margin-right: 20px;" src="@/assets/imgs/yunkongjianlogo.png" alt="">
              <span>云空间</span>
            </template>
            <el-menu-item-group>
              <el-menu-item style="margin-left: 20px" index="3-1" @click="goPage('teamFiles')" :style="colorData2">团队文件</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item style="margin-left: 20px" index="3-2" @click="goPage('personalFiles')" :style="colorData3">个人文件</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-menu-item index="2" @click="goPage('order')">
            <!-- <i class="el-icon-s-order"></i> -->
            <img style="margin-right: 20px;" src="@/assets/imgs/zhibologo.png" alt="">
            <span slot="title" :style="colorData4">直播项目</span>
          </el-menu-item>

          <el-menu-item index="4" @click="goPage('team')">
            <!-- <i class="el-icon-s-order"></i> -->
            <img style="margin-right: 20px;" src="@/assets/imgs/tuanduilogo  .png" alt="">
            <span slot="title" :style="colorData5">团队管理</span>
          </el-menu-item>

          <el-menu-item index="5" @click="goPage('goods')">
            <!-- <i class="el-icon-s-order"></i> -->
            <img style="margin-right: 20px;" src="@/assets/imgs/dingdanlogo .png" alt="">
            <span slot="title" :style="colorData6">订单管理</span>
          </el-menu-item>
          <el-menu-item index="6" @click="goPage('information')">
            <!-- <i class="el-icon-s-order"></i> -->
            <img style="margin-right: 20px;" src="@/assets/imgs/xinxilogo.png" alt="">
            <span slot="title" :style="colorData7">个人信息</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </el-aside>
</template>

<script>
var $this = {};
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      colorData: `color: #8b5ef5`,
      colorData8: `color: #303133`,
      colorData1: "",
      colorData2: "",
      colorData3: "",
      colorData4: "",
      colorData5: "",
      colorData6: "",
      colorData7: "",
    };
  },
  beforeCreate() {
    $this = this;
  },
  created() { // 创建vm实例后执行
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener('popstate', this.popstate, false);
  },
  destroyed() { // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.popstate, false);
  },
  mounted() {
    // is-active
    if (this.$route.name == "Centre") {
      this.colorData1 = this.colorData
      this.colorData2 = this.colorData8
      this.colorData3 = this.colorData8
      this.colorData4 = this.colorData8
      this.colorData5 = this.colorData8
      this.colorData6 = this.colorData8
      this.colorData7 = this.colorData8
    } else if (this.$route.name == "TeamFiles") {
      this.colorData2 = this.colorData
      this.colorData1 = this.colorData8
      this.colorData3 = this.colorData8
      this.colorData4 = this.colorData8
      this.colorData5 = this.colorData8
      this.colorData6 = this.colorData8
      this.colorData7 = this.colorData8
    } else if (this.$route.name == "PersonalFiles") {
      this.colorData3 = this.colorData
      this.colorData2 = this.colorData8
      this.colorData1 = this.colorData8
      this.colorData4 = this.colorData8
      this.colorData5 = this.colorData8
      this.colorData6 = this.colorData8
      this.colorData7 = this.colorData8
    } else if (this.$route.name == "order") {
      this.colorData4 = this.colorData
      this.colorData3 = this.colorData8
      this.colorData2 = this.colorData8
      this.colorData1 = this.colorData8
      this.colorData5 = this.colorData8
      this.colorData6 = this.colorData8
      this.colorData7 = this.colorData8
    } else if (this.$route.name == "team") {
      this.colorData5 = this.colorData
      this.colorData3 = this.colorData8
      this.colorData2 = this.colorData8
      this.colorData1 = this.colorData8
      this.colorData4 = this.colorData8
      this.colorData6 = this.colorData8
      this.colorData7 = this.colorData8
    } else if (this.$route.name == "Goods") {
      this.colorData6 = this.colorData
      this.colorData3 = this.colorData8
      this.colorData2 = this.colorData8
      this.colorData1 = this.colorData8
      this.colorData4 = this.colorData8
      this.colorData5 = this.colorData8
      this.colorData7 = this.colorData8
    } else if (this.$route.name == "Information") {
      this.colorData7 = this.colorData
      this.colorData3 = this.colorData8
      this.colorData2 = this.colorData8
      this.colorData1 = this.colorData8
      this.colorData4 = this.colorData8
      this.colorData5 = this.colorData8
      this.colorData6 = this.colorData8
    }
    //   // window.addEventListener('unload', e => this.beforeunloadHandler(e))
  },
  methods: {
    popstate() {
      if (this.$route.name == "Centre") {
        this.colorData1 = this.colorData
        this.colorData2 = this.colorData8
        this.colorData3 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
      } else if (this.$route.name == "TeamFiles") {
        this.colorData2 = this.colorData
        this.colorData1 = this.colorData8
        this.colorData3 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
      } else if (this.$route.name == "PersonalFiles") {
        this.colorData3 = this.colorData
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
      } else if (this.$route.name == "order") {
        this.colorData4 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
      } else if (this.$route.name == "team") {
        this.colorData5 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
      } else if (this.$route.name == "Goods") {
        this.colorData6 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData7 = this.colorData8
      } else if (this.$route.name == "Information") {
        this.colorData7 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
      }
    },
    beforeunloadHandler(e) {
      localStorage.removeItem('loginStatus');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('nickName');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('refreshNum');
      localStorage.removeItem('avatar');
      localStorage.removeItem('teamId');
      localStorage.removeItem('teamName');
      localStorage.removeItem('i');

    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    //跳转到某个导航页
    goPage(link) {
      if (link === "home") {
        this.colorData1 = this.colorData
        this.colorData2 = this.colorData8
        this.colorData3 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
        $this.$router.push("/control/").catch(error => error);

      } else if ((link == "order")) {
        this.colorData4 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
        $this.$router.push("/order").catch(error => error);

      } else if ((link == "team")) {
        this.colorData5 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
        $this.$router.push("/team").catch(error => error);
      } else if ((link == "goods")) {
        this.colorData6 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData7 = this.colorData8
        $this.$router.push("/goods").catch(error => error);
      } else if ((link == "information")) {
        this.colorData7 = this.colorData
        this.colorData3 = this.colorData8
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        $this.$router.push("/information").catch(error => error);
      } else if ((link == "teamFiles")) {
        this.colorData2 = this.colorData
        this.colorData1 = this.colorData8
        this.colorData3 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
        $this.$router.push("/teamFiles").catch(error => error);
      } else if ((link == "personalFiles")) {
        this.colorData3 = this.colorData
        this.colorData2 = this.colorData8
        this.colorData1 = this.colorData8
        this.colorData4 = this.colorData8
        this.colorData5 = this.colorData8
        this.colorData6 = this.colorData8
        this.colorData7 = this.colorData8
        $this.$router.push("/personalFiles").catch(error => error);
      }
      // location.reload();
    }
  }
};
</script>
<style scoped>
.el-menu-item.is-active {
  color: #8b5ef5;
}
.el-row {
  height: 100%;
}

.el-menu {
  border-right: none;
}

.el-aside {
  overflow: hidden;
  border-right: 1px solid #f5f1f1;
}
/* .el-menu-item:focus {
  background-color: #fff;
} */
</style>
