<template>
  <div id="current-box">
    <div class="top-box">
      <el-form
        style="margin-left: 65px"
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="">
          <el-input
            style="width: 300px"
            v-model="formInline.userName"
            placeholder="输入姓名进行搜索"
          >
            <template #prefix>
              <i
                style="margin-left: 10px"
                class="el-icon-search"
                @click="handelSearch"
              ></i>
            </template>
          </el-input>
          <el-input
            v-show="false"
            style="width: 300px"
            suffix-icon="el-icon-search"
            placeholder="输入关键词进行搜索"
          ></el-input>
        </el-form-item>
        <el-form-item style="float: right; margin-right: 80px">
          <el-button
            @click="invite"
            icon="el-icon-plus"
            style="color: #ffffff; background-color: #8b5ef5; width: 120px"
            >邀请成员</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="main-box">
      <el-table
        :header-cell-style="{
          height: '55px',
          padding: 0,
          color: '#464848',
          fontSize: '12px',
        }"
        :row-style="{ height: '66px' }"
        :cell-style="{ padding: '0' }"
        height="calc(100vh - 290px)"
        style="width: 90%; margin-left: 5%"
        ref="dragTable"
        row-key="id"
        :data="tableData"
        tooltip-effect="dark"
      >
        <el-table-column align="center" prop="userName" label="姓名"> </el-table-column>
        <el-table-column align="center" prop="isAdmin" label="角色权限">
          <template slot-scope="scope">
            <span v-if="scope.row.isAdmin">管理员</span>
            <span v-else>团队成员</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="inviteUser" label="邀请人">
        </el-table-column>
        <el-table-column
          v-if="isTeamAdmin == 1"
          width="280"
          align="center"
          fixed="right"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button @click="delRow(scope.row)" type="text" size="small"
              >删除</el-button
            >
            <el-button @click="allocationRow(scope.row)" type="text" size="small"
              >分配资源</el-button
            >
            <el-button
              v-show="!scope.row.isAdmin && adminPower === true"
              @click="putAdmin(scope.row)"
              type="text"
              size="small"
              >设为管理员</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 变更管理员弹窗 -->
      <el-dialog title="设置管理员" :visible.sync="AdminVisible" width="480px">
        <div
          style="
            text-align: center;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 14px;
            color: #444444;
          "
        >
          设置该用户为团队管理员，你将不是团队管理员
        </div>
        <div class="Admin-box">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 42px;
            "
          >
            <el-button
              @click="AdminVisible = false"
              style="
                width: 140px;
                height: 48px;
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #e9e9e9;
                font-weight: 500;
                font-size: 16px;
                color: #0f1114;
              "
              >取消</el-button
            >
            <div style="width: 50px; height: 10px"></div>
            <el-button
              @click="setPutAdmin"
              style="
                width: 140px;
                height: 48px;
                background: #8b5ef5;
                box-shadow: 0px 0px 4px 1px rgba(15, 12, 84, 0.2);
                border-radius: 6px 6px 6px 6px;
                font-weight: 500;
                font-size: 16px;
                color: #ffffff;
              "
              >确认</el-button
            >
          </div>
        </div>
      </el-dialog>

      <!-- 添加/编辑弹窗 -->
      <el-dialog
        :modal="false"
        :close-on-click-modal="false"
        title="资源分配（设置资源上限）"
        :visible.sync="outerVisible"
        width="40%"
      >
        <el-form ref="ruleForm" :rules="rules" :model="form" label-width="160px">
          <el-form-item label="云空间总剩余:" prop="cloudSpaceSize">
            <span style="font-weight: 400; font-size: 14px; color: #456bea"
              >{{ form.cloudSpaceSize }}GB</span
            >
          </el-form-item>
          <el-form-item label="分配空间:" prop="maxSpaceSize">
            <el-input style="width: 200px" v-model="form.maxSpaceSize"></el-input>
            <span style="margin-left: 5px">MB</span>
          </el-form-item>

          <el-form-item label="数字人合成总时长:" prop="remainingDuration">
            <span style="font-weight: 400; font-size: 14px; color: #456bea"
              >{{ form.remainingDuration }}分钟</span
            >
          </el-form-item>
          <el-form-item label="数字人合成:" prop="maxTime">
            <el-input style="width: 200px" v-model="form.maxTime"></el-input>
            <span style="margin-left: 5px">分钟</span>
          </el-form-item>

          <el-form-item>
            <div style="margin-left: 20%">
              <el-button @click="outerVisible = false">取消</el-button>
              <el-button
                style="margin-left: 30px"
                :disabled="submitBool"
                type="primary"
                @click="submitForm('ruleForm')"
                >保存</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="项目成员" :visible.sync="inviteVisible" width="30%">
        <div>邀请链接</div>
        <div style="display: flex; margin: 20px 0">
          <el-input placeholder="获得外部人员加入团队后，成为团队人员" :disabled="true">
          </el-input>
          <div>
            <el-button
              @click="handelInvite"
              style="
                color: #ffffff;
                background-color: #8b5ef5;
                width: 100px;
                margin-left: 20px;
              "
              >复制链接</el-button
            >
          </div>
        </div>
        <div>链接有效期:<span style="color: #456bea">14天</span></div>
      </el-dialog>

      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="tablePage.current"
          :page-size="tablePage.size"
          :page-sizes="pageSizes"
          :total="tablePage.total"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTeamList,
  putTeamChange,
  deleteTeam,
  allocationTeam,
  getTeamEquity,
} from "@/api/team";
import { informationId } from "@/api/information";
import Base64 from "@/utils/base64.js";
import Cookies from "js-cookie";
export default {
  name: "goods",
  data() {
    return {
      tableData: [],
      formInline: {
        current: 1,
        size: 10,
      },
      AdminVisible: false,
      inviteVisible: false,
      outerVisible: false,
      form: {
        id: "",
        cloudSpaceSize: "",
        maxSpaceSize: "",
        remainingDuration: "",
        maxTime: "",
        voiceRemainingDuration: "",
        maxLiveTime: "",
        leavrNum: "",
        isAllow: "",
        maxNum: "",
      },
      submitBool: false,
      options: [
        {
          value: false,
          label: "不限次数",
        },
        {
          value: true,
          label: "限制次数",
        },
      ],
      rules: {
        maxSpaceSize: [{ required: true, message: "请输入分配空间", trigger: "change" }],
        maxTime: [{ required: true, message: "请输入数字人合成", trigger: "change" }],
        maxLiveTime: [
          { required: true, message: "请输入数字语音直播", trigger: "change" },
        ],
        isAllow: [{ required: true, message: "请选择智能文案", trigger: "change" }],
      },
      value: "",
      tablePage: {
        current: 1, // 第几页
        size: 10, // 每页多少条
        total: 0, // 总记录数
      },
      pageSizes: [10, 20, 30],
      teamId: "",
      info: {},
      userId: "",
      adminPower: false,
      isTeamAdmin: "",
      nickName: "",
    };
  },
  created() {
    this.isTeamAdmin = localStorage.getItem("isTeamAdmin");
    this.teamId = localStorage.getItem("teamId");
    this.getList();
    this.getgetTeamEquity();
    this.infoGet();
  },
  methods: {
    infoGet() {
      informationId(localStorage.getItem("userId"), localStorage.getItem("teamId")).then(
        (res) => {
          localStorage.setItem("isTeamAdmin", res.data.data.isTeamAdmin);
          this.isTeamAdmin = res.data.data.isTeamAdmin;
          this.nickName = res.data.data.name;
        }
      );
    },
    getgetTeamEquity() {
      getTeamEquity(this.teamId).then((res) => {
        this.form.cloudSpaceSize = res.data.data.cloudSpaceSize;
        this.form.remainingDuration = Math.round(res.data.data.remainingDuration / 60);
        this.form.voiceRemainingDuration = res.data.data.voiceRemainingDuration;
        this.form.leavrNum = res.data.data.leavrNum;
        this.form.isAllow = res.data.data.isAllow;
      });
    },
    handelSearch() {
      this.formInline.current = this.tablePage.current;
      this.formInline.size = this.tablePage.size;
      this.formInline.teamId = this.teamId;
      getTeamList(this.formInline).then((res) => {
        this.tablePage.total = res.data.data.total;
        this.tablePage.current = res.data.data.current;
        this.tablePage.size = res.data.data.size;
        this.tableData = res.data.data.records;
        this.tableData.forEach((v) => {
          if (v.userId === localStorage.getItem("userId")) {
            if (v.isAdmin) {
              this.adminPower = true;
            } else {
              this.adminPower = false;
            }
          }
        });
      });
    },
    getList() {
      this.formInline.current = this.tablePage.current;
      this.formInline.size = this.tablePage.size;
      this.formInline.teamId = this.teamId;
      getTeamList(this.formInline).then((res) => {
        this.tablePage.total = res.data.data.total;
        this.tablePage.current = res.data.data.current;
        this.tablePage.size = res.data.data.size;
        this.tableData = res.data.data.records;
        this.tableData.forEach((v) => {
          if (v.userId === localStorage.getItem("userId")) {
            if (v.isAdmin) {
              this.adminPower = true;
            } else {
              this.adminPower = false;
            }
          }
        });
      });
    },
    invite() {
      this.inviteVisible = true;
    },
    handelInvite() {
      let teamName = "";
      this.tableData.forEach((v) => {
        if (v.isAdmin) {
          teamName = v.name;
        }
      });
      let teamId = Base64.encode(this.teamId);
      let avatar = localStorage.getItem("avatar");
      let userId = Base64.encode(localStorage.getItem("userId"));
      let url = window.location.origin;
      let text = url + "/#/invite?userId=" + userId + "&" + "teamId=" + teamId;
      this.copyText(text);
    },
    copyText(text) {
      var element = this.createElement(text);
      element.select();
      element.setSelectionRange(0, element.value.length);
      document.execCommand("copy");
      element.remove();
      this.$message({
        type: "success",
        message: "已复制到剪切板!",
      });
    },
    createElement(text) {
      var isRTL = document.documentElement.getAttribute("dir") === "rtl";
      var element = document.createElement("textarea");
      // 防止在ios中产生缩放效果
      element.style.fontSize = "12pt";
      // 重置盒模型
      element.style.border = "0";
      element.style.padding = "0";
      element.style.margin = "0";
      // 将元素移到屏幕外
      element.style.position = "absolute";
      element.style[isRTL ? "right" : "left"] = "-9999px";
      // 移动元素到页面底部
      let yPosition = window.pageYOffset || document.documentElement.scrollTop;
      element.style.top = `${yPosition}px`;
      //设置元素只读
      element.setAttribute("readonly", "");
      element.value = text;
      document.body.appendChild(element);
      return element;
    },
    putAdmin(row) {
      this.AdminVisible = true;
      this.userId = row.userId;
    },
    setPutAdmin() {
      const params = {
        teamId: this.teamId,
        userId: this.userId,
      };
      putTeamChange(params).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.AdminVisible = false;
        this.getList();
      });
    },
    delRow(row) {
      this.$confirm("此操作将删除团队成员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTeam(row.id).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除团队成员",
          });
        });
    },
    changeValue(val) {},
    allocationRow(row) {
      this.form.id = row.id;
      this.form.maxLiveTime = row.maxLiveTime;
      this.form.maxNum = row.maxNum;
      this.form.maxSpaceSize = row.maxSpaceSize;
      this.form.maxTime = row.maxTime;
      this.outerVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitBool = true;
          if (this.form.isAllow === false) {
            this.form.maxNum = 0;
          }
          if (this.form.cloudSpaceSize * 1024 < this.form.maxSpaceSize) {
            this.$message({
              message: "分配空间不能大于云空间总剩余！",
              type: "warning",
            });
            this.submitBool = false;
            return;
          }
          if (this.form.remainingDuration < this.form.maxTime) {
            this.$message({
              message: "数字人合成时间不能大于数字人合成总时长！",
              type: "warning",
            });
            this.submitBool = false;
            return;
          }
          const params = {
            id: this.form.id,
            maxLiveTime: this.form.maxLiveTime,
            maxNum: this.form.maxNum,
            maxSpaceSize: this.form.maxSpaceSize,
            maxTime: this.form.maxTime,
          };
          allocationTeam(params).then((res) => {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.submitBool = false;
            this.outerVisible = false;
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
    handlePageChange(currentPage) {
      this.tablePage.current = currentPage;
      this.getList();
      // 在此刷新数据
    },
    handleSizeChange(pageSize) {
      this.tablePage.size = pageSize;
      // 在此刷新数据
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background: linear-gradient(to bottom, #f2ecff, #ffffff);
}
#current-box {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  .top-box {
    margin-left: 20px;
    height: 50px;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    border-radius: 20px;
  }
  .main-box {
    // background-color:pink;
    width: 100%;
    // height: 90%;
    height: calc(100vh - 210px);
    margin-top: 30px;
    // overflow-y: scroll;
    ::v-deep .el-table th {
      background-color: #fafafa !important;
    }
    .pagination {
      float: right;
      margin-right: 5%;
      margin-top: 1.2%;
    }
  }
}
</style>
