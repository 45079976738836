<template>
  <div class="App">
    <div class="mask" v-if="isWeixin">
      <div class="left">
        <div class="left-one">
          <div class="num">1</div>
          <div class="text">点击右上角</div>
          <div class="dot">
            <img src="@/assets/imgs/app-dot.png" alt="" />
          </div>
        </div>
        <div class="left-two">
          <div class="num">2</div>
          <div class="text">选择“<span style="color: #ff4444">在浏览器打开</span>”</div>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/imgs/jiantou.png" alt="" />
      </div>
    </div>
    <div class="logo">
      <div class="image">
        <img style="width: 100%; height: 100%" src="@/assets/imgs/icon.png" alt="" />
      </div>
    </div>
    <div class="title">Hello Nvwa 智播</div>
    <div class="text-box">
      <div class="item1">数字人</div>
      <div class="item2">短视频</div>
      <div class="item3">虚拟直播</div>
      <div class="item4">虚拟场景</div>
    </div>
    <div class="details">详情</div>
    <div class="bar"></div>
    <div class="line">
      <div class="box"></div>
    </div>
    <div class="lunbotu">
      <div class="item"><img src="@/assets/imgs/app-1.png" alt="" /></div>
      <div class="item"><img src="@/assets/imgs/app-2.png" alt="" /></div>
      <div class="item"><img src="@/assets/imgs/app-3.png" alt="" /></div>
      <div class="item"><img src="@/assets/imgs/app-4.png" alt="" /></div>
      <div class="item"><img src="@/assets/imgs/app-5.png" alt="" /></div>
    </div>
    <div class="text-item">
      <div class="banben">版本：{{ version }}</div>
      <div class="beian">备案号：湘ICP备2023009258号-3A</div>
    </div>
    <div class="btn-box">
      <div class="btn-app" @click="download">
        <div class="icon">
          <img style="width:20px;height:20px" src="@/assets/imgs/android2.png" alt="" />
        </div>
        <div class="btn-text">立即下载</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDownload, addDownload } from "@/api/login";

export default {
  name: "App",
  components: {},
  data() {
    return {
      version: "",
      packetUrl: "",
      isWeixin: false,
    };
  },
  computed: {},
  created() {
    this.isWeixin = (function () {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    })();
  },
  mounted() {
    this.downloadApp();

  },
  methods: {
    async downloadApp() {
      const res = await getDownload(2);
      console.log(res.data.data.packetUrl, "111111111111111111111111111111111");
      this.version = res.data.data.version;
      this.packetUrl = res.data.data.packetUrl;
    },
    download() {
      window.open(this.packetUrl);
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}
.App {
  .mask {
    position: fixed;
    top: 0px;
    // background: #000000;
    background: rgba(0, 0, 0, 0.7);
    // opacity: 0.7;
    z-index: 9px;
    width: 100%;
    height: 100%;
    display: flex;
    .left {
      position: relative;
      left: 0px;
      top: 59px;
      margin-left: 73px;
      width: 220px;
      height: 100px;
      opacity: 1;
      .left-one {
        display: flex;
        align-items: center;
        .num {
          width: 22px;
          height: 22px;
          background-color: #8b5ef5;
          border-radius: 100px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          text-align: center;
        }
        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          margin-left: 5px;
        }
        .dot {
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            opacity: 1;
            z-index: 10px;
          }
        }
      }
      .left-two {
        opacity: 1;
        display: flex;
        align-items: center;
        .num {
          width: 22px;
          height: 22px;
          background-color: #8b5ef5;
          border-radius: 100px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          text-align: center;
        }
        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          margin-left: 5px;
        }
      }
    }
    .right {
      position: relative;
      right: 0px;
    }
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .image {
      width: 120px;
      height: 120px;
    }
  }
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #444444;
    text-align: center;
    margin-top: 10px;
  }
  .text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    .item1 {
      width: 44px;
      height: 20px;
      background: #e3f6f0;
      border-radius: 2px 2px 2px 2px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      line-height: 18px;
      font-size: 12px;
      color: #35a87f;
      text-align: center;
    }
    .item2 {
      width: 44px;
      height: 20px;
      background: #e3f6f0;
      border-radius: 2px 2px 2px 2px;
      margin: 0 5px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      line-height: 18px;
      font-size: 12px;
      color: #35a87f;
      text-align: center;
    }
    .item3 {
      width: 56px;
      height: 20px;
      background: #e3f6f0;
      border-radius: 2px 2px 2px 2px;
      margin-right: 5px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      line-height: 18px;
      font-size: 12px;
      color: #35a87f;
      text-align: center;
    }
    .item4 {
      width: 56px;
      height: 20px;
      background: #e3f6f0;
      border-radius: 2px 2px 2px 2px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      line-height: 18px;
      font-size: 12px;
      color: #35a87f;
      text-align: center;
    }
  }
  .details {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #505052;
    margin-top: 25px;
    margin-left: 30px;
  }
  .bar {
    width: 16px;
    height: 2px;
    background: #8b5ef5;
    border-radius: 20px 20px 20px 20px;
    margin-left: 36px;
    margin-top: 3px;
  }
  .line {
    width: 100%;
    height: 1px;
    .box {
      margin: 0 20px;
      margin-top: 4px;
      border: 1px solid #f9f9f9;
    }
  }
  .lunbotu {
    margin-top: 15px;
    width: 100%;
    height: 235px;
    padding-left: 20px;
    display: flex;
    overflow: auto;
    .item {
      width: 130px;
      height: 230px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      margin-right: 12px;
      flex-shrink: 0;
      img {
        width: 130px;
        height: 230px;
        border-radius: 8px;
      }
    }
  }
  .text-item {
    margin-top: 8px;
    margin-left: 20px;
    .banben {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #8a8a8a;
      line-height: 18px;
      text-align: left;
    }
    .beian {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #8a8a8a;
      line-height: 18px;
      text-align: left;
      line-height: 28px;
    }
  }
  .btn-box {
    margin-top: 73px;
    width: 100%;
    height: 50px;
    .btn-app {
      margin: 0 20px;
      height: 50px;
      background: linear-gradient(108deg, #8b5ef5 0%, #6c2dff 100%);
      box-shadow: 0px 0px 6px 1px rgba(15, 12, 84, 0.2);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-text {
        margin-left: 5px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 300;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}
</style>
